import { useEffect, useRef, useState } from "react"
import { connect } from "react-redux";
import {
  getFullSearch,
  changeFullSearchInputVal,
  changeFullSearchState,
} from "../../redux/Search/Search.Actions";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./FullSearch.css";
import { ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import { getTeamOrPlayerService } from "../../globalFn";

const FullSearch = ({
  FullSearchQuery,
  FullSearch,
  getFullSearch,
  changeFullSearchInputVal,
  changeFullSearchState,
  FullSearchsearching,
  isFetchingFullSearch,
  currentLanguageCode,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(true)

  const handleRowClick = async (sportId, type, id) => {
    const serviceId = await getTeamOrPlayerService(id, type)
    history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/${type}/${id}`, { previousPathname: history.location.pathname });
    setIsMenuOpen(!isMenuOpen)
  };

  const creatSearchCat = (arr) => {

    const catObj = {
      players: [],
      teams: [],
      tournaments: [],
    };
    arr.map((el) => {
      if (el.type === "player") {
        catObj.players.push(el);
      } else if (el.type === "team") {
        catObj.teams.push(el);
      } else if (el.type === "tournament") {
        catObj.tournaments.push(el);
      }
    });

    return catObj;
  };
  const ref = useRef()

  // useEffect(() => {
  //   const checkIfClickedOutside = e => {
  //     if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
  //       setIsMenuOpen(false)
  //     }
  //   }
  //   document.addEventListener("click", checkIfClickedOutside)
  //   return () => {
  //     document.removeEventListener("click", checkIfClickedOutside)
  //   }
  // }, [isMenuOpen])
  return (
    <div className="d-flex full-search">
      <input
        //  ref={ref}
        className="form-control me-1 full-search-input "
        type="search"
        placeholder="ابحث هنا......"
        aria-label="Search"
        value={FullSearchQuery}
        onChange={(e) => {
          changeFullSearchInputVal(e.target?.value?.match(/[A-Za-z ]*[ء-ي]*/g)?.join(""));
          // setIsMenuOpen(oldState => !oldState)
          if (!FullSearchsearching) {
            changeFullSearchState();
            setIsMenuOpen(true);
            setTimeout(() => {
              setIsMenuOpen(true);
              changeFullSearchState();
              getFullSearch(e.target?.value?.match(/[A-Za-z ]*[ء-ي]*/g)?.join(""), currentLanguageCode);
            }, 1000);
          }

          // getFullSearch(e.target.value,'ar',abortController.signal)
          // abortController.abort()
        }}
      />
      {isMenuOpen &&
        <ul
          className={`${FullSearchQuery === "" ? "d-none" : ""
            } full-search-menu text-light`}
        >
          {isFetchingFullSearch ? (
            <div className="text-center mt-5">
              <div className="spinner-border text-secondary fs-xs p-0" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : FullSearch.length === 0 ? (
            <li className="text-center p-5">لا يوجد نتائج</li>
          ) : (
            Object.keys(creatSearchCat(FullSearch)).map((key) => (
              <li>
                <h5
                  className={`px-3 py-2 ${creatSearchCat(FullSearch)[key].length === 0 ? "d-none" : ""
                    } `}
                >
                  {t(`Search.${key}`)}
                </h5>
                {creatSearchCat(FullSearch)[key].map((el) => (
                  <li
                    className="dropdown-item d-flex justify-content-between p-2 table-clickable-row text-light"
                    key={el.id * Math.random()}
                    onClick={() => handleRowClick(el.sport_id, el.type, el.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <div>{el.name}</div>
                    <img
                      src={ImgURL(el.image)}
                      width="30px"
                      height="35px"
                      alt=""
                    />
                  </li>
                ))}
              </li>
            ))
          )}
        </ul>
      }
      {/* <i className="fa fa-search fs-lg  text-light"></i> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  FullSearchQuery: state.search.FullSearchQuery,
  FullSearch: state.search.FullSearch,
  isFetchingFullSearch: state.search.isFetchingFullSearch,
  FullSearchFailed: state.search.FullSearchFailed,
  abortController: state.search.abortController,
  FullSearchsearching: state.search.FullSearchSearching,
  currentLanguageCode: state.language.currentLanguageCode,
});

const mapDispatchToProps = (dispatch) => ({
  getFullSearch: (query, lang) => dispatch(getFullSearch(query, lang)),
  changeFullSearchInputVal: (query) =>
    dispatch(changeFullSearchInputVal(query)),
  changeFullSearchState: () => dispatch(changeFullSearchState()),
});
export default connect(mapStateToProps, mapDispatchToProps)(FullSearch);
