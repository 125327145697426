import TournamentTeamsTypes from "./TournamentTeams.Types";


const INITIAL_STATE = {
    TournamentTeams:[''],
    isFetchingTournamentTeams:true,
    TournamentTeamsFailed:null
   };
   
   const TournamentTeams = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case TournamentTeamsTypes.GET_TOURNAMENT_TEAMS_START:
        return {
          ...state,
          isFetchingTournamentTeams:true
        }
      case TournamentTeamsTypes.GET_TOURNAMENT_TEAMS_SUCCESS:
        return {
          ...state,
          TournamentTeams:action.payload,
          isFetchingTournamentTeams:false,
          TournamentTeamsFailed:null
        }
      case TournamentTeamsTypes.GET_TOURNAMENT_TEAMS_FAILED:
        return {
          ...state,
          TournamentTeamsFailed:action.payload,
          isFetchingTournamentTeams:false
        }
       default:
         return state;
     }
   };
 
   export default TournamentTeams