import TournamentHistoryTypes from "./TournamentHistory.Types";
import axios from 'axios'
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_TOURNAMENT_HISTORY } = process.env

const getTournamentHistoryStart = () => ({
  type: TournamentHistoryTypes.GET_TOURNAMENT_HISTORY_START
})

const getTournamentHistorySuccess = (info) => ({
  type: TournamentHistoryTypes.GET_TOURNAMENT_HISTORY_SUCCESS,
  payload: info
})

const getTournamentHistoryFailed = (info) => ({
  type: TournamentHistoryTypes.GET_TOURNAMENT_HISTORY_FAILED,
  payload: info
})


const getTournamentHistory = (tournament_id, lang) => (
  dispatch => {
    dispatch(getTournamentHistoryStart())
    axios({
      method: "get",
      url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_TOURNAMENT_HISTORY}${`/${tournament_id}`}`,
      // params: { match_id:42312 },
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`
      },
    }).then(resp => {
      if (resp.data.status === 200) {
        dispatch(getTournamentHistorySuccess(resp.data.data))
      }
      else {
        dispatch(getTournamentHistoryFailed(resp.data.message))
      }
    }
    )
      .catch(error => dispatch(getTournamentHistoryFailed(error)))

  }
)

export { getTournamentHistory }