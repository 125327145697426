import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useServiceAndSport from '../../hooks/useServiceAndSport';
import TwistSettings from '../../TwistSettings.json'

const redirectSubscriptionLink = (serviceName, pathname) => {
    return <Link
        className="fs-md btn btn-link fs-sm active-color text-decoration-underline"
        data-testid="require-subscription-link"
        to={{
            pathname: `/${serviceName}/subscribe`,
            state: {
                previousPathname: pathname
            }
        }}
    >
        {
            TwistSettings['subscription']
            ? "لمشاهدة المزيد يرجي تسجيل الدخول"
            : "لمشاهدة المزيد ادخال رقم التليفون فقط مجاناً"
        }
        
    </Link>
}

function RequireSubscription({ children, isSubscribed }) {
    const [fristLineOfMediaDetailsDescription, setFristLineOfMediaDetailsDescription] = useState("");
    const [mediaDetailsDescriptionLines, setMediaDetailsDescriptionLines] = useState("");
    const { pathname } = useLocation()
    const { serviceName } = useServiceAndSport();

    useEffect(() => {
        if (typeof children !== 'string') return;
        let descriptionArray, firstDescriptionLine, allDescriptionLines = "";
        if (children.includes("</p>")) {
            descriptionArray = children.split("</p>");
            firstDescriptionLine = `${descriptionArray[0]}</p>`;
            descriptionArray.map(ele => allDescriptionLines += `${ele}</p>`);
        } else {
            descriptionArray = children.split("\r\n\r\n");
            firstDescriptionLine = `<p>${descriptionArray[0]}</p>`;
            descriptionArray.map(ele => allDescriptionLines += `<p>${ele}</p>`);
        }
        setFristLineOfMediaDetailsDescription(firstDescriptionLine);
        setMediaDetailsDescriptionLines(allDescriptionLines);
    }, [isSubscribed]);


    if (isSubscribed) {
        if (typeof children !== 'string') return children;
        return (
            <div
                className="fs-md white-color mt-3 "
                dangerouslySetInnerHTML={{ __html: mediaDetailsDescriptionLines }}
            />
        )
    } else {
        if (typeof children !== 'string') return redirectSubscriptionLink(serviceName, pathname);
        return (
            <>
                <div
                    className="fs-md white-color mt-3"
                    dangerouslySetInnerHTML={{ __html: fristLineOfMediaDetailsDescription }}
                />
                {redirectSubscriptionLink(serviceName, pathname)}
            </>
        )
    }
}

export default RequireSubscription