import SearchTypes from "./Search.Types";



const INITIAL_STATE = {
    FullSearchQuery:'',
    FullSearchSearching:false,
    FullSearch:[],
    abortController:new AbortController(),
    isFetchingFullSearch:false,
    FullSearchFailed:null
   };
   
   const Search = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case SearchTypes.CHANGE_FULL_SEARCH_VALUE:
        return {
          ...state,
          FullSearchQuery:action.payload,
        }
      case SearchTypes.CHANGE_FULL_SEARCH_STATE:
        return {
          ...state,
          FullSearchSearching:!state.FullSearchSearching
        }   
      case SearchTypes.GET_FULL_SEARCH_START:
        return {
          ...state,
          isFetchingFullSearch:true,
          FullSearch:[],
          FullSearchQuery:action.payload,
          abortController:new AbortController(),

        }
      case SearchTypes.GET_FULL_SEARCH_SUCCESS:
        return {
          ...state,
          FullSearch:action.payload,
          isFetchingFullSearch:false,
          FullSearchFailed:null
        }
      case SearchTypes.GET_FULL_SEARCH_FAILED:
        return {
          ...state,
          FullSearchFailed:action.payload,
          isFetchingFullSearch:false
        }
       default:
         return state;
     }
   };
 
   export default Search