import TournamentStatisticsTypes from "./TournamentStatistics.Types";



const INITIAL_STATE = {
    TournamentStatistics:{},
    isFetchingTournamentStatistics:true,
    TournamentStatisticsFailed:null
   };
   
   const TournamentStatistics = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case TournamentStatisticsTypes.GET_TOURNAMENT_STATISTICS_START:
        return {
          ...state,
          isFetchingTournamentStatistics:true
        }
      case TournamentStatisticsTypes.GET_TOURNAMENT_STATISTICS_SUCCESS:
        return {
          ...state,
          TournamentStatistics:action.payload,
          isFetchingTournamentStatistics:false,
          TournamentStatisticsFailed:null
        }
      case TournamentStatisticsTypes.GET_TOURNAMENT_STATISTICS_FAILED:
        return {
          ...state,
          TournamentStatisticsFailed:action.payload,
          isFetchingTournamentStatistics:false
        }
       default:
         return state;
     }
   };
 
   export default TournamentStatistics