import GeneralStatisticsTypes from "./GeneralStatistics.Types";
import { setDisplayed } from "./GeneralStatistics.utils";


const INITIAL_STATE = {
  GeneralStatistics: {},
  isFetchingGeneralStatistics: true,
  currentlyDisplayed: [],
  currentPage: 1,
  GeneralStatisticsFailed: null
};

const GeneralStatistics = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GeneralStatisticsTypes.GET_GENERAL_STATISTICS_START:
      return {
        ...state,
        isFetchingGeneralStatistics: true
      }
    case GeneralStatisticsTypes.GET_GENERAL_STATISTICS_SUCCESS:
      return {
        ...state,
        GeneralStatistics: action.payload.data,
        tournamentsIds: action.payload.tournament,
        currentlyDisplayed: setDisplayed(action.payload.data, state.currentPage),
        isFetchingGeneralStatistics: false,
        GeneralStatisticsFailed: null,
        currentPage: 1
      }
    case GeneralStatisticsTypes.SET_CURRENTLY_DISPLAYED_GENERAL_STANDING:
      return {
        ...state,
        currentPage: state.currentPage + action.payload,
        currentlyDisplayed: setDisplayed(state.GeneralStatistics, state.currentPage + action.payload)
      }
    case GeneralStatisticsTypes.GET_GENERAL_STATISTICS_FAILED:
      return {
        ...state,
        GeneralStatisticsFailed: action.payload,
        isFetchingGeneralStatistics: false
      }
    default:
      return state;
  }
};

export default GeneralStatistics