import SearchTypes from "./Search.Types";
import axios from 'axios'
import { globalBaseUrl } from "../../globalData";




const { REACT_APP_NEW_GET_FULL_SEARCH } = process.env

const changeFullSearchInputVal = (query) => ({
  type: SearchTypes.CHANGE_FULL_SEARCH_VALUE,
  payload: query,
})

const changeFullSearchState = () => ({
  type: SearchTypes.CHANGE_FULL_SEARCH_STATE,
})

const getFullSearchStart = (info) => ({
  type: SearchTypes.GET_FULL_SEARCH_START,
  payload: info
})

const getFullSearchSuccess = (info) => ({
  type: SearchTypes.GET_FULL_SEARCH_SUCCESS,
  payload: info
})

const getFullSearchFailed = (error) => ({
  type: SearchTypes.GET_FULL_SEARCH_FAILED,
  payload: error
})




const getFullSearch = (query, lang, acSignal) => (
  dispatch => {
    dispatch(getFullSearchStart(query))
    axios({
      method: "get",
      signal: acSignal,
      url: `${globalBaseUrl.current}/full-search`,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`
      },
      params: {
        keyword: query?.match(/[A-Za-z]*[ء-ي]*/g)?.join(" ")
      }
    }).then(resp => {
      if (resp.data.status === 200) {
        dispatch(getFullSearchSuccess(resp.data.data))
      }
      else {
        dispatch(getFullSearchFailed(resp.data.message))
      }
    }
    )
      .catch(error => dispatch(getFullSearchFailed(error)))

  }
)

export { getFullSearch, changeFullSearchInputVal, changeFullSearchState }