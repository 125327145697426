const eventsWithRound = [1, 2, 3, 4, 5, 6, 7, 8, 10, 11];
const eventsWithGoal = [20, 21, 24];
const OwnGoal = 21;

const removeDuplicates = (events, event) => {
    const eventIndex = events.findIndex(e => e.id === event.id)
    const eventsWithoutEvent = events.filter(e => e.id !== event.id)
    eventsWithoutEvent.splice(eventIndex, 0, event)
    return eventsWithoutEvent;
}

const arrangebySort = (state,event) => {
    const newStateArr=state.map(el => el)
    const eventSortArr=state.map(el => el.event_sort)
    if(eventSortArr.includes(event.event_sort)){
        const newEventArr=[]
        for(let i=0 ; i < state.length ;i++){
            if(newStateArr[i].event_sort===event.event_sort){
             newEventArr.push(event) 
             state.slice(i).forEach(el =>  {newEventArr.push({...el,event_sort:el.event_sort+1})})
             return removeDuplicates(newEventArr, event)
            }
            else {
                newEventArr.push(newStateArr[i])
            } 
        };
        return removeDuplicates(newEventArr, event)
    }
    else {
        return removeDuplicates([...state,event], event)
    }
}

const deleteScoreCounter = (matchEvents, team_id) => {
    const teamGoals = matchEvents.filter(event => event.team_id===team_id && eventsWithGoal.includes(event.event_id))
    return teamGoals.length
}

export { arrangebySort , deleteScoreCounter, eventsWithGoal, eventsWithRound, OwnGoal} 

