import React, { useState } from 'react'
import { useLocation } from "react-router-dom";
import { sha256 } from 'js-sha256';
import paymentSuccessImg from "../../../assets/img/subscription/PaymentSucess.png"
import paymentFailedImg from "../../../assets/img/subscription/PaymentFailed.png"

export default function AfterFawryPayment() {
    const { search: queryString } = useLocation()

    function queryStringToJSON(queryString) {

        queryString = queryString.slice(1);
        let pairs = queryString.split('&');
        let result = {};

        pairs.forEach((p) => {
            let pair = p.split('=');
            let key = pair[0];
            let value = decodeURIComponent(pair[1] || '');

            if (result[key]) {
                if (Object.prototype.toString.call(result[key]) === '[object Array]') {
                    result[key].push(value);
                } else {
                    result[key] = [result[key], value];
                }
            } else {
                result[key] = value;
            }
        });

        return JSON.parse(JSON.stringify(result));
    };

    const paramsObj = queryStringToJSON(queryString)
    console.log(paramsObj)


    function concatRequirdParams(paramsObj) {
        console.log("referenceNumber", paramsObj.referenceNumber)
        console.log("merchantRefNumber", paramsObj.merchantRefNumber)
        console.log("paymentAmount", `${Number.parseFloat(paramsObj.paymentAmount).toFixed(2)}`)
        console.log("orderAmount", `${Number.parseFloat(paramsObj.orderAmount).toFixed(2)}`)
        console.log("orderStatus", paramsObj.orderStatus)
        console.log("paymentMethod", paramsObj.paymentMethod)
        // console.log("fawryFees", `${Number.parseFloat(paramsObj.fawryFees).toFixed(2)}`)
        console.log("security key", process.env.REACT_APP_FAWRY_SECURITY_KEY)

        let concatinatedResult = paramsObj.referenceNumber
            + paramsObj.merchantRefNumber
            + Number.parseFloat(paramsObj.paymentAmount).toFixed(2)
            + Number.parseFloat(paramsObj.orderAmount).toFixed(2)
            + paramsObj.orderStatus
            + paramsObj.paymentMethod
            // + `${Number.parseFloat(paramsObj.fawryFees).toFixed(2)}`
            + process.env.REACT_APP_FAWRY_SECURITY_KEY

        return concatinatedResult
    }

    const concatinatedParams = concatRequirdParams(paramsObj)

    const calculatedSignature = sha256(concatinatedParams)

    console.log("incoming sha", paramsObj.signature)
    console.log("calculated sha", calculatedSignature)

    return (
        <div className='text-white d-flex align-items-center justify-content-center'>
            {paramsObj.statusCode === "200"
                ? (
                    <div className="d-flex flex-column align-items-center text-center">
                        <img src={paymentSuccessImg}
                            className='w-25 py-2'
                            alt="success payment green mark"
                        />
                        <h3>Payment Done Successfully </h3>
                    </div>
                )

                : (
                    <div className="d-flex flex-column align-items-center text-center">
                        <img src={paymentFailedImg}
                            className='w-25 py-2'
                            alt="failed payment red mark"
                        />
                        <h3 className='text-danger'>Payment Failed </h3>
                    </div>
                )
            }
        </div>
    )
}