import TeamStatisticsTypes from "./TeamStatistics.Types";
import axios from 'axios'
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_TEAM_STATISTICS } = process.env

const getTeamStatisticsStart = () => ({
  type: TeamStatisticsTypes.GET_TEAM_STATISTICS_START
})

const getTeamStatisticsSuccess = (info) => ({
  type: TeamStatisticsTypes.GET_TEAM_STATISTICS_SUCCESS,
  payload: info
})

const getTeamStatisticsFailed = (info) => ({
  type: TeamStatisticsTypes.GET_TEAM_STATISTICS_FAILED,
  payload: info
})


const getTeamStatistics = (sport_id, season_id, tournament_id, team_id, lang) => (
  dispatch => {
    dispatch(getTeamStatisticsStart())
    axios({
      method: "get",
      url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_TEAM_STATISTICS}`,
      params: { sport_id, season_id, tournament_id, team_id },
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`
      },
    }).then(resp => {
      if (resp.data.status === 200) {
        dispatch(getTeamStatisticsSuccess(resp.data.data))
      }
      else {
        dispatch(getTeamStatisticsFailed(resp.data.message))
      }
    }
    )
      .catch(error => dispatch(getTeamStatisticsFailed(error)))

  }
)

export { getTeamStatistics }