import SquashTypes from "./Squash.Types";
import api from '../../apis'


const squashStart = () => ({
    type:SquashTypes.GET_SQUASH_START
})

const squashSuccess = (info) => ({
    type:SquashTypes.GET_SQUASH_SUCCESS,
    payload:info
})

const squashFailed = () => ({
    type:SquashTypes.GET_SQUASH_FAILED
})


const getSquash = ( sport_id, service_id) => dispatch => {
  dispatch(squashStart())
  api.squash.getTournaments()

    .then(res => {   
      if (res.code===200) { 
        dispatch(squashSuccess(res.data))
      } else {
        dispatch(squashFailed(res.message))
      }
    })
    .catch(error => dispatch(squashFailed(error)))
}

export { getSquash }