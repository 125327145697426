import TeamPlayersTypes from "./TeamPlayers.Types";



const INITIAL_STATE = {
    TeamPlayers:[''],
    isFetchingTeamPlayers:true,
    TeamPlayersFailed:null
   };
   
   const TeamPlayers = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case TeamPlayersTypes.GET_TEAM_PLAYERS_START:
        return {
          ...state,
          isFetchingTeamPlayers:true
        }
      case TeamPlayersTypes.GET_TEAM_PLAYERS_SUCCESS:
        return {
          ...state,
          TeamPlayers:action.payload,
          isFetchingTeamPlayers:false,
          TeamPlayersFailed:null
        }
      case TeamPlayersTypes.GET_TEAM_PLAYERS_FAILED:
        return {
          ...state,
          TeamPlayersFailed:action.payload,
          isFetchingTeamPlayers:false
        }
       default:
         return state;
     }
   };
 
   export default TeamPlayers