import React from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MediaList from '../../components/News/MediaList';
import SportsSectionHeader from '../../components/sportsSectionHeader/sportsSectionHeader.component'
import api from '../../apis'
import { useState } from 'react';
import { IDS } from '../../globalData';
import { Container } from 'react-bootstrap';

const SectionMediaList = ({sectionName, subSectionName}) => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState({current_page: 1})

  const getByPage = (page) => {
    setLoading(true)
    api.sections.getSectionVideos({
      section_id: IDS.SECTIONS[sectionName?.toUpperCase()?.replaceAll('-', '')],
      page,
    })
      .then(res => {
        if (res.meta.current_page === 1) {
          setList(res.data)
        } else {
          setList([...list, ...res.data])
        }
        setMeta(res.meta)
        setLoading(false)
      })
  }

  useEffect(() => {
    getByPage(1);
  }, [sectionName, subSectionName])

  return <Container>
    <SportsSectionHeader title={t(`Sections.${sectionName}`)} dataTestId={`${sectionName}-title`} />
    <MediaList
      AllMedia={list}
      getByPage={getByPage}
      current_page={meta.current_page}
      last_page={meta.last_page}
      initialLoading={loading && meta.current_page === 1}
      paginationLoading={loading && list.length > 0}
    />
  </Container>
}

export default SectionMediaList