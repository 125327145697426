import React, { useState } from "react";
import ScrollToTop from "./ScrollToTop";
import MobileSideMenu from "./MobileSideMenu";
import HeaderNav from "./HeaderNav";
import SideMenu from "./SideMenu";
import TeamsCarousel from "./TeamsCarousel";
import FooterComponent from "./Footer";

function Layout({ children }) {
  const [showMobileSideMenu, setShowMobileSideMenu] = useState(false);
  return (
    <>
      <ScrollToTop />
      <MobileSideMenu
        showMobileSideMenu={showMobileSideMenu}
        setShowMobileSideMenu={setShowMobileSideMenu}
      />
      <HeaderNav />
      <TeamsCarousel />
      <SideMenu showMobileSideMenu={!showMobileSideMenu} />
      <div>{children}</div>
      <FooterComponent />
    </>
  );
}

export default Layout;
