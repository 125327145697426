import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import getServiceAndSport from '../helper';
import api from '../../apis';
import { getLoggingsParams } from './helper';

export default function useLogging(to_url) {
  const { location: { pathname, state } } = useHistory()
  const { serviceId: service_id } = getServiceAndSport(pathname)
  const {
    ip_address,
    msisdn,
    section_id,
    user_session,
    source,
  } = getLoggingsParams()

  useEffect(() => {
    if (ip_address !== null) {
      api.reports.sendLog({
        from_url: state?.previousPathname,
        ip_address,
        msisdn,
        section_id,
        service_id,
        user_session,
        source,
        to_url: to_url ?? pathname,
      })
    }
  }, [ip_address, source, service_id, pathname, section_id]);

  return null;
}