import React, { useState } from 'react'
import { useLocation } from "react-router-dom";
import { sha512 } from 'js-sha512';
import { useEffect } from 'react';
import api from '../../../apis';
import LoadingSpinnner from '../../loadingSpinner/loadingSpinner';
import paymentSuccessImg from "../../../assets/img/subscription/PaymentSucess.png"
import paymentFailedImg from "../../../assets/img/subscription/PaymentFailed.png"


export default function AfterVisaPayment() {

    const [isHmacValid, setIsHmacValid] = useState(false)
    const [isTransactionSuccessful, setIsTransactionSuccessful] = useState(false)
    const [loading, setLoading] = useState(true)
    const { search: queryString } = useLocation()

    const requiredParamsForHmac = [
        "amount_cents",
        "created_at",
        "currency",
        "error_occured",
        "has_parent_transaction",
        "id",
        "integration_id",
        "is_3d_secure",
        "is_auth",
        "is_capture",
        "is_refunded",
        "is_standalone_payment",
        "is_voided",
        "order",
        "owner",
        "pending",
        "source_data.pan",
        "source_data.sub_type",
        "source_data.type",
        "success"
    ]

    function queryStringToJSON(queryString) {

        queryString = queryString.slice(1);
        let pairs = queryString.split('&');
        let result = {};

        pairs.forEach((p) => {
            let pair = p.split('=');
            let key = pair[0];
            let value = decodeURIComponent(pair[1] || '');

            if (result[key]) {
                if (Object.prototype.toString.call(result[key]) === '[object Array]') {
                    result[key].push(value);
                } else {
                    result[key] = [result[key], value];
                }
            } else {
                result[key] = value;
            }
        });

        return JSON.parse(JSON.stringify(result));
    };

    const paramsObj = queryStringToJSON(queryString)
    const incomingHMAC = paramsObj.hmac
    const transationId = paramsObj.id

    function concatRequirdParams(paramsObj, requiredParams) {

        let concatinatedResult = ""

        requiredParams.forEach((param) => {
            concatinatedResult += paramsObj[param]
        })

        return concatinatedResult
    }

    const concatinatedParams = concatRequirdParams(paramsObj, requiredParamsForHmac)


    const personalHMAC = "07C7A9665B07ABF9C4C3327FCB7D0A14"
    const resultHMAC = sha512.hmac(personalHMAC, concatinatedParams)

    // console.log(incomingHMAC)
    // console.log(resultHMAC)

    if (incomingHMAC === resultHMAC && !isHmacValid) {
        setIsHmacValid(true)
    }

    useEffect(() => {

        if (isHmacValid) {

            api.paymob.authenticationRequest({ api_key: "ZXlKMGVYQWlPaUpLVjFRaUxDSmhiR2NpT2lKSVV6VXhNaUo5LmV5SndjbTltYVd4bFgzQnJJam8yTmpBd09EY3NJbTVoYldVaU9pSnBibWwwYVdGc0lpd2lZMnhoYzNNaU9pSk5aWEpqYUdGdWRDSjkuZkh0UkYtTk9NY3ZzQkxzdW13UTNCS1h5V1lndFA3SFJFdi1wR0JPWFJ3RFpacUJncUk0UGxkUmotS3I2VG5OT1h3aFZUbkdUR1lwUm9wc0ljZl8tVWc=" })
                .then((res) => {
                    if (res.token) {
                        api.paymob.transactionDetails(transationId, res.token)
                            .then(res => {
                                setIsTransactionSuccessful(res.success)
                                setLoading(false);
                                res.success && console.log(res.billing_data.phone_number)
                            })
                    } else {
                        setLoading(false);
                    }
                })
        }

    }, [isHmacValid])

    return (
        <div className='text-white d-flex align-items-center justify-content-center'>
            {loading
                ? <LoadingSpinnner p={2} fs={"sm"} />
                : isTransactionSuccessful
                    ? (
                        <div className="d-flex flex-column align-items-center text-center">
                            <img src={paymentSuccessImg}
                                className='w-25 py-2'
                                alt="success payment green mark"
                            />
                            <h3>Payment Done Successfully</h3>
                        </div>
                    )

                    : (
                        <div className="d-flex flex-column align-items-center text-center">
                            <img src={paymentFailedImg}
                                className='w-25 py-2'
                                alt="failed payment red mark"
                            />
                            <h3 className='text-danger'>Payment Failed</h3>
                        </div>
                    )
            }
        </div>
    )
}