import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import { connect } from 'react-redux';
import { ImgURL } from "../../globalData";
import { getMatchStatistics } from "../../redux/Match/Actions.Match";

function MatchStatistics({ matchId, getMatchStatistics, matchStatisticsFailure, team1Info, team2Info, isFetchingMatchStatistics }) {

  useEffect(() => {
    getMatchStatistics(matchId)
  }, [])

  const { t } = useTranslation()

  const statisticsKeys = {
    shots: t(`Match.Shots`),
    target_shots: t(`Match.TargetShots`),
    pass: t(`Match.Passes`),
    pass_accuracy: t(`Match.PassAccuracy`),
    fouls: t(`Match.Fouls`),
    offsides: t(`Match.Offside`),
    corners: t(`Match.Corners`),
    red_cards: "كروت حمراء",
    yellow_cards: "كروت صفراء",
    score: t(`StatsTable.Goals`),
    penalty_score: "اهداف الجزاء",
    possession: t(`Match.Possesion`)
  }
  const statistics = Object.keys(team1Info?.statistics[0] ?? {})?.filter(key => key !== "shots" || key !== "penalty_score")

  return (
    matchStatisticsFailure !== null ?
      <div>لا يوجد احصائيات</div>
      :
      isFetchingMatchStatistics ?
        <div>...loading</div>
        :
        <div className="container">
          <div className='et-shadow p-4 fs-md'>
            {/* <div className='text-center pb-3'>إحصائيات المباراة</div> */}
            <Table className='table-borderless bg-black-color table-striped table-dark white-color'>
              <tbody>
                <tr className='d-table-row align-items-center justify-content-center'>
                  <td className='d-table-cell text-center'>
                    <img src={ImgURL(team1Info.logo)} style={{ width: '35px' }} alt=""></img>
                    <span className='text-center mx-2'>{team1Info.score}</span>
                  </td>
                  <td className='text-center pt-3'>الأهداف</td>
                  <td className='d-table-cell text-center'>
                    <span className='text-center mx-2'>{team2Info.score}</span>
                    <img src={ImgURL(team2Info.logo)} style={{ width: '35px' }} alt=""></img>
                  </td>
                </tr>
                {
                  statistics.map((key, i) => {
                    return key !== 'pivot' &&
                      <tr key={key} className='align-items-center justify-content-between'>
                        <td className='text-center'>{team1Info.statistics[0][key]}</td>
                        <td className='text-center pt-3'>{statisticsKeys[key]}</td>
                        <td className='text-center'>{team2Info.statistics[0][key]}</td>
                      </tr>

                  })
                }

              </tbody>
            </Table>
          </div>
        </div>
  );
}

const mapStateToProps = state => ({
  isFetchingMatchStatistics: state.matchInfo.isFetchingMatchStatistics,
  team1Info: state.matchInfo.matchStatistics.team1,
  team2Info: state.matchInfo.matchStatistics.team2,
  matchStatisticsFailure: state.matchInfo.matchStatisticsFailure
})

const mapDispatchToProps = dispatch => ({
  getMatchStatistics: (id) => dispatch(getMatchStatistics(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(MatchStatistics)