import React from "react";
import { Container } from "reactstrap";
import NewsLoading from "./NewsLoader";
import { connect } from 'react-redux';
import { getMedisList } from "../../redux/MediaList/MediaList.Action";
import NewsCarousel from "./NewsCarousel";
import './newsSection.css'
import { useTranslation } from "react-i18next"
import { useEffect } from "react";
import MediaCard from "./MediaCard";
import ErrorMsg from "../ErrorPage/ErrorMsg";


function TopStories({ isFetchingTopStories, TopStories, TopStoriesFailed, currentLanguageCode, getMedisList, type, sport_id, season_id, service_id, items_count, media_type, tournament_id, media_id, team_id, player_id, top_stories, latest, popular }) {
  useEffect(() => {
    if (service_id === null || sport_id === null) return;
    getMedisList(sport_id, service_id, season_id, items_count, media_type, tournament_id, media_id, team_id, player_id, top_stories, latest, popular, currentLanguageCode)
  }
    , [currentLanguageCode, service_id, sport_id])

  const { t } = useTranslation()


  return (
    isFetchingTopStories ?
      <NewsLoading />
      :
      TopStories.length === 0 ?
        <ErrorMsg p={5} fs={'lg'} msg="لا يوجد اخبار" />
        :
        <Container fluid="md">
          <div className="news-section">
            <div className='row'>

              <div className='col-md-6 col-12 p-0 mt-2'>
                <NewsCarousel
                  isLoading={isFetchingTopStories}
                  MediaList={TopStories.slice(0, 4)}
                  type={1}
                  currentLanguageCode={currentLanguageCode}
                  sportId={sport_id}
                />
              </div>
              <div className='col-md-6 col-12'>
                <div className='row'>
                  {TopStories.slice(0, 4).map((news) => (
                    <div className='col-md-6 col-6 p-1' key={news.id} >
                      <MediaCard news={news} type={1} sportId={sport_id} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Container >

  );
}

const mapStateToProps = state => ({
  isFetchingTopStories: state.MediaList.isFetchingTopStories,
  TopStories: state.MediaList.TopStories,
  TopStoriesFailed: state.MediaList.TopStoriesFailed,
  currentLanguageCode: state.language.currentLanguageCode
})

const mapDispatchToProps = (dispatch) => ({
  getMedisList: (sport_id, service_id, season_id, items_count, Media_type, tournament_id, media_id, team_id, player_id, top_stories, latest, popular, lang) =>
    dispatch(getMedisList(sport_id, service_id, season_id, items_count, Media_type, tournament_id, media_id, team_id, player_id, top_stories, latest, popular, lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopStories);

