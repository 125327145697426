import TournamentMatchesTypes from "./TournamentMatches.Types";
import axios from 'axios'
import api from '../../apis'
import { injectSquashServiceAndSport } from "../helpers";
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_TOURNAMENT_MATCHES } = process.env

const getTournamentMatchesStart = () => ({
  type: TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_START
})

const getTournamentMatchesSuccess = (info, firstReload = false, date_type) => ({
  type: TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_SUCCESS,
  payload: info,
  firstReload,
  date_type
})

const getTournamentMatchesFailed = (info) => ({
  type: TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_FAILED,
  payload: info
})


const getTournamentMatches = (sport_id, tournament_id, team_id, start_date, lang, date_type) => (

  dispatch => {
    dispatch(getTournamentMatchesStart())
    if (sport_id === 7) {
      const tournament = tournament_id
      api.squash.getTournamentMatches({ tournament })
        .then(resp => {
          if (resp.code === 200) {
            dispatch(getTournamentMatchesSuccess(injectSquashServiceAndSport(resp.data)))
          } else {
            dispatch(getTournamentMatchesFailed(resp.message))
          }
        })
        .catch(error => dispatch(getTournamentMatchesFailed(error)))
    } else {
      axios({
        method: "get",
        url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_TOURNAMENT_MATCHES}`,
        params: { sport_id, start_date, tournament_id, team_id, date_type },
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": `${lang}`
        },
      }).then(resp => {
        if (resp.data.status === 200) {
          if (start_date === null) {
            dispatch(getTournamentMatchesSuccess(resp.data.data.data, true, date_type))
          } else {
            dispatch(getTournamentMatchesSuccess(resp.data.data.data, false, date_type))
          }
        }
        else {
          dispatch(getTournamentMatchesFailed(resp.data.message))
        }
      }
      )
        .catch(error => dispatch(getTournamentMatchesFailed(error)))
    }


  }
)

export { getTournamentMatches }