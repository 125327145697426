import React from "react";
import { Image, Col } from "react-bootstrap";
import { convertFullDate } from "../../../globalFn";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { AiOutlineClockCircle } from "react-icons/ai";
import "./BodyComponent.css";
import RequireSubscription from "../../require-subscription";
import { ImgURL } from "../../../globalData";
import { filterHTMLBlackColorStyles } from "./helper";

export default function BodyComponent(props) {
  const { i18n } = useTranslation();

  return (
    <div className="media_body_container">
      <Col xs={12} className="p-0">
        {props.mediaType === 1 ? (
          <>
            <div className="w-100">
              <Image
                src={ImgURL(props.mediaSrc)}
                className="w-100 h-auto"
              />
            </div>
            <div className={props.mediaType === 1 ? "article_body" : ""}>
              <h6 className="space text-left text-light">
                <a
                  className="text-light"
                  href="#"
                  onClick={() => navigator.clipboard.writeText(window.location.href)}
                >
                  <i className="fa fa-link"></i>
                </a>
                <a
                  className="text-light"
                  href={`https://twitter.com/intent/tweet?text=${props.mediaTitle}&url=${window.location.href}&hashtags=etisalatsports,اتصالات سبورتس&via=etisalatsports`}
                >
                  <i className="fa fa-twitter"></i>
                </a>
                <a
                  className="text-light"
                  href="#"
                  onClick={() => window.open(`http://www.facebook.com/sharer.php?u=${window.location.href}`, 'sharer', 'width=655,height=430')}
                >
                  <i className="fa fa-facebook"></i>

                </a>
              </h6>
              <h5 className="font-weight-bold fs-xm text-light d-flex align-items-center justify-content-between">
                {props.mediaTitle}
              </h5>
              <small>
                <AiOutlineClockCircle className="mx-1 fs-xm" />
                {convertFullDate(props.mediaDate, i18n.language)}
              </small>
            </div>
          </>
        ) : (
          <>
            <div className="player-wrapper">
              <RequireSubscription isSubscribed={props.isSubscriped}>
                <ReactPlayer
                  stopOnUnmount={false}
                  playIcon={<div className="play-btn"></div>}
                  className="react-player"
                  url={ImgURL(props.mediaSrc)}
                  width="100%"
                  height="100%"
                  controls={true}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload", //<- this is the important bit
                      },
                    },
                  }}
                />
              </RequireSubscription>
            </div>
            <div className={props.mediaType === 1 ? "article_body" : ""}>
              <h5 className="font-weight-bold fs-xm text-light">
                {props.mediaTitle}
              </h5>
              <small>
                <AiOutlineClockCircle className="mx-1 fs-xm" />
                {convertFullDate(props.mediaDate, i18n.language)}
              </small>
            </div>
          </>
        )}
      </Col>
      <div className="mx-3 mt-2 text-justify" data-testid="incoming-black-text-parent">
        <RequireSubscription isSubscribed={props.isSubscriped}  >
          {filterHTMLBlackColorStyles(props.mediaDesc)}
        </RequireSubscription>
      </div>
    </div >
  );
}
