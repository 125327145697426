import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { connect } from "react-redux";
import { ImgURL } from "../../globalData";
import "./RadialTimer.scss";

const timerProps = () => {
  // if (window.matchMedia("(max-width: 500px)").matches) {
  //   return {
  //     isPlaying: true,
  //     size: 100,
  //     strokeWidth: 6
  //   }
  // } else {
  return {
    isPlaying: true,
    size: 120,
    strokeWidth: 6,
  };
  // }
};


const getTimedEvents = (eventsArr) => {
  const eventsMin = Array.from({ length: 90 });
  eventsArr.map((event) => {
    return eventsMin.splice(Number(event.event_time - 1), 1, event)
  }
    // Number(event.event_time) > 45
    //   ? eventsMin.splice(Math.round(event.event_time - 45) - 1, 1, event)
    //   : eventsMin.splice(Number(event.event_time - 1), 1, event)

  );
  return eventsMin;
};

const RadialTimer = ({
  isFootball,
  initialStart,
  matchEvents,
  isFetchingMatchEvents,
  child,
}) => {

  const specificEventsForShowImage = [16, 24];
  if (!isFootball) return child
  return (
    <>
      {isFetchingMatchEvents ?
        <div className="match__radialTimerLoading w-100">... يتم التحميل</div>
        :
        <CountdownCircleTimer
          {...timerProps()}
          strokeLinecap="square"
          colors={[["#d9d9d9"]]}
          trailColor="#759409"
          duration={45 * 60}
          initialRemainingTime={initialStart / 1000}
          children={child}
        ></CountdownCircleTimer>
      }
      <div className="radial-timer">
        {isFetchingMatchEvents ? (
          <div>...يتم التحميل</div>
        ) :
          (
            getTimedEvents(matchEvents).map((event, index) =>
              specificEventsForShowImage.includes(event?.event_id) ? (
                <img key={index} className="radial-timer-event" src={ImgURL(event.event_image)} alt="event" />
              ) : (
                <img key={index} className="radial-timer-event" style={{ height: 0 }} alt="" />
              )
            )
          )
          //  (
          //   getTimedEvents(matchEvents).map((event) =>
          //     specificEventsForShowImage.includes(event?.event_id) ? (
          //       <img className="radial-timer-event" src={ImgURL(event.event_image)} alt="event" />
          //     ) :
          //       <img className="radial-timer-event" style={{ height: 0 }} alt="" />
          //   ))
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  matchEvents: state.matchInfo.matchEvents,
  isFetchingMatchEvents: state.matchInfo.isFetchingMatchEvents,
  matchEventsFailure: state.matchInfo.matchEventsFailure,
  isFetchingMatchStatus: state.matchInfo.isFetchingMatchStatus,
  teamScore: state.matchInfo.matchStatus[0],
});

export default connect(mapStateToProps)(RadialTimer);
