import TeamSquadTypes from "./TeamSquad.Types";
import axios from 'axios'
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_TEAMS, REACT_APP_NEW_GET_TEAM_SQUAD } = process.env

const getTeamSquadStart = () => ({
  type: TeamSquadTypes.GET_TEAM_SQUAD_START
})

const getTeamSquadSuccess = (info) => ({
  type: TeamSquadTypes.GET_TEAM_SQUAD_SUCCESS,
  payload: info
})

const getTeamSquadFailed = (info) => ({
  type: TeamSquadTypes.GET_TEAM_SQUAD_FAILED,
  payload: info
})


const getTeamSquad = (sport_id, season_id, tournament_id, team_id, lang) => (
  dispatch => {
    dispatch(getTeamSquadStart())
    axios({
      method: "get",
      url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_TEAMS}/${team_id}${REACT_APP_NEW_GET_TEAM_SQUAD}`,
      params: { sport_id, season_id, tournament_id },
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`
      },
    }).then(resp => {
      if (resp.data.status === 200) {
        dispatch(getTeamSquadSuccess(resp.data.data.players))
      }
      else {
        dispatch(getTeamSquadFailed(resp.data.message))
      }
    }
    )
      .catch(error => dispatch(getTeamSquadFailed(error)))

  }
)

export { getTeamSquad }