import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import guessPlayerImg from "../../../../../src/assets/games/guess-player.svg";
import Row from "react-bootstrap/Row";
import { ImgURL } from "../../../../globalData";
import PlayerSearch from "../GuessThePlayerModal/PlayerSearch/PlayerSearch";
import { useTranslation } from "react-i18next";
import api from "../../../../apis";
import GameSuccessView from "../../GameSuccessView/GameSuccessView";
import { getUserPhoneNumbers } from "../../../../globalFn";
import { sendGameReport } from "../helper";

function PlayerOfTheCompetitionModal({ show, onHide }) {
  const [showSuccessView, setShowSuccessView] = useState(false);
  const [guessedPlayer, setGuessedPlayer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { t } = useTranslation();

  const msisdn = getUserPhoneNumbers()[0]

  const getPlayerInfo = (playerInfo) => {
    setGuessedPlayer(playerInfo);
  };

  const handleSubmitAnswer = () => {
    let bodyData = {
      msisdn: msisdn,
      player_id: guessedPlayer.id,
    };

    async function sendAnswer() {
      setIsLoading(true);
      setErrorMsg("");
      let res = await api.playerOfTheCompetitionGame.postAnswer(bodyData);
      if (res.status !== 200) {
        setErrorMsg(res.message);
        setIsLoading(false);
      } else {
        setGuessedPlayer(null);
        setIsLoading(false);
        setShowSuccessView(true);
        const setTimeOutID = setTimeout(() => {
          onHide();
          clearTimeout(setTimeOutID);
        }, 2000);
      }
    }
    sendAnswer();
  };

  useEffect(() => {
    if (show) {
      sendGameReport("ThePlayerOfCompetition")
    }
  }, [show])

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      contentClassName={"player__ofCompetition modal__content"}
      dialogClassName="modal__dialog"
    >
      <Modal.Header className="modal__header" closeButton />
      <Modal.Body className="show-grid">
        <Container>
          {showSuccessView ? (
            <GameSuccessView />
          ) : (
            <Row>
              <Col xs={12} className="modal__bodyTitle text-center text-white">
              الكرة الذهبية
              </Col>
              <Col
                xs={12}
                className="modal__bodyDescription text-center text-white"
              >
                توقع مين اللاعب اللي هيحصد جائزة أفضل لاعب في كأس العالم قطر 2022. 
              </Col>
              <Col
                xs={12}
                className="modal__bodyGame--question d-flex align-items-center justify-content-center text-center text-white"
              >
                <div className="team__countryLogo d-flex flex-column align-items-center ">
                  <img
                    className={`${!guessedPlayer ? "bg-white" : ""}`}
                    src={
                      guessedPlayer
                        ? ImgURL(guessedPlayer?.image)
                        : guessPlayerImg
                    }
                    alt="player"
                  />
                  <div>
                    <PlayerSearch getPlayerInfo={getPlayerInfo} />
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Modal.Body>
      {!showSuccessView && (
        <Modal.Footer className="modal__footer d-flex flex-column justify-content-center">
          <Button
            className="text-capitalize"
            onClick={handleSubmitAnswer}
            disabled={isLoading || !guessedPlayer}
          >
            {isLoading ? (
              <div
                className="spinner-border text-light fs-xs pt-1"
                role="status"
                data-testid="playerOfTheCompetitionGame-submitBtn-loading"
              />
            ) : (
              "تأكيد"
            )}
          </Button>
          <p className="error-msg">{errorMsg}</p>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default PlayerOfTheCompetitionModal;
