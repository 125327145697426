import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { fetchMediaDetails } from "../../redux/MediaDetails/Actions.MediaDetails";
import SportsSectionHeader from "../../components/sportsSectionHeader/sportsSectionHeader.component";
import RelatedComponent from "../../components/MediaDetails/Related/RelatedComponent";
import TagsComponent from "../../components/MediaDetails/Tags/TagsComponent";
import AllMedia from "../../components/News/AllMedia";
import GeneralStandings from "../../components/generalStandings/generalStandings";
import BodyComponent from "../../components/MediaDetails/Body/BodyComponent";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import TwistSettings from '../../TwistSettings.json'
import ErrorMsg from "../../components/ErrorPage/ErrorMsg";

const MediaDetails = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { serviceId, serviceName } = useServiceAndSport();
  const [showStandings, setShowStandings] = useState(true);

  useEffect(() => {
    props.getMediaDetails(id, props.currentLanguageCode);
    if (serviceName === "othersports" || serviceName === "africa")
      setShowStandings(false);
  }, [id, serviceName]);
  if (props.errMess) {
    return (
      <ErrorMsg msg={"حدث خطأ ما"} p={1} fs={'sm'} />
    )
  }
  if (props.isLoading) {
    return (
      <Container className="mt-4" data-testid="media-details-loading" >
        <SkeletonTheme color={TwistSettings["twist-ui"] ? "#004F90" : "#323232"} highlightColor="#ecebeb" >
          <Row>
            <Col xs={12} lg={8}>
              <Skeleton height={400} width={"100%"} />
              <Skeleton height={30} width={"100%"} className="mt-3" />
              <Skeleton height={30} width={"100%"} className="mt-3" />
              <Skeleton height={30} width={"100%"} className="mt-3" />
              <Skeleton height={30} width={"100%"} className="mt-3" />
              <Skeleton height={30} width={"100%"} className="mt-3" />
              <Skeleton height={30} width={"100%"} className="mt-3" />
            </Col>
            <Col xs={12} lg={4}>
              <Skeleton height={"100%"} width={"100%"} />
            </Col>
            <Col xs={12} className="my-3">
              <Skeleton height={60} width={"100%"} className="mt-5" />
            </Col>
          </Row>
        </SkeletonTheme>
      </Container>
    );
  } else {
    return (
      <Container className="mt-4" data-testid="media-details-data">
        <Row>
          <Col xs={12} lg={8} >
            <BodyComponent
              mediaSrc={props.mediaDetails.media}
              mediaTitle={props.mediaDetails.title}
              mediaDesc={props.mediaDetails.description}
              mediaDate={props.mediaDetails.created_at}
              mediaType={props.mediaDetails.type}
              isSubscriped={props.isSubscriped}
            />
          </Col>
          {showStandings && (
            <Col xs={12} lg={4}>
              <RelatedComponent relatedMedia={props.mediaDetails.related} />
              {serviceName === "local" ? (
                <SportsSectionHeader
                  title="ترتيب الدوري"
                />
              ) : (
                <SportsSectionHeader
                  title="ترتيب الدوريات"
                />
              )}
              <GeneralStandings service_id={serviceId} />
            </Col>
          )}
          <Col xs={12} className="my-3">
            <TagsComponent mediaTags={props.mediaDetails.tags} />

            {props.mediaDetails.type === 1 ? (
              <div data-testid="media-details-type-news">
                <div className="d-flex justify-content-center container mt-3">
                  {/* <img src={CompoundAdd} height={250} width={"100%"} /> */}
                </div>
                <SportsSectionHeader title="اخر الاخبار" />
                <AllMedia
                  service_Id={serviceId}
                  season_id={props.activeSeason.id}
                  sport_id={1}
                  items_count={4}
                  type={1}
                  latest
                />
              </div>
            ) : (
              <div data-testid="media-details-type-vidos">
                <SportsSectionHeader title="احدث الفيديوهات" />
                <AllMedia
                  service_Id={serviceId}
                  sport_id={1}
                  season_id={props.activeSeason.id}
                  items_count={4}
                  type={2}
                  latest
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.MediaDetails.isLoading,
    errMess: state.MediaDetails.errMess,
    mediaDetails: state.MediaDetails.mediaDetails,
    currentLanguageCode: state.language.currentLanguageCode,
    activeSeason: state.seasons.activeSeason,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMediaDetails: (mediaId, currentLanguageCode) =>
    dispatch(fetchMediaDetails(mediaId, currentLanguageCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaDetails);
