import { useEffect } from 'react'
import Pusher from "pusher-js";
import { GiLobArrow } from "react-icons/gi";
import { connect } from 'react-redux'
import { getMatchEvents } from '../../redux/Match/Actions.Match'
import { Row, Col } from 'react-bootstrap'
import './MatchEvents.css';
import { ImgURL } from '../../globalData';
import { eventsWithGoal, eventsWithRound, OwnGoal } from "../../redux/Match/Utils.Match";
import {
    DeleteMatchLiveEventSuccess,
    getMatchLiveGoalEventSuccess,
    getMatchLiveRoundEventSuccess,
    getMatchLiveStartEventSuccess,
    getMatchOtherEventSuccess,
} from "../../redux/Match/Actions.Match";

const MatchEvents = ({
    getMatchEvents,
    matchEvents,
    isFetchingMatchEvents,
    teamScore,
    isFetchingMatchStatus,
    matchId,
    matchEventsFailure,
    getMatchLiveStartEventSuccess,
    getMatchLiveRoundEventSuccess,
    getMatchOtherEventSuccess,
    getMatchLiveGoalEventSuccess,
    DeleteMatchLiveEventSuccess,
}) => {
    const connectToSocket = () => {
        const pusher = new Pusher("53ef86890b8f6921a455", { cluster: "mt1" });
        const channel = pusher.subscribe(`match_events.${matchId}`);

        channel.bind("MatchEventCreated", function (data) {
            if (data.data.match_id == matchId) {
                if (data.data.event_id === 1) {
                    getMatchLiveStartEventSuccess();
                    getMatchLiveRoundEventSuccess(data.data);
                } else if (eventsWithRound.includes(data.data.event_id)) {
                    getMatchLiveRoundEventSuccess(data.data);
                } else if (eventsWithGoal.includes(data.data.event_id)) {
                    getMatchLiveGoalEventSuccess(data.data);
                } else {
                    getMatchOtherEventSuccess(data.data);
                }
            }
        });

        channel.bind("MatchEventDeleted", function (data) {
            DeleteMatchLiveEventSuccess(data.data);
        });
    }

    useEffect(() => {
        getMatchEvents(matchId)
        connectToSocket()
    }, [])

    const getPlayerName = (playerName) => {
        return playerName ? `${playerName.split(" ").at(0)[0]} . ${playerName.split(" ").at(-1)}` : "";
    }

    const getEvent = (event) => {
        if (eventsWithRound.includes(event.event_id)) {
            const isEventHasStartWord = event.event_name.toLowerCase().startsWith("بداية");
            return <div
                key={event.id}
                className='event-team white-color fs-xmd my-3'
                data-testid="match-events-event"
            >
                <div className='text-capitalize'>{event.event_name}</div>
                {
                    (isFetchingMatchStatus || isEventHasStartWord) ?
                        ''
                        :
                        <div className='text-center'>
                            <span>{teamScore.team1.score}
                            </span> - <span>{teamScore.team2.score}</span>
                        </div>
                }
            </div>
        }
        else {
            const isEventNameEqualExchange = event.event_name === "Exchange";
            const style = event.event_name.includes("Card") ?
                { height: "25px", width: "20px", transform: "rotate(20deg)" } :
                { height: "32px", width: "32px" };
            let direction = event.is_home === 1
            if (event.event_id === OwnGoal) direction = !direction
            return <div
                key={event.id}
                className={`justify-content-between white-color bg-black-color align-items-center my-2 p-2 ${direction
                        ? 'event-team-first d-flex flex-row-reverse'
                        : 'event-team-second d-flex flex-row'
                    }`}
                data-testid="match-events-event"
            >
                <div className={`fs-xmd d-flex align-items-center ${event.is_home === 1 ? "flex-row-reverse" : " flex-row"}`}>
                    {event.event_time}'
                    {
                        isEventNameEqualExchange ?
                            <div className={`exchange__iconWrapper d-flex ${isEventNameEqualExchange ? "flex-column-reverse" : "flex-column"} px-3`}>
                                <div className='exchange__icon'><GiLobArrow className='text-danger' /></div>
                                <div className='exchange__icon'><GiLobArrow className=' text-success' /></div>
                            </div>
                            :
                            <img src={ImgURL(event.event_image)} className='mx-3'
                                style={style} alt="event_image" />
                    }
                </div>

                <div className={`text-center ${event.is_home === 1 ? 'd-flex flex-row-reverse align-items-center' : 'd-flex flex-row align-items-center'}`}>

                    <div className={`d-flex ${isEventNameEqualExchange ? "flex-column-reverse" : "flex-column"}`}>
                        <div className={`mx-2 ${isEventNameEqualExchange ? "text-danger" : "text-white"}`}> {getPlayerName(event.playerOne_name)} </div>
                        <div className={`mx-2 ${isEventNameEqualExchange ? "text-success" : ""}`}> {getPlayerName(event.playerTwo_name)} </div>
                    </div>
                </div>
            </div >
        }
    }


    return (
        matchEventsFailure != null || matchEvents.length === 0 ?
            <div className='text-center mt-3'>لايوجد احداث</div>
            :
            <div className='container bg-black-color'>
                <Row className='match-events et-shadow p-3 container mx-auto'>
                    <Col xs={0} md={3}></Col>
                    <Col xs={12} md={6} className=' d-flex flex-column-reverse align-items-center'>
                        {
                            isFetchingMatchEvents ? <div>...يتم التحميل</div>
                                :
                                <>
                                    <div className="vl"></div>
                                    {
                                        matchEvents.map(event => getEvent(event))
                                    }
                                </>
                        }
                    </Col>
                    <Col xs={0} md={3}></Col>
                </Row>
            </div>
    )
}

const mapStateToProps = state => ({
    matchEvents: state.matchInfo.matchEvents,
    isFetchingMatchEvents: state.matchInfo.isFetchingMatchEvents,
    matchEventsFailure: state.matchInfo.matchEventsFailure,
    isFetchingMatchStatus: state.matchInfo.isFetchingMatchStatus,
    teamScore: state.matchInfo.matchStatus[0]
})
const mapDispatchToProps = dispatch => ({
    getMatchEvents: (matchId) => dispatch(getMatchEvents(matchId)),
    getMatchLiveRoundEventSuccess: (event) => dispatch(getMatchLiveRoundEventSuccess(event)),
    getMatchLiveGoalEventSuccess: (event) => dispatch(getMatchLiveGoalEventSuccess(event)),
    DeleteMatchLiveEventSuccess: (event) => dispatch(DeleteMatchLiveEventSuccess(event)),
    getMatchLiveStartEventSuccess: (event) => dispatch(getMatchLiveStartEventSuccess(event)),
    getMatchOtherEventSuccess: (event) => dispatch(getMatchOtherEventSuccess(event)),
})
export default connect(mapStateToProps, mapDispatchToProps)(MatchEvents)