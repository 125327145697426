import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import twistLogo from "../../assets/img/twist-logo.png";
import etisalteLogo from "../../assets/img/transparentLogo.png";
import TwistSettings from '../../TwistSettings.json'

export default function HeaderComponent({oneServiceLoading}) {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <Image src={TwistSettings["twist-ui"] ? twistLogo : etisalteLogo} height={100} className="mb-4" />
      <p className="fs-xm mx-auto text-capitalize active-color mb-3">
        {TwistSettings["twist-ui"]?t("Subscription.text1Twist"):t("Subscription.text1ET")}
      </p>
      {!oneServiceLoading && <p className="fs-sm m-auto text-capitalize white-color">
        {
          !TwistSettings['subscription']
          ? t("Subscription.text3")
          : t("Subscription.text2")
        }
      </p>}
    </div>
  );
}
