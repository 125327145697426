import React,{ useEffect } from 'react'
import { BarChart, Bar ,Cell} from "recharts";
import { connect } from 'react-redux'
import { getPlayerStatistics } from '../../redux/PlayerStatistics/PlayerStatistics.Actions';
import { useTranslation } from "react-i18next"
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import SeasonsDropdown from '../SeasonsDropdown/SeasonsDropdown';
import { makeOrderedColoredStats } from './helper';

const PlayerStatsChart = ({
    PlayerStatistics,
    isFetchingPlayerStatistics,
    PlayerStatisticsFailed,
    sport_id,
    season_id,
    team_id,
    player_id,
    currentLanguageCode,
    getPlayerStatistics,
    currentTeamActiveTournament,
}) => {
    const { t } = useTranslation()

    useEffect(()=> {
        getPlayerStatistics(sport_id, season_id, currentTeamActiveTournament?.id, team_id, player_id, currentLanguageCode)
    },[sport_id, season_id, currentTeamActiveTournament?.id, team_id, player_id, currentLanguageCode])

    const playerStatisticsList = makeOrderedColoredStats(PlayerStatistics);

    return (
        <div>
            {currentTeamActiveTournament?.id && <SeasonsDropdown tournament_id={currentTeamActiveTournament?.id} />}
            <div className='bg-light-black-color p-3 mx-3'>
            احصائيات الاعب
            </div> 
            {
                PlayerStatisticsFailed !== null
                ? <div className='mx-3'>
                    <ErrorMsg p={5} fs={'lg'} msg="لا يوجد احصائيات"/>
                </div>
                : isFetchingPlayerStatistics
                    ? <div className='mx-3'>
                        <LoadingSpinnner p={5} fs={'md'}/>
                    </div>
                    : <div className='row bg-black-color justify-content-between justify-content-md-around align-items-end  p-md-2 mx-3'>
                        <div className='col-4 col-md-5 fs-xxs p-0'>
                            {
                                playerStatisticsList?.map(stat => 
                                    <div key={stat.name} className='d-flex justify-content-between my-3 mx-2 mx-md-0'>
                                        <div>
                                            <span style={{minHeight:'15px', minWidth:'15px', background:`${stat.color}`,color:'transparent'}}>.....</span>
                                            <span className='mx-2'>{t(stat.name)}</span>
                                        </div>
                                        <div>
                                            {stat.value}
                                        </div>
                                    </div>)
                            }
                        </div>
                        <div className='col-7 d-flex flex-column align-items-end p-0'>  
                            <BarChart width={230} height={500} data={playerStatisticsList}>
                                <Bar dataKey="value" fill='red'>
                                {
                                    playerStatisticsList.map(({color}) => (
                                    <Cell key={color} fill={color} width={35} />
                                ))
                                }
                                </Bar>
                            </BarChart>
                        </div>    
                    </div>
            }
        </div>
    )
}

const mapStateToProps = state =>({
    PlayerStatistics:state.playerStatistics.PlayerStatistics,
    isFetchingPlayerStatistics:state.playerStatistics.isFetchingPlayerStatistics,
    PlayerStatisticsFailed:state.playerStatistics.PlayerStatisticsFailed,
    currentTeamActiveTournament: state.playerDetails.currentTeamActiveTournament,
    currentLanguageCode:state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch =>({
    getPlayerStatistics:(sport_id , season_id , tournament_id , team_id , player_id ,lang)=> dispatch(getPlayerStatistics(sport_id , season_id , tournament_id , team_id , player_id ,lang))
})

export default connect(mapStateToProps,mapDispatchToProps)(PlayerStatsChart)