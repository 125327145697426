import axios from "axios";
import { globalBaseUrl, IDS } from "../../globalData";
import TwistSettings from '../../TwistSettings.json';

const { REACT_APP_NEW_GET_NEWS } = process.env;

export const getSpecialMediaSectionData = (dispatch, params, lang) => {
    dispatch({ type: "FETCH_DATA_REQUEST" });
    axios({
        method: "get",
        url: `${globalBaseUrl.current}/${REACT_APP_NEW_GET_NEWS}`,
        params: {
            ...params,
            portal_id: TwistSettings['twist-ui'] ? IDS.PORTALS.TWIST : IDS.PORTALS.NEW_ET
        },
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": lang,
        },
    })
        .then((res) => {
            if (res.status === 200) {
                const { data, current_page, last_page } = res.data.data;
                dispatch({
                    type: "FETCH_DATA_SUCCESS", payload: data, meta: { current_page, last_page },
                });
            } else {
                dispatch({ type: "FETCH_DATA_FAILURE", payload: res.message });
            }
        })
        .catch((errors) => dispatch({ type: "FETCH_DATA_FAILURE", payload: errors }));
}