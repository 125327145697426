import React from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import MatchesCalendar from "../MatchesCalendar/MatchesCalendar";
import TournamentOverviewStats from "../TournamentStatistics/TournamentOverviewStats";
import TournamentOverviewStand from "../TournamentStanding/TournamentOverviewStand";
import TopScorers from "../topScorers/topScorers";
import TopAssists from "../topAssists/topAssists";
import { useTranslation } from "react-i18next";
import { IDS } from "../../globalData";
import AllMedia from "../News/AllMedia";
import TwistSettings from "../../TwistSettings.json";

const TournamentOverview = ({ activeSeason, season_id, toLocalTab }) => {
  const { id, sportName } = useParams();
  const sport_id = IDS.SPORTS[sportName?.toUpperCase() ?? "FOOTBALL"];
  const isFootball = sport_id === IDS.SPORTS.FOOTBALL
  const isIndividual =
    sport_id === IDS.SPORTS.SQUASH || sport_id === IDS.SPORTS.TENNIS;
  const { t } = useTranslation();

  return (
    <Container className="mb-3">
      {!!activeSeason.active && (
        <MatchesCalendar
          sport_id={sport_id}
          season_id={season_id}
          tournament_id={id}
        />
      )}
      <Row>
        <Col xs={12} md={isFootball ? 8 : 12}>
          {isFootball && (
            <TournamentOverviewStats
              sport_id={sport_id}
              season_id={season_id}
              toLocalTab={toLocalTab}
              tournament_id={id}
            />
          )}
          <div className="bg-black-color p-2">
            <div className="white-color fs-xm p-2">اخر الاخبار</div>
            <AllMedia
              sport_id={sport_id}
              season_id={season_id}
              tournament_id={id}
              items_count={8}
              mini={true}
              xs={6}
              md={4}
              type={1}
              latest={true}
            />
          </div>
          {TwistSettings["videos"] &&
            <div className="bg-black-color p-2">
              <div className="white-color fs-xm p-2">احدث الفيديوهات</div>
              <AllMedia
                sport_id={sport_id}
                season_id={season_id}
                tournament_id={id}
                items_count={8}
                mini={true}
                xs={6}
                md={4}
                type={2}
                latest={true}
              />
            </div>
          }
        </Col>
        {
          isFootball &&
          <Col xs={12} md={4}>
            <TournamentOverviewStand
              toLocalTab={toLocalTab}
              tournament_id={id}
            />
            <TopScorers
              sport_id={sport_id}
              season_id={season_id}
              tournament_id={id}
            />
            <TopAssists
              sport_id={sport_id}
              season_id={season_id}
              tournament_id={id}
            />
          </Col>
        }
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  activeSeason: state.seasons.activeSeason,
});

export default connect(mapStateToProps)(TournamentOverview);
