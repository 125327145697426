import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GeneralStandings from "../../components/generalStandings/generalStandings";
import GeneralStatistics from '../../components/generalStatistics/generalStatistics';
import MatchesCalendar from "../../components/MatchesCalendar/MatchesCalendar";
import LatestNews from "../../components/News/LatestNews";
import TopStories from "../../components/News/TopStories";
import SportsSectionHeader from '../../components/sportsSectionHeader/sportsSectionHeader.component';
import TournamentsCarousel from "../../components/TournamentsCarousel/TournamentsCarousel";
import { connect } from 'react-redux'
import NewsSearch from "../../components/News/NewsSearch";
import Comparison from "../../components/Comparison/Comparison";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import useWalkthroughCookie from "../../hooks/useWalkthroughCookie";
import TwistSettings from "../../TwistSettings.json";
import AllMedia from "../../components/News/AllMedia";

const Home = ({ NewsSearchQuery, appActiveSeason }) => {

  const { t } = useTranslation()
  const matchesCalender = useRef();
  const topStories = useRef();
  const tournaments = useRef();
  const latestNews = useRef();
  const generalStatistics = useRef();
  const comparison = useRef();
  const { walkthrough } = useWalkthroughCookie();

  const [modalShow, setModalShow] = useState(true);

  const [joyrideState, setJoyrideState] = useState({
    run: false,
    stepIndex: 0,
    steps: [],
  });

  useEffect(() => {
    setJoyrideState({
      run: true,
      steps: [
        {
          target: matchesCalender.current,
          content: "لمتابعة مواعيد المباريات",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
        {
          target: topStories.current,
          content: "لمتابعة احدث الاخبار",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
        {
          target: tournaments.current,
          content: "لمتابعة جدول الدوري",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
        {
          target: latestNews.current,
          content: "يمكنك البحث عن الخبر الذي تريدة",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
        {
          target: generalStatistics.current,
          content: "يمكنك من متابعة احصائيات الدوريات",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
        {
          target: comparison.current,
          content: "يمكنك من المقارنة بين الاندية واللاعبين",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
      ],
    });
  }, []);

  const locale = {
    back: "السابق",
    close: "تم",
    last: "إنهاء",
    next: "التالي",
    skip: "تخطي",
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setJoyrideState({ ...joyrideState, run: false, stepIndex: 0 });
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setJoyrideState({
        ...joyrideState,
        stepIndex: nextStepIndex,
      });
    }
  };

  return (
    <div className='container home-page '>
      {/* {TwistSettings["twist-ui"] && TwistSettings["world-cup-pop"] &&
        <WorldCupPopUp
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      } */}
      {walkthrough &&
        <Joyride
          callback={handleJoyrideCallback}
          run={joyrideState.run}
          scrollOffset={230}
          showProgress
          showSkipButton
          continuous={true}
          hideCloseButton={true}
          stepIndex={joyrideState.stepIndex}
          steps={joyrideState.steps}
          locale={locale}
          styles={{
            options: {
              primaryColor: 'var(--red)',
            }
          }}
        />}
      <div className="d-flex justify-content-center row">
        <div className="col-12">
          {/* <img src={IphoneAdd} height={200} width={"100%"} /> */}
        </div>
      </div>
      <div ref={matchesCalender}>
        <MatchesCalendar season_id={null} tournament_id={null} />
      </div>
      <SportsSectionHeader title="اهم الاحداث" navigateTo="/news" />
      <div ref={topStories}>
        <TopStories
          season_id={null}
          items_count={4}
          media_type={1}
          tournament_id={null}
          media_id={null}
          team_id={null}
          player_id={null}
          top_stories={true}
          latest={null}
          popular={null}
        />
      </div>
      <hr></hr>

      <div ref={tournaments} className='row align-items-baseline'>
        <div className='col-12 col-md-6 fs-xs'>
          <SportsSectionHeader title="ترتيب الدوريات" />
          <GeneralStandings sport_id={1} season_id={null} />
        </div>
        <div className='col-12 col-md-6 fs-md'>
          <div className='text-light mt-5 fs-lg '><strong>اختر بطولة</strong></div>
          <TournamentsCarousel />
        </div>
      </div>
      <div className="col-12">
        {/* <img src={Cokebanner} height={200} width={"100%"} className="mt-3" /> */}
      </div>
      <SportsSectionHeader title="اخر الاخبار" navigateTo="/news" />
      <NewsSearch />
      <div ref={latestNews} className={`${NewsSearchQuery.length > 0 ? 'd-none' : ""} my-2`}>
        <LatestNews season_id={appActiveSeason.id} items_count={9} media_type={1} latest={true} />
      </div>
      <hr />
      {TwistSettings["videos"] &&
        <>
          <SportsSectionHeader title="احدث الفيديوهات" navigateTo="/videos" />
          <AllMedia season_id={appActiveSeason.id} items_count={9} type={2} tournament_id={null} media_id={null} team_id={null} player_id={null} top_stories={null} latest={true} popular={null} />
          <hr />
        </>
      }
      <SportsSectionHeader title="الاكثر تداولا" navigateTo="/news" />
      <AllMedia season_id={appActiveSeason.id} items_count={12} type={1} tournament_id={null} media_id={null} team_id={null} player_id={null} top_stories={null} latest={null} popular={true} />
      <hr />
      <div className='row'>
        <div ref={generalStatistics} className='col-12 col-md-6 mt-4'>
          <GeneralStatistics />
        </div>
        {TwistSettings["comparison"] &&
          <div ref={comparison} className="col-11 ml-auto mr-auto col-md-5 mt-4 mx-md-0">
            <Comparison />
          </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  NewsSearchQuery: state.newsSearch.NewsSearchQuery,
  appActiveSeason: state.seasons.appActiveSeason
})

export default connect(mapStateToProps)(Home);