import { act } from "react-dom/test-utils";
import MediaListTypes from "./MediaList.Types";

const INITIAL_STATE = {
  TopStories: [],
  isFetchingTopStories: true,
  TopStoriesFailed: null,
  LatestNews: [],
  addingLatestNews: false,
  LatestNews_CurrentPage: 1,
  LatestNews_LastPage: null,
  isFetchingLatestNews: true,
  LatestNewsFailed: null,
  MostPopularNews: [],
  isFetchingMostPopularNews: true,
  MostPopularNewsFailed: null,
  AllNews: [],
  addingAllNews: false,
  AllNews_CurrentPage: 1,
  AllNews_LastPage: null,
  isFetchingAllNews: true,
  AllNewsFailed: null,
  Featured: [],
  isFetchingFeatured: true,
  FeaturedFailed: null,
  LatestVids: [],
  addingLatestVids: false,
  LatestVids_CurrentPage: 1,
  LatestVids_LastPage: null,
  isFetchingLatestVids: true,
  LatestVidsFailed: null,
  MostPopularVids: [],
  isFetchingMostPopularVids: true,
  MostPopularVidsFailed: null,
  AllMedia: [],
  addingAllMedia: false,
  AllMedia_CurrentPage: 1,
  AllMedia_LastPage: null,
  isFetchingAllMedia: true,
  AllMediaFailed: null,
};

const MediaList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MediaListTypes.GET_MEDIA_LIST_START:
      if (action.media_type === 1) {
        if (action.top_stories) {
          return {
            ...state,
            isFetchingTopStories: true,
            TopStoriesFailed: null,
          };
        } else if (action.latest) {
          return {
            ...state,
            isFetchingLatestNews: true,
            LatestNewsFailed: null,
          };
        } else if (action.popular) {
          return {
            ...state,
            isFetchingMostPopularNews: true,
            MostPopularNewsFailed: null,
          };
        } else {
          return {
            ...state,
            isFetchingAllNews: true,
            AllNewsFailed: null,
          };
        }
      }
      if (action.media_type === 2) {
        if (action.top_stories) {
          return {
            ...state,
            isFetchingFeatured: true,
            FeaturedFailed: null,
          };
        } else if (action.latest) {
          return {
            ...state,
            isFetchingLatestVids: true,
            LatestVidsFailed: null,
          };
        } else if (action.popular) {
          return {
            ...state,
            isFetchingMostPopularVids: true,
            MostPopularVidsFailed: null,
          };
        } else {
          return {
            ...state,
            isFetchingAllMedia: true,
            AllMediaFailed: null,
          };
        }
      }
    // this is the params in order  (Media_type, tournament_id, media_id, team_id , top_stories , latest , popular , lang)
    case MediaListTypes.GET_MEDIA_LIST_SUCCESS:
      if (action.media_type === 1) {
        if (action.top_stories) {
          return {
            ...state,
            TopStories: action.payload,
            isFetchingTopStories: false,
            TopStoriesFailed: null,
          };
        } else if (action.latest) {
          return {
            ...state,
            LatestNews: action.payload,
            LatestNews_CurrentPage: action.meta.current_page,
            LatestNews_LastPage: action.meta.last_page,
            isFetchingLatestNews: false,
            LatestNewsFailed: null,
          };
        } else if (action.popular) {
          return {
            ...state,
            MostPopularNews: action.payload,
            isFetchingMostPopularNews: false,
            MostPopularNewsFailed: null,
          };
        } else {
          return {
            ...state,
            AllNews: action.payload,
            AllNews_CurrentPage: action.meta.current_page,
            AllNews_LastPage: action.meta.last_page,
            isFetchingAllNews: false,
            AllNewsFailed: null,
          };
        }
      }
      if (action.media_type === 2) {
        if (action.top_stories) {
          return {
            ...state,
            Featured: action.payload,
            isFetchingFeatured: false,
            FeaturedFailed: null,
          };
        } else if (action.latest) {
          return {
            ...state,
            LatestVids: action.payload,
            LatestVids_CurrentPage: action.meta.current_page,
            LatestVids_LastPage: action.meta.last_page,
            isFetchingLatestVids: false,
            LatestVidsFailed: null,
          };
        } else if (action.popular) {
          return {
            ...state,
            MostPopularVids: action.payload,
            isFetchingMostPopularVids: false,
            MostPopularVidsFailed: null,
          };
        } else {
          return {
            ...state,
            AllMedia: action.payload,
            AllMedia_CurrentPage: action.meta.current_page,
            AllMedia_LastPage: action.meta.last_page,
            isFetchingAllMedia: false,
            AllMediaFailed: null,
          };
        }
      }
    case MediaListTypes.GET_MEDIA_LIST_FAILED:
      if (action.media_type === 1) {
        if (action.top_stories) {
          return {
            ...state,
            isFetchingTopStories: false,
            TopStoriesFailed: action.payload,
          };
        } else if (action.latest) {
          return {
            ...state,
            isFetchingLatestNews: false,
            LatestNewsFailed: action.payload,
          };
        } else if (action.popular) {
          return {
            ...state,
            isFetchingMostPopularNews: false,
            MostPopularNewsFailed: action.payload,
          };
        } else {
          return {
            ...state,
            isFetchingAllNews: false,
            AllNewsFailed: action.payload,
          };
        }
      }
      if (action.media_type === 2) {
        if (action.top_stories) {
          return {
            ...state,
            isFetchingFeatured: false,
            FeaturedFailed: action.payload,
          };
        } else if (action.latest) {
          return {
            ...state,
            isFetchingLatestVids: false,
            LatestVidsFailed: action.payload,
          };
        } else if (action.popular) {
          return {
            ...state,
            isFetchingMostPopularVids: false,
            MostPopularVidsFailed: action.payload,
          };
        } else {
          return {
            ...state,
            isFetchingAllMedia: false,
            AllMediaFailed: action.payload,
          };
        }
      }
    case MediaListTypes.ADD_MEDIA_LIST_START:
      if (action.media_type === 1) {
        if (action.top_stories) {
        } else if (action.latest) {
          return {
            ...state,
            addingLatestNews: true,
          };
        } else if (action.popular) {
        } else {
          return {
            ...state,
            addingAllNews: true,
          };
        }
      }
      if (action.media_type === 2) {
        if (action.top_stories) {
        } else if (action.latest) {
          return {
            ...state,
            addingLatestVids: true,
          };
        } else if (action.popular) {
        } else {
          return {
            ...state,
            addingAllMedia: true,
          };
        }
      }
    case MediaListTypes.ADD_MEDIA_LIST_SUCCESS:
      if (action.media_type === 1) {
        if (action.top_stories) {
        } else if (action.latest) {
          return {
            ...state,
            LatestNews: [...state.LatestNews, ...action.payload],
            addingLatestNews: false,
            LatestNews_CurrentPage: action.meta.current_page,
            LatestNews_LastPage: action.meta.last_page,
            isFetchingLatestNews: false,
            LatestNewsFailed: null,
          };
        } else if (action.popular) {
        } else {
          return {
            ...state,
            AllNews: [...state.AllNews, ...action.payload],
            addingAllNews: false,
            AllNews_CurrentPage: action.meta.current_page,
            AllNews_LastPage: action.meta.last_page,
            isFetchingAllNews: false,
            AllNewsFailed: null,
          };
        }
      }
      if (action.media_type === 2) {
        if (action.top_stories) {
        } else if (action.latest) {
          return {
            ...state,
            LatestVids: [...state.LatestVids, ...action.payload],
            addingLatestVids: false,
            LatestVids_CurrentPage: action.meta.current_page,
            LatestVids_LastPage: action.meta.last_page,
            isFetchingLatestVids: false,
            LatestVidsFailed: null,
          };
        } else if (action.popular) {
        } else {
          return {
            ...state,
            AllMedia: [...state.AllMedia, ...action.payload],
            addingAllMedia: false,
            AllMedia_CurrentPage: action.meta.current_page,
            AllMedia_LastPage: action.meta.last_page,
            isFetchingAllMedia: false,
            AllMediaFailed: null,
          };
        }
      }
    default:
      return state;
  }
};

export default MediaList;
