import getTournamentDetailsTypes from "./TournamentDetails.Types";
import api from "../../apis";
import axios from 'axios'
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_TOURNAMENTS } = process.env


const getTournamentDetailsStart = () => ({
  type: getTournamentDetailsTypes.GET_TOURNAMENT_DETAILS_START
})

const getTournamentDetailsSuccess = (info) => ({
  type: getTournamentDetailsTypes.GET_TOURNAMENT_DETAILS_SUCCESS,
  payload: info
})

const getTournamentDetailsFailed = (info) => ({
  type: getTournamentDetailsTypes.GET_TOURNAMENT_DETAILS_FAILED,
  payload: info
})

const setTournamentActiveTab = (tabName) => ({
  type: getTournamentDetailsTypes.SET_TOURNAMENT_ACTIVE_TAB,
  payload: tabName
})

const getTournamentDetails = (sport_id = 1, id, lang, sportName = "", season_id) => (
  dispatch => {
    dispatch(getTournamentDetailsStart())
    if (!sportName) {
      axios({
        method: "get",
        url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_TOURNAMENTS}/${id}`,
        params: { sport_id },
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": `${lang}`
        },
      }).then(resp => {
        if (resp.data.status === 200) {
          dispatch(getTournamentDetailsSuccess(resp.data.data))
        }
        else {
          dispatch(getTournamentDetailsFailed(resp.data.message))
        }
      }
      )
        .catch(error => dispatch(getTournamentDetailsFailed(error)))
    } else {
      api.squash.getTournaments({ id }).then(res => {
        if (res.code === 200) {
          const { start_date, image, name_ar } = res.data;
          const customizedSquashData = {
            ...res.data[0],
            service: {
              id: 10,
              title: "othersports",
              active: 1,
            },
            sport_id: {
              active: 1,
              created_at: start_date,
              id: 7,
              image: image,
              is_favourite: false,
              title: "Squash ",
              type: "Individual",
            },
            title: name_ar
          };
          dispatch(getTournamentDetailsSuccess(customizedSquashData));
        } else {
          dispatch(getTournamentDetailsFailed(res.message));
        }
      })
    }

  }
)

export { getTournamentDetails, setTournamentActiveTab }
