import React from "react";
import { Link, useLocation } from "react-router-dom";
import { GoCalendar } from "react-icons/go";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import { convertFullDate } from "../../globalFn";

function NewsCarousel(props) {
  const { pathname } = useLocation()
  const settings = {
    nextArrow: "",
    prevArrow: "",
    fade: true,
    infinite: true,
    draggable: false,
    touchMove: false,
    autoplay: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    // rtl: props.currentLanguageCode === "ar" ? true : false,
    // accessibility: true,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 12,
    //       slidesToScroll: 12,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 7,
    //       slidesToScroll: 7,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 6,
    //       slidesToScroll: 6,
    //     },
    //   },
    // ],
  };
  return (
    <div>
      {
        props.type === 2 && <div className="play-icon-wrapper">
          <div className="play-icon">
            <i className="fa fa-play"></i>
          </div>
        </div>
      }
      <Slider {...settings}>
        {props.MediaList.map((news) => (
          <div key={news.id} className="news-card-carousel">
            <Link to={{ pathname: `/${getServiceName(news.services?.[0])}${getSportRoute(news.sport_id, news.services?.[0])}/media/${news.id}`, state: { previousPathname: pathname } }}>
              <img
                src={props.type === 2 ? ImgURL(news.video_cover) : ImgURL(news.media)}
                alt="media"
              />
              <div
                className={`${props.currentLanguageCode === "ar"
                  ? "text-right"
                  : "text-left"
                  } news-card-description text-light bg-black-color-opacity p-2`}
              >
                <p className="text-cut-2 px-3 mb-1">{news.title}</p>
                <small className="white-color  mx-2 mb-2 d-flex flex-row-reverse align-items-center">
                  <GoCalendar className="mx-2" />
                  {convertFullDate(news.created_at)}
                </small>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default NewsCarousel;
