import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function QuizzesTimer({ isFetchingQuizzesQuestions, quizzesErrors, handleGetQuizzesQuestions, setQuizzesStarted, formPage, setFormPage, handleChangeFormPage }) {

    const { t } = useTranslation();
    const [seconds, setSeconds] = useState(10);

    useEffect(() => {
        const secondsInterval = setInterval(() => {
            setSeconds(preveState => preveState - 1);
        }, 1000);
        if (seconds < 0) {
            clearInterval(secondsInterval);
            handleChangeFormPage(true)
            setSeconds(10)
        }
        return () => clearInterval(secondsInterval);
    }, [seconds])

    useEffect(() => {
        setSeconds(10)
    }, [formPage])


    return (
        <div className='quizzes__timer d-flex justify-content-center'>
            <div className="timer__wrapper d-flex align-items-center justify-content-center">
                {isFetchingQuizzesQuestions && <div className="spinner-border text-center text-danger fs-xs p-0" role="status" />}
                {!isFetchingQuizzesQuestions && !quizzesErrors &&
                    <div>
                        <span>00</span>
                        <span>:</span>
                        <span>{`${seconds < 10 ? `0${seconds}` : `${seconds}`}`}</span>
                    </div>}
                {!isFetchingQuizzesQuestions && quizzesErrors &&
                    <button className='quizzes__modalButton--start' onClick={() => { handleGetQuizzesQuestions(); setQuizzesStarted(true); setFormPage(0) }}>
                        ابدأ
                    </button>
                }
            </div>
        </div>
    )
}

export default QuizzesTimer