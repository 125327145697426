import MatchActionTypes from "./Types.Match";
import api from '../../apis';
import axios from "axios";
import { globalBaseUrl } from "../../globalData";

const {
  REACT_APP_GET_MATCH_BY_ID,
  REACT_APP_API_KEY,
  REACT_APP_GET_MATCH_LINEUP,
  REACT_APP_GET_MATCH_EVENTS,
  REACT_APP_GET_MATCH_STATISTICS,
} = process.env;

const getMatchInfoStart = () => ({
  type: MatchActionTypes.GET_MATCH_INFO_START,
});

const getMatchInfoSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_INFO_SUCCESS,
  payload: info,
});

const getMatchInfoFailure = (error) => ({
  type: MatchActionTypes.GET_MATCH_INFO_FAILURE,
  payload: error,
});

const getMatchEventsStart = () => ({
  type: MatchActionTypes.GET_MATCH_EVENTS_START,
});

const getMatchEventsSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_EVENTS_SUCCESS,
  payload: info,
});

const getMatchEventsFailure = (error) => ({
  type: MatchActionTypes.GET_MATCH_EVENTS_FAILURE,
  payload: error,
});

const getMatchLineUpStart = () => ({
  type: MatchActionTypes.GET_MATCH_LINEUP_START,
});

const getMatchLineUpSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_LINEUP_SUCCESS,
  payload: info,
});

const getMatchLineUpFailure = (error) => ({
  type: MatchActionTypes.GET_MATCH_LINEUP_FAILURE,
  payload: error,
});

const getMatchLiveStartEventSuccess = () => ({
  type: MatchActionTypes.GET_MATCH_LIVE_START_EVENT_SUCCESS,
});

const getMatchLiveRoundEventSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_LIVE_ROUND_EVENT_SUCCESS,
  payload: info,
});

const getMatchLiveGoalEventSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_LIVE_GOAL_EVENT_SUCCESS,
  payload: info,
});

const getMatchOtherEventSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_LIVE_OTHER_EVENT_SUCCESS,
  payload: info,
});

const DeleteMatchLiveEventSuccess = (info) => ({
  type: MatchActionTypes.DELETE_MATCH_LIVE_EVENT_SUCCESS,
  payload: info,
});

const getMatchStatisticsStart = () => ({
  type: MatchActionTypes.GET_MATCH_STATISTICS_START,
});

const getMatchStatisticsSuccess = (info) => ({
  type: MatchActionTypes.GET_MATCH_STATISTICS_SUCCESS,
  payload: info,
});

const getMatchStatisticsFailure = (error) => ({
  type: MatchActionTypes.GET_MATCH_STATISTICS_FAILURE,
  payload: error,
});

const squashMatchStatus = {
  NOT_STARTED: 1,
  STARTED: 2,
  POSTPONED: 3,
  CANCELLED: 4,
  FINISHED: 5,
  Complete: 5,
}

const getMatchInfo = (match_id, sportName = "") => (dispatch) => {
  dispatch(getMatchInfoStart());
  if (!sportName || sportName !== "squash") {
    axios({
      method: "get",
      url: `${globalBaseUrl.current}${REACT_APP_GET_MATCH_BY_ID}?id=${match_id}`,
      // params: { match_id:42312 },
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ar",
      },
    })
      .then((resp) => {
        if (resp.data.status === 200) {
          const individualSports = ["tennis"];
          const isIndividualSport = individualSports.includes(sportName);
          if (isIndividualSport) {
            const resData = resp.data.data[0]
            const customizedResData = {
              ...resData,
              team1: {
                ...resData.team1,
                services: [10],
                sport_id: 2,
              },
              team2: {
                ...resData.team2,
                services: [10],
                sport_id: 2,
              },
              tournament: {
                ...resData.tournament,
                services: [10],
              }
            }
            dispatch(getMatchInfoSuccess([customizedResData]));
          } else {
            dispatch(getMatchInfoSuccess(resp.data.data.data));
          }
        } else {
          dispatch(getMatchInfoFailure(resp.data.message));
        }
      })
      .catch((error) => dispatch(getMatchInfoFailure(error)));
  } else {
    api.squash.getMatchDetails({ id: match_id })
      .then(res => {
        if (res.code === 200) {
          const { player1, player_one_image, player_one, player2, player_two_image, player_two, status, tournament, result } = res.data[0];
          const customizedResData = {
            ...res.data[0],
            services: [10],
            sport_id: 7,
            team1: {
              id: player1,
              is_favourite: false,
              logo: player_one_image,
              title: player_one,
              services: [10],
              sport_id: 7,
              team_name: player_one,
              score: result ? result.split("-")[0] : 0,

            },
            team2: {
              id: player2,
              is_favourite: false,
              logo: player_two_image,
              title: player_two,
              services: [10],
              sport_id: 7,
              team_name: player_two,
              score: result ? result.split("-")[1] : 0,
            },
            tournament: { id: tournament, services: [10] },
            matchStatus: squashMatchStatus[status],
            matchRoundInfo: ""
          }
          dispatch(getMatchInfoSuccess([customizedResData]))
        } else {
          dispatch(getMatchInfoFailure(res.message))
        }
      })
  }
};

const getMatchEvents = (match_id) => (dispatch) => {
  dispatch(getMatchEventsStart());
  axios({
    method: "get",
    url: `${globalBaseUrl.current}${REACT_APP_GET_MATCH_EVENTS}`,
    params: { match_id },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "ar",
    },
  })
    .then((resp) => {
      if (resp.data.status === 200) {
        dispatch(getMatchEventsSuccess(resp.data.data));
      } else {
        dispatch(getMatchEventsFailure(resp.data.message));
      }
    })
    .catch((error) => dispatch(getMatchEventsFailure(error)));
};

const getMatchLineUp = (match_id) => (dispatch) => {
  dispatch(getMatchLineUpStart());
  axios({
    method: "get",
    url: `${globalBaseUrl.current}${REACT_APP_GET_MATCH_LINEUP}${match_id}`,
    // params: { match_id:42312 },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "ar",
    },
  })
    .then((resp) => {
      if (resp.data.status === 200) {
        dispatch(getMatchLineUpSuccess(resp.data.data));
      } else {
        dispatch(getMatchLineUpFailure(resp.data.message));
      }
    })
    .catch((error) => dispatch(getMatchLineUpFailure(error)));
};

const getMatchStatistics = (id) => (dispatch) => {
  dispatch(getMatchStatisticsStart());
  axios({
    method: "get",
    url: `${globalBaseUrl.current}${REACT_APP_GET_MATCH_STATISTICS}`,
    params: { id },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "ar",
    },
  })
    .then((resp) => {
      if (resp.data.status === 200) {
        dispatch(getMatchStatisticsSuccess(resp.data.data));
      } else {
        dispatch(getMatchStatisticsFailure(resp.data.message));
      }
    })
    .catch((error) => dispatch(getMatchStatisticsFailure(error)));
};

export {
  getMatchInfo,
  getMatchEvents,
  getMatchLineUp,
  getMatchLiveRoundEventSuccess,
  getMatchLiveGoalEventSuccess,
  getMatchOtherEventSuccess,
  DeleteMatchLiveEventSuccess,
  getMatchLiveStartEventSuccess,
  getMatchStatistics,
};
