import PlayerSeasonStatisticsTypes from "./PlayerSeasonStatistics.Types";



const INITIAL_STATE = {
    PlayerSeasonStatistics:{},
    isFetchingPlayerSeasonStatistics:true,
    PlayerSeasonStatisticsFailed:null
   };
   
   const PlayerSeasonStatistics = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case PlayerSeasonStatisticsTypes.GET_PLAYER_SEASON_STATISTICS_START:
        return {
          ...state,
          isFetchingPlayerSeasonStatistics:true
        }
      case PlayerSeasonStatisticsTypes.GET_PLAYER_SEASON_STATISTICS_SUCCESS:
        return {
          ...state,
          PlayerSeasonStatistics:action.payload,
          isFetchingPlayerSeasonStatistics:false,
          PlayerSeasonStatisticsFailed:null
        }
      case PlayerSeasonStatisticsTypes.GET_PLAYER_SEASON_STATISTICS_FAILED:
        return {
          ...state,
          PlayerSeasonStatisticsFailed:action.payload,
          isFetchingPlayerSeasonStatistics:false
        }
       default:
         return state;
     }
   };
 
   export default PlayerSeasonStatistics