import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation, withRouter } from "react-router-dom";
import "./HeaderNav.css";
import setLanguage from "../../redux/language/language.Actions";
import twistLogo from "../../assets/img/twist-new-logo.png"
import etisalteLogo from "../../assets/img/dark-logo.png";
import FullSearch from "../../components/FullSearch/FullSearch";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import { isActive } from "../helper";
import TwistSettings from "../../TwistSettings.json";

const HeaderNav = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { route, serviceName, sportName } = useServiceAndSport()
  const isAlAhlyTeam = pathname.includes("team/6631");

  const videosNavItem = <Link
    className={`nav-link nav-link-fade-up nav-item ${isActive(pathname.endsWith('/videos'))}`}
    to={{ pathname: route + "/videos", state: { previousPathname: pathname } }}
    data-testid="header-nav-videos-link"
  >
    الفيديوهات
  </Link>

  const tournamentsNavItem = <Link
    className={`nav-link nav-link-fade-up nav-item ${isActive(pathname.endsWith('/tournaments'))}`}
    to={{ pathname: route + "/tournaments", state: { previousPathname: pathname } }}
    data-testid="header-nav-tournaments-link"
    tabIndex="-1"
    aria-disabled="true"
  >
    المسابقات
  </Link>

  const matchesNavItem = <Link
    className={`nav-link nav-link-fade-up nav-item ${isActive(pathname.endsWith('/matches'))}`}
    to={{ pathname: route + "/matches", state: { previousPathname: pathname } }}
    tabIndex="-1"
    aria-disabled="true"
    data-testid="header-nav-matches-link"
  >
    المباريات
  </Link>

  return (
    <nav className="navbar navbar-dark navbar-expand-lg fs-sm d-none d-lg-flex">
      <Link className="navbar-brand mb-1" to={{ pathname: "/", state: { previousPathname: pathname } }} data-testid="header-nav-logo-link">
        <img src={TwistSettings["twist-ui"] ? twistLogo : etisalteLogo} width="100x" height="50px" alt="logo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon "></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav text-center ">
          <li className="ms-2">
            <Link
              className={`nav-link nav-link-fade-up nav-item ${isActive(pathname === "/")}`}
              to={{ pathname: "/", state: { previousPathname: pathname } }}
              data-testid="header-nav-home-link"
            >
              الرئيسية <span className="sr-only">(current)</span>
            </Link>
            <Link
              className={`nav-link nav-link-fade-up nav-item ${isActive(pathname.endsWith('/news'))}`}
              to={{ pathname: route + "/news", state: { previousPathname: pathname } }}
              data-testid="header-nav-news-link"
            >
              الأخبار
              <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="ms-2">
            <Link
              className={`nav-link nav-link-fade-up nav-item ${isActive(serviceName === "international")}`}
              to={{ pathname: "/international", state: { previousPathname: pathname } }}
              data-testid="header-nav-international-football-link"
            >
              الكرة العالمية
              <span className="sr-only">(current)</span>
            </Link>
            {TwistSettings['videos'] ? videosNavItem : tournamentsNavItem}
          </li>
          <li className="ms-2">
            <Link
              className={`nav-link nav-link-fade-up nav-item ${isActive(serviceName === "local" && !isAlAhlyTeam)}`}
              to={{ pathname: "/local", state: { previousPathname: pathname } }}
              data-testid="header-nav-local-football-link"
            >
              الكرة المحلية
              <span className="sr-only">(current)</span>
            </Link>
            {TwistSettings['videos'] ? tournamentsNavItem : matchesNavItem}
          </li>
          <li className="ms-2">
            <Link
              className={`nav-link nav-link-fade-up nav-item ${isActive(serviceName === "africa")}`}
              to={{ pathname: "/africa", state: { previousPathname: pathname } }}
              data-testid="header-nav-africa-football-link"
            >
              الكرة الأفريقية{" "}
              <span className="sr-only">(current)</span>
            </Link>
            {TwistSettings['videos'] && matchesNavItem}
          </li>
          <li className="ms-2">
            <Link
              className={`nav-link nav-link-fade-up nav-item ${isActive(serviceName === "othersports" && !sportName)}`}
              to={{ pathname: "/othersports", state: { previousPathname: pathname } }}
              data-testid="header-nav-other-sports-link"
            >
              الرياضات الأخرى
              <span className="sr-only">(current)</span>
            </Link>
          </li>
          {/* <li className="ms-2">
            <a
              className="nav-link nav-link-fade-up nav-item"
              href={window.location.origin + "/meloukelkora"}
            >
              ملوك الكورة
            </a>
          </li> */}
          <li className="ms-2">
            <Link
              className={`nav-link nav-link-fade-up nav-item ${isActive(isAlAhlyTeam)}`}
              to={{ pathname: "/local/team/6631/overview", state: { previousPathname: pathname } }}
              data-testid="header-nav-world-cup-football-link"
            >
              النادي الأهلي
            </Link>
          </li>
          {TwistSettings["twist-fitness"] &&
            <li className="ms-2">
              <a
                className="nav-link nav-link-fade-up nav-item"
                href={window.location.origin + "/fitness"}
                data-testid="header-nav-fitness-link"
              >
                تويست فيتنس
              </a>
              {/* <Link
                className={`nav-link nav-link-fade-up nav-item ${isActive(pathname === "/fitness")}`}
                to={{ pathname: "/fitness", state: { previousPathname: pathname } }}
                data-testid="header-nav-fitness-link"
              >
                تويست فيتنس
                <span className="sr-only">(current)</span>
              </Link> */}
            </li>
          }
          {/* <li className="nav-item dropdown  ms-2">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown text-light"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              الرياضات الأخرى
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <a className="dropdown-item" onClick={() => gotoLocal("")}>
                FOOTBALL HOME
              </a>
              <a
                className="dropdown-item"
                onClick={() => gotoLocal("competitions")}
              >
                All leagues
              </a>
              <a className="dropdown-item" href="#">
                champoins league
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#">
                Euro 2021
              </a>
              <a className="dropdown-item" href="#">
                AFC 2021
              </a>
            </div>
          </li> */}
          {/* <div className="dropdown ms-2">
            <button
              className="btn btn-link dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <GlobeIcon />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li className="text-center">
                <span className="white-color m-3 ">
                  اللغه
                </span>
              </li>
              {languages.map(({ code, name, country_code }) => (
                <li key={country_code}>
                  <a
                    href="#"
                    className={classNames(
                      `dropdown-item ${
                        currentLanguageCode !== code ? "text-light" : ""
                      }`,
                      {
                        disabled: currentLanguageCode === code,
                      }
                    )}
                    onClick={() => {
                      i18next.changeLanguage(code);
                      window.document.body.lang = i18next.language;
                      window.document.documentElement.lang = i18next.language;
                      setLanguage();
                      window.location.reload();
                    }}
                  >
                    <span
                      className={`flag-icon flag-icon-${country_code} mx-2`}
                      style={{
                        opacity: currentLanguageCode === code ? 0.5 : 1,
                      }}
                    ></span>
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div> */}
        </ul>
      </div>
      <FullSearch />
      {/* <Link to={`/profile`}>
        <i className="fa fa-user-circle fs-lg mx-4 text-light" />
      </Link> */}
      {/* <img src={teamImg} width={"30px"} /> */}
    </nav>
  );
};

const mapStateToProps = (state) => ({
  languages: state.language.languages,
  currentLanguageCode: state.language.currentLanguageCode,
});
const mapDispatchToProps = (dispatch) => ({
  setLanguage: () => dispatch(setLanguage()),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderNav)
);
