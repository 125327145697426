import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { sendGameReport } from "../helper";
import "./PlaystationFormModal.css";

function PlaystationFormModal({ show, onHide, sendUserPlaystationRegistrationInfo, userPlaystationRegistrationError, isSendingUserPlaystationRegistrationInfo }) {
  const [formData, setFromData] = useState({
    userName: "",
    playStationName: "",
    userPhoneNumber: "",
  });
  const [formDataErrors, setFromDataErrors] = useState({
    userNameError: false,
    playStationNameError: false,
    userPhoneNumberError: false,
  });

  useEffect(() => {
    if (show) {
      sendGameReport("PlayStationRegister")
    }
  }, [show])

  const { t } = useTranslation();

  const handleFormChange = (e) => {
    setFromData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmitForm = () => {
    const phoneRegx = /^01[0125][0-9]{8}$/gm;
    const userNameAndPlaystationRegx = /^[\w-_ ]+$/igm;
    let foundError = false;
    for (let data in formData) {
      const currenInputRegx = data === "userPhoneNumber" ? phoneRegx : userNameAndPlaystationRegx;
      if (!formData[data] || !formData[data].match(currenInputRegx)) {
        setFromDataErrors((prev) => ({ ...prev, [`${data}Error`]: "not valid" }))
        foundError = true;
      } else {
        setFromDataErrors((prev) => ({ ...prev, [`${data}Error`]: "" }))
      }
    }
    if (foundError) return
    sendUserPlaystationRegistrationInfo(formData);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      contentClassName={"modal__content"}
      dialogClassName="modal__dialog"
    >
      <Modal.Header className="modal__header pt-3 p-3" closeButton />

      <Modal.Body className="show-grid">
        <Container>
          <Row className=" justify-content-center">
            <Col
              xs={12}
              className="modal__bodyTitle text-center text-white m-2"
            >
              Please Enter Required Information
            </Col>
            <Col
              xs={12}
              className="modal__bodyDescription text-center text-white p-2  m-3"
            >
              Description goes here lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat
            </Col>
            <Col
              xs={12}
              className="modal__bodyGame--question d-flex align-items-center justify-content-center text-center text-white"
            >
              <form className="playstation-form">
                <input
                  type="text"
                  name="userName"
                  placeholder="User Name .."
                  value={formData.userName}
                  onChange={(e) => handleFormChange(e)}
                />
                {formDataErrors.userNameError && (
                  <span>User Name is Required</span>
                )}
                <input
                  type="text"
                  name="playStationName"
                  placeholder="PlayStation Name .."
                  value={formData.playStationName}
                  onChange={(e) => handleFormChange(e)}
                />
                {formDataErrors.playStationNameError && (
                  <span>PlayStation Name is Required</span>
                )}
                <input
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  name="userPhoneNumber"
                  placeholder="User Phone Number .."
                  value={formData.userPhoneNumber}
                  onChange={(e) => handleFormChange(e)}
                />
                {formDataErrors.userPhoneNumberError && (
                  <span>Mobile Number is Required</span>
                )}
              </form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="modal__footer d-flex flex-column justify-content-center m-0">
        <Button
          className="playstation__formButton text-capitalize"
          onClick={handleSubmitForm}
          disabled={isSendingUserPlaystationRegistrationInfo}
        >
          {isSendingUserPlaystationRegistrationInfo ? (
            <div className="spinner-border text-center text-danger fs-xs p-0" role="status" />
          ) : (
            "تأكيد"
          )}
        </Button>
        {!!userPlaystationRegistrationError && !isSendingUserPlaystationRegistrationInfo &&
          <p className="error-msg">حدث خطا يرجي اعادة المحاولة</p>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default PlaystationFormModal;
