import React from "react";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { getTournaments } from "../../redux/Tournaments/Tournaments.Actions";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Competition.css";
import { useEffect } from "react";
import CompetitionLoader from "./CompetitionLoader";
import ErrorMsg from "../../components/ErrorPage/ErrorMsg";
import { ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import useServiceAndSport from "../../hooks/useServiceAndSport";

function TournamentsPage({
  tournaments,
  getTournaments,
  isFetchingTournaments,
  TournamentsFailed,
  currentLanguageCode,
  appActiveSeason
}) {
  const { pathname } = useLocation()
  const { t } = useTranslation();
  const { serviceId, sportId, sportName } = useServiceAndSport();

  useEffect(() => {
    if (serviceId === null || appActiveSeason.id === null) return;
    sportName === 'squash'
      ? getTournaments({ sport_id: sportId, service_id: serviceId, season_id: appActiveSeason.id, items_count: 1000 }, currentLanguageCode, sportName)
      : getTournaments({ sport_id: sportId, service_id: serviceId, season_id: appActiveSeason.id, items_count: 1000 }, currentLanguageCode);
  }, [appActiveSeason.id, serviceId, currentLanguageCode, sportId]);

  return (
    <div>
      <h3 className="all-competions-header text-center p-4 text-light">
        <strong>المسابقات</strong>
        <span className="competitions-overlay"></span>
      </h3>
      <Container>
        {
          isFetchingTournaments
            ? <CompetitionLoader />
            : TournamentsFailed || tournaments.length === 0 || tournaments[0] == ""
              ? <ErrorMsg p={5} fs={"lg"} msg="لا يوجد بطولات" />
              : <div className="row all-competitions justify-content-around" data-testid="Competition-Component">
                {tournaments.map((el) => (
                  <Link
                    to={{ pathname: `/${getServiceName(el.services?.[0])}${getSportRoute(el.sport_id?.id ?? sportId, el.services?.[0])}/tournament/${el.id}/overview`, state: { previousPathname: pathname } }}
                    className="competition-card d-flex flex-column col-4 col-md-2 border-radius-10 bg-black-color align-items-center mt-3 mx-2 py-3"
                    key={el.id}
                  >
                    <div className="img-rounded-circle text-center">
                      <img src={ImgURL(el.logo)} className="mt-3" alt="el.title" />
                    </div>
                    <div className="mt-2 text-center fs-xs">{el.title}</div>
                  </Link>
                ))}
              </div>
        }
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isFetchingTournaments: state.tournaments.isFetchingTournaments,
  tournaments: state.tournaments.Tournaments,
  TournamentsFailed: state.tournaments.TournamentsFailed,
  currentLanguageCode: state.language.currentLanguageCode,
  appActiveSeason: state.seasons.appActiveSeason
});
const mapDispatchToProps = (dispatch) => ({
  getTournaments: (params, currentLanguageCode, sportName) =>
    dispatch(getTournaments(params, currentLanguageCode, sportName)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TournamentsPage);
