import React, { useEffect } from 'react'
import { getTopAssists } from '../../redux/topAssists/topAssists.Actions'
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';
import { getServiceName, getSportRoute } from '../../Layout/helper';

const TopAssists = ({ getTopAssists, topAssists, isFetchingTopAssists, TopAssistsFailed, currentLanguageCode, sport_id, season_id, tournament_id, team_id }) => {

    const history = useHistory();

    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/player/${id}`, { previousPathname: history.location.pathname });
    }
    const { t } = useTranslation()


    useEffect(() =>
        getTopAssists(sport_id, season_id, tournament_id, team_id, currentLanguageCode)
        , [sport_id, season_id, tournament_id, team_id, currentLanguageCode])

    return (
        <div className='bg-black-color white-color fs-xs mt-2'>
            <div className='d-flex justify-content-between fs-md p-2'>
                <div>صانعين الاهداف</div>
            </div>
            <table className="top__assistsTable table-borderless bg-black-color table-dark white-color">
                <thead>
                    <tr className='text-center'>
                        <th scope="col"></th>
                        <th scope="col">لعب</th>
                        <th scope="col">صناعة أهداف</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        TopAssistsFailed !== null ?
                            <tr>
                                <td colSpan={3} className='p-0'>
                                    <ErrorMsg msg="لايوجد معلومات" p={5} fs={'md'} />
                                </td>
                            </tr>
                            :
                            isFetchingTopAssists ?
                                <tr>
                                    <td colSpan={7} className='p-0'>
                                        <LoadingSpinnner p={4} fs={"sm"} />
                                    </td>
                                </tr>
                                :
                                topAssists.length === 0 ?
                                    <tr>
                                        <td colSpan={3} className='p-0'>
                                            <ErrorMsg msg="لايوجد معلومات" p={5} fs={'md'} />
                                        </td>
                                    </tr>
                                    :
                                    topAssists.map(
                                        (player, i) => i <= 4 &&
                                            <tr key={player.id} onClick={() => handleRowClick(player.services?.[0], player.sport_id, player.id)} style={{ cursor: "pointer" }} className='table-clickable-row'>
                                                <th scope="row"><img src={ImgURL(player.image)} width='30px' height="30px" className='mx-2' style={{ borderRadius: '50%' }} alt="" /><span>{player.name}</span></th>
                                                <td className='text-center' >{player.played}</td>
                                                <td className='text-center'>{player.assissts}</td>
                                            </tr>
                                    )
                    }

                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = state => ({
    topAssists: state.topAssists.TopAssists,
    isFetchingTopAssists: state.topAssists.isFetchingTopAssists,
    TopAssistsFailed: state.topAssists.TopAssistsFailed,
    currentLanguageCode: state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch => ({
    getTopAssists: (sport_id, season_id, tournament_id, team_id, lang) => dispatch(getTopAssists(sport_id, season_id, tournament_id, team_id, lang))
})

export default connect(mapStateToProps, mapDispatchToProps)(TopAssists)