import TeamSquadTypes from "./TeamSquad.Types";



const INITIAL_STATE = {
    TeamSquad:{},
    isFetchingTeamSquad:true,
    TeamSquadFailed:null
   };
   
   const TeamSquad = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case TeamSquadTypes.GET_TEAM_SQUAD_START:
        return {
          ...state,
          isFetchingTeamSquad:true
        }
      case TeamSquadTypes.GET_TEAM_SQUAD_SUCCESS:
        return {
          ...state,
          TeamSquad:action.payload,
          isFetchingTeamSquad:false,
          TeamSquadFailed:null
        }
      case TeamSquadTypes.GET_TEAM_SQUAD_FAILED:
        return {
          ...state,
          TeamSquadFailed:action.payload,
          isFetchingTeamSquad:false
        }
       default:
         return state;
     }
   };
 
   export default TeamSquad