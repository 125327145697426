import getMediaListTypes from "./MediaList.Types";
import axios from "axios";
import TwistSettings from '../../TwistSettings.json'
import { globalBaseUrl, IDS } from "../../globalData";

const { REACT_APP_NEW_GET_NEWS } = process.env;

const getMedisList =
  (
    sport_id,
    service_id,
    season_id,
    items_count,
    Media_type,
    tournament_id,
    media_id,
    team_id,
    player_id,
    top_stories,
    latest,
    popular,
    lang
  ) =>
    (dispatch) => {
      dispatch(getMedisListStart(Media_type, top_stories, latest, popular));

      axios({
        method: "get",
        url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_NEWS}`,
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "ar",
        },
        params: {
          sport_id,
          service_id,
          season_id,
          items_count,
          type: Media_type,
          tournament_id: tournament_id,
          media_id: media_id,
          team_id: team_id,
          player_id,
          top_stories: top_stories,
          latest: latest,
          popular: popular,
          portal_id: TwistSettings['twist-ui'] ? IDS.PORTALS.TWIST : IDS.PORTALS.NEW_ET,
        },
      })
        .then((resp) => {
          if (resp.status === 200) {
            const { data, current_page, last_page } = resp.data.data;
            dispatch(
              getMedisListSuccess(
                data,
                { current_page, last_page },
                Media_type,
                top_stories,
                latest,
                popular
              )
            );
          } else {
            dispatch(
              getMedisListFailed(
                resp.message,
                Media_type,
                top_stories,
                latest,
                popular
              )
            );
          }
        })
        .catch((error) =>
          dispatch(
            getMedisListFailed(error, Media_type, top_stories, latest, popular)
          )
        );
    };

const addMedisList =
  (
    sport_id,
    service_id,
    season_id,
    items_count,
    Media_type,
    tournament_id,
    media_id,
    team_id,
    player_id,
    top_stories,
    latest,
    popular,
    page,
    lang
  ) =>
    (dispatch) => {
      dispatch(addMedisListStart(Media_type, top_stories, latest, popular));
      axios({
        method: "get",
        url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_NEWS}`,
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "ar",
        },
        params: {
          sport_id,
          service_id,
          season_id,
          items_count,
          type: Media_type,
          tournament_id: tournament_id,
          media_id: media_id,
          team_id: team_id,
          player_id,
          top_stories: top_stories,
          latest: latest,
          popular: popular,
          page,
          portal_id: TwistSettings['twist-ui'] ? IDS.PORTALS.TWIST : IDS.PORTALS.NEW_ET,
        },
      })
        .then((resp) => {
          if (resp.status === 200) {
            const { data, current_page, last_page } = resp.data.data;
            dispatch(
              addMedisListSuccess(
                data,
                { current_page, last_page },
                Media_type,
                top_stories,
                latest,
                popular
              )
            );
          } else {
            dispatch(
              getMedisListFailed(
                resp.message,
                Media_type,
                top_stories,
                latest,
                popular
              )
            );
          }
        })
        .catch((error) =>
          dispatch(
            getMedisListFailed(error, Media_type, top_stories, latest, popular)
          )
        );
    };

const getMedisListStart = (media_type, top_stories, latest, popular) => ({
  type: getMediaListTypes.GET_MEDIA_LIST_START,
  media_type,
  top_stories,
  latest,
  popular,
});

const addMedisListStart = (media_type, top_stories, latest, popular) => ({
  type: getMediaListTypes.ADD_MEDIA_LIST_START,
  media_type,
  top_stories,
  latest,
  popular,
});

const getMedisListSuccess = (info, meta, type, top_stories, latest, popular) => ({
  type: getMediaListTypes.GET_MEDIA_LIST_SUCCESS,
  payload: info,
  meta,
  media_type: type,
  top_stories,
  latest,
  popular,
});

const addMedisListSuccess = (info, meta, type, top_stories, latest, popular) => ({
  type: getMediaListTypes.ADD_MEDIA_LIST_SUCCESS,
  payload: info,
  meta,
  media_type: type,
  top_stories,
  latest,
  popular,
});

const getMedisListFailed = (error, type, top_stories, latest, popular) => ({
  type: getMediaListTypes.GET_MEDIA_LIST_FAILED,
  payload: error,
  media_type: type,
  top_stories,
  latest,
  popular,
});

export { getMedisList, addMedisList };
