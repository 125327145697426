import React from "react";
import { Col, Container } from "reactstrap";
import NewsLoading from "./NewsLoader";
import { connect } from 'react-redux';
import { getMedisList, addMedisList } from "../../redux/MediaList/MediaList.Action";
import NewsCarousel from "./NewsCarousel";
import { useTranslation } from "react-i18next"
import './newsSection.css'
import { useEffect } from "react";
import MediaCard from "./MediaCard";

function LatestNews({
  isFetchingLatestNews,
  addingLatestNews,
  LatestNews,
  LatestNewsFailed,
  currentLanguageCode,
  getMedisList,
  addMedisList,
  sport_id,
  season_id,
  service_id,
  items_count,
  media_type,
  mini,
  xs, md,
  tournament_id,
  media_id,
  team_id,
  player_id,
  top_stories,
  latest,
  popular,
  LatestNews_CurrentPage,
  LatestNews_LastPage,
}) {

  useEffect(() => {
    getMedisList(sport_id, service_id, season_id, items_count, media_type, tournament_id, media_id, team_id, player_id, top_stories, latest, popular, currentLanguageCode)
  }
    , [sport_id, service_id, season_id, tournament_id, team_id, player_id, media_type, top_stories, latest, popular, currentLanguageCode])

  const { t } = useTranslation()

  return (
    LatestNewsFailed !== null ?
      <div className='p-5 bg-light-black-color fs-lg text-light text-center'>{media_type === 1 ? t(`Error.NewsError`) : t(`Error.VideosError`)}</div>
      :
      isFetchingLatestNews ?
        <NewsLoading mini={mini} />
        :
        LatestNews.length === 0 ?
          <div className='p-5 bg-light-black-color fs-lg text-light text-center'>{media_type === 1 ? t(`Error.NewsError`) : t(`Error.VideosError`)}</div>
          :
          mini ?
            <div className="news-section">
              <div className='row p-2'>
                {LatestNews.map((news) => (
                  <Col xs={xs} md={md} key={news.id} className='p-1'>
                    <MediaCard news={news} type={1} />
                  </Col>
                ))}
              </div>
              <div className='text-center'>
                <button className='load__moreButton btn bg-active-color text-light fs-sm text-nowrap' onClick={() => addMedisList(sport_id, season_id, service_id, items_count, media_type, tournament_id, media_id, team_id, player_id, top_stories, latest, popular, LatestNews_CurrentPage + 1, currentLanguageCode)} disabled={LatestNews_CurrentPage >= LatestNews_LastPage}>
                  {
                    addingLatestNews ?
                      <div className="spinner-border text-light fs-xs p-0" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      :
                      "حمل المزيد"
                  }
                </button>
              </div>
            </div>
            :
            <Container fluid="md">
              <div className="news-section">
                <div className='row'>

                  <div className='col-md-6 col-12 p-0 mt-2'>
                    <NewsCarousel
                      isLoading={isFetchingLatestNews}
                      MediaList={LatestNews.slice(0, 4)}
                      currentLanguageCode={currentLanguageCode}
                      type={1}
                    />
                  </div>
                  <div className='col-md-6 col-12'>
                    <div className='row'>
                      {LatestNews.slice(0, 4).map((news) => (
                        <div className='col-md-6 col-6 p-1' key={news.id}>
                          <MediaCard news={news} type={1} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  {LatestNews.slice(4, 8).map((news) => (
                    <div className='col-md-3 col-6 p-1' key={news.id}>
                      <MediaCard news={news} type={1} />
                    </div>
                  ))}
                </div>
              </div>
            </Container>
  );
}

const mapStateToProps = state => ({
  isFetchingLatestNews: state.MediaList.isFetchingLatestNews,
  LatestNews: state.MediaList.LatestNews,
  addingLatestNews: state.MediaList.addingLatestNews,
  LatestNews_CurrentPage: state.MediaList.LatestNews_CurrentPage,
  LatestNews_LastPage: state.MediaList.LatestNews_LastPage,
  LatestNewsFailed: state.MediaList.LatestNewsFailed,
  currentLanguageCode: state.language.currentLanguageCode
})

const mapDispatchToProps = (dispatch) => ({
  getMedisList: (sport_id, service_id, season_id, items_count, Media_type, tournament_id, media_id, team_id, player_id, top_stories, latest, popular, lang) =>
    dispatch(getMedisList(sport_id, service_id, season_id, items_count, Media_type, tournament_id, media_id, team_id, player_id, top_stories, latest, popular, lang)),

  addMedisList: (sport_id, service_id, season_id, items_count, Media_type, tournament_id, media_id, team_id, player_id, top_stories, latest, popular, lang) =>
    dispatch(addMedisList(sport_id, service_id, season_id, items_count, Media_type, tournament_id, media_id, team_id, player_id, top_stories, latest, popular, lang))

});

export default connect(mapStateToProps, mapDispatchToProps)(LatestNews);