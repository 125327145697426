import { combineReducers } from "redux";
import matchInfoReducer from "./Match/Reducer.Match";

// import { GetTournamentNews } from "./tournaments";
import AllTeamsReducer from "./AllTeams/AllTeams.Reducer";
import TeamDetailsReducer from "./teamDetails/teamDetails.Reducer";
import TournamentTeamsReducer from "./TournamentTeams/TournamentTeams.Reducer";
import TournamentsReducer from "./Tournaments/Tournaments.Reducer";
import TournamentDetailsReducer from "./TournamentDetails/TournamentDetails.Reducer";
import MediaList from "./MediaList/MediaList.Reducer";
import MatchesReducer from "./Matches/Matches.Reducer";
import StandingReducer from "./Standing/Standing.Reducer";
import TournamentStatisticsReducer from "./TournamentStatistics/TournamentStatistics.Reducer";
import TeamPlayersReducer from "./TeamPlayers/TeamPlayers.Reducer";
import CalendarReducer from "./Calendar/Calendar.Reducer";
import LanguageReducer from "./language/language.Reducer";
import GeneralStatisticsReducer from "./GeneralStatistics/GeneralStatistics.Reducer";
import SeasonsReducer from "./Seasons/Seasons.Reducer";
import TopScorersReducer from "./topScorers/topScorers.Reducer";
import TopAssistReducer from "./topAssists/topAssists.Reducer";
import TournamentMatchesReducer from "./TournamentMatches/TournamentMatches.Reducer";
import TournamentHistoryReducer from "./TournamentHistory/TournamentHistory.Reducer";
import TeamStatisticsReducer from "./TeamStatistics/TeamStatistics.Reducer";
import TeamSquadReducer from "./TeamSquad/TeamSquad.Reducer";
import PlayerDetailsReducer from "./PlayerDetails/PlayerDetails.Reducer";
import TransferHistoryReducer from "./TransferHistory/TransferHistory.Reducer";
import PlayerStatisticsReducer from "./PlayerStatistics/PlayerStatistics.Reducer";
import PlayerSeasonStatisticsReducer from "./PlayerSeasonStatistics/PlayerSeasonStatistics.Reducer";
import {
  AllSports,
  FavTeams,
  UserFav,
  addUserChoicesFav,
} from "./Favorite/Reducers.Favorite";
import { MediaDetails } from "./MediaDetails/Reducers.MediaDetails";
import SearchReducer from "./Search/Search.Reducer";
import NewsSearchReducer from "./NewsSearch/NewsSearch.Reducer";
import Subscription from "./Subscription/subscription.Reducer";
import SquashSport from "./Squash/Squash.Reducer";
import SquashMatchCalender from "./SquashMatchCalender/SquashMatchCalender.Reducer";
import SquashMatchDetails from "./SquashMatchDetails/SquashMatchDetails.Reducer";

const rootReducer = combineReducers({
  language: LanguageReducer,
  seasons: SeasonsReducer,
  matchInfo: matchInfoReducer,
  generalStatistics: GeneralStatisticsReducer,
  AllTeams: AllTeamsReducer,
  MediaList: MediaList,
  teamDetails: TeamDetailsReducer,
  teamPlayers: TeamPlayersReducer,
  tournamentHistory: TournamentHistoryReducer,
  tournamentTeams: TournamentTeamsReducer,
  tournaments: TournamentsReducer,
  tournamentDetails: TournamentDetailsReducer,
  standing: StandingReducer,
  tournamentMatches: TournamentMatchesReducer,
  Matches: MatchesReducer,
  tournamentStatistics: TournamentStatisticsReducer,
  teamStatistics: TeamStatisticsReducer,
  teamSquad: TeamSquadReducer,
  calendar: CalendarReducer,
  topScorers: TopScorersReducer,
  topAssists: TopAssistReducer,
  playerDetails: PlayerDetailsReducer,
  transferHistory: TransferHistoryReducer,
  playerStatistics: PlayerStatisticsReducer,
  playerSeasonStatistics: PlayerSeasonStatisticsReducer,
  AllSports: AllSports,
  FavTeams: FavTeams,
  MediaDetails: MediaDetails,
  UserFav: UserFav,
  UserChoicesFav: addUserChoicesFav,
  search:SearchReducer,
  newsSearch:NewsSearchReducer,
  Subscription:Subscription,
  SquashSport:SquashSport,
  matchCalender:SquashMatchCalender,
  squashMatchDetails:SquashMatchDetails
});

export default rootReducer;
