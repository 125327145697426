import { connect } from 'react-redux';
import React from 'react';
import { Container } from 'reactstrap';
import "./SquashMatchDetails.css";
const SquashMatchDetails = ({ matchStatus }) => {


  return (
    <Container className='text-white my-5  squashMatchDetails-page py-2'>
      <h6 className='text-center border-bottom py-3'>نتائج المباراة</h6>
      <div className='justify-content-between d-flex px-4'>

        <div>{matchStatus.result?.split("-")[0]}</div>
        <div> النتيجة</div>
        <div>{matchStatus.result?.split("-")[1]}</div>
      </div>
      {/* <div className='justify-content-between d-flex px-4'>

        <div>{matchStatus.scores?.split(",")[3]?.split("-")[0]}</div>
        <div>الجوله الرابعه</div>
        <div>{matchStatus.scores?.split(",")[3]?.split("-")[1]}</div>
      </div>
      <div className='justify-content-between d-flex  px-4'>
        <div>{matchStatus.scores?.split(",")[2]?.split("-")[0]}</div>
        <div>الجوله الثالثة</div>
        <div>{matchStatus.scores?.split(",")[2]?.split("-")[1]}</div>
      </div>
      <div className='justify-content-between d-flex  px-4'>
        <div>{matchStatus.scores?.split(",")[1]?.split("-")[0]}</div>
        <div>الجوله الثانية</div>
        <div>{matchStatus.scores?.split(",")[1]?.split("-")[1]}</div>
      </div>
      <div className='justify-content-between d-flex  px-4'>
        <div>{matchStatus.scores?.split(",")[0]?.split("-")[0]}</div>
        <div>الجوله الاولي</div>
        <div>{matchStatus.scores?.split(",")[0]?.split("-")[1]}</div>
      </div> */}
    </Container>
  )
}

const mapStateToProps = state => ({
  matchStatus: state.matchInfo.matchStatus[0],
})

export default connect(mapStateToProps)(SquashMatchDetails);
