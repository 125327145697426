import React from 'react'
import { useTranslation } from 'react-i18next';
import ErrorPage from '../ErrorPage/ErrorPage';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import MatchCard from '../MatchCard/MatchCard';
import './TournamentsMatchesList.css'

function TournamentsMatchesList({ tournamentsMatches, isFetchingTournamentsMatches, tournamentsMatchesFailed }) {
  const { t } = useTranslation();

  const renderComingAndResultMatches = (matches, tournamentTitle) => {
    const sortedMatches = matches.sort((a, b) => a.date > b.date ? 1 : -1)
    const comingMatches = sortedMatches.filter(match => match.matchStatus !== 5 && match?.status !== "Complete");
    const resultMatches = sortedMatches.filter(match => match.matchStatus === 5 || match?.status === "Complete");
    return <>
      {
        comingMatches.length > 0 && <>
          <div className='bg-light-black-color p-2 white-color' data-testid="tournaments-matches-coming-title">القادمة من {tournamentTitle ?? comingMatches[0]?.tournament?.title}</div>
          {comingMatches.map((el) => <MatchCard el={el} key={el.id} />)}
        </>
      }
      {
        resultMatches.length > 0 && <>
          <div className='bg-light-black-color p-2 white-color' data-testid="tournaments-matches-results-title">النتائج من {tournamentTitle ?? resultMatches[0]?.tournament?.title}</div>
          {resultMatches.map((el) => <MatchCard el={el} key={el.id} />)}
        </>
      }
    </>
  }

  const handleRenderTournamentsMatches = (tournamentsMatches) => {
    return tournamentsMatches.map(
      (tournament, index) =>
        tournament.matches.length > 0 && (
          <div key={index} className='my-3' data-testid="tournaments-matches-tournament-wrapper">
            {renderComingAndResultMatches(tournament.matches, tournament.title)}
          </div>
        ),
    );
  };

  const isThereAnyTournamentsMatchesToday = (tournamentsMatches) => {
    const foundTournamentWithMatches = tournamentsMatches?.some(
      tournament => tournament.matches?.length > 0,
    );
    return foundTournamentWithMatches;
  };

  if (isFetchingTournamentsMatches) {
    return <div className='col-12 mx-auto col-md-8 white-color' data-testid="tournaments-matches-list-loading">
      <div className='my-3'>
        <div className='bg-light-black-color p-2 white-color'>
          المباريات
        </div>
        <div className='pt-3 white-color bg-black-color'>
          <LoadingSpinnner p={3} fs={"md"} />
        </div>
      </div>
    </div>
  } else if (tournamentsMatchesFailed) {
    return <div className='col-12 col-md-8' data-testid="tournaments-matches-list-error">
      <ErrorPage error="لا يوجد مباريات" />
    </div>
  } else if (!isThereAnyTournamentsMatchesToday(tournamentsMatches)) {
    return <div className='col-12 mx-auto col-md-8 white-color' data-testid="tournaments-matches-list-empty-data">
      <div className='my-3'>
        <div className='bg-light-black-color p-2 white-color'>
          المسابقات
        </div>
        <div className='p-5 white-color bg-black-color'>
        لا يوجد مباريات
        </div>
      </div>
    </div>
  } else {
    return <div className='col-12 mx-auto col-md-8 tournaments-matches-list'>
      {handleRenderTournamentsMatches(tournamentsMatches)}
    </div>
  }
}

export default TournamentsMatchesList;