import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import LoadingSpinnner from "../loadingSpinner/loadingSpinner";
import { useTranslation } from "react-i18next";
import TwistSettings from '../../TwistSettings.json'

const PlayerOverviewLoader = () =>{

    const { t } = useTranslation()
    return (
        <SkeletonTheme color={TwistSettings["twist-ui"] ? "#004F90" : "#323232"} highlightColor="#ecebeb">
        <div className='row p-4 bg-light-black-color'>    
         
   
        <div className='col-3'>
        <Skeleton height={80} width={'100%'} className='border border-x border-secondary'/>
        </div>     
        
        <div className='col-3'>
        <Skeleton height={80} width={'100%'} className='border border-x border-secondary'/>
        </div>
        <div className='col-3'>
        <Skeleton height={80} width={'100%'} className='border border-x border-secondary'/>
        </div>

        <div className='col-3'>
        <Skeleton height={80} width={'100%'} className='border border-x border-secondary'/>
        </div>
        </div>
        <div className='row d-flex justify-content-around my-5'>
            <div className='col-12 col-md-5'>
                <div className='row text-light bg-black-color p-4'>
                    <div className='col-3 '>
                        <Skeleton height={50} width={50} className="rounded-circle" />
                    </div>
                    <div className='col-6'>
                        <Skeleton height={50} width={150} />
                    </div>
                    <div className='col-3'>
                        <Skeleton height={50} width={50} className="rounded-circle" />
                    </div>   
                </div>
            </div>
            <div className='col-12 col-md-5'>
                <div className='row text-light bg-black-color p-4'>
                    <div className='col-3 '>
                        <Skeleton height={50} width={50} className="rounded-circle" />
                    </div>
                    <div className='col-6'>
                        <Skeleton height={50} width={150} />
                    </div>
                    <div className='col-3'>
                        <Skeleton height={50} width={50} className="rounded-circle" />
                    </div>   
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-6'>
                <div className='bg-black-color white-color p-2'>المركز الاساسي</div>
                <LoadingSpinnner p={5} fs={'md'}/>
            </div>
            <div className='col-6'>
                <div className='bg-black-color white-color p-2'>تاريخ الانتقالات</div>
                <LoadingSpinnner p={5} fs={'md'}/>
            </div>    
        </div>

        </SkeletonTheme>
    )
}

export default PlayerOverviewLoader