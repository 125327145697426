import TransferHistoryTypes from "./TransferHistory.Types";
import axios from 'axios'
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_TRANSFER_HISTORY } = process.env

const getTransferHistoryStart = () => ({
  type: TransferHistoryTypes.GET_TRANSFER_HISTORY_START
})

const getTransferHistorySuccess = (info) => ({
  type: TransferHistoryTypes.GET_TRANSFER_HISTORY_SUCCESS,
  payload: info
})

const getTransferHistoryFailed = (info) => ({
  type: TransferHistoryTypes.GET_TRANSFER_HISTORY_FAILED,
  payload: info
})


const getTransferHistory = (player_id, lang) => (
  dispatch => {
    dispatch(getTransferHistoryStart())
    axios({
      method: "get",
      url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_TRANSFER_HISTORY}${`/${player_id}`}`,
      // params: { match_id:42312 },
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`
      },
    }).then(resp => {
      if (resp.data.status === 200) {
        dispatch(getTransferHistorySuccess(resp.data.data))
      }
      else {
        dispatch(getTransferHistoryFailed(resp.data.message))
      }
    }
    )
      .catch(error => dispatch(getTransferHistoryFailed(error)))

  }
)

export { getTransferHistory }