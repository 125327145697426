import moment from "moment";
import Cookies from "universal-cookie";
import api from "./apis";
import { globalBaseUrl } from "./globalData";
import TwistSettings from './TwistSettings.json';


export function convertDate(date) {
  var dateObj = new Date(date);
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();

  return year + '-' + month + '-' + day;
}

export function convertFullDate(date, lang = "ar") {
  const daysEn = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
    monthsEn = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthsAr = [
      "يناير",
      "فبراير",
      "مارس",
      "إبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    daysAr = [
      "اﻷحد",
      "اﻷثنين",
      "الثلاثاء",
      "اﻷربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ],
    day = moment(date).format("DD"),
    year = moment(date).year();
  if (lang === "en") {
    return `${daysEn[moment(date).day()]}, ${day} ${monthsEn[moment(date).month()]
      } ${year} `;
  } else if (lang === "ar") {
    return `${daysAr[moment(date).day()]}, ${day} ${monthsAr[moment(date).month()]
      } ${year} `;
  }
}

export const getName = (title, wordLength = 12) => {
  if (!title) return '';
  const titleArr = title.split(" ")
  const newTitle = []
  let temp = 0
  for (let i = 0; i < titleArr.length; i++) {
    const newtemp = titleArr[i].length + temp
    if (newtemp < wordLength) {
      newTitle.push(titleArr[i])
      temp = newtemp
    }
  }
  return `${newTitle.join(' ')}`
}

export const getUserPhoneNumbers = () => {
  const cookies = new Cookies();
  const allCookies = cookies.getAll();
  const { public: publicService, local, africa, international, othersports } = allCookies;
  const foundPhoneNumbers = [local, africa, international, othersports, publicService].filter(ele => ele).map(ele => ele.split(",")[1]);
  return foundPhoneNumbers;
}

export const defineCurrentBaseUrl = () => {
  const { REACT_APP_NEW_BASE_URL } = process.env;
  if (!TwistSettings['current-base-url-performane']) globalBaseUrl.current = REACT_APP_NEW_BASE_URL;
}

defineCurrentBaseUrl()

export const getTeamOrPlayerService = async (id, type) => {
  const res = await api.service.get({ id, type });
  const { services } = res?.data
  return services[0];
}