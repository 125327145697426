import SquashTypes from "./Squash.Types";



const INITIAL_STATE = {
    Squash:[''],
    isFetchingSquash:true,
    SquashFailed:null
   };
   
   const SquashSport = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case SquashTypes.GET_SQUASH_START:
        return {
          ...state,
          isFetchingSquash:true
        }
      case SquashTypes.GET_SQUASH_SUCCESS:
        return {
          ...state,
          Squash:action.payload,
          isFetchingSquash:false,
          SquashFailed:null
        }
      case SquashTypes.GET_SQUASH_FAILED:
        return {
          ...state,
          SquashFailed:action.payload,
          isFetchingSquash:false
        }
       default:
         return state;
     }
   };
 
   export default SquashSport