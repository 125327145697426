import TopAssistsTypes from "./topAssists.Types";
import axios from 'axios'
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_TOP_ASSISTS } = process.env

const getTopAssistsStart = () => ({
  type: TopAssistsTypes.GET_TOP_ASSISTS_START
})

const getTopAssistsSuccess = (info) => ({
  type: TopAssistsTypes.GET_TOP_ASSISTS_SUCCESS,
  payload: info
})

const getTopAssistsFailed = () => ({
  type: TopAssistsTypes.GET_TOP_ASSISTS_FAILED
})


const getTopAssists = (sport_id, season_id, tournament_id, team_id, lang) => (
  dispatch => {
    dispatch(getTopAssistsStart())
    axios({
      method: "get",
      url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_TOP_ASSISTS}`,
      params: { sport_id, season_id, tournament_id, team_id },
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`
      },
    }).then(resp => {
      if (resp.data.status === 200) {
        dispatch(getTopAssistsSuccess(resp.data.data))
      }
      else {
        dispatch(getTopAssistsFailed(resp.data.message))
      }
    }
    )
      .catch(error => dispatch(getTopAssistsFailed(error)))

  }
)

export { getTopAssists }