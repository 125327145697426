import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getPlayerDetails } from "../../redux/PlayerDetails/PlayerDetails.Actions";
import { useTranslation } from "react-i18next";
import HeaderInfoComponentLoader from "./HeaderInfoComponentLoader";
import "./player.css";
import PlayerOverview from "../../components/PlayerOverview/PlayerOverview";
import PlayerStatistics from "../../components/PlayerStatistics/PlayerStatistics";
import PlayerPrizes from "../../components/PlayerPrizes/PlayerPrizes";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { useHistory } from "react-router-dom";
import LoadingSpinnner from "../../components/loadingSpinner/loadingSpinner";
import { ImgURL } from "../../globalData";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import useServiceGuard from "../../hooks/useServiceGuard";
import PrizesCarousel from "./PrizesCarousel";
import AllMedia from "../../components/News/AllMedia";

const Player = ({
  PlayerDetails,
  PlayerDetailsFailed,
  activeSeason,
  isFetchingPlayerDetails,
  getPlayerDetails,
  currentLanguageCode,
}) => {
  const { t } = useTranslation();
  const [key, setKey] = useState("Overview");
  const id = useParams().id;
  const tab = useParams().tab || "overview";
  const { serviceName, sportName, sportId } = useServiceAndSport();
  const history = useHistory();
  useServiceGuard(isFetchingPlayerDetails, PlayerDetails?.services);

  const handelTabClick = (tab) => {
    const sportUrl = `${sportName ? `${sportName}/` : ""}`;
    history.push(`/${serviceName}/${sportUrl}player/${id}/${tab}`, { previousPathname: history.location.pathname });
  };

  useEffect(() => {
    if (sportName !== null) {
      getPlayerDetails(id, currentLanguageCode, sportName)
    }
  }, [id, currentLanguageCode, sportName, serviceName]);

  const hasBackground = !!PlayerDetails.current_team?.background;


  return (
    <div>
      {PlayerDetailsFailed ? (
        <ErrorPage p={5} fs={"md"} msg="لا يوجد معلومات عن الاعب" />
      ) : isFetchingPlayerDetails ? (
        <HeaderInfoComponentLoader />
      ) : (
        <div className={`player-details-header-container ${hasBackground ? '' : 'without-team-bg'}`} data-testid="player-details-wrapper">
          <div className="overlay"></div>
          <div
            className="player-details-header"
            style={{ backgroundImage: `${hasBackground ? `url(${PlayerDetails.current_team?.background})` : ''}` }}
          ></div>
          <div className="player-details-img">
            <img src={ImgURL(PlayerDetails.image)} alt="player" />
          </div>
          <div className="player-details-header-info ps-sm-3 ps-md-0">
            <div>
              <div className="player-details-header-info-num ms-md-5">
                {PlayerDetails.shirt_number}
              </div>
              <div className="player-details-name fs-xxl  ms-md-5 text-light">
                <strong>{PlayerDetails.name}</strong>
              </div>
            </div>
            {
              PlayerDetails.current_team?.logo &&
              <img
                src={
                  ImgURL(PlayerDetails.current_team?.logo)
                }
                className="player-details-current-team-img ms-2 ms-md-0"
                alt="team"
              />
            }
          </div>
          <div className="player-details-header-champions-market d-flex justify-content-start">
            <div className="d-none d-md-flex justify-content-around align-items-center player-details-header-champions p-3 mx-1">
              <PrizesCarousel PlayerDetails={PlayerDetails} />
            </div>
            {
              PlayerDetails.market_value && <div className="player-details-header-market mx-0 mx-md-4 p-sm-2 p-md-3 p-md-3 text-center fs-xl">
                <span className="text-nowrap">{
                  PlayerDetails.market_value > 1000000
                    ? `${Number(PlayerDetails.market_value / 1000000)} م`
                    : `${Number(PlayerDetails.market_value / 1000)} ألف`
                }</span>
              </div>
            }
          </div>
        </div>
      )}
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={tab || "Overview"}
        onSelect={(k) => setKey(k)}
        activeKey={tab || key}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Nav
          variant="tabs"
          className="mb-3 d-flex justify-content-center flex-nowrap fs-sm"
        >
          <Nav.Item>
            <Nav.Link
              eventKey="overview"
              onClick={() => handelTabClick("overview")}
            >
              نظره عامه
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="news" onClick={() => handelTabClick("news")}>
            اخر الاخبار
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="stats" onClick={() => handelTabClick("stats")}>
            احصائيات
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="prizes"
              onClick={() => handelTabClick("prizes")}
            >
              جوائز
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="overview">
            <PlayerOverview
              toLocalTab={setKey}
              season_id={activeSeason.id}
              playerDetails={PlayerDetails}
              isFetchingPlayerDetails={isFetchingPlayerDetails}
              PlayerDetailsFailed={PlayerDetailsFailed}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="news">
            <Container>
              <AllMedia
                sport_id={sportId}
                season_id={activeSeason.id}
                player_id={id}
                items_count={16}
                latest={true}
                type={1}
              />
            </Container>
          </Tab.Pane>
          <Tab.Pane eventKey="stats">
            <PlayerStatistics
              sport_id={sportId}
              season_id={activeSeason.id}
              player_id={id}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="prizes">
            {isFetchingPlayerDetails ? (
              <LoadingSpinnner fs={"lg"} p={5} />
            ) : (
              <PlayerPrizes prizes={PlayerDetails.champions} />
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPlayerDetails: (player_id, currentLanguageCode, sportName) =>
    dispatch(getPlayerDetails(player_id, currentLanguageCode, sportName)),
});

const mapStateToProps = (state) => ({
  PlayerDetailsFailed: state.playerDetails.PlayerDetailsFailed,
  PlayerDetails: state.playerDetails.PlayerDetails,
  isFetchingPlayerDetails: state.playerDetails.isFetchingPlayerDetails,
  activeSeason: state.seasons.activeSeason,
  currentLanguageCode: state.language.currentLanguageCode,
});

export default connect(mapStateToProps, mapDispatchToProps)(Player);
