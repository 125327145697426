const StandingTypes = {
    GET_STANDING_START: 'GET_STANDING_START',
    GET_STANDING_SUCCESS: 'GET_STANDING_SUCCESS',
    ADD_H2H_TEAM: 'ADD_H2H_TEAM',
    OPEN_H2H_MODAL: "OPEN_H2H_MODAL",
    CLOSE_H2H_MODAL: 'CLOSE_H2H_MODAL',
    GET_STANDING_FAILED: 'GET_STANDING_FAILED',

}

export default StandingTypes