import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useTranslation } from "react-i18next"

const TeamStatChart = ({ TeamStatistics }) => {
    const { t } = useTranslation()

   const getCharArr=(obj)=>{
     const ChartArr=[{value:Number(obj.win),color:"#fc9700"},{value:Number(obj.lose),color:"#14C4BC"},{value:Number(obj.draw),color:"#682BC5"}]
    const sortedArray=ChartArr.sort(function(a, b) {
        return b.value - a.value
    })
       return sortedArray
   }
   const defaultLabelStyle = {
    fontSize: '6px',
    fill:'white'
  };

    return (
          <div style={{position:'relative'}}>
              <div className='bg-black-color text-light pb-4'>
              <div style={{height:'300px'}}>    
              <PieChart
                    data={getCharArr(TeamStatistics)}
                    radius={40}
                    segmentsShift={.4}
                    label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
                    lineWidth={40}
                    labelStyle={{
                    ...defaultLabelStyle,
                    }}
                    labelPosition={80}
                    animate
                    paddingAngle={0}
                    viewBoxSize={[100,110]}
                    startAngle={120}
                    lengthAngle={360}
                    segmentsStyle={(index) => (index === 0 ? {strokeWidth:'20px'}: index === 1 ?  {strokeWidth:'18px'} : 0)}
                />
                </div>
                <div className='d-flex justify-content-around'>
                    <div>
                        <span className='mx-2'>{TeamStatistics.win}</span>   
                        <span style={{backgroundColor:'#fc9700',padding:'7px', color:'transparent'}}>1</span>  
                        <span className="fs-sm">فوز</span>   
                    </div>
                    <div>
                        <span className='mx-2'> {TeamStatistics.lose}</span>
                        <span style={{backgroundColor:'#14C4BC',padding:'7px', color:'transparent'}}>1</span>
                        <span className="fs-sm">خساره</span>
                    </div>
                    <div>
                        <span className='mx-2'>  {TeamStatistics.draw}</span>
                        <span style={{backgroundColor:'#fc9700',padding:'7px', color:'transparent'}}>1</span>
                        <span className="fs-sm">تعادل</span>
                    </div>
                </div>    
              </div>
              <div className='text-center text-light' style={{position:'absolute' ,top:'30%',right:'45%'}}>
                  <div>لعب</div>
                  <div><strong>{TeamStatistics.played}</strong></div>
              </div>
          </div>
    )
}





export default TeamStatChart