import TournamentsTypes from "./Tournaments.Types";
import api from "../../apis";
import axios from 'axios'
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_TOURNAMENTS } = process.env

const getTournamentsStart = () => ({
  type: TournamentsTypes.GET_TOURNAMENTS_START
})

const getTournamentsSuccess = (info) => ({
  type: TournamentsTypes.GET_TOURNAMENTS_SUCCESS,
  payload: info
})

const getTournamentsFailed = (error) => ({
  type: TournamentsTypes.GET_TOURNAMENTS_FAILED,
  payload: error
})


const getTournaments = (params, lang, sportName = "") => (
  dispatch => {
    dispatch(getTournamentsStart())
    if (!sportName) {
      axios({
        method: "get",
        url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_TOURNAMENTS}`,
        params,
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": `${lang}`
        },
      }).then(resp => {
        if (resp.data.status === 200) {
          dispatch(getTournamentsSuccess(resp.data.data.data))
        }
        else {
          dispatch(getTournamentsFailed(resp.data.message))
        }
      }
      )
        .catch(error => {
          dispatch(getTournamentsFailed(error))
        })
    }
    else {
      api.squash.getTournaments().then(res => {
        if (res.code === 200) {
          const customizedSquashData = [...res.data];
          customizedSquashData.forEach(data => {
            const { start_date, image, name_ar } = data;
            // data.service = {
            //   id: 10,
            //   title: "othersports",
            //   active: 1,
            // };
            // data.sport_id = {
            //   active: 1,
            //   created_at: start_date,
            //   id: 7,
            //   image: image,
            //   is_favourite: false,
            //   title: "Squash ",
            //   type: "Individual",
            // }
            data.services = [10];
            data.sport_id = 7;
            data.title = name_ar;
          })
          dispatch(getTournamentsSuccess(customizedSquashData));
        } else {
          dispatch(getTournamentsFailed(res.message));
        }
      })
    }

  }
)

export { getTournaments }

