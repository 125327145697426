import React from "react";
import { Route, useLocation } from "react-router-dom";
import Cookies from 'universal-cookie';


function ProtectedRoute({ component: Component, ...rest }) {
  const { pathname } = useLocation();
  const serviceName = pathname.split("/")[1];
  const cookies = new Cookies();
  const isAllowed = !!cookies.get(serviceName);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAllowed) {
          return <Component {...props} isSubscriped={true} />;
        } else {
          return <Component {...props} isSubscriped={false} />;
        }
      }}
    />
  );
}

export default ProtectedRoute;
