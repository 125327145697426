import React from "react";
import { useTranslation } from "react-i18next";
import AllMedia from "../../components/News/AllMedia";
import SportsSectionHeader from "../../components/sportsSectionHeader/sportsSectionHeader.component";
import { connect } from "react-redux";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import SpecialMedia from "../../components/SpecialMedia";
import SectionMediaList from "../Section/SectionMediaList";
import { IDS } from "../../globalData";
import TwistSettings from "../../TwistSettings.json"

const Videos = ({ activeSeason }) => {
  const { t } = useTranslation();
  const { serviceId, sportId } = useServiceAndSport();

  return (
    <div className="container home-page">
      <div className="d-flex justify-content-center row">
        <div className="col-12">
          {/* <img src={IphoneAdd} height={200} width={"100%"} /> */}
        </div>
      </div>
      {serviceId === IDS.SERVICES.AFRICA && TwistSettings["media-sections"] && (
        <>
          <SectionMediaList sectionName="adelSaad" />
          <SectionMediaList sectionName="africaStories" />
        </>
      )}
      <SportsSectionHeader title="فيديوهات متميزه" />
      <AllMedia
        sport_id={sportId}
        service_id={serviceId}
        season_id={activeSeason.id}
        items_count={4}
        type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        top_stories={true}
        latest={null}
        popular={null}
      />

      <div className="d-flex justify-content-center mt-4">
        {/* <img src={pepsiAdd} width={"100%"} height={200} /> */}
      </div>
      <SportsSectionHeader title="احدث الفيديوهات" />
      <AllMedia
        sport_id={sportId}
        service_id={serviceId}
        season_id={activeSeason.id}
        items_count={9}
        type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        top_stories={null}
        latest={true}
        popular={null}
      />
      <hr />
      <SportsSectionHeader title="الاكثر تداولا" />
      <AllMedia
        sport_id={sportId}
        service_id={serviceId}
        season_id={activeSeason.id}
        items_count={12}
        type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        top_stories={null}
        latest={null}
        popular={true}
      />
      <hr />
      {
        (serviceId === IDS.SERVICES.LOCAL || serviceId === IDS.SERVICES.INTERNATIONAL) &&
        TwistSettings["media-sections"] &&
        <SpecialMedia />
      }
      {
        serviceId === IDS.SERVICES.OTHERSPORTS && <>
          <SportsSectionHeader title="حلم الفراعنة" />
          <AllMedia items_count={9} type={2} section_id={27} sport_id={sportId} service_id={serviceId} />
        </>
      }
      <SportsSectionHeader title="كل الفيديوهات" />
      <AllMedia
        sport_id={sportId}
        service_id={serviceId}
        season_id={activeSeason.id}
        items_count={12}
        type={2}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        top_stories={null}
        latest={null}
        popular={null}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  NewsSearchQuery: state.newsSearch.NewsSearchQuery,
  activeSeason: state.seasons.activeSeason,
});

export default connect(mapStateToProps)(Videos);
