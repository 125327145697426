import HttpHelper from './helper';

export const matchesEndpoints = {
  get: (params) => HttpHelper.baseAxios
    .get(`/matches`, { params })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  getTournamentsMatches: (params) => HttpHelper.baseAxios
    .get(`/tournament_matches`, { params })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  getMatchEvents: (match_id) => HttpHelper.baseAxios
    .get(`/match_events?match_id=${match_id}`)
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),
};
