import axios from 'axios';

class HttpHelper {
  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_PERFORMANCE_BASE_URL;
    this.apiSquashBaseUrl = process.env.REACT_APP_NEW_BASE_URL;
    this.apiMobi89Url = process.env.REACT_APP_89_BASE_URL;
    this.etisalatsportsUrl = process.env.REACT_APP_ETISALATSPORTS;
    this.apiPlaystationUrl = process.env.REACT_APP_PLAYSTATION_URL;
    this.paymobApiBaseUrl = process.env.REACT_APP_PAYMOB_API_BASE_URL;
    this.fawryApiBaseUrl = process.env.REACT_APP_FAWRY_API_BASE_URL;

    this.baseAxios = axios.create({
      baseURL: this.apiBaseUrl,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ar"
      },
    });

    this.baseSquashAxios = axios.create({
      baseURL: this.apiSquashBaseUrl,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ar"
      },
    });

    this.mobi89Axios = axios.create({
      baseURL: this.apiMobi89Url,
      headers: {
        "Content-Type": "application/json",
        "application_key": process.env.REACT_APP_API_KEY,
      },
    });

    this.etisalatsportsAxios = axios.create({
      baseURL: this.etisalatsportsUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.basePlaystationAxios = axios.create({
      baseURL: this.apiPlaystationUrl,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ar"
      },
    });

    this.paymobAxios = axios.create({
      baseURL: this.paymobApiBaseUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.fawryAxios = axios.create({
      baseURL: this.fawryApiBaseUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
};

export default new HttpHelper();