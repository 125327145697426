import TournamentMatchesTypes from "./TournamentMatches.Types";



const INITIAL_STATE = {
  Fixtures: [],
  Results: [],
  allTournamentMatchesFixtures: [],
  allTournamentMatchesResults: [],
  isFetchingTournamentMatches: true,
  TournamentMatchesFailed: null,
  firstReload: false
};

const TournamentMatches = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_START:
      return {
        ...state,
        isFetchingTournamentMatches: true
      }
    case TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_SUCCESS:
      let Fixtures = [...state.Fixtures];
      let Results = [...state.Results];
      if (action.date_type === 1) {
        Fixtures = action.payload.filter(match => match.matchStatus !== 5 && match.status !== "Complete");
      } else if (action.date_type === 2) {
        Results = action.payload.filter(match => match.matchStatus === 5 || match.status === "Complete");
      } else {
        Fixtures = action.payload.filter(match => match.matchStatus !== 5 && match.status !== "Complete");
        Results = action.payload.filter(match => match.matchStatus === 5 || match.status === "Complete");
      }
      return {
        ...state,
        Fixtures,
        Results,
        allTournamentMatchesFixtures: action.firstReload ? Fixtures : [...state.allTournamentMatchesFixtures],
        allTournamentMatchesResults: action.firstReload ? Results : [...state.allTournamentMatchesResults],
        isFetchingTournamentMatches: false,
        TournamentMatchesFailed: null,
        firstReload: action.firstReload
      }
    case TournamentMatchesTypes.GET_TOURNAMENT_MATCHES_FAILED:
      return {
        ...state,
        TournamentMatchesFailed: action.payload,
        isFetchingTournamentMatches: false
      }
    default:
      return state;
  }
};

export default TournamentMatches