import React, { useEffect, useState } from 'react';
import { Col, Nav, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getCalendar } from '../../redux/Calendar/Calendar.Actions';
import MatchesCarouselComponent from "../MatchesCarousel/MatchesCarouselComponent";
import { useTranslation } from "react-i18next"
import "./MatchesCalendar.css";
import MatchesCalendarLoader from './MatchesCalendarLoader';
import ErrorMsg from '../ErrorPage/ErrorMsg';

function MatchesTabComponent({
  getCalendar,
  CalendarFailed,
  isFetchingCalendar,
  Calendar,
  currentLanguageCode,
  sport_id,
  service_id,
  season_id,
  tournament_id,
  team_id,
  showSquashTab
}) {
  const { t } = useTranslation();

  useEffect(() => {
    getCalendar(sport_id, service_id, season_id, tournament_id, team_id, currentLanguageCode)
  }, [sport_id, service_id, season_id, tournament_id, team_id, currentLanguageCode])

  return (
    <div className="my-4 matches-calendar">
      <Tab.Container id="left-tabs-example" defaultActiveKey="today">
        <Col>
          <Nav className='d-flex justify-content-center'>
            <Nav.Item className="mx-1 mx-md-4 mb-3" >
              <Nav.Link eventKey="yesterday" >
                <div className="  text-center fs-xs mt-2"><strong>امس</strong></div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mx-1 mx-md-4 mb-3">
              <Nav.Link eventKey="today" className="  text-center fs-xs days_tab">
                <div className="  text-center fs-xs mt-2"><strong>اليوم</strong></div></Nav.Link>
            </Nav.Item>
            <Nav.Item className={`mx-1 mx-md-4 mb-3`} >
              <Nav.Link eventKey="tomorrow" className=" text-center fs-xs days_tab">
                <div className="  text-center fs-xs mt-2"><strong>غدا</strong></div></Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <div className="my-auto"  >
          <Tab.Content >
            <Tab.Pane eventKey="yesterday" >
              {
                CalendarFailed !== null
                  ? <ErrorMsg p={5} fs={'md'} msg="لا يوجد مباريات" />
                  : isFetchingCalendar
                    ? <MatchesCalendarLoader />
                    : <MatchesCarouselComponent showSquashTab={showSquashTab} matches={Calendar?.yesterday} />
              }
            </Tab.Pane>
            <Tab.Pane eventKey="today"  >
              {
                CalendarFailed !== null
                  ? <ErrorMsg p={5} fs={'md'} msg="لا يوجد مباريات" />
                  : isFetchingCalendar
                    ? <MatchesCalendarLoader />
                    : <MatchesCarouselComponent showSquashTab={showSquashTab} matches={Calendar?.today} />
              }
            </Tab.Pane>
            <Tab.Pane eventKey="tomorrow">
              {
                CalendarFailed !== null
                  ? <ErrorMsg p={5} fs={'md'} msg="لا يوجد مباريات" />
                  : isFetchingCalendar
                    ? <MatchesCalendarLoader />
                    : <MatchesCarouselComponent showSquashTab={showSquashTab} matches={Calendar?.tomorrow} />
              }
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  )
}


const mapDispatchToProps = dispatch => ({
  getCalendar: (sport_id, service_id, season_id, tournament_id, team_id, lang) => dispatch(getCalendar(sport_id, service_id, season_id, tournament_id, team_id, lang))

})

const mapStateToProps = state => ({
  isFetchingCalendar: state.calendar.isFetchingCalendar,
  Calendar: state.calendar.Calendar,
  CalendarFailed: state.calendar.CalendarFailed,
  currentLanguageCode: state.language.currentLanguageCode,
  isFetchingSquashMatchCalender: state.matchCalender.isFetchingMatchCalender,
  isFailedSquashMatchCalender: state.matchCalender.matchCalenderFailed
})

export default connect(mapStateToProps, mapDispatchToProps)(MatchesTabComponent)
