import SquashMatchCalenderTypes from "./SquashMatchCalender.Types";
import api from '../../apis'
import { injectSquashServiceAndSport } from "../helpers";

const updateDataStrucutre = (data) => {
  const newData = data;
  newData.today = injectSquashServiceAndSport(newData.today)
  newData.tomorrow = injectSquashServiceAndSport(newData.tomorrow)
  newData.yesterday = injectSquashServiceAndSport(newData.yesterday)
  return newData;
}

const squashMatchCalenderStart = () => ({
    type:SquashMatchCalenderTypes.GET_SQUASH_MATCH_CALENDER_START
})

const squashMatchCalenderSuccess = (info) => ({
    type:SquashMatchCalenderTypes.GET_SQUASH_MATCH_CALENDER_SUCCESS,
    payload:info
})

const squashMatchCalenderFailed = () => ({
    type:SquashMatchCalenderTypes.GET_SQUASH_MATCH_CALENDER_FAILED
})


const getSquashMatchCalender = () => dispatch => {
  dispatch(squashMatchCalenderStart())
  api.squash.getMatchCalender()
    .then(res => {   
      if (res.status===200) { 
        dispatch(squashMatchCalenderSuccess(updateDataStrucutre(res.data)))
      } else {
        dispatch(squashMatchCalenderFailed(res.message))
      }
    })
    .catch(error => dispatch(squashMatchCalenderFailed(error)))
}

export { getSquashMatchCalender }