import React from "react";
import "./GameSuccessView.css";
import { useTranslation } from "react-i18next";

function GameSuccessView({numOfCorrectAswers, questionsLength, gameName}) {
  const { t } = useTranslation();
  return (
    <div className="success-conatiner d-flex flex-column justify-content-center align-items-center text-center">
      <span>تم إرسال الإجابات</span>
      {
      (gameName === "quzzes") && 
      <>
        <span>{`جاوبت   ( ${numOfCorrectAswers} ) إجابات صحيحة `}</span>
        <span>{ `من أصل ( ${questionsLength+1} ) اسئله` }</span>
      </>
      }

    </div>
  );
}

export default GameSuccessView;
