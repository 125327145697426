import React from 'react';
import { useReducer } from 'react';
import { connect } from 'react-redux';
import ElHadaryJourneySection from './ElHadaryJourneySection';
import EtisalatStudioSection from './EtisalatStudioSection';
import useServiceAndSport from '../../hooks/useServiceAndSport';
import { useParams } from 'react-router-dom';
import FanClubNewsSection from './FanClubNewsSection';

const initialState = {
    isLoading: false,
    fetchedData: [],
    meta: { current_page: 1 },
    errors: ""
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_DATA_REQUEST":
            return {
                ...state,
                isLoading: true
            }
        case "FETCH_DATA_SUCCESS":
            return {
                ...state,
                fetchedData: [...state.fetchedData, ...action.payload],
                meta: action.meta,
                isLoading: false,
            }
        case "FETCH_DATA_FAILURE":
            return {
                ...state,
                fetchedData: [],
                meta: { current_page: 1 },
                errors: action.payload
            }
        default:
            return state;
    }
}

function SpecialMedia({ currentLanguageCode, msisdn }) {
    const [elhadaryState, dispatchElhadary] = useReducer(reducer, initialState);
    const [etisalatStudioState, dispatchEtisalatStudio] = useReducer(reducer, initialState);
    const [fanClubState, dispatchFanClub] = useReducer(reducer, initialState);
    const { serviceId } = useServiceAndSport();
    const { id } = useParams();

    return (
        <>
            {serviceId === 7 &&
                <ElHadaryJourneySection currentState={elhadaryState} dispatch={dispatchElhadary} lang={currentLanguageCode} />
            }
            {
                serviceId !== null && serviceId !== 9 && id !== "726" &&
                <EtisalatStudioSection service_id={serviceId} currentState={etisalatStudioState} dispatch={dispatchEtisalatStudio} lang={currentLanguageCode} />
            }
            {
                id === "726" &&
                <FanClubNewsSection msisdn={msisdn} currentState={fanClubState} dispatch={dispatchFanClub} lang={currentLanguageCode} />
            }
        </>
    )
}

const mapStateToProps = state => ({
    currentLanguageCode: state.language.currentLanguageCode,
})

export default connect(mapStateToProps)(SpecialMedia)
