import React from "react";
import { useHistory } from "react-router-dom";

const SportsSectionHeader = ({ title, className, navigateTo, dataTestId }) => {
  const history = useHistory()
  return (
    <div data-testid={dataTestId} className={`${className} text-light fs-xxm my-3 d-flex align-items-center justify-content-between`}>
      <strong>{title}</strong>
      {
        navigateTo && <h3
          className="swipe-animation m-0"
          data-testid="sports-section-header-navigateTo"
          onClick={() => history.push(navigateTo, { previousPathname: history.location.pathname })}
        ><strong>{'>'}</strong></h3>
      }
    </div>
  );
};

export default SportsSectionHeader;
