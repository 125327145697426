import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getTournamentHistory } from "../../redux/TournamentHistory/TournamentHistory.Actions";
import { getGeneralStatistics } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { setCurrentlyDisplayedGeneralTournamentHistory } from '../../redux/GeneralStatistics/GeneralStatistics.Actions'
import { addH2Hteam } from '../../redux/TournamentHistory/TournamentHistory.Actions'
import { useTranslation } from "react-i18next"
import Language from '../../redux/language/language.Reducer'
import { Container } from 'react-bootstrap';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';



const TournamentHistory = ({ TournamentHistory, isFetchingTournamentHistory, TournamentHistoryFailed, currentLanguageCode, getTournamentHistory }) => {


    const id = useParams().id;

    useEffect(() => {
        getTournamentHistory(id, currentLanguageCode)
    }, [id, currentLanguageCode])

    const { t } = useTranslation()


    return (


        <Container>
            {
                TournamentHistoryFailed !== null ?
                    <div className='mt-2'>
                        <ErrorMsg p={5} fs={'lg'} msg="لايوجد معلومات" />
                    </div>
                    :
                    isFetchingTournamentHistory ?
                        <div className='mt-2'>
                            <LoadingSpinnner p={5} fs={'md'} />
                        </div>

                        :
                        <div>
                            <div className='my-3 bg-black-color p-5 white-color'>
                                {TournamentHistory.description}
                            </div>
                            <div className='bg-black-color white-color fs-xs mt-3 row p-1'>
                                <table className="table-borderless bg-black-color table-striped table-dark white-color col-12">
                                    <thead>
                                        <tr style={{ padding: '10px' }} className="text-center">
                                            <th>المركز</th>
                                            <th scope="col" className={window.document.body.dir === 'rtl' ? 'text-right' : 'text-left'}>الفريق</th>
                                            <th scope="col">البطل</th>
                                            {/* <th scope="col">الوصيف</th> */}
                                            <th scope="col">مواسم الفوز</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            TournamentHistory.winners.length === 0 ?
                                                <tr>
                                                    <td colSpan={15} className='p-0'>
                                                        <ErrorMsg msg="لايوجد معلومات" p={5} fs={'lg'} />
                                                    </td>
                                                </tr>
                                                :
                                                TournamentHistory.winners.map(
                                                    (team, i) =>
                                                        <tr key={team.id} className="text-center">
                                                            <td style={{ width: '25px' }}>{i + 1}</td>
                                                            <td className={window.document.body.dir === 'rtl' ? 'text-right team-name' : 'text-left team-name'}>
                                                                <img src={ImgURL(team.logo)} style={{ width: '20px' }} alt="team_logo" />
                                                                <span className="mx-2 fs-xxs ">{team.team_name}</span>
                                                            </td>
                                                            <td>{team.count}</td>
                                                            {/* <td>tbd</td> */}
                                                            <td>{team.seasons}</td>
                                                        </tr>

                                                )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

            }
        </Container>
    )
}


const mapDispatchToProps = (dispatch) => ({
    getTournamentHistory: (tournament_id, lang) => dispatch(getTournamentHistory(tournament_id, lang)),
});

const mapStateToProps = (state) => ({
    isFetchingTournamentHistory: state.tournamentHistory.isFetchingTournamentHistory,
    TournamentHistoryFailed: state.tournamentHistory.TournamentHistoryFailed,
    TournamentHistory: state.tournamentHistory.TournamentHistory,
    currentLanguageCode: state.language.currentLanguageCode,
});



export default connect(mapStateToProps, mapDispatchToProps)(TournamentHistory)