import React from 'react'
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';
import { getTournamentMatches } from '../../redux/TournamentMatches/TournamentMatches.Actions';
import { Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next"
import { useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import MatchCard from '../MatchCard/MatchCard';

function PlayerMatches({ TournamentMatchesFailed, Fixtures, Results, getTournamentMatches, isFetchingTournamentMatches, currentLanguageCode, sport_id, tournament_id, team_id }) {

    //NOTE: the same component is used to get team matches just using team_id insted of tournament_id so recap if tournament_id used it get tournament matches if team_id is used it gets team matches
    //NOTE: the same component is used to get player matches just using team_id insted of tournament_id so recap if tournament_id used it get tournament matches if team_id is used it gets team matches
    useEffect(() => {
        getTournamentMatches(sport_id, tournament_id, team_id, null, currentLanguageCode, 1)
        getTournamentMatches(sport_id, tournament_id, team_id, null, currentLanguageCode, 2)
    }, [sport_id, tournament_id, team_id, currentLanguageCode])

    const { t } = useTranslation()

    return (
        <div className='mt-2'>

            {
                TournamentMatchesFailed != null ?
                    <div>error</div>
                    :
                    isFetchingTournamentMatches ?
                        <div>...loading</div>
                        :

                        <div className='mx-auto'>
                            <div className='fs-md white-color mb-2'>القادمه</div>
                            {
                                Fixtures.length === 0 ?
                                    <div className='my-3 white-color bg-black-color p-5'>لا يوجد مباريات</div>
                                    :
                                    <Row xs={1} md={2} className='justify-content-between'>
                                        {
                                            Fixtures.slice(0, 2).map(el =>
                                                <div key={el.id} className='col-12 col-md-6'>
                                                    <MatchCard el={el} />
                                                </div>
                                            )
                                        }
                                    </Row>
                            }
                            <div className='fs-md white-color my-2'>النتائج</div>
                            {
                                Results.length === 0 ?
                                    <div className='my-3 white-color bg-black-color p-5'>لا يوجد مباريات</div>
                                    :
                                    <Row xs={1} md={2} className='justify-content-between'>
                                        {
                                            Results.slice(0, 2).map(el =>
                                                <div key={el.id} className='col-12 col-md-6'>
                                                    <MatchCard el={el} />
                                                </div>
                                            )
                                        }
                                    </Row>
                            }
                        </div>


            }
        </div>
    )
}

const mapStateToProps = state => ({
    isFetchingTournamentMatches: state.tournamentMatches.isFetchingTournamentMatches,
    Fixtures: state.tournamentMatches.Fixtures,
    Results: state.tournamentMatches.Results,
    TournamentMatchesFailed: state.tournamentMatches.TournamentMatchesFailed,
    currentLanguageCode: state.language.currentLanguageCode
})
const mapDispatchToProps = dispatch => ({
    getTournamentMatches: (sport_id, tournament_id, team_id, start_date, currentLanguageCode, date_type) => dispatch(getTournamentMatches(sport_id, tournament_id, team_id, start_date, currentLanguageCode, date_type))
})
export default connect(mapStateToProps, mapDispatchToProps)(PlayerMatches)