import React from 'react';
import { useEffect } from 'react';
import { getSpecialMediaSectionData } from "../helper";
import MediaList from '../../News/MediaList';
import { useTranslation } from 'react-i18next';
import SportsSectionHeader from '../../sportsSectionHeader/sportsSectionHeader.component';


function ElHadaryJourneySection({ currentState, dispatch, lang }) {
    const { t } = useTranslation();


    useEffect(() => {
        getSpecialMediaSectionData(dispatch, { section_id: 6, page: 1 }, lang)
    }, [])


    return (
        <>
            <SportsSectionHeader title="مشوار الحضري" dataTestId="elhadary-journey-section-title" />
            <MediaList
                AllMedia={currentState.fetchedData}
                getByPage={(page) => getSpecialMediaSectionData(dispatch, { section_id: 6, page }, lang)}
                current_page={currentState.meta.current_page}
                last_page={currentState.meta.last_page}
                initialLoading={currentState.isLoading && currentState.fetchedData.length === 0}
                paginationLoading={currentState.isLoading && currentState.fetchedData.length > 0}
            />
            <hr />
        </>
    )
}

export default React.memo(ElHadaryJourneySection)