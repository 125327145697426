import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import TwistSettings from '../../TwistSettings.json'
import './Competition.css'

const CompetitionLoader = () =>{

    const { t } = useTranslation()
    return (
        <SkeletonTheme color={TwistSettings["twist-ui"] ? "#004F90" : "#323232"} highlightColor="#ecebeb">
            <div className='d-flex justify-content-between flex-wrap mt-4' data-testid="tournament-page-loading-element">
               <Skeleton height={150} width={150} className='mx-0 mx-md-3 mt-3 competition-card  bg-black-color '/>
               <Skeleton height={150} width={150} className='mx-0 mx-md-3 mt-3 competition-card bg-black-color '/>
               <Skeleton height={150} width={150} className='mx-0 mx-md-3 mt-3 competition-card bg-black-color '/>
               <Skeleton height={150} width={150} className='mx-0 mx-md-3 mt-3 competition-card bg-black-color '/>
               <Skeleton height={150} width={150} className='mx-0 mx-md-3 mt-3 competition-card bg-black-color '/>
               <Skeleton height={150} width={150} className='mx-0 mx-md-3 mt-3 competition-card bg-black-color '/>
               <Skeleton height={150} width={150} className='mx-0 mx-md-3 mt-3 competition-card bg-black-color '/>
               <Skeleton height={150} width={150} className='mx-0 mx-md-3 mt-3 competition-card bg-black-color '/>
               <Skeleton height={150} width={150} className='mx-0 mx-md-3 mt-3 competition-card bg-black-color '/>
               <Skeleton height={150} width={150} className='mx-0 mx-md-3 mt-3 competition-card bg-black-color '/>
            </div>
        </SkeletonTheme>
    )
}

export default CompetitionLoader