import { getServiceName, getSportRoute } from "../../Layout/helper";
import SubscriptionTypes from "./subscription.Types";

const INITIAL_STATE = {
  currentService: 0,
  currentServiceRoute: '',
  currentSport: 0,
  currentSportRoute: '',
  subscribedServices: [],
};

const SubscriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SubscriptionTypes.UPDATE_CURRENT_SERVICE:
      return {
        ...state,
        currentService: action.payload,
        currentServiceRoute: getServiceName(action.payload),
      }
    case SubscriptionTypes.UPDATE_CURRENT_SPORT:
      return {
        ...state,
        currentSport: action.payload,
        currentSportRoute: getSportRoute(action.payload, state.currentService),
      }
    case SubscriptionTypes.UPDATE_SUBSCRIBED_SERVICES:
      return {
        ...state,
        subscribedServices: action.payload,
      }
    default:
      return state;
  }
};

export default SubscriptionReducer