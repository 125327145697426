import i18next from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import TournamentsPage from "./Pages/Competition/Competition";
import MatchesPage from "./Pages/Matches/Matches";
import MatchPage from "./Pages/MatchPage/MatchPage.component";
import News from "./Pages/News/News";
import player from "./Pages/Player/player";
import Team from "./Pages/Team/Team";
import Tournament from "./Pages/Tournament/Tournament";
import Videos from "./Pages/Videos";
import Section from "./Pages/Section";
import OtherSports from "./Pages/OtherSports/OtherSports";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import Subscription from "./Pages/Subscription/Subscription";
import { getSeasons } from "./redux/Seasons/Seasons.Actions";
import useSubscriptionCookieValidator from "./hooks/useSubscriptionCookieValidator";
import Fitness from "./Pages/Fitness/Fitness";
import Tennis from "./Pages/Tennis/Tennis";
import Handball from "./Pages/Handball/Handball";
import Squash from "./Pages/Squash/Squash";
import MediaDetails from "./Pages/MediaDetails/MediaDetails"
import TwistSettings from "./TwistSettings.json";
import AfterVisaPayment from "./components/AfterPaymentViews/AfterVisaPayment";
import AfterFawryPayment from "./components/AfterPaymentViews/AfterFawryPayment";


import Home from "./Pages/Home/Home"
import International from "./Pages/International/International"
import Local from "./Pages/Local/Local"
import Africa from "./Pages/Africa/Africa"

function App({
  languages,
  currentLanguageCode,
  getSeasons
}) {
  useSubscriptionCookieValidator()
  const { t } = useTranslation();

  const getTwistUIConfig = () => {
    if (TwistSettings["twist-ui"]) {
      document.body.classList.add("twist-ui");
    }
  }

  useEffect(() => {
    getSeasons({ parent: 1 });
    getTwistUIConfig();
  }, [])

  useEffect(() => {
    i18next.changeLanguage(currentLanguageCode);
    window.document.body.lang = i18next.language;
    window.document.documentElement.lang = i18next.language;
    const currentLanguage = languages.find(
      (l) => l.code === currentLanguageCode
    );
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = TwistSettings["twist-ui"] ? "Twist Sports | تويست سبورتس" : "Etisalat Sports | اتصالات سبورتس";
  }, [currentLanguageCode]);

  return (
    <div>

      <Switch>
        {/* Home */}
        <Route exact path="/" component={Home} />
        <Route exact path="/news" component={News} />
        <Route exact path="/videos" component={Videos} />
        <Route exact path="/tournaments" component={TournamentsPage} />
        <Route exact path="/matches" component={MatchesPage} />

        {/* Sections */}
        <ProtectedRoute exact path="/sections/:sectionName/:subSectionName?/" component={Section} />

        {/* Services */}
        <ProtectedRoute exact path="/international" component={International} />
        <ProtectedRoute exact path="/local" component={Local} />
        <ProtectedRoute exact path="/africa" component={Africa} />
        <ProtectedRoute exact path="/othersports" component={OtherSports} />
        <ProtectedRoute exact path="/fitness" component={Fitness} />


        {/* Other Sports */}
        <ProtectedRoute exact path="/othersports/tennis" component={Tennis} />
        <ProtectedRoute exact path="/othersports/squash" component={Squash} />
        <ProtectedRoute exact path="/othersports/handball" component={Handball} />

        {/* Shared Routes */}
        <ProtectedRoute exact path="/:serviceName/:sportName?/news" component={News} />
        <ProtectedRoute exact path="/:serviceName/:sportName?/videos" component={Videos} />
        <ProtectedRoute exact path="/:serviceName/:sportName?/tournaments" component={TournamentsPage} />
        <ProtectedRoute exact path="/:serviceName/:sportName?/matches" component={MatchesPage} />

        <ProtectedRoute exact path="/:serviceName/:sportName?/tournament/:id/:tab?" component={Tournament} />
        <ProtectedRoute exact path="/:serviceName/:sportName?/team/:id/:tab?" component={Team} />
        <ProtectedRoute exact path="/:serviceName/:sportName?/player/:id/:tab?" component={player} />
        <ProtectedRoute path="/:serviceName/:sportName?/match/:id" component={MatchPage} />
        <ProtectedRoute exact path="/:serviceName/:sportName?/media/:id" component={MediaDetails} />
        {/* <Route exact path="profile" component={Profile} /> */}
        {/* <Route exact path="favorite/:choice" component={Favorite} /> */}
        <Route exact path="/:serviceName/subscribe" render={(props) => <Subscription {...props} />} />
        <Route exact path="/afterPayment" render={(props) => <AfterVisaPayment {...props} />} />
        <Route exact path="/afterFawryPayment" render={(props) => <AfterFawryPayment {...props} />} />
        <Route exact path="/terms-conditions" component={TermsAndConditions} />
        <Route path='*'><PageNotFound /></Route>
      </Switch>
    </div>
  );
}


const mapDispatchToProps = (dispatch) => ({
  getSeasons: (params) => dispatch(getSeasons(params))
});

const mapStateToProps = (state) => ({
  languages: state.language.languages,
  currentLanguageCode: state.language.currentLanguageCode,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
