import TopAssistsTypes from "./topAssists.Types";



const INITIAL_STATE = {
    TopAssists:[''],
    isFetchingTopAssists:true,
    TopAssistsFailed:null
   };
   
   const TopAssists = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case TopAssistsTypes.GET_TOP_ASSISTS_START:
        return {
          ...state,
          isFetchingTopAssists:true
        }
      case TopAssistsTypes.GET_TOP_ASSISTS_SUCCESS:
        return {
          ...state,
          TopAssists:action.payload,
          isFetchingTopAssists:false,
          TopAssistsFailed:null
        }  
      case TopAssistsTypes.GET_TOP_ASSISTS_FAILED:
        return {
          ...state,
          TopAssistsFailed:action.payload,
          isFetchingTopAssists:false
        }
       default:
         return state;
     }
   };
 
   export default TopAssists