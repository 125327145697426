import languageTypes from "./language.types";
import cookies from 'js-cookie'


const INITIAL_STATE = {
    languages : [
        {
          code: 'en',
          name: 'English',
          country_code: 'gb',
        },
        {
          code: 'ar',
          name: 'العربية',
          dir: 'rtl',
          country_code: 'eg',
        },
    ],
    currentLanguageCode:cookies.get('i18next') || 'ar',

   };
   
   const Language = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case languageTypes.SET_LANGUAGE:
        return {
          ...state,
          currentLanguageCode:cookies.get('i18next') || 'ar'
        }
       default:
         return state;
     }
   };
 
   export default  Language