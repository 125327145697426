import { useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import MatchEvents from "../../components/MatchEvents/MatchEvents";
import MatchHeader from "../../components/MatchHeader/MatchHeader";
import MatchLineup from "../../components/MatchLineup/MatchLineup.component";
import MatchStatistics from "../../components/MatchStatistics/MatchStatistics";
import SquashMatchDetails from "../../components/SquashMatchDetails/SquashMatchDetails";
import { IDS } from "../../globalData";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import useServiceGuard from "../../hooks/useServiceGuard";
import "./MatchPage.css";

const LiveMatchPage = ({
  match,
  matchStatus,
  matchStatusFailure,
  squashMatchDetails,
  isFetchingMatchStatus
}) => {
  const location = useLocation()
  const [key, setKey] = useState(location.pathname?.split("/")[2] === "squash" ? ("squash") : ("lineup"));

  const { sportId } = useServiceAndSport();
  const isFootball = sportId === IDS.SPORTS.FOOTBALL || sportId === undefined
  const isIndividual = sportId === IDS.SPORTS.SQUASH || sportId === IDS.SPORTS.TENNIS;


  useServiceGuard(isFetchingMatchStatus, matchStatus?.services);


  return matchStatusFailure != null ? (
    <ErrorPage />
  ) : (
    <div className="match-page">
      <MatchHeader
        matchId={match.params.id}
      />
      {!isFootball && isIndividual &&
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          variant="tabs"
          className="d-flex justify-content-center"
        >
          <Tab eventKey="squash" title="تفاصيل">
            <SquashMatchDetails />
          </Tab>
        </Tabs>}
      {!isIndividual && isFootball &&
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          variant="tabs"
          className="d-flex justify-content-center"
        >
          <Tab eventKey="lineup" title="التشكيل">
            <Container>
              <MatchLineup matchId={match.params.id} />
            </Container>
          </Tab>
          <Tab eventKey="live" title="مباشر">
            <MatchEvents matchId={match.params.id} />
          </Tab>
          {/* Commented For Now Maybe Will Be Used Later */}
          {/* <Tab eventKey="min by min" title="دقيقة بـ دقيقة">
                  <MinBymin />
                </Tab> */}
          <Tab eventKey="stats" title="الاحصائيات">
            <MatchStatistics matchId={match.params.id} />
          </Tab>
        </Tabs>
      }
    </div>
  );
};


const mapStateToProps = (state) => ({
  matchStatus: state.matchInfo.matchStatus?.[0],
  matchStatusFailure: state.matchInfo.matchStatusFailure,
  squashMatchDetails: state.squashMatchDetails.matchDetails,
  isLoadingSquashMatchDetails: state.squashMatchDetails.isFetchingSquashMatchDetails,
  SquashMatchDetailsFailed: state.squashMatchDetails.SquashMatchDetailsFailed,
  isFetchingMatchStatus: state.matchInfo.isFetchingMatchStatus,
});

export default withRouter(
  connect(mapStateToProps)(LiveMatchPage)
);
