import PlayerDetailsTypes from "./PlayerDetails.Types";

const findLeagueIfPresent = (tournaments) => tournaments?.sort(({type}) => type === 'League' ? -1 : 1)[0]

const INITIAL_STATE = {
  PlayerDetails: {},
  isFetchingPlayerDetails: true,
  PlayerDetailsFailed: null,
  currentTeam: {},
  teams: [],
  currentTeamActiveTournament: {},
  currentTeamTournaments: []
};

const PlayerDetails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PlayerDetailsTypes.GET_PLAYER_DETAILS_START:
      return {
        ...state,
        isFetchingPlayerDetails: true
      }
    case PlayerDetailsTypes.GET_PLAYER_DETAILS_SUCCESS:
      return {
        ...state,
        PlayerDetails: action.payload,
        isFetchingPlayerDetails: false,
        PlayerDetailsFailed: null,
        currentTeam: action.payload.current_team,
        teams: action.payload.teams,
        currentTeamActiveTournament: findLeagueIfPresent(action.payload.current_team?.tournaments),
        currentTeamTournaments: action.payload.current_team?.tournaments
      }
    case PlayerDetailsTypes.SET_CURRENT_PLAYER_TEAM:
      return {
        ...state,
        currentTeam: action.payload,
        currentTeamTournaments: action.payload.tournaments,
        currentTeamActiveTournament: findLeagueIfPresent(action.payload.tournaments)
      }
    case PlayerDetailsTypes.SET_CURRENT_PLAYER_TOURNAMENT:
      return {
        ...state,
        currentTeamActiveTournament: action.payload
      }
    case PlayerDetailsTypes.GET_PLAYER_DETAILS_FAILED:
      return {
        ...state,
        PlayerDetailsFailed: action.payload,
        isFetchingPlayerDetails: false
      }
    default:
      return state;
  }
};

export default PlayerDetails