import CalendarTypes from "./Calendar.Types";
import axios from "axios";
import { globalBaseUrl } from "../../globalData";
import api from "../../apis";
import { injectSquashServiceAndSport } from "../helpers";

const { REACT_APP_NEW_GET_CALENDAR } = process.env;

const updateDataStrucutre = (data) => {
  const newData = data;
  newData.today = injectSquashServiceAndSport(newData.today)
  newData.tomorrow = injectSquashServiceAndSport(newData.tomorrow)
  newData.yesterday = injectSquashServiceAndSport(newData.yesterday)
  return newData;
}

const getCalendarStart = () => ({
  type: CalendarTypes.GET_CALENDAR_START,
});

const getCalendarSuccess = (info) => ({
  type: CalendarTypes.GET_CALENDAR_SUCCESS,
  payload: info,
});

const getCalendarFailed = (error) => ({
  type: CalendarTypes.GET_CALENDAR_FAILED,
  payload: error
});

const getCalendar = (sport_id, service_id, season_id, tournament_id, team_id, lang) => (dispatch) => {
  dispatch(getCalendarStart());
  if (sport_id !== 7) {
    axios({
      method: "get",
      url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_CALENDAR}`,
      // ${
      //   tournament_id || team_id ? "?" : ""
      // }${tournament_id ? `tournament_id=${tournament_id}` : ""}${
      //   tournament_id && team_id ? "&" : ""
      // }${team_id ? `team_id=${team_id}` : ""}`,
      params: { tournament_id, team_id, season_id, sport_id, service_id },
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`,
      },
    })
      .then((resp) => {
        if (resp.data.status === 200) {
          dispatch(getCalendarSuccess(resp.data.data));
        } else {
          dispatch(getCalendarFailed(resp.data.message));
        }
      })
      .catch((error) => dispatch(getCalendarFailed(error)));
  } else {
    api.squash.getMatchCalender()
      .then(res => {
        if (res.status === 200) {
          dispatch(getCalendarSuccess(updateDataStrucutre(res.data)))
        } else {
          dispatch(getCalendarFailed(res.message))
        }
      })
      .catch(error => {
        dispatch(getCalendarFailed(error))
      }
      )
  }
};

export { getCalendar };
