import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import useIpAddress from '../../hooks/useIpAddress';
import useLogging from '../../hooks/useLogging';

function ScrollToTop({ history }) {
  useIpAddress()
  useLogging()
  useGoogleAnalytics()
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);