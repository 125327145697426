import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getStanding } from '../../redux/Standing/Standing.Actions';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import { AiOutlineStar, AiTwotoneStar } from 'react-icons/ai';
import "./FanClub.css";
import api from '../../apis/index'
import SpecialMedia from '../SpecialMedia';
import { getUserPhoneNumbers } from '../../globalFn';
import { IDS, ImgURL } from '../../globalData'
import TwistSettings from '../../TwistSettings.json'
import FanClubLoader from './FanClubLoader/FanClubLoader';
import worldCupLogo from "../../assets/games/fan-club-logo.svg";

function FanClub({
    Standing,
    currentLanguageCode,
    getStanding,
    sport_id,
    season_id,
    standingFailed,
    isFetchingStanding,
    subscriptionModal

}) {

    const [favTeamsId, setFavTeamsId] = useState([]);
    const id = useParams().id;
    const { t } = useTranslation();
    const standingHalfTeamsLength = Math.trunc(Standing.flat().length / 2);
    const msisdn = getUserPhoneNumbers()[0]


    const handleAddFavTeam = (teamId) => {
        setFavTeamsId([...favTeamsId, teamId])

        api.fanClub.postFavTeams({
            phone: msisdn,
            favouriteable_type: ["Team"],
            portal_id: TwistSettings['twist-ui'] ? IDS.PORTALS.TWIST : IDS.PORTALS.NEW_ET,
            favouriteable_ids: [teamId]
        })
    }

    const handleRemoveFavTeam = (teamId) => {
        const favTeamsIdCopy = [...favTeamsId]
        const index = favTeamsIdCopy.indexOf(teamId);
        favTeamsIdCopy.splice(index, 1);
        setFavTeamsId(favTeamsIdCopy);

        api.fanClub.postFavTeams({
            phone: msisdn,
            favouriteable_type: ["Team"],
            portal_id: TwistSettings['twist-ui'] ? IDS.PORTALS.TWIST : IDS.PORTALS.NEW_ET,
            favouriteable_ids: [teamId]
        })
    }

    useEffect(() => {
        api.fanClub.getFavTeams({
            phone: msisdn,
            favouriteable_type: ["Team"],
            portal_id: TwistSettings['twist-ui'] ? IDS.PORTALS.TWIST : IDS.PORTALS.NEW_ET,
        })
            .then(res => setFavTeamsId(res.data.map(team => team.favouriteable_id)))

    }, [subscriptionModal])

    const renderTeams = (start, to) => {
        return (
            <div className='teams__wrapper'>
                {
                    Standing.flat().slice(start, to).map(team =>
                    (
                        <div className='team d-flex' key={team.team_id}>
                            <div className='fav__iconWrapper'>
                                {
                                    favTeamsId.includes(team.team_id) ?
                                        <AiTwotoneStar className={`fav__iconBold text-white`} onClick={() => handleRemoveFavTeam(team.team_id)} /> :
                                        <AiOutlineStar className={`fav__icon text-white`} onClick={() => handleAddFavTeam(team.team_id)} />
                                }
                            </div>
                            <figure className='team__info d-flex flex-column   mb-0'>
                                <img src={ImgURL(team.team_logo)} alt="team logo" />
                                <figcaption className='text-white text-nowrap'>{team.team_name}</figcaption>
                            </figure>
                        </div>
                    ))
                }
            </div>
        )
    }

    useEffect(() => {
        getStanding(sport_id, season_id, id, currentLanguageCode)
    }, [sport_id, season_id, id, currentLanguageCode])


    return (
        <section className='fan__club'>
            <div className="container">
                {
                    standingFailed !== null ?
                        <div colSpan={7} className='p-0'>
                            <ErrorMsg msg="لا يوجد معلومات عن الاندية" p={4} fs={'md'} />
                        </div>
                        :
                        Standing.length === 0 ?
                            <div colSpan={7} className='p-0'>
                                <ErrorMsg msg="لا يوجد معلومات عن الاندية" p={4} fs={'md'} />
                            </div>
                            :
                            <div className="wrapper">
                                {isFetchingStanding ? <FanClubLoader /> : renderTeams(0, standingHalfTeamsLength)}
                                <img src={worldCupLogo} className='world__cupLogo' alt="" />
                                {isFetchingStanding ? <FanClubLoader /> : renderTeams(standingHalfTeamsLength)}
                            </div>
                }
                <hr />
                <SpecialMedia msisdn={msisdn} />
            </div>
        </section>
    )
}


const mapStateToProps = (state) => ({
    isFetchingStanding: state.standing.isFetchingStanding,
    standingFailed: state.standing.StandingFailed,
    Standing: state.standing.Standing,
    currentLanguageCode: state.language.currentLanguageCode,
});

const mapDispatchToProps = (dispatch) => ({
    getStanding: (sport_id, season_id, tournament_id, lang) => dispatch(getStanding(sport_id, season_id, tournament_id, lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FanClub)
