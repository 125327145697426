
import TeamDetailsTypes from "./teamDetails.Types";
const INITIAL_STATE = {
    TeamDetails:{
      main_tournament:{
        id:null
      }
    },
    activeTab:'overview',
    isFetchingTeamDetails:true,
    TeamDetailsFailed:null
   };
   
   const TeamDetails = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case TeamDetailsTypes.SET_TEAM_ACTIVE_TAB:
        return {
          ...state,
          activeTab:action.payload
        }
      case TeamDetailsTypes.GET_TEAM_DETAILS_START:
        return {
          ...state,
          isFetchingTeamDetails:true
        }
      case TeamDetailsTypes.GET_TEAM_DETAILS_SUCCESS:
        return {
          ...state,
          TeamDetails:action.payload,
          isFetchingTeamDetails:false,
          TeamDetailsFailed:null
        }
      case TeamDetailsTypes.GET_TEAM_DETAILS_FAILED:
        return {
          ...state,
          TeamDetailsFailed:action.payload,
          isFetchingTeamDetails:false
        }
       default:
         return state;
     }
   };
 
   export default TeamDetails