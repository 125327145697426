import HttpHelper from "./helper";
import TwistSettings from '../TwistSettings.json'
import { IDS } from "../globalData";

export const medialistEndpoints = {
  getMediaList: (sentParams = {}, signal) =>
    HttpHelper.baseAxios
      .get(`/media`, {
        params: { ...sentParams, portal_id: TwistSettings['twist-ui'] ? IDS.PORTALS.TWIST : IDS.PORTALS.NEW_ET },
        signal
      })
      .then((res) => res.data)
      .catch((err) => err.message),
};
