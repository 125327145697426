import * as ActionTypes from "./Types.Favorite";

export const AllSports = (
  state = { isLoading: true, errMess: null, allSports: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_ALL_SPORTS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        allSports: action.payload,
      };

    case ActionTypes.ALL_SPORTS_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
        allSports: [],
      };

    case ActionTypes.ALL_SPORTS_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export const FavTeams = (
  state = { isLoading: true, errMess: null, favTeams: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_ALL_TEAMS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        favTeams: action.payload,
      };

    case ActionTypes.ALL_TEAMS_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
        favTeams: [],
      };

    case ActionTypes.ALL_TEAMS_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export const UserFav = (
  state = { isLoading: true, errMess: null, userFav: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_USER_FAV:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        userFav: action.payload,
      };

    case ActionTypes.USER_FAV_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
      };

    case ActionTypes.USER_FAV_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export const addUserChoicesFav = (
  state = { isLoading: false, errMess: null, success: false },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_USER_CHOICES_FAV:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        success: true,
      };

    case ActionTypes.USER_CHOICES_FAV_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
      };

    case ActionTypes.USER_CHOICES_FAV_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};
