import React from 'react'
import { useTranslation } from "react-i18next";
import MatchesCalendar from "../../components/MatchesCalendar/MatchesCalendar";
import LatestNews from "../../components/News/LatestNews";
import SportsSectionHeader from '../../components/sportsSectionHeader/sportsSectionHeader.component';
import TournamentsCarousel from "../../components/TournamentsCarousel/TournamentsCarousel";
import { connect } from 'react-redux'
import NewsSearch from "../../components/News/NewsSearch";
import { IDS } from '../../globalData';
import TopStories from '../../components/News/TopStories';
import TwistSettings from '../../TwistSettings.json'
import AllMedia from '../../components/News/AllMedia';

const Tennis = ({ NewsSearchQuery }) => {
  const { t } = useTranslation()

  return <div className='container home-page '>
    <div className="d-flex justify-content-center row">
      <div className="col-12">
        {/* <img src={IphoneAdd} height={200} width={"100%"} /> */}
      </div>
    </div>
    <MatchesCalendar sport_id={IDS.SPORTS.TENNIS} service_id={IDS.SERVICES.OTHERSPORTS} season_id={null} tournament_id={null} />
    <SportsSectionHeader title="اهم الاحداث" navigateTo="/othersports/tennis/news" />
    <TopStories sport_id={IDS.SPORTS.TENNIS} service_id={IDS.SERVICES.TENNIS} season_id={null} items_count={4} media_type={1} tournament_id={null} media_id={null} team_id={null} player_id={null} top_stories={true} latest={null} popular={null} />
    <hr></hr>

    <div className='row'>
      <div className='col-12 fs-md'>
        <div className='text-light mt-5 fs-lg '><strong>اختر بطولة</strong></div>
        <TournamentsCarousel sport_id={IDS.SPORTS.TENNIS} service_id={IDS.SERVICES.OTHERSPORTS} />
      </div>
    </div>
    <div className="col-12">
      {/* <img src={Cokebanner} height={200} width={"100%"} className="mt-3" /> */}
    </div>
    <SportsSectionHeader title="اخر الاخبار" />
    <NewsSearch />
    <div className={`${NewsSearchQuery?.length > 0 ? 'd-none' : ""} my-2`}>
      <LatestNews sport_id={IDS.SPORTS.TENNIS} service_id={IDS.SERVICES.OTHERSPORTS} items_count={9} media_type={1} latest={true} />
    </div>
    {
      TwistSettings['videos'] && <>
        <hr />
        <SportsSectionHeader title="احدث الفيديوهات" />
        <AllMedia sport_id={IDS.SPORTS.TENNIS} service_id={IDS.SERVICES.OTHERSPORTS} items_count={9} type={2} latest />
        <hr />
        <SportsSectionHeader title="الاكثر تداولا" />
        <AllMedia sport_id={IDS.SPORTS.TENNIS} service_id={IDS.SERVICES.OTHERSPORTS} items_count={12} type={2} popular />
      </>
    }
  </div>
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(null, mapDispatchToProps)(Tennis);