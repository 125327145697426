import MatchActionTypes from './Types.Match'
import { arrangebySort, deleteScoreCounter, OwnGoal } from './Utils.Match';

const INITIAL_STATE = {
    matchStatus:[''],
    matchRoundInfo:[],
    isFetchingMatchStatus:true,
    matchStatusFailure:null,
    matchEvents:[''],
    isFetchingMatchEvents:true,
    matchEventsFailure:null,
    matchLineUp:[''],
    isFetchingMatchLineUp:true,
    matchLineUpFailure:null,
    matchStatistics:{},
    isFetchingMatchStatistics:true,
    matchStatisticsFailure:null,
    matchDetails: null,
   };
   
   const matchInfo = (state = INITIAL_STATE, action) => {
     switch (action.type) {
       case MatchActionTypes.GET_MATCH_INFO_START:
         return {
           ...state,
           isFetchingMatchStatus:true
         }
       case  MatchActionTypes.GET_MATCH_INFO_SUCCESS:
         return {
             ...state,
             isFetchingMatchStatus:false,
             matchStatusFailure:null,
             matchStatus:action.payload,
             matchDetails:action.payload[0],
             matchRoundInfo:action.payload[0].matchRoundInfo
         }
         case MatchActionTypes.GET_MATCH_INFO_FAILURE:
         return {
             ...state,
             isFetchingMatchStatus:true,
             matchStatusFailure:action.payload
         }
         case MatchActionTypes.GET_MATCH_EVENTS_START:
          return {
            ...state,
            isFetchingMatchEvents:true
          }
        case  MatchActionTypes.GET_MATCH_EVENTS_SUCCESS:
          return {
              ...state,
              isFetchingMatchEvents:false,
              matchEventsFailure:null,
              matchEvents:action.payload
          }
          case MatchActionTypes.GET_MATCH_EVENTS_FAILURE:
          return {
              ...state,
              isFetchingMatchEvents:false,
              matchEventsFailure:action.payload
          }
          case MatchActionTypes.GET_MATCH_LINEUP_START:
            return {
              ...state,
              isFetchingMatchLineUp:true
            }
          case  MatchActionTypes.GET_MATCH_LINEUP_SUCCESS:
            return {
                ...state,
                isFetchingMatchLineUp:false,
                matchLineUpFailure:null,
                matchLineUp:action.payload
            }
            case MatchActionTypes.GET_MATCH_LINEUP_FAILURE:
            return {
                ...state,
                isFetchingMatchLineUp:false,
                matchLineUpFailure:action.payload
            }
            case MatchActionTypes.GET_MATCH_STATISTICS_START:
              return {
                ...state,
                isFetchingMatchStatistics:true
              }
            case  MatchActionTypes.GET_MATCH_STATISTICS_SUCCESS:
              return {
                  ...state,
                  isFetchingMatchStatistics:false,
                  matchStatisticsFailure:null,
                  matchStatistics:action.payload
              }
              case MatchActionTypes.GET_MATCH_STATISTICS_FAILURE:
              return {
                  ...state,
                  isFetchingMatchStatistics:false,
                  matchStatisticsFailure:action.payload
              }
            case MatchActionTypes.GET_MATCH_LIVE_START_EVENT_SUCCESS:
              return {
                ...state,
                matchStatus:[{...state.matchStatus[0],matchStatus:2}]
              }
            case  MatchActionTypes.GET_MATCH_LIVE_ROUND_EVENT_SUCCESS:
              return {
                  ...state,
                  matchRoundInfo:action.payload,
                  matchEvents:arrangebySort(state.matchEvents,action.payload)
              }
              case  MatchActionTypes.GET_MATCH_LIVE_OTHER_EVENT_SUCCESS:
                return {
                    ...state,
                    matchEvents:arrangebySort(state.matchEvents,action.payload)
                }
            case  MatchActionTypes.GET_MATCH_LIVE_GOAL_EVENT_SUCCESS:
              return {
                  ...state,
                  matchStatus:(action.payload.is_home === 1) && (action.payload.event_id !== OwnGoal)
                  ? [
                      {
                        ...state.matchStatus[0],
                        team1: {
                          ...state.matchStatus[0].team1,
                          score: (state.matchStatus[0].team1?.score ?? 0) +1
                        }
                      }
                    ] 
                  : [
                      {
                        ...state.matchStatus[0],
                        team2: {
                          ...state.matchStatus[0].team2,
                          score: (state.matchStatus[0].team2?.score ?? 0) +1
                        }
                      }
                    ],
                  matchEvents: arrangebySort(state.matchEvents,action.payload)
              }  
              case  MatchActionTypes.DELETE_MATCH_LIVE_EVENT_SUCCESS:
                const updatedMatchEvents = state.matchEvents.filter(el => el.id !== action.payload.id)
                return {
                    ...state,
                    matchRoundInfo: action.payload.matchRoundInfo,
                    matchEvents: updatedMatchEvents,
                    matchStatus: [
                      {
                        ...state.matchStatus[0],
                        team1: {
                          ...state.matchStatus[0].team1,
                          score: deleteScoreCounter(updatedMatchEvents, (state.matchStatus[0].team1?.id ?? 1))
                        },
                        team2: {
                          ...state.matchStatus[0].team2,
                          score: deleteScoreCounter(updatedMatchEvents, (state.matchStatus[0].team2?.id ?? 2))
                        }
                      }
                    ],

                }  
       default:
         return state;
     }
   };
 
   export default matchInfo