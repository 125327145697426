import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getTeamSquad } from '../../redux/TeamSquad/TeamSquad.Actions';
import { useTranslation } from "react-i18next"
import { Container, Row } from 'reactstrap';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import { ImgURL } from '../../globalData';  
import useServiceAndSport from '../../hooks/useServiceAndSport';



const TeamSquad = ({ TeamSquad, isFetchingTeamSquad, TeamSquadFailed, sport_id, season_id, tournament_id, team_id, currentLanguageCode, getTeamSquad, toLocalTab }) => {
    const { route } = useServiceAndSport()
    const { t } = useTranslation()
    const history = useHistory();
    const handleRowClick = (id) => {
        history.push(`${route}/player/${id}`, { previousPathname: history.location.pathname });
    }

    useEffect(() => {
        getTeamSquad(sport_id, season_id, tournament_id, team_id, currentLanguageCode)
    }, [sport_id, season_id, tournament_id, team_id, currentLanguageCode])

    const TeamSquadKeys = {
        goal_keeper: "حراس المرمي",
        defense: "الدفاع",
        midfield: "وسط الملعب",
        attack: "الهجوم",
        manager:"المدير الفني"
    }

    return (
        TeamSquadFailed !== null ?
            <ErrorMsg p={5} fs={'md'} msg="لا يوجد لاعبين" />
            :
            isFetchingTeamSquad ?
                <div>...loading</div>
                :
                <Container className='d-flex justify-content-center'>
                    <Row>
                        {
                            Object.keys(TeamSquad).map((key, i) =>
                                <div key={key} className='col-12 col-md-4 mt-3 '>
                                    <div className='bg-light-black-color p-3 text-light'>{TeamSquadKeys[key]}</div>
                                    <table className="table-borderless bg-black-color table-striped table-dark white-color">
                                        <thead>
                                            <tr className="fs-xxs">
                                                <th scope="col">الاعب</th>
                                                <th scope="col">الجنسيه</th>
                                                <th scope="col">السن</th>
                                                <th scope="col">القيمة السوقيه</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.values(TeamSquad)[i].length === 0 ?
                                                    <tr>
                                                        <td colSpan={10} className='p-0'>
                                                            <ErrorMsg p={5} fs={'md'} msg="لا يوجد لاعبين" />
                                                        </td>
                                                    </tr>
                                                    :
                                                    Object.values(TeamSquad)[i].map((player, i) =>
                                                        <tr key={player.id} onClick={() => handleRowClick(player.id)} className='table-clickable-row'>
                                                            <td scope='row'>
                                                                <div className='d-flex justify-content-start'>
                                                                    <img
                                                                        src={ImgURL(player.image,true)}
                                                                        width={35}
                                                                        height={35}
                                                                        className="border-radius-50"
                                                                        alt="player"
                                                                    />
                                                                    <div>
                                                                        <div className='fs-xs mx-1'>{player.name}</div>
                                                                        <div className='fs-xxs mx-1'>{player.position}</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <img src={ImgURL(player.national_logo)} style={{ width: "30px", height: "20px" }} alt="national logo" />
                                                            </td>
                                                            <td>
                                                                {
                                                                    player.age
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    player.market_value
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            )
                        }
                    </Row>
                </Container>
    )
}

const mapStateToProps = state => ({
    TeamSquad: state.teamSquad.TeamSquad,
    isFetchingTeamSquad: state.teamSquad.isFetchingTeamSquad,
    TeamSquadFailed: state.teamSquad.TeamSquadFailed,
    currentLanguageCode: state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch => ({
    getTeamSquad: (sport_id, season_id, tournament_id, team_id, lang) => dispatch(getTeamSquad(sport_id, season_id, tournament_id, team_id, lang))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamSquad)