const MatchActionTypes={
    GET_MATCH_INFO_START:'GET_MATCH_INFO_START',
    GET_MATCH_INFO_SUCCESS:'GET_MATCH_INFO_SUCCESS',
    GET_MATCH_INFO_FAILURE:'GET_MATCH_INFO_FAILURE',
    GET_MATCH_EVENTS_START:'GET_MATCH_EVENTS_START',
    GET_MATCH_EVENTS_SUCCESS:'GET_MATCH_EVENTS_SUCCESS',
    GET_MATCH_EVENTS_FAILURE:'GET_MATCH_EVENTS_FAILURE',
    GET_MATCH_LINEUP_START:'GET_MATCH_LINEUP_START',
    GET_MATCH_LINEUP_SUCCESS:'GET_MATCH_LINEUP_SUCCESS',
    GET_MATCH_LINEUP_FAILURE:'GET_MATCH_LINEUP_FAILURE',
    GET_MATCH_STATISTICS_START:'GET_MATCH_STATISTICS_START',
    GET_MATCH_STATISTICS_SUCCESS:'GET_MATCH_STATISTICS_SUCCESS',
    GET_MATCH_STATISTICS_FAILURE:'GET_MATCH_STATISTICS_FAILURE',
    GET_MATCH_LIVE_START_EVENT_SUCCESS:'GET_MATCH_LIVE_START_EVENT_SUCCESS',
    GET_MATCH_LIVE_ROUND_EVENT_SUCCESS:'GET_MATCH_LIVE_ROUND_EVENT_SUCCESS',
    GET_MATCH_LIVE_GOAL_EVENT_SUCCESS:'GET_MATCH_LIVE_GOAL_EVENT_SUCCESS',
    GET_MATCH_LIVE_OTHER_EVENT_SUCCESS:'GET_MATCH_LIVE_OTHER_EVENT_SUCCESS',
    DELETE_MATCH_LIVE_EVENT_SUCCESS:'DELETE_MATCH_LIVE_EVENT_SUCCESS',
}

export default MatchActionTypes