import HttpHelper from './helper';


export const teamsEndpoints = {
    getTeamDetails: (id) => HttpHelper.baseAxios
        .get(`/teams/${id}`)
        .then((res) => res?.data)
        .catch((err) => err?.response?.data),

    getTeamStatistics: (params) => HttpHelper.baseAxios
        .get(`/teamStatistics`, { params })
        .then((res) => res?.data)
        .catch((err) => err?.response?.data),
};
