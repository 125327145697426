import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./TagsComponent.css";
import { getServiceName, getSportRoute } from "../../../Layout/helper";
import { ImgURL } from "../../../globalData";

export default function TagsComponent(props) {
  const { t } = useTranslation();

  const history = useHistory();

  const handleRowClick = (serviceId, sportId, type, id) => {
    history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/${type}/${id}`, { previousPathname: history.location.pathname });
  };

  return (
    <div className="bg-black-color px-4 py-3 tags_container">
      <h5 className="fs-xm text-light">المواضيع المتعلقه</h5>
      <Row>
        {props.mediaTags.slice(0, 6).map((tag) => (
          <Col
            key={tag.id}
            className="tag_body mx-2 p-2"
            onClick={() =>
              handleRowClick(tag.services?.[0], tag.sport_id, tag.type.toLowerCase(), tag.tagable_id)
            }
            style={{ cursor: "pointer" }}
          >
            <div className="d-flex">
              {tag.image ? (
                <Image
                  src={ImgURL(tag.image)}
                  height={30}
                />
              ) : null}
              <p className="fs-sm mx-1 my-auto text-light">{tag.title}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}
