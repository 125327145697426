import * as ActionTypes from "./Types.MediaDetails";
// import { useTranslation } from "react-i18next";
import axios from "axios";
import { globalBaseUrl } from "../../globalData";

export const fetchMediaDetails = (mediaId, Lang) => (dispatch) => {
  dispatch(mediaDetailsLoading(true));

  axios({
    method: "GET",
    url:
      globalBaseUrl.current +
      process.env.REACT_APP_GET_MEDIA_DETAILS +
      mediaId,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": `${Lang}`,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((mediaDetails) => dispatch(addMediaDetails(mediaDetails.data.data)))

    .catch((error) => dispatch(medidDetialsFailed(error.message)));
};

export const addMediaDetails = (mediaDetails) => ({
  type: ActionTypes.ADD_MEDA_DETAILS,
  payload: mediaDetails,
});

export const mediaDetailsLoading = () => ({
  type: ActionTypes.MEDA_DETAILS_LOADING,
});

export const medidDetialsFailed = (errmess) => ({
  type: ActionTypes.MEDA_DETAILS_FAILED,
  payload: errmess,
});
