import TournamentsTypes from "./Tournaments.Types";



const INITIAL_STATE = {
  Tournaments: [''],
  isFetchingTournaments: true,
  TournamentsFailed: null
};

const Tournaments = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TournamentsTypes.GET_TOURNAMENTS_START:
      return {
        ...state,
        isFetchingTournaments: true
      }
    case TournamentsTypes.GET_TOURNAMENTS_SUCCESS:
      return {
        ...state,
        Tournaments: action.payload,
        isFetchingTournaments: false,
        TournamentsFailed: null
      }
    case TournamentsTypes.GET_TOURNAMENTS_FAILED:
      return {
        ...state,
        TournamentsFailed: action.payload,
        isFetchingTournaments: false
      }
    default:
      return state;
  }
};

export default Tournaments