import HttpHelper from "./helper";

export const guessPlayerEndpoints = {
  getTeamsLogos: () =>
    HttpHelper.baseAxios
      .get(`/guess_player/get`)
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),

  postAnswer: (inputData) =>
    HttpHelper.baseAxios
      .post(`/guess_player/answer`, inputData)
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),
};
