import React from 'react'
import './ChooseProvider.css'

import { useTranslation } from "react-i18next";

import twistLogo from '../../../assets/img/twist-new-logo.png'
import etisalatLogo from '../../../assets/img/subscription/etisalat.png'
import orangeLogo from '../../../assets/img/subscription/orange.png'
import vodafoneLogo from '../../../assets/img/subscription/vodafone.png'
import weLogo from '../../../assets/img/subscription/we.png'

function ChooseProvider({ setStep, userChoicesRef }) {
  const { t } = useTranslation()

  const providers = [
    { name: "we", logo: weLogo },
    { name: "orange", logo: orangeLogo },
    { name: "vodafone", logo: vodafoneLogo },
    { name: "etisalat", logo: etisalatLogo },
  ]

  const handleProviderClick = (providerName) => {
    userChoicesRef.current.push(providerName)
    setStep("1")
  }

  return (
    <section className='choose-provider d-flex flex-column justify-content-center align-items-center'>
      <img src={twistLogo} alt="Twist Logo" />
      <h6 className='text-center w-100 pt-5 pb-4 text-white'>"اختر شبكة الهاتف الخاصة بك"</h6>

      <div className='d-flex justify-content-center gap-3 flex-wrap flex-md-nowrap'>
        {providers.map((provider, i) => (
          <button key={i} onClick={() => handleProviderClick(provider.name)}>
            <img src={provider.logo} alt={provider.name} />
          </button>
        ))}
      </div>

    </section>
  )
}

export default ChooseProvider