import React, { useEffect } from "react";
import { Container, Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import RequireSubscription from "../../components/require-subscription";
import TeamInfo from "../../components/TeamInfo/TeamInfo";
import TeamOverview from "../../components/TeamOverview/TeamOverview";
import TeamSquad from "../../components/TeamSquad/TeamSquad";
import TeamStatsDetail from "../../components/TeamStatistics/TeamStatsDetail";
import TournamentMatches from "../../components/TournamentMatches/TournamentMatches";
import { ImgURL } from "../../globalData";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import { getTeamDetails, setActiveTab } from "../../redux/teamDetails/teamDetails.Actions";
import Player from "../Player/player";
import HeaderInfoComponentLoader from "./HeaderInfoComponentLoader";
import TwistSettings from '../../TwistSettings.json'
import "./Team.css";
import AllMedia from "../../components/News/AllMedia";
import Games from "../../components/Games";

const Team = ({
  TeamDetails,
  TeamDetailsFailed,
  activeSeason,
  isFetchingTeamDetails,
  getTeamDetails,
  currentLanguageCode,
  setActiveTab,
  activeTab,
  isSubscriped
}) => {
  const { t } = useTranslation();
  const { route, sportName, sportId } = useServiceAndSport()
  const id = useParams().id;
  const tab = useParams().tab || "overview";
  const history = useHistory();

  const handelTabClick = (tab) => {
    setActiveTab(tab);
    // change team to player for squash
    history.push(`${route}/team/${id}/${tab}`, { previousPathname: history.location.pathname });
  };

  useEffect(() => {
    getTeamDetails(sportId, id, currentLanguageCode);
  }, [id, currentLanguageCode, sportName, sportId]);

  useEffect(() => {
    if (TeamDetails?.main_tournament?.id === 726) {
      document.body.classList.add("worldCup-styles");
    }

    if (TeamDetails.id === 6631) {
      document.body.classList.add("alAhly-page-styles");
    }

    return () => {
      document.body.classList.remove("worldCup-styles");
      document.body.classList.remove("alAhly-page-styles");
    };
  }, [TeamDetails?.main_tournament?.id, TeamDetails?.id]);

  return (
    <div>
      {TeamDetailsFailed !== null ? (
        <ErrorPage error="لا يوجد معلومات عن النادي" />
      ) : isFetchingTeamDetails ? (
        <HeaderInfoComponentLoader />
      ) : sportName === "squash" ? (<Player />) : (
        <div className="Team-details-header-container" data-testid="team-details-wrapper">
          <div className="overlay"></div>
          <div
            className="Team-details-header"
            style={{
              backgroundImage: `url(${ImgURL(TeamDetails.background
              )})`,
            }}
          ></div>
          <div className="Team-details-header-desc text-center">
            <img src={ImgURL(TeamDetails.logo)} alt="" />
            <div className="text-light text-center mt-1 fs-lg">
              <strong>{TeamDetails.name}</strong>
            </div>
          </div>
          {!!TeamDetails.champions.length &&
            <div
              className="d-flex justify-content-around align-items-center Team-details-header-champions p-3 "
              onClick={() => {
                handelTabClick("info");
              }}
              style={{ cursor: "pointer" }}
            >
              {TeamDetails.champions.map((el, index) => (
                <div key={index} style={{ position: "relative" }} className="mx-2">
                  <img src={ImgURL(el.logo)} alt="" />
                  <div className="text-light champions-times text-center">
                    x{el.count}
                  </div>
                </div>
              ))}
            </div>
          }
          {
            TeamDetails.market_value && <div
              className="Team-details-header-market text-light p-3 p-3 text-center"
              onClick={() => {
                handelTabClick("players");
              }}
              style={{ cursor: "pointer" }}
            >
              <span>{TeamDetails.market_value}</span>
            </div>
          }
        </div>
      )}
      <div className="container"></div>
      {sportName !== "squash" && <Tab.Container
        id="left-tabs-example"
        // defaultActiveKey="Overview"
        defaultActiveKey={activeTab}
        activeKey={tab || activeTab}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Nav variant="tabs" className="mb-3 flex-nowrap fs-sm">
          <div className="d-flex justify-content-center mx-auto">
            <Nav.Item>
              <Nav.Link
                eventKey="overview"
                onClick={() => handelTabClick("overview")}
              >
                نظره عامه
              </Nav.Link>
            </Nav.Item>
            <RequireSubscription isSubscribed={isSubscriped} >
              <Nav.Item>
                <Nav.Link
                  eventKey="matches"
                  onClick={() => handelTabClick("matches")}
                >
                  المباريات
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                {id === "6631" && TwistSettings["world-cup-games-and-fan-club"] &&
                  <Nav.Link
                    eventKey="games"
                    onClick={() => handelTabClick("games")}
                  >
                    الألعاب
                  </Nav.Link>}
              </Nav.Item>
              <Nav.Item>
                {TeamDetails.sport_id === 2 ? " " :
                  <Nav.Link
                    eventKey="players"
                    onClick={() => handelTabClick("players")}
                  >
                    اللاعبين
                  </Nav.Link>}
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="news" onClick={() => handelTabClick("news")}>
                اخر الاخبار
                </Nav.Link>
              </Nav.Item>
              {
                TwistSettings['videos'] && <>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="videos"
                      onClick={() => handelTabClick("videos")}
                    >
                      احدث الفيديوهات
                    </Nav.Link>
                  </Nav.Item>
                </>
              }
              <Nav.Item>
                <Nav.Link
                  eventKey="standing"
                  onClick={() => handelTabClick("standing")}
                >
                  احصائيات
                </Nav.Link>
              </Nav.Item>
            </RequireSubscription>
            <Nav.Item>
              {TeamDetails.sport_id === 2 ? " " :
                <Nav.Link eventKey="info" onClick={() => handelTabClick("info")}>
                  معلومات النادي
                </Nav.Link>}
            </Nav.Item>
          </div>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="overview">
            <TeamOverview
              toLocalTab={handelTabClick}
              season_id={activeSeason.id}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="matches">
            <TournamentMatches sport_id={1} team_id={id} />
          </Tab.Pane>
          <Tab.Pane eventKey="players">
            <TeamSquad sport_id={1} team_id={id} />
          </Tab.Pane>
          <Tab.Pane eventKey="news">
            <Container>
              <AllMedia
                sport_id={1}
                season_id={activeSeason.id}
                team_id={id}
                items_count={16}
                type={1}
                latest={true}
              />
            </Container>
          </Tab.Pane>
          {
            TwistSettings['videos'] && <>
              <Tab.Pane eventKey="videos">
                <Container>
                  <AllMedia
                    sport_id={1}
                    season_id={activeSeason.id}
                    team_id={id}
                    items_count={16}
                    type={2}
                    latest
                  />
                </Container>
              </Tab.Pane>
            </>
          }
          <Tab.Pane eventKey="standing">
            <TeamStatsDetail team_id={id} toLocalTab={setActiveTab} />
          </Tab.Pane>
          <Tab.Pane eventKey="info">
            <TeamInfo
              TeamDetails={TeamDetails}
              isFetchingTeamDetails={isFetchingTeamDetails}
              TeamDetailsFailed={TeamDetailsFailed}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="games">
            <Games />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>}
    </div >
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTeamDetails: (sport_id, team_id, currentLanguageCode) =>
    dispatch(getTeamDetails(sport_id, team_id, currentLanguageCode)),
  setActiveTab: (tabName) => dispatch(setActiveTab(tabName)),
});

const mapStateToProps = (state) => ({
  TeamDetailsFailed: state.teamDetails.TeamDetailsFailed,
  TeamDetails: state.teamDetails.TeamDetails,
  isFetchingTeamDetails: state.teamDetails.isFetchingTeamDetails,
  activeTab: state.teamDetails.activeTab,
  activeSeason: state.seasons.activeSeason,
  currentLanguageCode: state.language.currentLanguageCode,
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
