import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import api from '../apis';

export default function useIpAddress() {
    const cookies = new Cookies();
    useEffect(() => {
        api.reports.getIp().then((ip) => cookies.set('ip_address', ip, { path: "/", maxAge: `${60 * 60 * 12}`}))
    }, []);

    return null;
}