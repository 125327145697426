import React, { useState, useEffect } from 'react'
import Calendar from "react-calendar";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useServiceAndSport from '../../hooks/useServiceAndSport';
import "./TournamentsMatchesMonthsCalendar.css"

function TournamentsMatchesMonthsCalendar({
  getTournamentsMatchesAPI,
  APIParams = {},
  setTournamentsMatches,
  setIsFetchingTournamentsMatches,
  setTournamentsMatchesFailed,
  squashSports
}) {
  const [busyDays, setBusyDays] = useState([]);
  const [calendarValue, setCalendarValue] = useState(new Date());

  const { serviceId: service_id, sportId: sport_id } = useServiceAndSport();

  const getBusyDays = (params) => {
    if (service_id === null) return;
    params = { ...APIParams, ...params, service_id, sport_id }
    getTournamentsMatchesAPI(params).then(res => {
      let tournamentsMatches;
      if (res?.status === 200) {
        tournamentsMatches = extractTournamentMatchesFromRes(res)
      } else if (res?.code === 200) {
        tournamentsMatches = extractTournamentMatchesFromRes(res?.data)
      }
      const days = [];
      tournamentsMatches[0].matches.forEach(m => {
        days.push(new Date(m.date).toLocaleDateString())
      })
      setBusyDays(days.flat());
    })
  }

  const extractTournamentMatchesFromRes = (res) => {
    let tournamentsMatches = res?.data?.data ?? res;

    if (APIParams.hasOwnProperty('tournament_id')) {
      tournamentsMatches = [{
        id: APIParams.tournament_id,
        matches: res?.data?.data ?? res,
      }]
    }
    return tournamentsMatches
  }

  const getTournamentsMatches = (params) => {
    if (service_id === null) return;
    params = { ...APIParams, ...params, service_id, sport_id }
    setIsFetchingTournamentsMatches(true);
    getTournamentsMatchesAPI(params).then(res => {
      if (res?.status === 200 && !squashSports) {
        const tournamentsMatches = extractTournamentMatchesFromRes(res)
        setTournamentsMatches(tournamentsMatches);
        setTournamentsMatchesFailed(false)
      } else if (res?.code === 200 && squashSports) {
        const customizedData = res?.data?.map(ele => {
          const { tournament_name_ar, player1, player_one_image, player_one, player2, player_two_image, player_two, tournament, result } = ele;
          return {
            ...ele,
            services: [10],
            sport_id: 7,
            team1: {
              id: player1,
              is_favourite: false,
              logo: player_one_image,
              title: player_one,
              services: [10],
              sport_id: 7,
              score: result ? result.split("-")[0] : 0
            },
            team2: {
              id: player2,
              is_favourite: false,
              logo: player_two_image,
              title: player_two,
              services: [10],
              sport_id: 7,
              score: result ? result.split("-")[1] : 0
            },
            tournament: { id: tournament, services: [10], title: tournament_name_ar }
          }
        })

        const tournamentsMatches = extractTournamentMatchesFromRes(customizedData)
        setTournamentsMatches(tournamentsMatches);
        setTournamentsMatchesFailed(false)
      }
      else {
        setTournamentsMatchesFailed(true)
      }
      setIsFetchingTournamentsMatches(false);
    })
  }

  useEffect(() => {
    getBusyDays({ sport_id, service_id, month: calendarValue.getMonth() + 1, year: calendarValue.getFullYear() })
  }, [calendarValue, service_id, sport_id]);

  useEffect(() => {
    const todayDate = new Date();
    getTournamentsMatches({ sport_id, service_id, month: todayDate.getMonth() + 1, year: todayDate.getFullYear() })
  }, [service_id, sport_id]);

  const convertToYYYYMMDD = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const tileClassName = (date) => {
    return busyDays.includes(date.toLocaleDateString())
      ? "match__activeDay"
      : "";
  };

  return <Calendar
    onChange={(e) => {
      getTournamentsMatches({ start_date: convertToYYYYMMDD(e) });
      setCalendarValue(e)
    }}
    onActiveStartDateChange={(e) => {
      const date = new Date(e.activeStartDate);
      getBusyDays({ month: date.getMonth() + 1, year: date.getFullYear() })
    }}
    // value={calendarValue}
    className='bg-black-color'
    tileClassName={({ date }) => tileClassName(date)}
  />
}

export default TournamentsMatchesMonthsCalendar