import TransferHistoryTypes from "./TransferHistory.Types";



const INITIAL_STATE = {
    TransferHistory:[],
    isFetchingTransferHistory:true,
    TransferHistoryFailed:null
   };
   
   const TransferHistory = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case TransferHistoryTypes.GET_TRANSFER_HISTORY_START:
        return {
          ...state,
          isFetchingTransferHistory:true
        }
      case TransferHistoryTypes.GET_TRANSFER_HISTORY_SUCCESS:
        return {
          ...state,
          TransferHistory:action.payload,
          isFetchingTransferHistory:false,
          TransferHistoryFailed:null
        }
      case TransferHistoryTypes.GET_TRANSFER_HISTORY_FAILED:
        return {
          ...state,
          TransferHistoryFailed:action.payload,
          isFetchingTransferHistory:false
        }
       default:
         return state;
     }
   };
 
   export default TransferHistory