import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getStanding } from "../../redux/Standing/Standing.Actions";
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';
import { getServiceName, getSportRoute } from '../../Layout/helper';

const TournamentOverviewStats = ({
    Standing,
    tournamentDetails,
    standingFailed,
    isFetchingStanding,
    currentLanguageCode,
    getStanding,
    sport_id,
    season_id,
    tournament_id,
    toLocalTab,
    competitionType
}) => {

    useEffect(() => {
        getStanding(sport_id, season_id, tournament_id, currentLanguageCode)
    }, [sport_id, season_id, tournament_id, currentLanguageCode])

    const { t } = useTranslation()

    const history = useHistory();
    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/team/${id}`, { previousPathname: history.location.pathname });
    }

    const standingTableRow = (standingData) => {
        return (
            standingData.sort((a, b) => b.point - a.point).map(
                (team, i) => i <= 7 &&
                    <tr key={team.id} onClick={() => handleRowClick(tournamentDetails.services?.[0], tournamentDetails.sport_id, team.team_id)} className='table-clickable-row text-center'>
                        <td style={{ width: '20px' }}>{i + 1}</td>
                        <th scope="row" className={window.document.body.dir === 'rtl' ? 'text-right team-name' : 'text-left team-name'}><img src={ImgURL(team.team_logo)} style={{ width: '20px' }} alt="team logo" /><span className="mx-2 fs-xxs ">{team.team_name}</span></th>
                        <td>{team.goals_in}</td>
                        <td>{team.clean_sheet}</td>
                        <td>{team.played}</td>
                        {/* <td>{team.attempts}</td> */}
                        <td>{team.dribbles}</td>
                    </tr>
            ))
    }

    const handleRenderStanding = () => {
        if (competitionType === "Cup") {
            const teams = Object.values(Standing).flat();
            return standingTableRow(teams)
        } else {
            return standingTableRow(Standing)
        }
    }

    return (
        <div className='bg-black-color white-color fs-xs'>
            <div className='d-flex justify-content-between fs-md p-2'>
                <div>احصائيات</div>
                <div style={{ direction: 'rtl' }}>
                    <button className={`swipe-animation fa fa-chevron-${currentLanguageCode === 'ar' ? 'left' : 'right'} btn white-color`} aria-hidden="true" onClick={() => toLocalTab('stats')}></button>
                </div>
            </div>
            <table className="table-borderless bg-black-color table-striped table-dark white-color">
                <thead>
                    <tr className='text-center'>
                        <th scope="col">المركز</th>
                        <th scope="col" style={{ textAlign: 'start' }}>الفريق</th>
                        <th scope="col">اهداف</th>
                        <th scope="col">شباك نظيفه</th>
                        <th scope="col">مباريات</th>
                        {/* <th scope="col">محاولات على المرمى</th> */}
                        <th scope="col">المراوغات</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        standingFailed !== null ?
                            <tr>
                                <td colSpan={7} className='p-0'>
                                    <ErrorMsg msg="لا يوجد احصائيات" p={5} fs={'lg'} />
                                </td>
                            </tr>
                            :
                            isFetchingStanding ?
                                <tr>
                                    <td colSpan={7} className='p-0'>
                                        <LoadingSpinnner p={5} fs={'md'} />
                                    </td>
                                </tr>
                                :
                                Standing.length === 0 ?
                                    <tr>
                                        <td colSpan={7} className='p-0'>
                                            <ErrorMsg msg="لا يوجد احصائيات" p={5} fs={'lg'} />
                                        </td>
                                    </tr>
                                    :
                                    handleRenderStanding()
                    }

                </tbody>
            </table>
        </div>
    )
}


const mapDispatchToProps = (dispatch) => ({
    getStanding: (sport_id, season_id, tournament_id, lang) => dispatch(getStanding(sport_id, season_id, tournament_id, lang)),
});

const mapStateToProps = (state) => ({
    isFetchingStanding: state.standing.isFetchingStanding,
    standingFailed: state.standing.StandingFailed,
    Standing: state.standing.Standing,
    competitionType: state.standing.competitionType,
    currentLanguageCode: state.language.currentLanguageCode,
    tournamentDetails: state.standing.TournamentDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(TournamentOverviewStats)
