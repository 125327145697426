import React from 'react'
import "./MatchesCarouselComponent.css"
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MatchCard from '../MatchCard/MatchCard';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import { useTranslation } from 'react-i18next';
import SampleArrowBtn from '../SampleArrowBtn/SampleArrowBtn'

function MatchesCarouselComponent({ matches }) {

  const matchesSortedByTime = matches?.sort((a, b) => new Date(b.date) - new Date(a.date))

  const { t } = useTranslation();

  const settings = {
    infinite: false,
    draggable: false,
    touchMove: false,
    speed: 900,
    slidesToShow: 4.15,
    slidesToScroll: 4,
    rtl: false,
    nextArrow: <SampleArrowBtn arrowDirection="left"
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        borderRadius: "6px",
        border: "unset",
        position: "absolute",
        top: "0px",
        height: "100%",
        right: "-12px",
        zIndex: "15",
        width: "20px",
        opacity: "1",
        color: "white"
      }}
    />,
    prevArrow: <SampleArrowBtn
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        borderRadius: "6px",
        border: "unset",
        position: "absolute",
        top: "0px",
        height: "100%",
        left: "-12px",
        zIndex: "15",
        width: "20px",
        opacity: "1",
        color: "white"
      }}
    />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return <div className="matchs-carousel start-carousel-at-right" data-testid="matches-carousel-container">
    <Slider {...settings}>
      {
        matches?.length === 0 || !matches || matches.every(match => match.matchStatus === 3)
          ? <ErrorMsg p={5} fs={'md'} msg="لا يوجد مباريات" />
          : matchesSortedByTime?.map(el => el.matchStatus !== 3 && <MatchCard key={el.id} el={el} direction="rtl" />)
      }
    </Slider>
  </div>
}

export default MatchesCarouselComponent



