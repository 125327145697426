import React from 'react';
import { useTranslation } from "react-i18next";
import { ImgURL } from '../../globalData';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { GiTShirt } from "react-icons/gi";
import { Col, Row } from 'react-bootstrap';


const TeamInfo = ({ TeamDetails, isFetchingTeamDetails, TeamDetailsFailed }) => {

    const { t } = useTranslation();

    return (
        <div className='container'>
            {
                TeamDetailsFailed !== null
                    ? <ErrorMsg p={5} fs={'lg'} msg={'Error.GeneralError'} />
                    : isFetchingTeamDetails
                        ? <LoadingSpinnner p={5} fs={'md'} />
                        : <div>
                            <div className='bg-black-color p-2 p-md-4'>
                                <div className='d-flex justify-content-center text-center text-light fs-xxs'>
                                    <div className='px-md-5 px-2 '>
                                        <img src='/icons/founded.svg' alt="" />
                                        <div className='white-color mt-1'>تاسس</div>
                                        <div className='text-light mt-1'>{TeamDetails.founded}</div>
                                    </div>
                                    <div className='border-right border-secondary px-md-5 px-2'>
                                        <img src='/icons/stadium.svg' alt="" />
                                        <div className='white-color mt-1'>استاد</div>
                                        <div className='text-light mt-1'>{TeamDetails.stadium}</div>
                                    </div>
                                    <div className='border-right border-secondary px-md-5 px-2'>
                                        {TeamDetails.manager?.image ?
                                            <img src={ImgURL(TeamDetails.manager.image)} style={{ width: "35px", height: "35px" }} alt="" /> :
                                            <img src='/icons/manager.svg' alt="" />}
                                        <div className='white-color mt-1'>المدير الفني</div>
                                        <div className='text-light mt-1'>{TeamDetails.manager?.name}</div>
                                    </div>
                                    <div className='border-right border-secondary px-md-5 px-2'>
                                        <img src='/icons/speaker.svg' alt="" />
                                        <div className='white-color mt-1'>رئيس</div>
                                        <div className='text-light mt-1'>{TeamDetails.chairman}</div>
                                    </div>
                                    <div className='border-right border-secondary px-md-5 px-2'>
                                        <img src='/icons/player.svg' alt="" />
                                        <div className='white-color mt-1'>اخر انتقال</div>
                                        <div className='text-light mt-1'></div>
                                    </div>
                                    <div className='border-right border-secondary px-md-5 px-2'>
                                        <GiTShirt style={{ color: `${TeamDetails.first_color}`, width: "2rem", height: "2rem" }} />
                                    </div>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-between m-1 mt-4'>
                                {
                                    TeamDetails.champions.map(prize =>
                                        <div className='col-12 col-md-4 bg-black-color p-3 border border-dark fs-xs' height="200px">
                                            <div className='text-light mb-3 p-2'>x{prize.count} {prize.title}</div>
                                            <Row>
                                                <Col className='d-flex flex-row justify-content-around align-items-center'>
                                                    <img src={ImgURL(prize.logo)} width="70px" height="70px" alt="" />
                                                </Col>
                                                <Col className='white-color text-center'>
                                                    {prize.seasons}
                                                </Col>
                                            </Row>
                                        </div>)
                                }
                            </div>
                        </div>
            }
        </div>
    )
}

export default TeamInfo