import React, { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios';
import { Col, ProgressBar, Row, Dropdown } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { globalBaseUrl, ImgURL } from '../../globalData';
import { connect } from 'react-redux'
import SeasonsDropdown from '../SeasonsDropdown/SeasonsDropdown';

const ComparisonPlayersResults = ({ comparisonItems, activeSeason }) => {
    const { t } = useTranslation()

    const [isFetchingPlayerStats, setIsFetchingPlayerStats] = useState(false)
    const [PlayersStats, setPlayersStats] = useState([])
    const [errorPlayerStats, setErrorPlayerStats] = useState(null)

    const [isFetchingPlayerDetails, setIsFetchingPlayerDetails] = useState(false)
    const [PlayersDetails, setPlayersDetails] = useState([]);
    const [errorPlayerDetails, setErrorPlayerDetails] = useState(null)
    const tempStatsState = []
    const tempDetailsState = []

    const getPlayerStatistics = (sport_id, season_id, tournament_id, team_id, player_id, lang, i) => {
        axios({
            method: "get",
            url: `${globalBaseUrl.current}${process.env.REACT_APP_NEW_GET_PLAYER_STATISTICS}`,
            params: { sport_id, season_id, tournament_id, team_id, player_id },
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": `${lang}`
            },
        }).then(resp => {
            if (resp.data.status === 200) {
                if (PlayersStats.length >= 2) {
                    setPlayersStats(PlayersStats.map((player, i2) => i === i2 ? resp.data.data : player))
                }
                else {
                    tempStatsState.length < 1 ? tempStatsState.push(resp.data.data) : setPlayersStats([...tempStatsState, resp.data.data])
                }
            }
            else {
                setErrorPlayerStats(resp.data.message)
            }
        }
        )
            .catch(error => {
                setErrorPlayerStats(error)
            })
    }

    const getPlayerDetails = (player_id, lang) => {
        axios({
            method: "get",
            url: `${globalBaseUrl.current}${process.env.REACT_APP_NEW_GET_PLAYERS}/${player_id}`,
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": `${lang}`
            },
        }).then(resp => {
            if (resp.data.status === 200) {
                const newPlayer = { ...resp.data.data, currentTournament: resp.data.data.current_team.tournaments.filter(tour => tour.type === 'League')[0] }
                tempDetailsState.length < 1 ? tempDetailsState.push(newPlayer) : setPlayersDetails([...tempDetailsState, newPlayer])
            }
            else {
                setErrorPlayerDetails(resp.data.message)
            }
        }
        )
            .catch(error => setErrorPlayerDetails(error))
    }

    useEffect(() => {
        comparisonItems.map(player => {
            setIsFetchingPlayerStats(true)
            getPlayerStatistics(null, activeSeason?.id, player.main_tournament, player.current_team, player.id, 'ar')
            setIsFetchingPlayerStats(false)
        })
        comparisonItems.map(player => {
            setIsFetchingPlayerDetails(true)
            getPlayerDetails(player.id, 'ar')
            setIsFetchingPlayerDetails(false)
        })

    }, [])

    const statisticsKeys = {
        played: "لعب",
        win: "فوز",
        lose: "خساره",
        draw: "تعادل",
        goals_in: "عليه",
        goals_out: "اهداف له",
        goals_penalty: "اهداف الجزاء",
        clean_sheet: "شباك نظيفه",
        yellow_cards: "كروت صفراء",
        red_cards: "كروت حمراء",
        difference: "فارق الاهداف",
        point: "نقاط",
        passes: "تمريرات ناجحه",
        lost_balls: "كرات ضائعه",
        attempts: "محاولات على المرمى",
        chances: "فرص",
        dribbles: "المراوغات",
        fouls_in: "اخطاء عليه",
        fouls_out: "اخطاء له",
        ball_win: "استعاد الكرة",
        ball_lose: "خسر الكرة",
        appearance: "لعب",
        missed_penalty: "اضاع بنلتي",
        played_minutes: "دقائق اللعب",
        assisst: t(`Player.Assists`),
        reversed_goals: "هدف في مرماه",
        goals: "اهداف"
    }

    const showedStats =
        [
            "assisst",
            "attempts",
            "ball_lose",
            "ball_win",
            "dribbles",
            "fouls_in",
            "fouls_out",
            "goals",
            // "goals_penalty",
            // "missed_penalty",
            "played",
            "played_minutes",
            "red_cards",
            // "reversed_goals",
            "yellow_cards"
        ]

    const getTotalPlayersStatsDetails = (playersStats, playersStatsLength, key) => {
        if (playersStatsLength < 0) return 0;
        let totalPlayersStatsDetails = playersStats[playersStatsLength][key] + getTotalPlayersStatsDetails(playersStats, playersStatsLength - 1, key);
        return totalPlayersStatsDetails;
    }

    return (
        <div>
            {
                isFetchingPlayerDetails ?
                    <div className='row justify-content-around'>
                        <div className='col-4'>
                            <div className='text-center mt-5'>
                                <div className="spinner-border text-secondary fs-xs p-0" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='text-center mt-5'>
                                <div className="spinner-border text-secondary fs-xs p-0" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='row justify-content-around'>
                        {/* <div className='order-2 p-2 col-2'><img src={Vs} width={100} height={120}/></div>  */}
                        {
                            PlayersDetails.map((player, i) =>

                                <div className={i > 0 ? 'order-3 col-6' : 'order-1 col-6'}>
                                    <div className={`comparison-item d-flex justify-content-start align-items-center p-2 mb-2`}>
                                        <div className='comparison-search-item'>
                                            <img src={ImgURL(player.image)} alt="" />
                                            <img src={ImgURL(player.current_team.logo)} alt="" />
                                        </div>
                                        <div className='mx-3 fs-sm text-nowrap'>
                                            <div className='text-light'>{player.name}</div>
                                            <div className='white-color'>{player.position}</div>
                                        </div>
                                    </div>
                                    <Row className='d-flex  justify-content-start align-items-start'>
                                        <div className='px-1 col-12 col-md-auto'>
                                            <SeasonsDropdown
                                                tournament_id={player.currentTournament?.id}
                                                onChange={(season) => getPlayerStatistics(1, season?.id, player.currentTournament.id, player.current_team.id, player.id, 'ar', i)}
                                                classes="fs-xs"
                                            />
                                        </div>
                                        <div className='px-1 col-12 col-md-auto'>
                                            <Dropdown className="m-1">
                                                <Dropdown.Toggle variant="secondary" id={`dropdown-button-drop-down`} drop='down' className='fs-xs'>
                                                    {player.current_team.name}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='fs-xs'>
                                                    {
                                                        player.teams.map(team =>
                                                            <div><Dropdown.Item className='text-light'
                                                                onClick={() => {
                                                                    setPlayersDetails(PlayersDetails.map((player, i2) => i === i2 ? { ...player, current_team: team, currentTournament: team.tournaments.filter(tour => tour.type === 'League')[0] } : player))
                                                                    getPlayerStatistics(null, activeSeason?.id, team.tournaments.filter(tour => tour.type === 'League')[0].id, team.id, player.id, 'ar', i)
                                                                }}>
                                                                {team.name}
                                                            </Dropdown.Item>
                                                            </div>)
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className='px-1 col-12 col-md-auto'>
                                            <Dropdown className="m-1">
                                                <Dropdown.Toggle variant="secondary" id={`dropdown-button`} drop='down' className='fs-xs'>
                                                    {player.currentTournament?.title}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='fs-xs'>
                                                    {
                                                        player.current_team.tournaments.map(tour => <div key={tour.id}><Dropdown.Item className='text-light'
                                                            onClick={() => {
                                                                setPlayersDetails(PlayersDetails.map((player, i2) => i === i2 ? { ...player, currentTournament: tour } : player))
                                                                getPlayerStatistics(null, activeSeason?.id, tour.id, player.current_team.id, player.id, 'ar', i)
                                                            }}>
                                                            {tour.title}
                                                        </Dropdown.Item>
                                                        </div>)
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Row>
                                </div>)
                        }
                    </div>
            }
            {
                isFetchingPlayerStats ?
                    <div className='mt-5'>
                        <LoadingSpinnner p={5} fs={'md'} />
                    </div>
                    :
                    <Row className='d-flex justify-content-around items-stats-comparison'>
                        {
                            PlayersStats.map(Player =>
                                <Col xs={5}>
                                    {
                                        ['goals', ...Object.keys(Player).filter(k => k !== 'goals').slice(0, 15)].map((key, i) =>
                                            showedStats.includes(key) &&
                                            <div className='mt-3' >
                                                <div className='d-flex justify-content-between'>
                                                    <div className='fs-xs'>{statisticsKeys[key]}</div>
                                                </div>
                                                <ProgressBar
                                                    now={Player[key]}
                                                    label={Player[key]}
                                                    max={getTotalPlayersStatsDetails(PlayersStats, PlayersStats.length - 1, key) || 1}
                                                />
                                            </div>
                                        )
                                    }

                                </Col>)
                        }
                    </Row >
            }
        </div>
    )
}


const mapStateToProps = (state) => ({
    activeSeason: state.seasons.activeSeason,
});


export default connect(mapStateToProps)(ComparisonPlayersResults)
