import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import teamOfTheCompetitionImg from '../../assets/games/help.png';
import guessThePlayerImg from '../../assets/games/anonymous.png';
import playerOfTheCompetitionImg from '../../assets/games/competition4.png';
import worldCupQuizzesImg from '../../assets/games/FIFA_World_Cup_trophy580.png';
import handballWorldCupImg from '../../assets/games/HandballWorldCupLogoWithBG.png'
import cupImgForHandball from '../../assets/games/cupImgForHandball.jpg'
import elAhlyGameImg from '../../assets/games/ElAhlyLogoNoStars.png'
import './games.css';
import GuessThePlayerModal from './Modals/GuessThePlayerModal';
import QuizzesModal from './Modals/QuizzesModal';
import { useTranslation } from 'react-i18next';
import TeamOfTheCompetition from './Modals/TeamOfTheCompetition';
import PlayerOfTheCompetitionModal from './Modals/PlayerOfTheCompetition';
import PlaystationFormModal from './Modals/PlaystationFormModal';
import PlaystationMatchDetailsModal from './Modals/PlaystationMatchDetails';
import Cookies from 'universal-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../apis';
import { ImCheckmark } from 'react-icons/im';
import TwistSettings from '../../TwistSettings.json'
import { getUserPhoneNumbers } from '../../globalFn';

const Games = () => {
  const [teamOfTheCompetition, setTeamOfTheCompetition] = useState(false);
  const [guessThePlayerModal, setGuessThePlayerModal] = useState(false);
  const [quizzesModal, setQuizzesModal] = useState(false);
  const [showPlayerOfTheCompetitionModal, setShowPlayerOfTheCompetitionModal] =
    useState(false);
  const [showPlaystationFormModal, setShowPlaystationFormModal] =
    useState(false);
  const [
    showPlaystationMatchDetailsModal,
    setShowPlaystationMatchDetailsModal,
  ] = useState(false);
  const [phone, setPhone] = useState('');
  const [userPlaystationInfoData, setUserPlaystationInfoData] = useState(null);
  const [
    isFetchingUserPlaystationInfoData,
    setIsFetchingUserPlaystationInfoData,
  ] = useState(false);
  const [userPlaystationInfoError, setUserPlaystationInfoError] = useState('');
  const [
    isSendingUserPlaystationRegistrationInfo,
    setIsSendingUserPlaystationRegistrationInfo,
  ] = useState(false);
  const [
    userPlaystationRegistrationError,
    setUserPlaystationRegistrationError,
  ] = useState('');
  const [submittedGames, setSubmittedGames] = useState({});
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();

  const gamesData = [
    // {
    //   title: t('Games.teamOfTheCompetition.title'),
    //   imgSrc: teamOfTheCompetitionImg,
    //   gameName: "teamOfCompetition",
    //   onClick: (isSubmitted) => setTeamOfTheCompetition(isSubmitted)
    // },
    // {
    //   title: t('Games.guessPlayer.title'),
    //   imgSrc: guessThePlayerImg,
    //   gameName: "guessThePlayer",
    //   onClick: (isSubmitted) => setGuessThePlayerModal(isSubmitted),
    // },
    // {
    //   title: t('Games.playerOfTheCompetition.title'),
    //   imgSrc: playerOfTheCompetitionImg,
    //   gameName: "playerOfCompetition",
    //   onClick: (isSubmitted) => setShowPlayerOfTheCompetitionModal(isSubmitted),
    // },
    {
      title: t('Games.quizzesGame.title'),
      imgSrc: elAhlyGameImg,
      gameName: "quiz",
      onClick: (isSubmitted) => setQuizzesModal(isSubmitted),
    },
  ];

  const getUserPlaystationInfo = () => {
    setIsFetchingUserPlaystationInfoData(true);
    api.playstation.getUserInfo({ phone }).then((res) => {
      setIsFetchingUserPlaystationInfoData(false);
      if (res.status === 200) {
        setUserPlaystationInfoData(res.data);
        handleShowPlaystationModal(res.data);
      } else {
        setUserPlaystationInfoError(res.message);
      }
    });
  };

  const sendUserPlaystationRegistrationInfo = (data) => {
    const {
      userName: name,
      playStationName: playstation_name,
      userPhoneNumber,
    } = data;
    const mobile = `2${userPhoneNumber}`;
    setPhone(mobile);
    setIsSendingUserPlaystationRegistrationInfo(true);
    api.playstation
      .sendUserRegistrationInfo({ name, playstation_name, mobile })
      .then((res) => {
        if (res.status === 200) {
          setShowPlaystationFormModal(false);
        } else {
          setUserPlaystationRegistrationError(res.message);
        }
      });
  };

  const handleShowPlaystationModal = (response) => {
    if (!Object.keys(response).length) {
      setShowPlaystationFormModal(true);
    } else {
      setShowPlaystationMatchDetailsModal(true);
    }
  };

  const handlePlaystationButtonClick = () => {
    if (!phone) {
      history.push({
        pathname: '/international/subscribe',
        state: { previousPathname: pathname },
      });
    } else {
      getUserPlaystationInfo();
    }
  };

  const getSubmittedGames = async () => {
    const cookies = new Cookies();
    const msisdn = TwistSettings["subscription"] ? cookies.get('international')?.split(',')[1] : getUserPhoneNumbers()[0];
    if (msisdn) {
      await api.games.getSubmittedGames({ msisdn }).then(res => {
        if (res.status === 200) setSubmittedGames(res.data);
      })
    }
  }

  useEffect(() => {
    const cookies = new Cookies();
    const phone = TwistSettings["subscription"] ? cookies.get('international')?.split(',')[1] : getUserPhoneNumbers()[0];
    setPhone(phone);
    getSubmittedGames()
  }, []);

  return (
    <Container className='games'>
      {
        TwistSettings['playstation-game'] && <div className='games-page-title'>
          <h5>مسابقة البلايستيشن</h5>
          <p>
          اشترك في مونديال بلايستيشن اتصالات سبورتس ونافس أصحابك والمشتركين في الخدمة خلال كأس العالم.
            <span className='mx-1'>اعرف التفاصيل من الشروط والأحكام</span>
          </p>
          <button
            className='playstaion__button btn bg-white'
            onClick={() => {
              handlePlaystationButtonClick();
            }}
          >
            {isFetchingUserPlaystationInfoData ? (
              <div
                className='spinner-border text-center text-danger fs-xs p-0'
                role='status'
              />
            ) : (
              t('Games.quizzesGame.start')
            )}
          </button>
          {!!userPlaystationInfoError && !isFetchingUserPlaystationInfoData && (
            <div className='text-danger mt-2'>حدث خطا يرجي اعادة المحاولة</div>
          )}
        </div>
      }
      <PlaystationMatchDetailsModal
        show={showPlaystationMatchDetailsModal}
        onHide={() => setShowPlaystationMatchDetailsModal(false)}
        userPlaystationInfoData={userPlaystationInfoData}
      />

      <Row className='justify-content-center'>
        {gamesData.map(({ title, imgSrc, onClick, gameName }) => (
          <Col xs={12} sm={6} lg={gamesData.length === 1 ? 12 : 3} key={title} className="mb-3">
            <div className={`game-card ${submittedGames[gameName] ? "submitted" : ""}`} onClick={() => onClick(!submittedGames[gameName])}>
              <div className="title ">
                <h6 className="">{title}</h6>
              </div>
              <div className="image__wrapper">
                <img src={imgSrc} alt={title} />
                {submittedGames[gameName] &&
                  <ImCheckmark className="submitted__icon" />
                }
              </div>
            </div>
          </Col>
        ))}
      </Row>

      <PlaystationFormModal
        show={showPlaystationFormModal}
        onHide={() => setShowPlaystationFormModal(false)}
        sendUserPlaystationRegistrationInfo={
          sendUserPlaystationRegistrationInfo
        }
        userPlaystationRegistrationError={userPlaystationRegistrationError}
        isSendingUserPlaystationRegistrationInfo={
          isSendingUserPlaystationRegistrationInfo
        }
      />
      <TeamOfTheCompetition
        show={teamOfTheCompetition}
        onHide={() => { getSubmittedGames(); setTeamOfTheCompetition(false); }}
      />
      <GuessThePlayerModal
        show={guessThePlayerModal}
        onHide={() => { getSubmittedGames(); setGuessThePlayerModal(false); }}
      />
      <QuizzesModal
        show={quizzesModal}
        onHide={() => { getSubmittedGames(); setQuizzesModal(false); }}
      />
      <PlayerOfTheCompetitionModal
        show={showPlayerOfTheCompetitionModal}
        onHide={() => { getSubmittedGames(); setShowPlayerOfTheCompetitionModal(false); }}
      />
    </Container >
  );
};

export default Games;
