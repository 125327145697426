import React, { useState, useEffect, lazy } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import RequireSubscription from "../../components/require-subscription";
import SeasonsDropdown from "../../components/SeasonsDropdown/SeasonsDropdown";
import { IDS, ImgURL } from "../../globalData";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import { getTournamentDetails, setTournamentActiveTab } from "../../redux/TournamentDetails/TournamentDetails.Actions";
import HeaderInfoComponentLoader from "./HeaderInfoComponentLoader";
import CupTournamentGroupsStanding from "../../components/TournamentStanding/CupTournamentGroupsStanding/CupTournamentGroupsStanding";
import UserPhoneNumModal from "../../components/Games/Modals/UserPhoneNumModal";
import { getUserPhoneNumbers } from "../../globalFn";
import TwistSettings from "../../TwistSettings.json";
import worldCupLogo from "../../assets/games/fan-club-logo.svg";
import UserPoints from "../../components/UserPoints/UserPoints";
import "./tournament.css";
import OtherSportsPlayers from "../../components/OtherSportsPlayer";

import TournamentOverview from '../../components/TournamentOverview/TournamentOverview';
import TournamentMatches from '../../components/TournamentMatches/TournamentMatches';
import Games from '../../components/Games';
import TourStandingDetails from '../../components/TournamentStanding/TourStandingDetails';
import TournamentKnockOuts from '../../components/TournamentKnockOuts';
import TourStatsDetails from '../../components/TournamentStatistics/TourStatsDetails';
import TournamentHistory from '../../components/TournamentHistory/TournamentHistory';
import FanClub from '../../components/FanClub';

const Tournament = ({
  TournamentDetails,
  TournamentDetailsFailed,
  activeSeason,
  isFetchingTournamentDetails,
  getTournamentDetails,
  setTournamentActiveTab,
  activeTab,
  currentLanguageCode,
  isSubscriped,
  competitionType,
}) => {
  const { t } = useTranslation();
  const { route, sportId, sportName } = useServiceAndSport();
  const isFootball = sportId === IDS.SPORTS.FOOTBALL || sportId === undefined
  const id = useParams().id;
  const tab = useParams().tab || "overview";
  const history = useHistory();
  const [modalShow, setModalShow] = useState(true);
  const [foundUserPhoneNumber, setFoundUserPhoneNumber] = useState("");

  const handelTabClick = (tab) => {
    history.push(`${route}/tournament/${id}/${tab}`, {
      previousPathname: history.location.pathname,
    });
  };

  const addClassDependingOnTournamentID = (id) => {
    if (id === "726") {
      document.body.classList.add("worldCup-styles");
    }
  };

  useEffect(() => {
    if (sportName === null || activeSeason.id === null) return;
    sportName === "squash"
      ? getTournamentDetails(sportId, id, currentLanguageCode, sportName)
      : getTournamentDetails(sportId, id, currentLanguageCode);

    const userPhoneNumbers = getUserPhoneNumbers();
    if (userPhoneNumbers.length > 0) {
      setFoundUserPhoneNumber(userPhoneNumbers[0]);
    }

    addClassDependingOnTournamentID(id);

    return () => {
      document.body.classList.remove("worldCup-styles");
    };
  }, [id, currentLanguageCode, sportName, activeSeason.id, route]);

  return (
    <div>
      {!foundUserPhoneNumber && id === "7940" && (
        <UserPhoneNumModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
      {TournamentDetailsFailed !== null ? (
        <ErrorPage error="لا يوجد معلومات عن البطوله" />
      ) : isFetchingTournamentDetails ? (
        <HeaderInfoComponentLoader />
      ) : (
        <div className="Tournament-details-header-container">
          {
            id === "726" &&
            TwistSettings["user-points"] && <div className="Tournament-user-points-conatiner">
              <UserPoints />
            </div>
          }
          <div className="Tournament-details-header"></div>
          <div className="Tournament-details-header-desc text-center">
            <img
              src={id == "726" ? worldCupLogo : ImgURL(TournamentDetails?.logo)}
              alt=""
            />
            <div className="text-light text-center mt-3 fs-lg">
              <strong>{TournamentDetails?.title}</strong>
            </div>
          </div>
        </div>
      )}
      <div className="container"></div>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={activeTab}
        onSelect={(k) => setTournamentActiveTab(k)}
        activeKey={tab || activeTab}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Nav variant="tabs" className="mb-3  flex-nowrap fs-sm .tournament-nav">
          <div className="d-flex justify-content-center mx-auto">
            <Nav.Item>
              <Nav.Link
                eventKey="overview"
                onClick={() => handelTabClick("overview")}
              >
                نظره عامه
              </Nav.Link>
            </Nav.Item>
            {sportId === 7 &&
              <Nav.Item>
                <Nav.Link
                  eventKey="players"
                  onClick={() => handelTabClick("players")}
                >
                  {t(`TournamentDetails.Players`)}
                </Nav.Link>
              </Nav.Item>
            }
            <Nav.Item>
              <Nav.Link
                eventKey="matches"
                onClick={() => handelTabClick("matches")}
              >
                المباريات
              </Nav.Link>
            </Nav.Item>
            {
              // id === "7940" && TwistSettings["world-cup-games-and-fan-club"] && <Nav.Item>
              //   <Nav.Link
              //     eventKey="games"
              //     onClick={() => handelTabClick("games")}
              //   >
              //     الألعاب
              //   </Nav.Link>
              // </Nav.Item>
            }
            {isFootball &&
              <Nav.Item>
                <Nav.Link
                  eventKey="standing"
                  onClick={() => handelTabClick("standing")}
                >
                  الترتيب
                </Nav.Link>
              </Nav.Item>
            }
            <RequireSubscription
              isSubscribed={id === "7940" ? true : isSubscriped}
            >
              {!!TournamentDetails.has_tree && (
                <Nav.Item>
                  <Nav.Link
                    eventKey="knockouts"
                    onClick={() => handelTabClick("knockouts")}
                  >
                    الادوار الاقصائية
                  </Nav.Link>
                </Nav.Item>
              )}
              {
                isFootball && <Nav.Item>
                  <Nav.Link
                    eventKey="stats"
                    onClick={() => handelTabClick("stats")}
                  >
                    احصائيات
                  </Nav.Link>
                </Nav.Item>
              }
              <Nav.Item>
                <Nav.Link
                  eventKey="history"
                  onClick={() => handelTabClick("history")}
                >
                  تاريخ البطوله
                </Nav.Link>
              </Nav.Item>
              {id === "726" && TwistSettings["world-cup-games-and-fan-club"] && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="fanClub"
                      onClick={() => handelTabClick("fanClub")}
                    >
                      نادي المشجعين
                    </Nav.Link>
                  </Nav.Item>

                </>
              )}
            </RequireSubscription>
          </div>
        </Nav>
        <Nav
          className={
            `container mt-3 ${tab === "games" || tab === "fanClub"
              ? "d-none"
              : ""
            }`
          }>
          <SeasonsDropdown tournament_id={TournamentDetails?.id} />
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="overview">
            <TournamentOverview
              toLocalTab={handelTabClick}
              season_id={activeSeason.id}
            />
          </Tab.Pane>
          {sportId === 7 &&
            <Tab.Pane eventKey="players">
              <OtherSportsPlayers
                toLocalTab={handelTabClick}
                season_id={activeSeason.id}
                sportName={sportName}
                id={id}
              />
            </Tab.Pane>
          }
          <Tab.Pane eventKey="matches">
            <TournamentMatches sport_id={sportId} tournament_id={id} />
          </Tab.Pane>
          <Tab.Pane eventKey="standing">
            {competitionType === "Cup" ? (
              <CupTournamentGroupsStanding
                sport_id={sportId}
                season_id={activeSeason.id}
              />
            ) : (
              <TourStandingDetails
                sport_id={sportId}
                season_id={activeSeason.id}
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="stats">
            <TourStatsDetails sport_id={sportId} season_id={activeSeason.id} />
          </Tab.Pane>
          <Tab.Pane eventKey="knockouts">
            <TournamentKnockOuts
              sport_id={sportId}
              season_id={activeSeason.id}
              id={id}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="history">
            <TournamentHistory />
          </Tab.Pane>
          <Tab.Pane eventKey="fanClub">
            <FanClub subscriptionModal={modalShow} />
          </Tab.Pane>
          {/* <Tab.Pane eventKey="games">
            <Games />
          </Tab.Pane> */}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTournamentDetails: (
    sport_id,
    tournament_id,
    currentLanguageCode,
    sportName
  ) =>
    dispatch(
      getTournamentDetails(
        sport_id,
        tournament_id,
        currentLanguageCode,
        sportName
      )
    ),
  setTournamentActiveTab: (tabName) =>
    dispatch(setTournamentActiveTab(tabName)),
});

const mapStateToProps = (state) => ({
  TournamentDetailsFailed: state.tournamentDetails.TournamentDetailsFailed,
  TournamentDetails: state.tournamentDetails.TournamentDetails,
  isFetchingTournamentDetails:
    state.tournamentDetails.isFetchingTournamentDetails,
  activeSeason: state.seasons.activeSeason,
  activeTab: state.tournamentDetails.activeTab,
  currentLanguageCode: state.language.currentLanguageCode,
  competitionType: state.standing.competitionType,
});

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);
