import * as ActionTypes from "./Types.MediaDetails";

export const MediaDetails = (
  state = { isLoading: true, errMess: null, mediaDetails: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_MEDA_DETAILS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        mediaDetails: action.payload,
      };

    case ActionTypes.MEDA_DETAILS_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
        mediaDetails: [],
      };

    case ActionTypes.MEDA_DETAILS_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};
