import React from "react";
import { Link, useLocation } from "react-router-dom";
import { GoCalendar } from "react-icons/go";
import Moment from "moment";
import { ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import { convertFullDate } from "../../globalFn";

const MediaCard = ({ news, type }) => {
  const { pathname } = useLocation();

  return (
    <div className="news-card p-0 w-100">
      <Link
        to={{
          pathname: `/${getServiceName(news.services?.[0])}${getSportRoute(
            news.sport_id,
            news.services?.[0]
          )}/media/${news.id}`,
          state: { previousPathname: pathname },
        }}
      >
        <div>
          {type === 2 && (
            <div className="play-icon-wrapper">
              <div
                data-testid="MediaCard_video_play_icon"
                className="play-icon"
              >
                <i className="fa fa-play"></i>
              </div>
            </div>
          )}
          <img
            src={type === 2 ? ImgURL(news.video_cover) : ImgURL(news.media)}
            alt="media"
          />
        </div>
        <div>
          <div className="news-card-description bg-black-color-opacity p-1 text-center">
            <p className="text-cut-2 text-light px-1 mb-1">{news.title}</p>
            <span className="text-left px-2 mb-1 white-color">
              <GoCalendar />
              <span className="mx-1" data-testid="media_card_date">
                {convertFullDate(news.created_at)}
              </span>
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default MediaCard;
