import React from "react";
import { useTranslation } from "react-i18next";
import MatchesCalendar from "../../components/MatchesCalendar/MatchesCalendar";
import LatestNews from "../../components/News/LatestNews";
import SportsSectionHeader from "../../components/sportsSectionHeader/sportsSectionHeader.component";
import TournamentsCarousel from "../../components/TournamentsCarousel/TournamentsCarousel";
import { connect } from "react-redux";
import NewsSearch from "../../components/News/NewsSearch";
import { IDS } from "../../globalData";
import TopStories from "../../components/News/TopStories";
import AllMedia from "../../components/News/AllMedia";
import TwistSettings from "../../TwistSettings.json";

const Africa = ({ NewsSearchQuery, activeSeason }) => {
  const { t } = useTranslation();

  return (
    <div className="container home-page ">
      <div className="d-flex justify-content-center row">
        <div className="col-12">
          {/* <img src={IphoneAdd} height={200} width={"100%"} /> */}
        </div>
        <MatchesCalendar sport_id={IDS.SPORTS.FOOTBALL} service_id={IDS.SERVICES.AFRICA} season_id={null} tournament_id={null} />
        <SportsSectionHeader title="اهم الاحداث" navigateTo="/africa/news" />
        <TopStories sport_id={IDS.SPORTS.FOOTBALL} service_id={IDS.SERVICES.AFRICA} season_id={null} items_count={4} media_type={1} tournament_id={null} media_id={null} team_id={null} player_id={null} top_stories={true} latest={null} popular={null} />
        <hr></hr>

        <div className="row align-items-baseline">
          {/* <div className="col-12 col-md-6 fs-xs">
            <SportsSectionHeader title="ترتيب الدوريات" />
            <GeneralStandings sport_id={IDS.SPORTS.FOOTBALL} service_id={IDS.SERVICES.AFRICA} season_id={null} />
          </div> */}
          <div className='col-12 col-md-12 fs-md'>
            <div className='text-light mt-5 fs-lg '><strong>اختر بطولة</strong></div>
            <TournamentsCarousel sport_id={IDS.SPORTS.FOOTBALL} service_id={IDS.SERVICES.AFRICA} />
          </div>
        </div>
        <div className="col-12">
          {/* <img src={Cokebanner} height={200} width={"100%"} className="mt-3" /> */}
        </div>
        <SportsSectionHeader title="اخر الاخبار" navigateTo="/africa/news" />
        <NewsSearch />
        <div className={`${NewsSearchQuery.length > 0 ? 'd-none' : ""} my-2`}>
          <LatestNews sport_id={IDS.SPORTS.FOOTBALL} service_id={IDS.SERVICES.AFRICA} season_id={activeSeason.id} items_count={9} media_type={1} latest={true} />
        </div>
        <hr />
        {TwistSettings["videos"] &&
          <>
            <SportsSectionHeader title="احدث الفيديوهات" navigateTo="/africa/videos" />
            <AllMedia sport_id={IDS.SPORTS.FOOTBALL} service_id={IDS.SERVICES.AFRICA} season_id={activeSeason.id} items_count={9} type={2} latest={true} />
            <hr />
          </>
        }
        <SportsSectionHeader title="الاكثر تداولا" navigateTo="/africa/news" />
        <AllMedia sport_id={IDS.SPORTS.FOOTBALL} service_id={IDS.SERVICES.AFRICA} season_id={activeSeason.id} items_count={12} type={1} popular={true} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  NewsSearchQuery: state.newsSearch.NewsSearchQuery,
  activeSeason: state.seasons.activeSeason,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Africa);
