import React, {useState} from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import "react-calendar/dist/Calendar.css";
import api from '../../apis'
import TournamentsMatchesMonthsCalendar from "../../components/TournamentsMatchesMonthsCalendar";
import TournamentsMatchesList from "../../components/TournamentsMatchesList";

function MatchesPage() {
  const [tournamentsMatches, setTournamentsMatches] = useState([]);
  const [isFetchingTournamentsMatches, setIsFetchingTournamentsMatches] = useState(true);
  const [tournamentsMatchesFailed, setTournamentsMatchesFailed] = useState(false);
  
  const { t } = useTranslation();

  return <div className="matches-page">
    <h3 className='all-competions-header text-center p-4 text-light'>
      <strong>المباريات</strong>
      <span className='competitions-overlay'></span>
    </h3>
    <Container>
      <div className='row d-flex mt-5 justify-content-center'>
        <div className='col-10 col-md-4 my-3'>
          <TournamentsMatchesMonthsCalendar
            getTournamentsMatchesAPI={api.matches.getTournamentsMatches}
            setTournamentsMatches={setTournamentsMatches}
            setIsFetchingTournamentsMatches={setIsFetchingTournamentsMatches}
            setTournamentsMatchesFailed={setTournamentsMatchesFailed}
          />
        </div>
        <TournamentsMatchesList
          tournamentsMatches={tournamentsMatches}
          isFetchingTournamentsMatches={isFetchingTournamentsMatches}
          tournamentsMatchesFailed={tournamentsMatchesFailed}
        />
      </div>
    </Container>
  </div>
}

export default MatchesPage;
