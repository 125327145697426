import React, { useEffect } from 'react'
import { getTopScorers } from '../../redux/topScorers/topScorers.Actions'
import { useTranslation } from "react-i18next"
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { PieChart } from 'react-minimal-pie-chart';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';
import { getServiceName, getSportRoute } from '../../Layout/helper';

const TopScorers = ({ getTopScorers, topScorers, isFetchingTopScorers, TopScorersFailed, currentLanguageCode, sport_id, season_id, tournament_id, team_id }) => {


    useEffect(() =>
        getTopScorers(sport_id, season_id, tournament_id, team_id, currentLanguageCode)
        , [sport_id, season_id, tournament_id, team_id, currentLanguageCode])

    const { t } = useTranslation()
    const history = useHistory();
    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/player/${id}`, { previousPathname: history.location.pathname });
    }



    const goalsData = (Arr) => {
        const newData = Arr[0]
        return [{ value: newData.goals, color: '#9EC41B' }]
    }
    const ratioData = (Arr) => {
        const newData = Arr[0]
        return [{ value: newData.ratio, color: '#9EC41B' }]
    }

    return (
        <div className='bg-black-color white-color fs-xs  mb-2' onClick={() => handleRowClick(topScorers[0].services?.[0], topScorers[0].sport_id, topScorers[0]?.id)} style={{ cursor: "pointer" }}>
            <div className="p-2 fs-md">هداف الفريق</div>
            {
                TopScorersFailed !== null ?
                    <ErrorMsg msg="لايوجد معلومات" p={5} fs={'md'} />
                    :
                    isFetchingTopScorers ?
                        <LoadingSpinnner p={4} fs={"sm"} />
                        :
                        topScorers.length === 0 ?
                            <ErrorMsg msg="لايوجد معلومات" p={5} fs={'md'} />
                            :
                            <div className='p-2'>
                                <div className='my-2 d-flex flex-column align-items-center'>
                                    <img src={ImgURL(topScorers[0].image)} style={{ width: '50px', borderRadius: '50%' }} alt="player" />
                                    <div className='mt-2'>{topScorers[0].name}</div>
                                </div>
                                <div className='d-flex justify-content-center text-center'>
                                    <div className='d-flex flex-column align-items-center  mx-4'>
                                        <div className='mb-1'>لعب</div>
                                        <div style={{ border: '3.71px solid #9EC41B', borderRadius: '50%', height: '39px', width: '39px' }} className='pt-2'> {topScorers[0].played} </div>
                                    </div>
                                    <div style={{ width: '100px', overflow: 'hidden', position: 'relative' }}>
                                        <div className='mb-1'>اهداف</div>
                                        <div style={{ position: 'absolute', top: '47.5%', right: '47%' }}>{topScorers[0].played}</div>
                                        <PieChart data={goalsData(topScorers)} lineWidth={16} radius={19} viewBoxSize={[100, 50]} center={[50, 10]} totalValue={topScorers[0].played} background={'#A4A4A4'} />
                                    </div>
                                    <div style={{ width: '100px', overflow: 'hidden', position: 'relative' }}>
                                        <div className='mb-1'>النسبه</div>
                                        <div style={{ position: 'absolute', top: '47.5%', right: '40%' }} className='fs-xs'>{topScorers[0].ratio}%</div>
                                        <PieChart data={ratioData(topScorers)} lineWidth={16} radius={19} viewBoxSize={[100, 50]} center={[50, 10]} totalValue={100} background={'#A4A4A4'} />
                                    </div>
                                </div>
                            </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    topScorers: state.topScorers.TopScorers,
    isFetchingTopScorers: state.topScorers.isFetchingTopScorers,
    TopScorersFailed: state.topScorers.TopScorersFailed,
    currentLanguageCode: state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch => ({
    getTopScorers: (sport_id, season_id, tournament_id, team_id, lang) => dispatch(getTopScorers(sport_id, season_id, tournament_id, team_id, lang))
})

export default connect(mapStateToProps, mapDispatchToProps)(TopScorers)