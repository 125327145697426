import { useEffect } from 'react';
import api from '../apis';
import Cookies from "universal-cookie";
import { useState } from 'react';
import TwistSettings from '../TwistSettings.json'
import { getUserPhoneNumbers } from '../globalFn';
import { grantAccessToAllServices } from './helper';

export default function useHeaderEnrichement(serviceName) {
  const [isWifi, setIsWifi] = useState(null);
  const [oneServiceLoading, setoneServiceLoading] = useState(true)
  const [isSubscribedToOneService, setisSubscribedToOneService] = useState(null)
  const cookies = new Cookies();

  const checkSubs = (serviceName, msisdn) => {
    api.subscription[serviceName]?.checkSubsByMsisdn(msisdn)
      .then((res) => {
        if (res.status === 1){
          setisSubscribedToOneService(true)
          setoneServiceLoading(false)
          api.subscription[serviceName]?.updateSubsToken(msisdn)
            .then((tokenRes) => {
              if (tokenRes?.status === 200) {
                cookies.set(
                  serviceName,
                  `${tokenRes.token},${msisdn}`,
                  { path: "/", maxAge: `${60 * 60 * 12}`, domain: window.location.hostname }
                )
              }
            })
        } else { // res.status === -6
          setisSubscribedToOneService(false)
          setoneServiceLoading(false)
          cookies.remove(serviceName, { path: '/', domain: window.location.hostname })
        }
      })

  }

  useEffect(() => {
    if (!TwistSettings['subscription']) {
      if (getUserPhoneNumbers().length > 0){
        grantAccessToAllServices(getUserPhoneNumbers()[0])
        setisSubscribedToOneService(true)
      } else {
        setisSubscribedToOneService(false)
      }
      setoneServiceLoading(false)
    } else api.subscription.getHeaderEnrichement()
      .then((param) => {
        if (param) {
          setIsWifi(false)
          api.subscription[serviceName]?.decryptSubParam(param)
            .then((decryptedRes) => {
              if (decryptedRes?.status === 200) {
                checkSubs(serviceName, decryptedRes?.user)
              }
            })
        } else {
          setIsWifi(true)
          const msisdn = cookies.get(serviceName)?.split(",")?.[1]
          checkSubs(serviceName, msisdn)
        }
      })
  }, [])

  return {isWifi, oneServiceLoading, isSubscribedToOneService};
}