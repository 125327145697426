import { subscriptionEndpoints } from "./subscription";
import { squashEndpoints } from "./squash";
import { reportsEndpoints } from "./reports";
import { matchesEndpoints } from "./matches";
import { sectionsEndpoints } from "./sections";
import { tournamentEndpoints } from "./tournament";
import { teamsEndpoints } from "./teams";
import { playerSearchEndpoints } from "./playerSearch";
import { seasonsEndpoints } from "./seasons";
import { guessPlayerEndpoints } from "./guessPlayerGame";
import { medialistEndpoints } from "./mediaList";
import { quizzesGameEndpoints } from "./quizzesGame";
import { playerOfTheCompetitionGameEndpoints } from "./playerOfTheCompetitionGame";
import { predictAndWinEndpoints } from "./predictAndWin";
import { teamOfTheCompetitionGameEndpoints } from "./teamOfTheCompetitionGame";
import { playstationEndpoints } from "./playstation";
import { playsStationTopTenPLayersEndpoints } from "./playsStationTopTenPLayers";
import { gamesEndpoints } from "./games";
import { fanClubEndpoints } from "./fanClub";
import { calendarEndpoints } from "./calendar";
import { paymobEndpoints } from "./paymob";
import { fawryEndpoints } from "./fawry";
import { serviceIdEndpoints } from "./TeamAndPlayerService";

const api = {
  subscription: subscriptionEndpoints,
  squash: squashEndpoints,
  reports: reportsEndpoints,
  matches: matchesEndpoints,
  calendar: calendarEndpoints,
  sections: sectionsEndpoints,
  tournament: tournamentEndpoints,
  teams: teamsEndpoints,
  playerSearch: playerSearchEndpoints,
  seasons: seasonsEndpoints,
  mediaList: medialistEndpoints,
  guessPlayerGame: guessPlayerEndpoints,
  quizzesGame: quizzesGameEndpoints,
  playerOfTheCompetitionGame: playerOfTheCompetitionGameEndpoints,
  predictAndWin: predictAndWinEndpoints,
  teamOfTheCompetitionGame: teamOfTheCompetitionGameEndpoints,
  playstation: playstationEndpoints,
  playsStationTopTenPLayers: playsStationTopTenPLayersEndpoints,
  games: gamesEndpoints,
  fanClub: fanClubEndpoints,
  paymob: paymobEndpoints,
  fawry: fawryEndpoints,
  service: serviceIdEndpoints,
};

export default api;
