import TopScorersTypes from "./topScorers.Types";



const INITIAL_STATE = {
    TopScorers:[''],
    isFetchingTopScorers:true,
    TopScorersFailed:null
   };
   
   const TopScorers = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case TopScorersTypes.GET_TOP_SCORERS_START:
        return {
          ...state,
          isFetchingTopScorers:true
        }
      case TopScorersTypes.GET_TOP_SCORERS_SUCCESS:
        return {
          ...state,
          TopScorers:action.payload,
          isFetchingTopScorers:false,
          TopScorersFailed:null
        }  
      case TopScorersTypes.GET_TOP_SCORERS_FAILED:
        return {
          ...state,
          TopScorersFailed:action.payload,
          isFetchingTopScorers:false
        }
       default:
         return state;
     }
   };
 
   export default TopScorers