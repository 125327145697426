import HttpHelper from "../helper";
import { userPointsEndpoints } from "./userPoints";

export const gamesEndpoints = {
    getSubmittedGames: (params, signal) =>
        HttpHelper.baseAxios
            .get(`/check/played-games`, { params, signal })
            .then((res) => res?.data)
            .catch((err) => err?.response?.data),
    
    points: userPointsEndpoints
};