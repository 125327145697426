import React, { useState } from 'react'
import './ChooseMethod.css'
import { useTranslation } from "react-i18next";
import { sha256 } from 'js-sha256';

import LoadingSpinnner from '../../loadingSpinner/loadingSpinner';
import visaLogo from '../../../assets/img/subscription/visa.png'
import fawryLogo from '../../../assets/img/subscription/fawry.png'
import twistLogo from '../../../assets/img/twist-new-logo.png'
import api from '../../../apis';


function ChooseMethod({ userChoicesRef }) {
  const { t } = useTranslation()
  const [paymentKey, setPaymentKey] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')


  const handleMethodClick = (methodName) => {
    userChoicesRef.current.push(methodName)
  }

  const buildChargeRequest = () => {
    setLoading(true)
    const chargeRequest = {
      merchantCode: process.env.REACT_APP_FAWRY_MERCHANT_CODE,
      merchantRefNum: Math.floor(Math.random() * 1000000000),
      customerMobile: '01xxxxxxxxx',
      customerEmail: 'email@domain.com',
      customerName: 'Customer Name',
      customerProfileId: '',
      paymentExpiry: '1631138400000',
      language: "en-gb",
      chargeItems: [
        {
          itemId: '6b5fdea340e31b3b0339d4d4ae5',
          description: 'Product Description',
          price: 50.00,
          quantity: 2,
          imageUrl: 'https://developer.fawrystaging.com/photos/45566.jpg',
        }
      ],
      returnUrl: 'https://3273-41-37-2-228.eu.ngrok.io/afterFawryPayment',
      authCaptureModePayment: false,
    };

    chargeRequest.signature = `${chargeRequest.merchantCode
      }${chargeRequest.merchantRefNum
      }${chargeRequest.customerProfileId
      }${chargeRequest.returnUrl
      }${chargeRequest.chargeItems.map(c => `${c.itemId}${c.quantity}${Number.parseFloat(c.price).toFixed(2)}`).join('')
      }${process.env.REACT_APP_FAWRY_SECURITY_KEY
      }`;

    chargeRequest.signature = sha256(chargeRequest.signature)
    api.fawry.expressCheckout(chargeRequest)
      .then(redirectUrl => window.location.replace(redirectUrl))
  }

  const authenticationRequest = async () => {
    setLoading(true);
    await api.paymob.authenticationRequest({ api_key: "ZXlKMGVYQWlPaUpLVjFRaUxDSmhiR2NpT2lKSVV6VXhNaUo5LmV5SndjbTltYVd4bFgzQnJJam8yTmpBd09EY3NJbTVoYldVaU9pSnBibWwwYVdGc0lpd2lZMnhoYzNNaU9pSk5aWEpqYUdGdWRDSjkuZkh0UkYtTk9NY3ZzQkxzdW13UTNCS1h5V1lndFA3SFJFdi1wR0JPWFJ3RFpacUJncUk0UGxkUmotS3I2VG5OT1h3aFZUbkdUR1lwUm9wc0ljZl8tVWc=" })
      .then((res) => {
        if (res.token) {
          orderRegistration(res.token)
        } else {
          setLoading(false);
          setErrorMsg(res.message);
        }
      })
  }

  const orderRegistration = async (token) => {
    await api.paymob.orderRegistration({
      "auth_token": token,
      "delivery_needed": "false",
      "amount_cents": "100",
      "currency": "EGP",
      "items": [
        {
          "name": "plan-20",
          "amount_cents": "20",
          "description": "plan-20-desc",
          "quantity": "1"
        },
      ]
    }).then(res => {
      if (res.id) {
        paymentKeyRequest(res.id, token)
      } else {
        setLoading(false);
        setErrorMsg(res.message);
      }
    })
  }

  const paymentKeyRequest = async (id, token) => {
    const data = {
      "auth_token": token,
      "amount_cents": "100",
      "expiration": 3600,
      "order_id": id,
      "billing_data": {
        "apartment": "NA",
        "email": "email@gmail.com",
        "floor": "NA",
        "first_name": "first_name",
        "street": "NA",
        "building": "NA",
        "phone_number": "201277041971",
        "shipping_method": "NA",
        "postal_code": "NA",
        "city": "NA",
        "country": "NA",
        "last_name": "last_name",
        "state": "NA"
      },
      "currency": "EGP",
      "integration_id": process.env.REACT_APP_PAYMOB_INTEGRATION_ID,
      "lock_order_when_paid": "false"
    };
    await api.paymob.paymentKeyRequest(data).then(res => {
      if (res.token) {
        setPaymentKey(res.token);
        setLoading(false);
        setErrorMsg([]);
      } else {
        setLoading(false);
        setErrorMsg(res.message);
      }
    })
  }

  return (
    <section className='choose-method d-flex flex-column justify-content-center align-items-center'>
      <img src={twistLogo} alt="Twist Logo" />
      <h6 className='text-center w-100 pt-5 pb-4 text-white'>اختر طريقة الدفع المناسبة</h6>
      <div className='d-flex flex-column justify-content-center gap-3 flex-wrap flex-md-nowrap'>
        {
          loading
            ? <LoadingSpinnner p={1} fs={"xs"} />
            : paymentKey
              ? <iframe
                src={`https://accept.paymob.com/api/acceptance/iframes/714520?payment_token=${paymentKey}`}
                title="paymob"
                className="paymob"
                width={500}
                height={1000}
              />
              : <>
                <button
                  type='button'
                  onClick={() => { handleMethodClick("visa"); authenticationRequest(); }}
                >
                  <img src={visaLogo} alt='visa-logo' />
                </button>
                <button
                  type="button"
                  onClick={() => { handleMethodClick("fawry"); buildChargeRequest() }}
                >
                  <img src={fawryLogo} alt='fawry-logo' />
                </button>
                {errorMsg.length > 0 && <h6 className='text-center text-danger'>{errorMsg}</h6>}
              </>
        }
      </div>
    </section>
  )
}

export default ChooseMethod