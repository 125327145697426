import { useState } from "react";
import { useTranslation } from "react-i18next";
import ComparisonPopUp from './ComparisonPopUp'




const Comparison =()=>{


    const { t } = useTranslation()
    const [show,setShow] = useState(false)

    return (
    <div className='bg-black-color white-color fs-xs p-3  text-center'  >
        <h5>مقارنات</h5>
        <div className='mt-4'>
          <strong>
          مقارنة اتصالات سبورتس هي أداة تتيح لك إنشاء مقارنات إحصائية بين اللاعبين والفرق المتنافسة في كل الدوريات المتاحة والمواسم المختلفة وفقا لقاعدة بيانات تتسع لأكثر من 3500 فريقا و81 ألف لاعبا في كرة القدم.
        </strong>
        </div>
        <button className='btn bg-active-color etisalat-border text-light px-4 mt-3' onClick={()=> setShow(true)}>قارن</button>
        <ComparisonPopUp show={show} setShow={setShow}/>
    </div>
    )
}

export default Comparison 