import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getStanding } from "../../redux/Standing/Standing.Actions";
import {
    addH2Hteam,
    openH2Hmodal,
} from "../../redux/Standing/Standing.Actions";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import './TournamentStanding.css'
import { Container } from 'react-bootstrap';
import H2HModal from './H2HModal.jsx';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import Vs from '../../assets/Vs.png'
import { ImgURL } from '../../globalData';
import useServiceAndSport from '../../hooks/useServiceAndSport';
import TwistSettings from "../../TwistSettings.json";

const TourStandingDetails = ({
    Standing,
    standingFailed,
    isFetchingStanding,
    currentLanguageCode,
    getStanding,
    showModal,
    H2HTeams,
    addH2Hteam,
    openH2Hmodal,
    sport_id,
    season_id,
    competitionType,
}) => {
    const { route } = useServiceAndSport();
    const id = useParams().id;
    const { t } = useTranslation()
    const history = useHistory();

    const handleRowClick = (id) => {
        history.push(`${route}/team/${id}`, { previousPathname: history.location.pathname });
    }

    useEffect(() => {
        getStanding(sport_id, season_id, id, currentLanguageCode);
    }, [sport_id, season_id, id, currentLanguageCode]);


    return (
        <Container>
            <H2HModal show={showModal} />
            <div className='bg-black-color white-color fs-xs mt-3 row'>
                <table className="responsive-table tournament-standing table-borderless bg-black-color table-striped table-dark white-color col-12">
                    <thead>
                        <tr style={{ padding: '10px' }} className="text-center">
                            <th>المركز</th>
                            <th scope="col" className={window.document.body.dir === 'rtl' ? 'text-right' : 'text-left'}>الفريق</th>
                            <th scope="col">لعب</th>
                            <th scope="col">فوز</th>
                            <th scope="col">خساره</th>
                            <th scope="col">تعادل</th>
                            <th scope="col">اهداف له</th>
                            <th scope="col">عليه</th>
                            <th scope="col">فارق الاهداف</th>
                            <th scope="col">نقاط</th>
                            {TwistSettings["comparison"] &&
                                <th scope="col">Vs</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            standingFailed !== null ?
                                <tr>
                                    <td colSpan={11} className='p-0'>
                                        <ErrorMsg msg="لا يوجد ترتيب" p={5} fs={'lg'} />
                                    </td>
                                </tr>
                                :
                                isFetchingStanding ?
                                    <tr>
                                        <td colSpan={11} className='p-0'>
                                            <LoadingSpinnner p={4} fs={'md'} />
                                        </td>
                                    </tr>
                                    :
                                    Standing.length === 0 ?
                                        <tr>
                                            <td colSpan={11} className='p-0'>
                                                <ErrorMsg msg="لا يوجد ترتيب" p={5} fs={'lg'} />
                                            </td>
                                        </tr>
                                        :
                                        Standing.flat().sort((a, b) => b.point - a.point).map(
                                            (team, i) => (
                                                <tr key={team.id} onClick={() => handleRowClick(team.team_id)} className={`table-clickable-row-no-animation text-center`}>
                                                    <td style={{ width: '25px' }}>{i + 1}</td>
                                                    <td style={{ minWidth: '130px' }} className={window.document.body.dir === 'rtl' ? 'text-right team-name' : 'text-left team-name'}><img src={ImgURL(team.team_logo)} style={{ width: '20px' }} alt="" /><span className="mx-1 mx-md-2 text-nowrap fs-xxs ">{team.team_name}</span></td>
                                                    <td>{team.played}</td>
                                                    <td>{team.win}</td>
                                                    <td>{team.lose}</td>
                                                    <td>{team.draw}</td>
                                                    <td>{team.goals_in}</td>
                                                    <td>{team.goals_out}</td>
                                                    <td>{team.difference}</td>
                                                    <td>{team.point}</td>
                                                    {TwistSettings["comparison"] &&
                                                        <td onClick={(e) => e.stopPropagation()}>
                                                            <div className="form-check d-flex justify-content-center">
                                                                <input className="form-check-input " type="checkbox" value={i} checked={team.checked} id={team.id} onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    addH2Hteam(Standing[e.target.value])
                                                                }}></input>
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        )
                        }
                    </tbody>
                </table>
            </div>
            {TwistSettings["comparison"] &&
                <div className='text-center vs-button'>
                    <button className='btn  mt-1' disabled={H2HTeams.length < 2} onClick={() => openH2Hmodal()}><img src={Vs} width={90} alt="" /></button>
                </div>
            }
        </Container>
    )
}


const mapDispatchToProps = (dispatch) => ({
    getStanding: (sport_id, season_id, tournament_id, lang) =>
        dispatch(getStanding(sport_id, season_id, tournament_id, lang)),
    addH2Hteam: (info) => dispatch(addH2Hteam(info)),
    openH2Hmodal: () => dispatch(openH2Hmodal()),
});

const mapStateToProps = (state) => ({
    isFetchingStanding: state.standing.isFetchingStanding,
    standingFailed: state.standing.StandingFailed,
    Standing: state.standing.Standing,
    competitionType: state.standing.competitionType,
    currentLanguageCode: state.language.currentLanguageCode,
    tournamentDetails: state.standing.TournamentDetails,
    H2HTeams: state.standing.H2HTeams,
    showModal: state.standing.showModal,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TourStandingDetails);
