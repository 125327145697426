import TournamentHistoryTypes from "./TournamentHistory.Types";



const INITIAL_STATE = {
    TournamentHistory:{},
    isFetchingTournamentHistory:true,
    TournamentHistoryFailed:null
   };
   
   const TournamentHistory = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case TournamentHistoryTypes.GET_TOURNAMENT_HISTORY_START:
        return {
          ...state,
          isFetchingTournamentHistory:true
        }
      case TournamentHistoryTypes.GET_TOURNAMENT_HISTORY_SUCCESS:
        return {
          ...state,
          TournamentHistory:action.payload,
          isFetchingTournamentHistory:false,
          TournamentHistoryFailed:null
        }
      case TournamentHistoryTypes.GET_TOURNAMENT_HISTORY_FAILED:
        return {
          ...state,
          TournamentHistoryFailed:action.payload,
          isFetchingTournamentHistory:false
        }
       default:
         return state;
     }
   };
 
   export default TournamentHistory