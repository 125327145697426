import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getGeneralStatistics } from "../../redux/GeneralStatistics/GeneralStatistics.Actions";
import { setTournamentActiveTab } from "../../redux/TournamentDetails/TournamentDetails.Actions";
import { setCurrentlyDisplayedGeneralStanding } from "../../redux/GeneralStatistics/GeneralStatistics.Actions";
import { useTranslation } from "react-i18next";
import ErrorMsg from "../ErrorPage/ErrorMsg";
import "./generalStanding.css";
import LoadingSpinnner from "../loadingSpinner/loadingSpinner";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import SampleArrowBtn from "../SampleArrowBtn/SampleArrowBtn";

const GeneralStanding = ({
  getGeneralStatistics,
  GeneralStatisticsFailed,
  generalStatistics,
  currentlyDisplayed,
  currentLanguageCode,
  tournamentsIds,
  currentPage,
  setCurrentlyDisplayedGeneralStanding,
  setTournamentActiveTab,
  isFetchingGeneralStatistics,
  sport_id,
  service_id,
  season_id,
}) => {
  useEffect(() => {
    getGeneralStatistics(
      sport_id,
      service_id,
      season_id,
      true,
      2,
      currentLanguageCode
    );
  }, [currentLanguageCode, service_id, sport_id]);

  const { t } = useTranslation();
  const history = useHistory();
  const handleRowClick = (serviceId, sportId, type, id) => {
    history.push(
      `/${getServiceName(serviceId)}${getSportRoute(
        sportId,
        serviceId
      )}/${type}/${id}`,
      { previousPathname: history.location.pathname }
    );
  };


  return (
    <table className="table-borderless bg-black-color table-striped table-dark white-color fs-xs">
      <thead>
        <tr>
          <th scope="col" style={{ direction: "rtl", minWidth: "fit" }}>
            <SampleArrowBtn
              arrowDirection="left"
              onClick={() => setCurrentlyDisplayedGeneralStanding(+1)}
              isDisabled={
                currentPage === generalStatistics?.length
              }
            />

            <span
              className="btn  white-color table-clickable-row fs-sm"
              onClick={() => {
                setTournamentActiveTab("standing");
                history.push(
                  `/${getServiceName(
                    currentlyDisplayed.services?.[0]
                  )}${getSportRoute(sport_id, service_id)}/tournament/${tournamentsIds[currentPage - 1]
                  }/standing`,
                  { previousPathname: history.location.pathname }
                );
              }}
              style={{ cursor: "pointer" }}
              data-testid="general-standing-tournament-name"
            >
              {generalStatistics[currentPage - 1]?.title}
            </span>

            <SampleArrowBtn
              onClick={() => setCurrentlyDisplayedGeneralStanding(-1)}
              isDisabled={currentPage === 1}
            />
          </th>
          <th scope="col" className="text-center">
          لعب
          </th>
          <th scope="col" className="text-center">
          فارق
          </th>
          <th scope="col" className="text-center">
          نقاط
          </th>
        </tr>
      </thead>

      <tbody>
        {GeneralStatisticsFailed !== null ? (
          <tr>
            <td colSpan={7} className="p-0">
              <ErrorMsg msg="لا يوجد ترتيب" p={5} fs={"lg"} />
            </td>
          </tr>
        ) : isFetchingGeneralStatistics ? (
          <tr data-testid="general-standings-loading">
            <td colSpan={7} className="p-0">
              <LoadingSpinnner p={5} fs={"md"} />
            </td>
          </tr>
        ) : currentlyDisplayed?.data.length === 0 ? (
          <tr>
            <td colSpan={7} className="p-0">
              <ErrorMsg msg="لا يوجد ترتيب" p={5} fs={"lg"} />
            </td>
          </tr>
        ) : (
          currentlyDisplayed?.data
            .sort((a, b) => b.point - a.point)
            .map((team, i) =>
              i <= 7 ? (
                <tr
                  key={team.id}
                  onClick={() =>
                    handleRowClick(
                      currentlyDisplayed.services?.[0],
                      currentlyDisplayed.sport_id,
                      "team",
                      team.team_id
                    )
                  }
                  className="table-clickable-row "
                  data-testid="general-standings-teams-tournament-data-row"
                >
                  <th scope="row">
                    <span>{i + 1}</span>{" "}
                    <span className="mx-2">{team.team_name}</span>
                  </th>
                  <td className="text-center">{team.played}</td>
                  <td className="text-center">{team.difference}</td>
                  <td className="text-center">{team.point}</td>
                </tr>
              ) : (
                null
              )
            )
        )}
        <tr>
          <td colSpan={7} className="justify-content-end p-1">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-link fs-sm active-color swipe-animation"
                onClick={() => {
                  setTournamentActiveTab("standing");
                  history.push(
                    `/${getServiceName(currentlyDisplayed?.services[0])}${getSportRoute(
                      sport_id,
                      service_id
                    )}/tournament/${tournamentsIds[currentPage - 1]}/standing`,
                    { previousPathname: history.location.pathname }
                  );
                }}
                data-testid="general-standings-view-all-standings-button"
              >
                عرض الكل
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => ({
  generalStatistics: state.generalStatistics.GeneralStatistics,
  isFetchingGeneralStatistics:
    state.generalStatistics.isFetchingGeneralStatistics,
  GeneralStatisticsFailed: state.generalStatistics.GeneralStatisticsFailed,
  currentlyDisplayed: state.generalStatistics.currentlyDisplayed,
  tournamentsIds: state.generalStatistics.tournamentsIds,
  currentPage: state.generalStatistics.currentPage,
  currentLanguageCode: state.language.currentLanguageCode,
  tournamentDetails: state.standing.TournamentDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getGeneralStatistics: (
    sport_id,
    service_id,
    season_id,
    priority,
    competition_type,
    currentLanguageCode
  ) =>
    dispatch(
      getGeneralStatistics(
        sport_id,
        service_id,
        season_id,
        priority,
        competition_type,
        currentLanguageCode
      )
    ),
  setCurrentlyDisplayedGeneralStanding: (page) =>
    dispatch(setCurrentlyDisplayedGeneralStanding(page)),

  setTournamentActiveTab: (tabName) =>
    dispatch(setTournamentActiveTab(tabName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralStanding);
