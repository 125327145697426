import HttpHelper from './helper';

export const subscriptionEndpoints = {
  getHeaderEnrichement: () => HttpHelper.etisalatsportsAxios
    .get('/HeaderEnrichemnt')
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),
  local: {
    decryptSubParam: (param) =>  {
      const data = new FormData();
      data.append('headerParam', param);

      return HttpHelper.mobi89Axios
      .post('rakam1/header-enrichment/decrypt', data)
      .then((res) => res.data)
      .catch((err) => err.response.data)
    },
  
    decryptSubId: (subId) => {
      const data = new FormData();
      data.append('subId', subId);

      return HttpHelper.mobi89Axios
        .post('rakam1/sub-id/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },
  
    sendCode: (params) => HttpHelper.mobi89Axios
      .post('rakam1/subscription/send-code', null, {params})
      .then((res) => res.data)
      .catch((err) => err.response.data),
  
    checkPin: (params) => HttpHelper.mobi89Axios
      .post('rakam1/subscription/check/pin', null, {params})
      .then((res) => res.data)
      .catch((err) => err.response.data),
  
    checkSubsByMsisdn: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.mobi89Axios
        .post('rakam1/subscription/checkSubscription', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
      },

    updateSubsToken: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.mobi89Axios
        .post('rakam1/subscription/updateToken', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
      }
  },

  international: {
    decryptSubParam: (param) =>  {
      const data = new FormData();
      data.append('headerParam', param);

      return HttpHelper.mobi89Axios
      .post('rakam1/header-enrichment/decrypt', data)
      .then((res) => res.data)
      .catch((err) => err.response.data)
    },
  
    decryptSubId: (subId) => {
      const data = new FormData();
      data.append('subId', subId);

      return HttpHelper.mobi89Axios
        .post('elmal3ab/sub-id/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },
  
    sendCode: (params) => HttpHelper.mobi89Axios
      .post('elmal3ab/subscription/send-code', null, {params})
      .then((res) => res.data)
      .catch((err) => err.response.data),
  
    checkPin: (params) => HttpHelper.mobi89Axios
      .post('elmal3ab/subscription/check/pin', null, {params})
      .then((res) => res.data)
      .catch((err) => err.response.data),
  
    checkSubsByMsisdn: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.mobi89Axios
        .post('elmal3ab/subscription/checkSubscription', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
      },

    updateSubsToken: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.mobi89Axios
        .post('elmal3ab/subscription/updateToken', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
      }
  },

  africa: {
    decryptSubParam: (param) =>  {
      const data = new FormData();
      data.append('headerParam', param);

      return HttpHelper.mobi89Axios
      .post('rakam1/header-enrichment/decrypt', data)
      .then((res) => res.data)
      .catch((err) => err.response.data)
    },
  
    decryptSubId: (subId) => {
      const data = new FormData();
      data.append('subId', subId);

      return HttpHelper.mobi89Axios
        .post('el3almy/sub-id/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },
  
    sendCode: (params) => HttpHelper.mobi89Axios
      .post('el3almy/subscription/send-code', null, {params})
      .then((res) => res.data)
      .catch((err) => err.response.data),
  
    checkPin: (params) => HttpHelper.mobi89Axios
      .post('el3almy/subscription/check/pin', null, {params})
      .then((res) => res.data)
      .catch((err) => err.response.data),
  
    checkSubsByMsisdn: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.mobi89Axios
        .post('el3almy/subscription/checkSubscription', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
      },

    updateSubsToken: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.mobi89Axios
        .post('el3almy/subscription/updateToken', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
      }
  },

  othersports: {
    decryptSubParam: (param) =>  {
      const data = new FormData();
      data.append('headerParam', param);

      return HttpHelper.mobi89Axios
      .post('rakam1/header-enrichment/decrypt', data)
      .then((res) => res.data)
      .catch((err) => err.response.data)
    },
  
    decryptSubId: (subId) => {
      const data = new FormData();
      data.append('subId', subId);

      return HttpHelper.mobi89Axios
        .post('othersports/sub-id/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },
  
    sendCode: (params) => HttpHelper.mobi89Axios
      .post('othersports/subscription/send-code', null, {params})
      .then((res) => res.data)
      .catch((err) => err.response.data),
  
    checkPin: (params) => HttpHelper.mobi89Axios
      .post('othersports/subscription/check/pin', null, {params})
      .then((res) => res.data)
      .catch((err) => err.response.data),
  
    checkSubsByMsisdn: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.mobi89Axios
        .post('othersports/subscription/checkSubscription', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
      },

    updateSubsToken: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.mobi89Axios
        .post('othersports/subscription/updateToken', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
      }
  },
};
