import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { getPlayerSeasonStatistics } from '../../redux/PlayerSeasonStatistics/PlayerSeasonStatistics.Actions'
import { setCurrentTeam, setCurrentTournament } from '../../redux/PlayerDetails/PlayerDetails.Actions'
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { Dropdown, Row } from 'react-bootstrap'
import ErrorMsg from '../ErrorPage/ErrorMsg'
import { makeOrderedSeasonStats } from './helper'

const PlayerStatsTable = ({
    PlayerSeasonStatistics,
    getPlayerSeasonStatistics,
    isFetchingPlayerSeasonStatistics,
    PlayerSeasonStatisticsFailed,
    currentTeam,
    teams,
    currentTeamActiveTournament,
    currentTeamTournaments,
    setCurrentTeam,
    setCurrentTournament,
    player_id,
    currentLanguageCode,
}) => {
    const { t } = useTranslation()

    useEffect(() => {
        getPlayerSeasonStatistics(1, currentTeamActiveTournament?.id, currentTeam?.id, player_id, currentLanguageCode)
    }, [1, currentTeamActiveTournament, currentTeam, player_id, currentLanguageCode])

    const seasonsKeys = Object.keys(PlayerSeasonStatistics);
    const PlayerSeasonStats = makeOrderedSeasonStats(PlayerSeasonStatistics)

    return PlayerSeasonStatisticsFailed !== null
        ? <div className='mt-3'>
            <ErrorMsg p={5} fs={'md'} msg="لا يوجد احصائيات" />
        </div>
        : <div className='mt-3'>
            <Row className='d-flex  justify-content-start'>
                <Dropdown className='col-auto'>
                    <Dropdown.Toggle variant="secondary" id={`dropdown-button-drop-down`} drop='down' disabled={isFetchingPlayerSeasonStatistics}>
                        {currentTeam?.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {teams?.map(team => <div key={team.id}><Dropdown.Item className='text-light' onClick={() => setCurrentTeam(team)}>{team.name}</Dropdown.Item></div>)}
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown className='col-auto'>
                    <Dropdown.Toggle variant="secondary" id={`dropdown-button`} drop='down' disabled={isFetchingPlayerSeasonStatistics}>
                        {currentTeamActiveTournament?.title}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {currentTeamTournaments?.map(tour => <div key={tour.id}><Dropdown.Item className='text-light' onClick={() => setCurrentTournament(tour)} >{tour.title}</Dropdown.Item></div>)}
                    </Dropdown.Menu>
                </Dropdown>
            </Row>
            {
                isFetchingPlayerSeasonStatistics
                    ? <div className='mt-3'>
                        <div className='bg-black-color p-3'>احصائيات</div>
                        <LoadingSpinnner p={5} fs={'md'} />
                    </div>
                    : <table className="responsive-table table-borderless bg-black-color table-striped table-dark white-color mt-3">
                        <thead>
                            <tr>
                                <th style={{ padding: '20px' }}>احصائيات</th>
                                {seasonsKeys.map(season => <th key={season} scope="col" style={{ padding: '20px' }} className='text-nowrap'>{season.split("-").sort((a, b) => a - b).join(" - ")}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                PlayerSeasonStats[seasonsKeys?.[0]]?.map(stat => <tr key={stat.name} className='fs-sm'>
                                    <td>{t(stat.name)}</td>
                                    {seasonsKeys?.map(seasonKey => <td key={seasonKey}>{PlayerSeasonStats[seasonKey].find(stat2 => stat2.name === stat.name).value}</td>)}
                                </tr>)
                            }
                        </tbody>
                    </table>
            }
        </div>
}

const mapStateToProps = state => ({
    isFetchingPlayerSeasonStatistics: state.playerSeasonStatistics.isFetchingPlayerSeasonStatistics,
    PlayerSeasonStatistics: state.playerSeasonStatistics.PlayerSeasonStatistics,
    PlayerSeasonStatisticsFailed: state.playerSeasonStatistics.PlayerSeasonStatisticsFailed,
    currentTeam: state.playerDetails.currentTeam,
    teams: state.playerDetails.teams,
    currentTeamActiveTournament: state.playerDetails.currentTeamActiveTournament,
    currentTeamTournaments: state.playerDetails.currentTeamTournaments,
    currentLanguageCode: state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch => ({
    getPlayerSeasonStatistics: (sport_id, tournament_id, team_id, player_id, lang) => dispatch(getPlayerSeasonStatistics(sport_id, tournament_id, team_id, player_id, lang)),
    setCurrentTeam: (info) => dispatch(setCurrentTeam(info)),
    setCurrentTournament: (info) => dispatch(setCurrentTournament(info))
})


export default connect(mapStateToProps, mapDispatchToProps)(PlayerStatsTable)

