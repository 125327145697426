import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { ImgURL } from '../../globalData'

const PlayerPrizes = ({ prizes }) => {
    return (
        <Container>
            <div className='row'>
                {
                    prizes && prizes.map(prize =>
                        <div className='col-12 col-md-3 bg-black-color p-3' height="200px" width="400px">
                            <div className='text-light mb-3'>x{prize.count} {prize.title}</div>
                            <div className='d-flex flex-row justify-content-around align-items-center'>
                                <img src={ImgURL(prize.logo)} width="70px" height="70px" alt="prize logo" />
                                <div className='white-color'>{prize.seasons}</div>
                            </div>
                        </div>)
                }
            </div>
        </Container>
    )
}

export default PlayerPrizes