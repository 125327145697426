import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getGeneralStatistics } from "../../redux/GeneralStatistics/GeneralStatistics.Actions";
import { setCurrentlyDisplayedGeneralStanding } from "../../redux/GeneralStatistics/GeneralStatistics.Actions";
import { useTranslation } from "react-i18next";
import ErrorMsg from "../ErrorPage/ErrorMsg";
import LoadingSpinnner from "../loadingSpinner/loadingSpinner";
import { setTournamentActiveTab } from "../../redux/TournamentDetails/TournamentDetails.Actions";
import { ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import SampleArrowBtn from "../SampleArrowBtn/SampleArrowBtn";

const GeneralStatistics = ({
  setTournamentActiveTab,
  tournamentsIds,
  GeneralStatisticsFailed,
  generalStatistics,
  currentlyDisplayed,
  currentPage,
  currentLanguageCode,
  setCurrentlyDisplayedGeneralStanding,
  isFetchingGeneralStatistics,
  sport_id,
  service_id,
  season_id,
  tournamentDetails,
}) => {
  //  its already geting the data from server on the home page general standing uses same function and mounts first
  useEffect(() => {
    getGeneralStatistics(
      sport_id,
      service_id,
      season_id,
      true,
      2,
      currentLanguageCode
    );
  }, [currentLanguageCode, service_id, sport_id]);
  const { t } = useTranslation();
  const history = useHistory();
  const handleRowClick = (serviceId, sportId, id) => {
    history.push(
      `/${getServiceName(serviceId)}${getSportRoute(
        sportId,
        serviceId
      )}/team/${id}`,
      { previousPathname: history.location.pathname }
    );
  };

  return (
    <div className="bg-black-color white-color fs-xs">
      <div className="d-flex justify-content-between fs-md p-2">
        <div
          className="btn white-color table-clickable-row fs-sm"
          onClick={() => {
            setTournamentActiveTab("stats");
            history.push(
              `/${getServiceName(service_id)}${getSportRoute(
                sport_id,
                service_id
              )}/tournament/${tournamentsIds[currentPage - 1]}/stats`,
              { previousPathname: history.location.pathname }
            );
          }}
        >
          {generalStatistics[currentPage - 1]?.title}
        </div>
        <div style={{ direction: "rtl" }}>
          <SampleArrowBtn
            arrowDirection="left"
            onClick={() => setCurrentlyDisplayedGeneralStanding(+1)}
            isDisabled={
              currentPage === generalStatistics?.length
            }
          />

          <span className="mx-2">احصائيات</span>

          <SampleArrowBtn
            onClick={() => setCurrentlyDisplayedGeneralStanding(-1)}
            isDisabled={currentPage === 1}
          />
        </div>
      </div>
      <table className="responsive-table table-borderless bg-black-color table-striped table-dark white-color">
        <thead>
          <tr>
            <th scope="col">المركز</th>
            <th scope="col">الفريق</th>
            <th scope="col" className="text-nowrap">
              {"اهداف له"}
            </th>
            <th scope="col" className="text-nowrap">
              {"شباك نظيفه"}
            </th>
            <th scope="col" className="text-nowrap">
              {"مباريات"}
            </th>
            <th scope="col" className="text-nowrap">
              {"كروت صفراء"}
            </th>
          </tr>
        </thead>
        <tbody>
          {GeneralStatisticsFailed !== null ? (
            <tr>
              <td colSpan={7} className="p-0">
                <ErrorMsg msg="لا يوجد احصائيات" p={5} fs={"lg"} />
              </td>
            </tr>
          ) : isFetchingGeneralStatistics ? (
            <tr>
              <td colSpan={7} className="p-0">
                <LoadingSpinnner p={5} fs={"md"} />
              </td>
            </tr>
          ) : currentlyDisplayed?.data.length === 0 ? (
            <tr>
              <td colSpan={7} className="p-0">
                <ErrorMsg msg="لا يوجد احصائيات" p={5} fs={"lg"} />
              </td>
            </tr>
          ) : (
            currentlyDisplayed?.data.map((team, i) =>
              i <= 6 ? (
                <tr
                  key={team.id}
                  onClick={() =>
                    handleRowClick(
                      currentlyDisplayed.services?.[0],
                      currentlyDisplayed.sport_id,
                      team.team_id
                    )
                  }
                  className="table-clickable-row text-center"
                >
                  <td style={{ width: "20px" }}>{i + 1}</td>
                  <td
                    style={{ minWidth: "130px" }}
                    className={
                      window.document.body.dir === "rtl"
                        ? "text-right team-name"
                        : "text-left team-name"
                    }
                  >
                    <img src={ImgURL(team.team_logo)} width="30x" alt="team logo" />
                    <span className="mx-2 fs-xxs ">{team.team_name}</span>
                  </td>
                  <td>{team.goals_in}</td>
                  <td>{team.clean_sheet}</td>
                  <td>{team.played}</td>
                  <td>{team.yellow_cards}</td>
                </tr>
              ) : (
                null
              )
            )
          )}
          <tr>
            <td colSpan={7} className="justify-content-end p-1">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-link fs-sm active-color swipe-animation"
                  onClick={() => {
                    setTournamentActiveTab("stats");
                    history.push(
                      `/${getServiceName(service_id)}${getSportRoute(
                        sport_id,
                        service_id
                      )}/tournament/${tournamentsIds[currentPage - 1]}/stats`,
                      { previousPathname: history.location.pathname }
                    );
                  }}
                >
                  عرض الكل
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  generalStatistics: state.generalStatistics.GeneralStatistics,
  GeneralStatisticsFailed: state.generalStatistics.GeneralStatisticsFailed,
  isFetchingGeneralStatistics:
    state.generalStatistics.isFetchingGeneralStatistics,
  currentlyDisplayed: state.generalStatistics.currentlyDisplayed,
  tournamentsIds: state.generalStatistics.tournamentsIds,
  currentPage: state.generalStatistics.currentPage,
  currentLanguageCode: state.language.currentLanguageCode,
  tournamentDetails: state.standing.TournamentDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getGeneralStatistics: (
    sport_id,
    service_id,
    season_id,
    priority,
    competition_type,
    currentLanguageCode
  ) =>
    dispatch(
      getGeneralStatistics(
        sport_id,
        service_id,
        season_id,
        priority,
        competition_type,
        currentLanguageCode
      )
    ),
  setCurrentlyDisplayedGeneralStanding: (page) =>
    dispatch(setCurrentlyDisplayedGeneralStanding(page)),
  setTournamentActiveTab: (tabName) =>
    dispatch(setTournamentActiveTab(tabName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralStatistics);
