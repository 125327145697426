import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getTeamStatistics } from '../../redux/TeamStatistics/TeamStatistics.Actions';
import { useTranslation } from "react-i18next"
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';



const TeamOverviewStats = ({ teamStatistics, isFetchingTeamStatistics, TeamStatisticsFailed, sport_id, season_id, tournament_id, team_id, currentLanguageCode, getTeamStatistics, toLocalTab }) => {


    const { t } = useTranslation()


    useEffect(() => {
        if (tournament_id === null) getTeamStatistics(sport_id, season_id, tournament_id, team_id, currentLanguageCode)
    }, [sport_id, season_id, tournament_id, team_id, currentLanguageCode])

    const statisticsKeys = {
        played: "لعب",
        win: "فوز",
        lose: "خساره",
        draw: "تعادل",
        goals_in: "اهداف له",
        goals_out: "عليه",
        goals_penalty: "اهداف الجزاء",
        clean_sheet: "شباك نظيفه",
        yellow_cards: "كروت صفراء",
        red_cards: "كروت حمراء",
        difference: "فارق الاهداف",
        point: "نقاط",
        passes: "تمريرات ناجحه",
        lost_balls: "كرات ضائعه",
        attempts: "محاولات على المرمى",
        chances: "فرص",
        dribbles: "المراوغات",
        fouls_in: "اخطاء عليه",
        fouls_out: "اخطاء له",
        ball_win: "استعاد الكرة",
        ball_lose: "خسر الكرة",
    }
    return (
        <div className='bg-black-color white-color fs-xs mt-2'>
            <div className='d-flex justify-content-between fs-md p-2'>
                <div>احصائيات</div>
                <div style={{ direction: 'rtl' }}>
                    <button className={`swipe-animation fa fa-chevron-${currentLanguageCode === 'ar' ? 'left' : 'right'} btn white-color`} aria-hidden="true" onClick={() => toLocalTab('standing')}></button>
                </div>
            </div>
            <table className="table-borderless bg-black-color table-striped table-dark white-color">
                <thead>
                </thead>
                <tbody>
                    {
                        TeamStatisticsFailed !== null ?
                            <tr>
                                <td>
                                    <ErrorMsg p={5} fs={'md'} msg="لا يوجد احصائيات" />
                                </td>
                            </tr>
                            :
                            isFetchingTeamStatistics ?
                                <tr>
                                    <td>
                                        <LoadingSpinnner p={5} fs={'md'} />
                                    </td>
                                </tr>
                                :
                                Object.keys(teamStatistics)
                                    .filter(k => k !== "scoringIntervals")
                                    .map((key, i) =>
                                        <tr key={i}>
                                            <td>
                                                <div className='d-flex justify-content-between mx-1 p-2'>
                                                    <div>{statisticsKeys[key]}</div>
                                                    <div>{Object.values(teamStatistics)[i]}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                    }

                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = state => ({
    teamStatistics: state.teamStatistics.TeamStatistics,
    isFetchingTeamStatistics: state.teamStatistics.isFetchingTeamStatistics,
    TeamStatisticsFailed: state.teamStatistics.TeamStatisticsFailed,
    currentLanguageCode: state.language.currentLanguageCode,
})

const mapDispatchToProps = dispatch => ({
    getTeamStatistics: (sport_id, season_id, tournament_id, team_id, lang) => dispatch(getTeamStatistics(sport_id, season_id, tournament_id, team_id, lang))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamOverviewStats)