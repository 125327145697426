import AllTeamsTypes from "./AllTeams.Types";



const INITIAL_STATE = {
    AllTeams:[''],
    isFetchingAllTeams:true,
    AllTeamsFailed:null
   };
   
   const AllTeams = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case AllTeamsTypes.GET_ALL_TEAMS_START:
        return {
          ...state,
          isFetchingAllTeams:true
        }
      case AllTeamsTypes.GET_ALL_TEAMS_SUCCESS:
        return {
          ...state,
          AllTeams:action.payload,
          isFetchingAllTeams:false,
          AllTeamsFailed:null
        }
      case AllTeamsTypes.GET_ALL_TEAMS_FAILED:
        return {
          ...state,
          AllTeamsFailed:action.payload,
          isFetchingAllTeams:false
        }
       default:
         return state;
     }
   };
 
   export default AllTeams