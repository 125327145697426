import MatchesTypes from "./Matches.Types";



const INITIAL_STATE = {
  Matches: [''],
  allMatches: [],
  isFetchingMatches: true,
  MatchesFailed: null,
  firstReload: false

};

const Matches = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MatchesTypes.GET_MATCHES_START:
      return {
        ...state,
        isFetchingMatches: true
      }
    case MatchesTypes.GET_MATCHES_SUCCESS:
      return {
        ...state,
        Matches: action.payload,
        allMatches: action.firstReload ? action.payload : [...state.allMatches],
        isFetchingMatches: false,
        MatchesFailed: null,
        firstReload: action.firstReload

      }
    case MatchesTypes.GET_MATCHES_FAILED:
      return {
        ...state,
        MatchesFailed: action.payload,
        isFetchingMatches: false
      }
    default:
      return state;
  }
};

export default Matches