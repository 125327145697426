import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getStanding } from "../../../redux/Standing/Standing.Actions";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import ErrorMsg from "../../ErrorPage/ErrorMsg";
import LoadingSpinnner from "../../loadingSpinner/loadingSpinner";
import { getServiceName, getSportRoute } from '../../../Layout/helper';
import "./CupTournamentGroupsStanding.css";
import { ImgURL } from "../../../globalData";

const CupTournamentGroupsStanding = ({
  Standing,
  currentLanguageCode,
  getStanding,
  sport_id,
  season_id,
  standingFailed,
  isFetchingStanding,
  tournamentDetails,
}) => {
  const id = useParams().id;
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    getStanding(sport_id, season_id, id, currentLanguageCode);
  }, [sport_id, season_id, id, currentLanguageCode]);

  const handleTeamClick = (serviceId, sportId, teamId) => {
      history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/team/${teamId}`, { previousPathname: history.location.pathname });
  }

  return (
    <Container>
      <div className="bg-black-color grey-color fs-xs mt-3 row">
        {standingFailed !== null ? (
          <div colSpan={7} className="p-0">
            <ErrorMsg msg="لا يوجد ترتيب" p={4} fs={"md"} />
          </div>
        ) : isFetchingStanding ? (
          <div colSpan={7} className="p-0">
            <LoadingSpinnner p={4} fs={"sm"} />
          </div>
        ) : Standing.length === 0 ? (
          <div colSpan={7} className="p-0">
            <ErrorMsg msg="لا يوجد ترتيب" p={4} fs={"md"} />
          </div>
        ) : (
          Standing.map((group, index) => (
            <div className="col-12 col-lg-6" key={group[0].id}>
              <div className="groups__standingWrapper  mb-3">
                <h2 className="fs-md text-white">
                  <span>المجموعة</span>
                  <span className="px-1">{index + 1}</span>
                </h2>
                <table className="groups__standing table table-borderless text-white">
                  <thead>
                    <tr>
                      <th scope="col" className="">
                      الفرق
                      </th>
                      <th scope="col">لعب</th>
                      <th scope="col">فوز</th>
                      <th scope="col">تعادل</th>
                      <th scope="col">خساره</th>
                      <th scope="col">نقاط</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.map((team) => (
                      <tr 
                        data-testid = "tournament-group-tr"
                        key={team.id} 
                        className="row-hover" 
                        onClick={() => handleTeamClick(tournamentDetails.services?.[0], tournamentDetails.sport_id, team.team_id)}
                      >
                        <td>
                          <img
                            src={ImgURL(team.team_logo)}
                            style={{ width: 25, height: 25 }}
                            alt="team logo"
                          />
                          <span className="px-2">{team.team_name}</span>
                        </td>
                        <td>{team.played}</td>
                        <td>{team.win}</td>
                        <td>{team.draw}</td>
                        <td>{team.lose}</td>
                        <td>{team.point}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))
        )}
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getStanding: (sport_id, season_id, tournament_id, lang) =>
    dispatch(getStanding(sport_id, season_id, tournament_id, lang)),
});

const mapStateToProps = (state) => ({
  isFetchingStanding: state.standing.isFetchingStanding,
  standingFailed: state.standing.StandingFailed,
  Standing: state.standing.Standing,
  currentLanguageCode: state.language.currentLanguageCode,
  tournamentDetails: state.standing.TournamentDetails,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CupTournamentGroupsStanding);
