import { connect } from "react-redux";
import { getMatchInfo } from "../../redux/Match/Actions.Match";
import { Row, Col } from "react-bootstrap";
import "./MatchHeader.css";
import { useEffect } from "react";
import Countdown from "react-countdown";
import { convertFullDate } from "../../globalFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useHistory } from "react-router-dom";
import MatchHeaderLoader from "./matchHeaderLoader";
import RadialTimer from "../matchRadialTimer/RadialTimer";
import { IDS, ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import whistle from "../../assets/whistle-icon2.png"
import { getMatchEvents } from '../../redux/Match/Actions.Match'
import useServiceAndSport from "../../hooks/useServiceAndSport";
import { eventsWithGoal, OwnGoal } from "../../redux/Match/Utils.Match";
import { handleShowPenaltyScore } from '../MatchCard/helper';
import stadiumIcon from "../../assets/img/stadium.svg"
import calendarIcon from "../../assets/img/calendar.svg"

const MatchHeader = ({
  getMatchInfo,
  matchRoundInfo,
  team1,
  team2,
  isFetchingMatchStatus,
  matchStatus,
  stadium,
  date,
  matchId,
  matchDetails,
  matchEvents
}) => {
  const history = useHistory();
  const parseDate = convertFullDate(date, "ar");
  const { sportName } = useServiceAndSport();
  const sport_id = IDS.SPORTS[sportName?.toUpperCase() ?? "FOOTBALL"];
  const isFootball = sport_id === IDS.SPORTS.FOOTBALL

  useEffect(() => {
    if (sportName === null) return;
    getMatchInfo(matchId, sportName);
    getMatchEvents(matchId)
  }, [matchId, matchStatus, sportName, sport_id]);


  const handleRowClick = (serviceId, sportId, id) => {
    const individualSports = ["squash", "tennis"];
    const teamOrPlayerUrl = individualSports.includes(sportName)
      ? "player"
      : "team";
    history.push(
      `/${getServiceName(serviceId)}${getSportRoute(
        sportId,
        serviceId
      )}/${teamOrPlayerUrl}/${id}/overview`,
      { previousPathname: history.location.pathname }
    );
  };
  const getTime = () => {
    return date?.split(" ")[1]?.split(":")?.slice(0, 2)?.join(":");
  };

  const getTimer = () => {
    // if(matchStatus===1){
    //     const date=dateTime.split(' ').join('T')
    //     return  <div>
    //                 <Countdown date={ Date.parse(date)} zeroPadDays={false} onComplete={()=> {
    //                     getMatchInfoUpdate(matchId)
    //                 }}/>
    //            </div>
    // }

    if (
      Date.now() < Date.parse(date) &&
      Date.parse(date) - Date.now() <= 3600000
    ) {
      const d = date.split(" ")?.join("T");
      return (
        <div>
          <Countdown
            date={Date.parse(d)}
            zeroPadDays={false}
            onComplete={() => {
              getMatchInfo(matchId);
            }}
          />
        </div>
      );
    }
    if (matchStatus === 4) {
      return <div>ملغي</div>;
    }
    if (matchStatus === 3) {
      return <div>موجل</div>;
    }
    if (matchStatus === 2 && !matchRoundInfo.length) {
      return <div> بدات</div>;
    }
    if (matchStatus === 1 && !matchRoundInfo.length) {
      return <div>لم تبدا</div>;
    }
    if (matchStatus === 5 || matchRoundInfo?.event_id === 11) {
      return <div data-testid="match-state">انتهت</div>;
    }
    if (matchStatus === 2 && matchRoundInfo?.event_id === 10) {
      return <div>بداية الضربات الترجيحية</div>;
    }
    if (matchStatus === 2 && matchRoundInfo?.event_id === 8) {
      return (
        <div>
          <div>نهاية الشوط الثاني الاضافي </div>
          <div>30:00</div>
        </div>
      );
    } else if (matchStatus === 2 && matchRoundInfo?.event_id === 7) {
      const d = matchRoundInfo.created_at?.split(" ")?.join("T");
      return (
        <div>
          <Countdown date={Date.parse(d) + 900000} renderer={round2extra} />
        </div>
      );
    } else if (matchStatus === 2 && matchRoundInfo?.event_id === 6) {
      return (
        <div>
          <div>نهاية الشوط الاضافي الاول</div>
          <div>15:00</div>
        </div>
      );
    } else if (matchStatus === 2 && matchRoundInfo?.event_id === 5) {
      const d = matchRoundInfo.created_at?.split(" ")?.join("T");
      return (
        <div>
          <Countdown date={Date.parse(d) + 900000} renderer={round1extra} />
        </div>
      );
    } else if (matchStatus === 2 && matchRoundInfo?.event_id === 4) {
      return (
        <RadialTimer
          isFootball={isFootball}
          initialStart={45 * 60 * 1000}
          child={
            <div className="fs-xxs p-3">
              <div>نهاية الشوط الثاني</div>
              <div>90:00</div>
              <div>
                {matchRoundInfo.event_time?.split("+")[1]} دقيقه وقت بدل ضائع
              </div>
            </div>
          }
        />
      );
    } else if (matchStatus === 2 && matchRoundInfo?.event_id === 3) {
      const d = matchRoundInfo.created_at?.split(" ")?.join("T");
      return (
        <RadialTimer
          isFootball={isFootball}
          initialStart={Date.parse(d) + 2700000 - Date.now()}
          child={
            <Countdown date={Date.parse(d) + 2700000} renderer={round2} />
          }
        />
      );
    } else if (matchStatus === 2 && matchRoundInfo?.event_id === 2) {
      return (
        <RadialTimer
          isFootball={isFootball}
          initialStart={45 * 60 * 1000}
          child={
            <div className="fs-xxs p-3">
              <div>نهاية الشوط الاول</div>
              <div>45:00</div>
              <div>
                {matchRoundInfo.event_time.split("+")[1]} دقيقه وقت بدل ضائع
              </div>
            </div>
          }
        />
      );
    } else if (matchStatus === 2 || matchRoundInfo?.event_id === 1) {
      const d = matchRoundInfo?.created_at?.split(" ")?.join("T");
      return (
        <RadialTimer
          isFootball={isFootball}
          initialStart={Date.parse(d) + 2700000 - Date.now()}
          child={
            <Countdown date={Date.parse(d) + 2700000} renderer={round1} />
          }
        />
      );
    }
  };
  const round1 = ({ hours, minutes, seconds, completed, api, formatted }) => {
    const minLeft = 44;
    if (completed && isFootball) {
      // Render a completed state
      return <span>45:00</span>;
    } else {
      // Render a countdown
      return (
        <div className="mt-3">
          <div>الشوط الاول</div>
          {
            isFootball && <div>
              {minLeft - parseInt(formatted.minutes)}:
              {60 - parseInt(formatted.seconds)}
            </div>
          }
          <div>
            {" "}
            <span className="mx-1">{team2.score}</span>-
            <span className="mx-1">{team1.score}</span>
          </div>
        </div>
      );
    }
  };
  const round1extra = ({
    hours,
    minutes,
    seconds,
    completed,
    api,
    formatted,
  }) => {
    const minLeft = 14;
    if (completed && isFootball) {
      // Render a completed state
      return <span>15:00</span>;
    } else {
      // Render a countdown
      return (
        <div>
          <div>بدأت</div>
          <div> الشوط الاول الاضافي</div>
          {
            isFootball && <div>
              {minLeft - parseInt(formatted.minutes)}:
              {60 - parseInt(formatted.seconds)}
            </div>
          }
        </div>
      );
    }
  };
  const round2 = ({ hours, minutes, seconds, completed, api, formatted }) => {
    const minLeft = 89;
    if (completed && isFootball) {
      // Render a completed state
      return <span>90:00</span>;
    } else {
      return (
        <div className="mt-3">
          <div>الشوط الثاني</div>
          {
            isFootball && <div>
              {minLeft - parseInt(formatted.minutes)}:
              {60 - parseInt(formatted.seconds)}
            </div>
          }
          <div>
            {" "}
            <span className="mx-1">{team2.score}</span>-
            <span className="mx-1">{team1.score}</span>
          </div>
        </div>
      );
    }
  };
  const round2extra = ({
    hours,
    minutes,
    seconds,
    completed,
    api,
    formatted,
  }) => {
    const minLeft = 29;
    if (completed && isFootball) {
      // Render a completed state
      return <span>30:00</span>;
    } else {
      return (
        <div>
          <div>بدأت</div>
          <div> الشوط الثاني الاضافي</div>
          {
            isFootball && <div>
              {minLeft - parseInt(formatted.minutes)}:
              {60 - parseInt(formatted.seconds)}
            </div>
          }
        </div>
      );
    }
  };

  const renderTeam = (t, i) => <>
    {i === 2 &&
      <>
        {
          handleShowPenaltyScore(team1, team2) && <span className="fs-sm match-header-penalty-score" data-testid="match-header-away-team-penalty-score">({team2.penalty_score})</span>
        }
        <div className='fs-md match-header-score ml-2 pt-1 pt-md-2' data-testid="match-header-away-team-score">{t?.score}</div>
      </>
    }
    <div data-testid={`match-header-${i === 1 ? 'home' : 'away'}-team-image-and-title-wrapper`}>
      <div className="mb-3"
        onClick={() =>
          handleRowClick(
            matchDetails?.tournament.services[0],
            t?.sport_id,
            t?.id
          )
        }
        style={{ cursor: "pointer" }}
      >
        <img
          className="match-header-team-img "
          src={ImgURL(t?.logo)}
          alt=""
          data-testid={`match-header-${i === 1 ? 'home' : 'away'}-team-image`}
        />
        <div className="fs-sm text-center mt-2" data-testid={`match-header-${i === 1 ? 'home' : 'away'}-team-title`}>{t?.title}</div>
      </div>
    </div>
    {i === 1 &&
      <>
        <div className='fs-md match-header-score mr-2 pt-1 pt-md-2' data-testid="match-header-home-team-score">{t?.score}</div>
        {
          handleShowPenaltyScore(team1, team2) && <span className="fs-sm match-header-penalty-score" data-testid="match-header-home-team-penalty-score">({team1.penalty_score})</span>
        }
      </>
    }
  </>
  const renderManager = (manager_name, manager_image) => <div>
    {manager_name && (
      <>
        <div className="white-color fs-xs manager__name">
          <img src={manager_image ? ImgURL(manager_image) : "/icons/manager.svg"} className="mx-2" alt="" height={30} width={30} data-testid="match-header-team-manager-image" />
          <span data-testid="match-header-team-manager-name">{manager_name}</span>
        </div>
        <hr className="p-0 m-0 mt-2" />
      </>
    )}
  </div>

  const renderScorers = (team) => {
    const goalsArr = matchEvents?.filter(
      event => (
        eventsWithGoal.filter(id => id !== OwnGoal).includes(event.event_id) && event.team_id === team.id
      ) || (
          event.event_id === OwnGoal && event.team_id !== team.id
        )
    )
    return (
      <div className="team1Scorers-container d-flex flex-wrap justify-content-center">
        <div className="team1Scorers d-flex flex-column align-items-start">
          {goalsArr?.map((goal) => (
            <div className="scorer white-color fs-xs pt-1 text-nowrap px-2" key={goal.id}>
              <img src={ImgURL(goal.event_image)} className="mx-2" alt="" height={30} width={30} />
              {` ${goal.event_time} د  -  ${goal.playerOne_name}`}
            </div>
          ))}
        </div>
      </div>
    )
  }

  return isFetchingMatchStatus ? (
    <MatchHeaderLoader />
  ) : (
    <div className="match-header text-light p-2">
      <div className="match-header-team-info p-3">
        <div className="d-flex justify-content-start align-items-center mb-3">
          <div className="time-container d-flex align-items-center">
            <b className="d-flex align-items-center">
              <FontAwesomeIcon icon={faClock} size="1x" />
            </b>
            <b>
              <span className="parse__time mx-1" data-testid="match-header-time">{getTime()}</span>
            </b>
          </div>
          <div className="date-container mx-1">
            <b>
              <img src={calendarIcon} width="25px" height="20px" alt="" />
              <span className="parse__date px-1 text-nowrap" data-testid="match-header-date">{parseDate}</span>
            </b>
          </div>
          <div className="stadium-name-container" >
            {" "}
            <img src={stadiumIcon} width="25px" height="20px" alt="" />{" "}
            <b className="stadium__name px-1 text-nowrap">{matchDetails?.stadium?.title ? matchDetails?.stadium?.title : `- - - -`}</b>
          </div>
          <div className="referee-name-container mx-1">
            {" "}
            <img src={whistle} width="25px" height="20px" alt="" />{" "}
            <b className="referee-name px-1 text-nowrap">{matchDetails?.referee_name ? matchDetails?.referee_name : `- - - -`}</b>
          </div>
        </div>
        <Row className="d-flex align-items-center justify-content-start fs-xm text-center" data-testid="match-header-info-wrapper">
          <Col
            xs={4}
            className="d-flex justify-content-center align-items-center"
            data-testid="team-home-info-wrapper"
          >
            {renderTeam(team1, 1)}
          </Col>
          <Col xs={4} md={4} className="p-2" data-testid="match-state-wrapper">
            <div className="fs-md d-flex justify-content-center">
              {getTimer()}
            </div>
          </Col>
          <Col
            xs={4}
            className="d-flex justify-content-center align-items-center"
            data-testid="team-away-info-wrapper"
          >
            {renderTeam(team2, 2)}
          </Col>
        </Row>
        <Row className="d-flex align-items-start justify-content-start fs-xm text-center">
          <Col
            xs={5} md={4}
            className="d-flex flex-column justify-content-center align-items-center p-0"
          >
            {renderManager(team1?.manager_name, team1?.manager_image)}
            {isFootball && renderScorers(team1)}

          </Col>
          <Col xs={1} md={4}>
          </Col>
          <Col
            xs={5} md={4}
            className="d-flex flex-column justify-content-center align-items-center p-0"
          >
            {renderManager(team2?.manager_name, team2?.manager_image)}
            {isFootball && renderScorers(team2)}

          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  stadium: state.matchInfo.matchStatus[0].stadium || "",
  team1: state.matchInfo.matchStatus[0].team1,
  team2: state.matchInfo.matchStatus[0].team2,
  isFetchingMatchStatus: state.matchInfo.isFetchingMatchStatus,
  statusString: state.matchInfo.matchStatus[0].status,
  matchStatus: state.matchInfo.matchStatus[0].matchStatus,
  date: state.matchInfo.matchStatus[0].date,
  matchRoundInfo: state.matchInfo.matchRoundInfo,
  matchDetails: state.matchInfo.matchDetails,
  matchEvents: state.matchInfo.matchEvents,
});
const mapDispatchToProps = (dispatch) => ({
  getMatchInfo: (matchId, sportName) =>
    dispatch(getMatchInfo(matchId, sportName)),

  getMatchEvents: (matchId) => dispatch(getMatchEvents(matchId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchHeader);
