import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { getTournaments } from "../../redux/Tournaments/Tournaments.Actions";
import { useHistory } from "react-router-dom";
import "./tournamentsCarousel.css";
import { ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import SampleArrowBtn from '../../components/SampleArrowBtn/SampleArrowBtn'

function TournamentsCarousel({
  tournaments,
  getTournaments,
  isFetchingTournaments,
  sport_id,
  service_id,
  season_id,
  currentLanguageCode,
  squashtSport,
  isFetchingSquashtSport,
  appActiveSeason,
}) {
  const history = useHistory();
  const [tournamentsArr, setTournamentsArr] = useState([])
  const originalDataLength = tournaments?.length

  const handleRowClick = (serviceId, sportId, id) => {
    history.push(
      `/${getServiceName(serviceId)}${getSportRoute(
        sportId,
        serviceId
      )}/tournament/${id}`,
      { previousPathname: history.location.pathname }
    );
  };

  useEffect(() => {
    if (service_id === null || appActiveSeason.id === null) return;
    sport_id === 7
      ? getTournaments({ sport_id, service_id, season_id: appActiveSeason.id, priority: 1 }, currentLanguageCode, 'squash')
      : getTournaments({ sport_id, service_id, season_id: appActiveSeason.id, priority: 1 }, currentLanguageCode);
  }, [appActiveSeason?.id, service_id, currentLanguageCode, sport_id]);

  const settings = {
    infinite: false,
    draggable: false,
    touchMove: false,
    speed: 900,
    slidesToShow: service_id === 9 ? 4 : 3,
    rows: service_id === 9 ? 1 : 2,
    slidesToScroll: 1,
    // rtl: currentLanguageCode === "ar" ? true : false,
    nextArrow: <SampleArrowBtn arrowDirection="left" service_id={service_id}
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        borderRadius: "6px",
        border: "unset",
        position: "absolute",
        top: "0px",
        height: "100%",
        right: "-12px",
        zIndex: "15",
        width: "30px",
        opacity: "1",
        color: "white"
      }}
    />,
    prevArrow: <SampleArrowBtn service_id={service_id}
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        borderRadius: "6px",
        border: "unset",
        position: "absolute",
        top: "0px",
        height: "100%",
        left: "-12px",
        zIndex: "15",
        width: "30px",
        opacity: "1",
        color: "white"
      }}
    />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const idGenerator = () => Math.floor(Math.random() * 1000000);

  const addDummyData = (originalLength) => {
    if (originalLength > 6) return []
    const dummyObj = {
      title: "الدوري المصري الممتاز",
      description: "الدوري المصري الممتاز هو دوري كرة قدم مصري، ويُعتبر الدوري الأعلى في مصر، ويُشرف عليه الاتحاد المصري لكرة القدم، ويقام بنظام دورة روبن، ويتأهل البطل والوصيف إلى دوري أبطال أفريقيا، فيما يتأهل المركز الثالث وأحيانًا الرابع إلى كأس الكونفيدرالية الأفريقية، ويهبط آخر ثلاث فرق في ترتيب الدوري إلى دوري الدرجة الثانية",
      weeks: 34,
      logo: "https://tipsscore.com/resb/league/egypt-premier-league.png",
      type: "League",
      team_type: "Team",
      created_at: "2022-05-31 16:05:06",
      country: {
        id: 7,
        title: "مصر",
        flag: "/assets/uploads/Location/2022/09/06/63173f34a9df8.png",
        continentId: 1,
        continent: "أفريقيا"
      },
      services: [
        7
      ],
      sport_id: 1,
      continent: {
        id: 1,
        name: "أفريقيا"
      }
    }

    const dummyData = []
    let numOfDummyData;

    if (service_id === 9) { numOfDummyData = 4 - originalLength }
    else { numOfDummyData = 6 - originalLength }

    for (let i = 0; i <= numOfDummyData; i++) {
      dummyData.push(dummyObj)
    }

    return dummyData
  }


  useEffect(() => {
    setTournamentsArr([...tournaments, ...addDummyData(originalDataLength)])
  }, [tournaments])

  return (
    <Container className="tournament-carousel-component start-carousel-at-right">

      <Slider {...settings}>

        {isFetchingTournaments && isFetchingSquashtSport ? (
          <div>...loading</div>
        ) : (originalDataLength === 0 || tournaments[0] === "") && (squashtSport?.length === 0 || squashtSport?.length === undefined) ? (
          <div className="my-5 text-white text-center">لا يوجد دورات</div>
        ) : (
          (tournaments?.length === 0 ? squashtSport : tournamentsArr)?.map(
            (el, i) => (
              <div
                style={{ backgroundColor: "red" }}
                key={el.id ? el.id : idGenerator()}
                className={`text-light tournament-carousel-card d-flex flex-column bg-primary-color align-items-center justify-content-center table-clickable-row rounded ${i >= originalDataLength ? "dummy-card" : ""}`}
                onClick={() =>
                  handleRowClick(el.services?.[0], el.sport_id, el.id)
                }
              >
                <div className="rounded-circle p-2">
                  <img src={ImgURL(el.image || el.logo)} alt="tournament" />
                </div>
                <div className="mt-2 text-center fs-xs">
                  {el.title || el.name_ar}
                </div>
              </div>
            )
          )
        )}
      </Slider>

    </Container>
  );
}

const mapStateToProps = (state) => ({
  isFetchingTournaments: state.tournaments.isFetchingTournaments,
  tournaments: state.tournaments.Tournaments,
  currentLanguageCode: state.language.currentLanguageCode,
  appActiveSeason: state.seasons.appActiveSeason,
});
const mapDispatchToProps = (dispatch) => ({
  getTournaments: (params, currentLanguageCode, sportName) =>
    dispatch(getTournaments(params, currentLanguageCode, sportName)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TournamentsCarousel);
