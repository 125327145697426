import React, { useState, useEffect, useRef } from "react";
import api from "../../../../../apis";
import { useTranslation } from "react-i18next";
import { ImgURL } from "../../../../../globalData";
import "../../../../FullSearch/FullSearch.css";

const PlayerSearch = ({ getPlayerInfo }) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isFetchingFullSearch, setIsFetchingFullSearch] = useState(false);
  const [FullSearch, setFullSearch] = useState([]);
  const selectPlayerInput = useRef(null);

  const handleInputSearchClick = (el) => {
    getPlayerInfo(el);
    setInputValue(el.name);
    setIsMenuOpen(false);
  };

  const creatSearchCat = (arr) => {
    const catObj = { players: [] };
    arr.map((el) => el.type === "player" && catObj.players.push(el));
    return catObj;
  };

  const handlePlayerSearch = (param) => {
    setIsFetchingFullSearch(true);
    api.playerSearch.getPlayer(param).then((res) => {
      setFullSearch(res.data);
      setIsFetchingFullSearch(false);
    });
  };

  useEffect(() => {
    selectPlayerInput.current.focus();
  }, []);

  return (
    <div className="d-flex full-search">
      <input
        className="form-control me-2 full-search-input "
        type="search"
        ref={selectPlayerInput}
        placeholder="ابحث هنا......"
        aria-label="Search"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target?.value?.match(/[A-Za-z ]*[ء-ي]*/g)?.join(""));
          if (!isSearching) {
            setIsSearching(!isSearching);
            setIsMenuOpen(true);
            setTimeout(() => {
              setIsMenuOpen(true);
              setIsSearching(false);
              handlePlayerSearch({ keyword: e.target?.value?.match(/[A-Za-z ]*[ء-ي]*/g)?.join("") });
            }, 500);
          }
          if (!e.target.value) getPlayerInfo("");
        }}
      />

      {isMenuOpen && (
        <ul
          className={`${inputValue === "" ? "d-none" : ""
            } full-search-menu text-light`}
        >
          {isFetchingFullSearch ? (
            <div className="text-center mt-2">
              <div
                className="spinner-border text-secondary fs-xs p-0"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : FullSearch.length === 0 ? (
            <li className="text-center p-5">لا يوجد نتائج</li>
          ) : (
            Object.keys(creatSearchCat(FullSearch)).map((key, i) => (
              <li key={i}>
                <h5
                  className={`px-3 py-2 ${creatSearchCat(FullSearch)[key].length === 0 ? "d-none" : ""
                    } `}
                >
                  {t(`Search.${key}`)}
                </h5>
                {creatSearchCat(FullSearch)[key].map((el) => (
                  <div
                    className="dropdown-item d-flex justify-content-between p-2 table-clickable-row text-light"
                    key={el.id * Math.random()}
                    onClick={() =>
                      handleInputSearchClick(
                        el,
                        el.services?.[0],
                        el.sport_id,
                        el.type,
                        el.id
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div>{el.name}</div>
                    <img
                      src={ImgURL(el.image)}
                      width="30px"
                      height="35px"
                      alt=""
                    ></img>
                  </div>
                ))}
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default PlayerSearch;
