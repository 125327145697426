import PlayerSeasonStatisticsTypes from "./PlayerSeasonStatistics.Types";
import axios from 'axios'
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_PLAYER_SEASON_STATISTICS } = process.env

const getPlayerSeasonStatisticsStart = () => ({
  type: PlayerSeasonStatisticsTypes.GET_PLAYER_SEASON_STATISTICS_START
})

const getPlayerSeasonStatisticsSuccess = (info) => ({
  type: PlayerSeasonStatisticsTypes.GET_PLAYER_SEASON_STATISTICS_SUCCESS,
  payload: info
})



const getPlayerSeasonStatisticsFailed = (info) => ({
  type: PlayerSeasonStatisticsTypes.GET_PLAYER_SEASON_STATISTICS_FAILED,
  payload: info
})


const getPlayerSeasonStatistics = (sport_id, tournament_id, team_id, player_id, lang) => (
  dispatch => {
    dispatch(getPlayerSeasonStatisticsStart())
    axios({
      method: "get",
      url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_PLAYER_SEASON_STATISTICS}`,
      params: { sport_id, tournament_id, team_id, player_id },
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`
      },
    }).then(resp => {
      if (resp.data.status === 200) {
        dispatch(getPlayerSeasonStatisticsSuccess(resp.data.data))
      }
      else {
        dispatch(getPlayerSeasonStatisticsFailed(resp.data.message))
      }
    }
    )
      .catch(error => dispatch(getPlayerSeasonStatisticsFailed(error)))

  }
)

export { getPlayerSeasonStatistics }