import PlayerDetailsTypes from "./PlayerDetails.Types";
import axios from 'axios'
import api from "../../apis";
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_PLAYERS, REACT_APP_NEW_GET_TEAMS } = process.env


const getPlayerDetailsStart = () => ({
  type: PlayerDetailsTypes.GET_PLAYER_DETAILS_START
})

const getPlayerDetailsSuccess = (info) => ({
  type: PlayerDetailsTypes.GET_PLAYER_DETAILS_SUCCESS,
  payload: info
})

const setCurrentTeam = (info) => ({
  type: PlayerDetailsTypes.SET_CURRENT_PLAYER_TEAM,
  payload: info
})

const setCurrentTournament = (info) => ({
  type: PlayerDetailsTypes.SET_CURRENT_PLAYER_TOURNAMENT,
  payload: info
})

const getPlayerDetailsFailed = (info) => ({
  type: PlayerDetailsTypes.GET_PLAYER_DETAILS_FAILED,
  payload: info
})

const getPlayerDetails = (player_id, lang, sportName = "") => (
  dispatch => {
    dispatch(getPlayerDetailsStart());
    if (!sportName || sportName !== "squash") {
      const individualSports = ["tennis"];
      const isIndividualSport = individualSports.includes(sportName);
      const teamOrPlayerUrl = isIndividualSport ?
        REACT_APP_NEW_GET_TEAMS :
        REACT_APP_NEW_GET_PLAYERS;
      axios({
        method: "get",
        url: `${globalBaseUrl.current}${teamOrPlayerUrl}/${player_id}`,
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": `${lang}`
        },
      }).then(resp => {
        if (resp.data.status === 200) {
          if (isIndividualSport) {
            const customizedResData = {
              ...resp.data.data,
              image: resp.data.data.logo
            }
            dispatch(getPlayerDetailsSuccess(customizedResData))

          } else {
            dispatch(getPlayerDetailsSuccess(resp.data.data))
          }
        }
        else {
          dispatch(getPlayerDetailsFailed(resp.data.message))
        }
      })
        .catch(error => dispatch(getPlayerDetailsFailed(error)))
    } else {
      api.squash.getPlayerDetails({ id: player_id }).then(
        res => {
          if (res.code === 200) {
            const { nameAr, country } = res.data[0];
            const customizedData = {
              ...res.data[0],
              nationality: {
                flag: country.flag,
                title: country.nameAr
              },
              name: nameAr,
              services: [10]
            }
            dispatch(getPlayerDetailsSuccess(customizedData))
          } else {
            dispatch(getPlayerDetailsFailed(res.message))
          }
        }
      )
    }
  }
)

export { getPlayerDetails, setCurrentTeam, setCurrentTournament }
