import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import './sideMenu.css'
import salama from '../../assets/salama.png'
import fun2fit from '../../assets/fun2fit.png'
import goldTrophy from '../../assets/Gold-Trophy.png'
import useServiceAndSport from '../../hooks/useServiceAndSport';
import { isActive } from '../helper';
import TwistSettings from '../../TwistSettings.json'
import tennisIcon from "../../assets/img/tennis.svg"
import squashIcon from "../../assets/img/squash2.png"
import handballIcon from "../../assets/img/handball.png"
import fitnessIcon from "../../assets/img/fitnessLogo.PNG"


const SideMenu = (props) => {
  const { sportName } = useServiceAndSport()
  const { pathname } = useLocation()

  return (props.showMobileSideMenu &&
    <div className="sideMenu text-light p-2">
      <Link
        to={{ pathname: "/othersports/handball", state: { previousPathname: pathname } }}
        className={`table-clickable-row-no-animation p-1 ${isActive(sportName === "handball")}`}
      >
        <img className='handball__image' src={handballIcon} width="35px" height="35px" alt="handball"></img>
      </Link>
      <Link
        to={{ pathname: "/othersports/tennis", state: { previousPathname: pathname } }}
        className={`table-clickable-row-no-animation p-1 ${isActive(sportName === "tennis")}`}
      >
        <img src={tennisIcon} alt="tennis"></img>
      </Link>
      <Link
        to={{ pathname: "/othersports/squash", state: { previousPathname: pathname } }}
        className={`table-clickable-row-no-animation p-1 ${isActive(sportName === "squash")}`}
      >
        <img src={squashIcon} width="35px" height="35px" alt="squash"></img>
      </Link>
      {
        TwistSettings["twist-fitness"] && <Link
          to="/fitness"
          className={`table-clickable-row-no-animation p-1`}
        >
          <img
            src={fitnessIcon}
            width="35px"
            height="35px"
            className="border-radius-35"
            alt="fitness"
          ></img>
        </Link>
      }
      {
        TwistSettings['media-sections'] && <>
          <a
            href="http://olympics.etisalatsports.com/"
            className="table-clickable-row-no-animation p-1"
          >
            <img
              src="/icons/oly.png"
              width="35px"
              height="35px"
              className="border-radius-35"
              alt="olympics"
            ></img>
          </a>
          <a
            href={window.location.origin + "/meloukelkora"}
            className="table-clickable-row-no-animation p-1"
          >
            <img
              src="/icons/mlook-elkora.png"
              width="35px"
              height="35px"
              className="border-radius-35"
              alt="melouk-el-koura"
            ></img>
          </a>
          <Link to={{ pathname: "/sections/road-to-championship/1", state: { previousPathname: pathname } }} className="table-clickable-row-no-animation p-1">
            <img src={goldTrophy} alt="road-to-champion" width="33px" height="30px" />
          </Link>
          <Link to={{ pathname: "/sections/fun2fit", state: { previousPathname: pathname } }} className="table-clickable-row-no-animation p-1">
            <img src={fun2fit} alt="fun2fit" width="35px" height="35px" />
          </Link>
          <Link to={{ pathname: "/sections/fl3aglaelsalama", state: { previousPathname: pathname } }} className="table-clickable-row-no-animation p-1">
            <img src={salama} alt="fe-el-agala-el-salama" width="35px" height="35px" />
          </Link>
        </>
      }
    </div>
  );
};

export default SideMenu
