import SquashMatchDetailsTypes from "./SquashMatchDetails.Types";



const INITIAL_STATE = {
    matchDetails:[''],
    isFetchingSquashMatchDetails:true,
    SquashMatchDetailsFailed:null
   };
   
   const SquashMatchDetails = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case SquashMatchDetailsTypes.GET_SQUASH_MATCH_DETAILS_START:
        return {
          ...state,
          isFetchingSquashMatchDetails:true
        }
      case SquashMatchDetailsTypes.GET_SQUASH_MATCH_DETAILS_SUCCESS:
        return {
          ...state,
          matchDetails:action.payload,
          isFetchingSquashMatchDetails:false,
          SquashMatchDetailsFailed:null
        }
      case SquashMatchDetailsTypes.GET_SQUASH_MATCH_DETAILS_FAILED:
        return {
          ...state,
          SquashMatchDetailsFailed:action.payload,
          isFetchingSquashMatchDetails:false
        }
       default:
         return state;
     }
   };
 
   export default SquashMatchDetails