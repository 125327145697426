
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getStanding } from "../../redux/Standing/Standing.Actions";
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { getServiceName, getSportRoute } from '../../Layout/helper';

const TournamentOverviewStand = ({
    Standing,
    standingFailed,
    isFetchingStanding,
    currentLanguageCode,
    getStanding,
    sport_id,
    season_id,
    tournament_id,
    team_id,
    toLocalTab,
    competitionType,
    groups
}) => {

    const [currentGroup, setCurrentGroup] = useState(0);

    useEffect(() => {
        if (team_id) getStanding(sport_id, season_id, tournament_id, currentLanguageCode)
    }, [sport_id, season_id, tournament_id, currentLanguageCode])


    const history = useHistory();

    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/team/${id}`, { previousPathname: history.location.pathname });
    }

    const { t } = useTranslation()

    const getTeamStand = (Arr, id) => {
        const index = Arr.flat().findIndex(el => el.team_id === Number(id));
        const newIndex = index - 2 < 0 ? 0 : index - 2
        const newLastIndex = index - 2 < 0 ? 4 : index + 2
        const newStanding = Arr.flat().map((el, i) => ({ ...el, index: i })).slice(newIndex, newLastIndex)
        return (
            newStanding.map(
                (team, i) =>
                    <tr key={team.id} style={{ backgroundColor: `${team.team_id === Number(id) ? 'var(--red)' : ''}` }}>
                        <th scope="row">{team.index + 1}</th>
                        <th>{team.team_name}</th>
                        <td>{team.played}</td>
                        <td>{team.difference}</td>
                        <td>{team.point}</td>
                        <td>{team.difference}</td>
                        <td>{team.point}</td>
                    </tr>
            ))
    }

    const handleRenderStanding = () => {
        const cupOrLeagueTeamsArr = competitionType === "Cup" ?
            Standing.slice(currentGroup, currentGroup + 1)[0]
            : Standing;

        return (
            cupOrLeagueTeamsArr.sort((a, b) => b.point - a.point).map(
                (team, i) => i <= 3 && (
                    <tr key={team.id} onClick={() => handleRowClick(team.services?.[0], team.sport_id, team.team_id)} className='table-clickable-row text-center'>
                        <td >{i + 1}</td>
                        <td scope="row" style={{ textAlign: 'start' }}>{team.team_name}</td>
                        <td>{team.win}</td>
                        <td>{team.lose}</td>
                        <td>{team.draw}</td>
                        <td>{team.difference}</td>
                        <td>{team.point}</td>
                    </tr>
                )
            )
        )
    }


    return (
        <div className='bg-black-color white-color fs-xs'>
            <div className='d-flex justify-content-between fs-md p-2'>
                {competitionType === "Cup" ?
                    <>
                        <div className='d-flex align-items-center'>
                            <button className={`swipe-animation fa fa-chevron-${currentLanguageCode === 'ar' ? 'right' : 'left'} btn white-color`} disabled={currentGroup === Object.values(Standing).length - 1} aria-hidden="true" onClick={() => setCurrentGroup(currentGroup + 1)}></button>
                            <div style={{ cursor: 'pointer' }} className='btn white-color table-clickable-row fs-md px-3' onClick={() => toLocalTab('standing')}>
                                <span className='px-1 text-capitalize'>
                                    {t(`TournamentDetails.${groups[currentGroup].split(" ")[0].toLowerCase()}`)}
                                </span>
                                <span className='px-1'>{currentGroup + 1}</span>
                            </div>
                            <button className={`swipe-animation fa fa-chevron-${currentLanguageCode === 'ar' ? 'left' : 'right'} btn white-color`} disabled={currentGroup === 0} aria-hidden="true" onClick={() => setCurrentGroup(currentGroup - 1)}></button>
                        </div>
                    </> :
                    <>
                        <div>{"الترتيب"}</div>
                        <div style={{ direction: 'rtl' }}>
                            <button className={`swipe-animation fa fa-chevron-${currentLanguageCode === 'ar' ? 'left' : 'right'} btn white-color`} aria-hidden="true" onClick={() => toLocalTab('standing')}></button>
                        </div>
                    </>

                }
            </div>
            <div className='responsive-table overflow-auto'>
                <table className="table-borderless bg-black-color table-striped table-dark white-color">
                    <thead>
                        <tr>
                            <th scope="col" className="text-center">المركز</th>
                            <th scope="col">الفريق</th>
                            <th scope="col" className="text-center">فوز</th>
                            <th scope="col" className="text-center">خساره</th>
                            <th scope="col" className="text-center">تعادل</th>
                            <th scope="col" className="text-center">فارق</th>
                            <th scope="col" className="text-center">نقاط</th>
                        </tr>
                    </thead>
                    <tbody>
                        {standingFailed !== null ?
                            <tr>
                                <td colSpan={7} className='p-0'>
                                    <ErrorMsg msg="لا يوجد ترتيب" p={4} fs={'md'} />
                                </td>
                            </tr>
                            :
                            isFetchingStanding ?
                                <tr>
                                    <td colSpan={7} className='p-0'>
                                        <LoadingSpinnner p={4} fs={'sm'} />
                                    </td>
                                </tr>
                                :
                                Standing.length === 0 ?
                                    <tr>
                                        <td colSpan={7} className='p-0'>
                                            <ErrorMsg msg="لا يوجد ترتيب" p={4} fs={'md'} />
                                        </td>
                                    </tr>
                                    :
                                    team_id ?
                                        getTeamStand(Standing, team_id)
                                        :
                                        handleRenderStanding()
                        }

                    </tbody>
                </table>
            </div>
        </div >
    )
}


const mapStateToProps = (state) => ({
    isFetchingStanding: state.standing.isFetchingStanding,
    standingFailed: state.standing.StandingFailed,
    Standing: state.standing.Standing,
    groups: state.standing.groups,
    competitionType: state.standing.competitionType,
    currentLanguageCode: state.language.currentLanguageCode,
    tournamentDetails: state.standing.TournamentDetails,
});

const mapDispatchToProps = (dispatch) => ({
    getStanding: (sport_id, season_id, tournament_id, lang) => dispatch(getStanding(sport_id, season_id, tournament_id, lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TournamentOverviewStand)
