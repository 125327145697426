import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import PlayerSearch from '../../GuessThePlayerModal/PlayerSearch/PlayerSearch';
import guessPlayerImg from "../../../../../assets/games/guess-player.svg";
import { ImgURL } from '../../../../../globalData';

function SelectPlayer(props) {
    const [guessPlayer, setGuessPlayer] = useState("");
    const [selectedPlayer, setSelectedPlayer] = useState([]);

    const getPlayerInfo = (el) => {
        setGuessPlayer(el);
        let selectedPlayersId = [];
        for (let key in props.squad) selectedPlayersId.push(props.squad[key])
        let selectedPlayerForCurrentFormation = selectedPlayersId.flat().map(ele => ele.id);
        setSelectedPlayer(selectedPlayerForCurrentFormation)
    };

    const handleConfirm = (el) => {
        setGuessPlayer("")
        if (!selectedPlayer.includes(el.id)) setSelectedPlayer([...selectedPlayer, el.id])

    }

    const { t } = useTranslation();


    return (
        <Modal {...props} onHide={() => { props.onHide(); setGuessPlayer(null) }} size="md" centered className='select__playerModal' contentClassName={"modal__content"} dialogClassName="modal__dialog"
        >
            <Modal.Header className='modal__header' closeButton />
            <Modal.Body className="show-grid">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h3>اختر اللاعب</h3>
                        </Col>
                        <Col className='text-center' xs={12}>
                            <img src={guessPlayer ? ImgURL(guessPlayer?.image) : guessPlayerImg} className='select__playerModal--image' alt="" />
                        </Col>
                        <Col xs={12}>
                            <PlayerSearch getPlayerInfo={getPlayerInfo} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className='modal__footer d-flex justify-content-center'>
                {
                    <Button
                        disabled={selectedPlayer.includes(guessPlayer?.id) ? true : false}
                        className='text-capitalize'
                        onClick={() => {
                            props.onHide(guessPlayer, props.playerInfo);
                            handleConfirm(guessPlayer)
                        }}>
                        تأكيد
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default SelectPlayer