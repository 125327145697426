import TeamStatisticsTypes from "./TeamStatistics.Types";



const INITIAL_STATE = {
    TeamStatistics:{},
    isFetchingTeamStatistics:true,
    TeamStatisticsFailed:null
   };
   
   const TeamStatistics = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case TeamStatisticsTypes.GET_TEAM_STATISTICS_START:
        return {
          ...state,
          isFetchingTeamStatistics:true
        }
      case TeamStatisticsTypes.GET_TEAM_STATISTICS_SUCCESS:
        return {
          ...state,
          TeamStatistics:action.payload,
          isFetchingTeamStatistics:false,
          TeamStatisticsFailed:null
        }
      case TeamStatisticsTypes.GET_TEAM_STATISTICS_FAILED:
        return {
          ...state,
          TeamStatisticsFailed:action.payload,
          isFetchingTeamStatistics:false
        }
       default:
         return state;
     }
   };
 
   export default TeamStatistics