export const ADD_ALL_SPORTS = "ADD_ALL_SPORTS";
export const ALL_SPORTS_LOADING = "ALL_SPORTS_LOADING";
export const ALL_SPORTS_FAILED = "ALL_SPORTS_FAILED";

export const ADD_ALL_TEAMS = "ADD_ALL_TEAMS";
export const ALL_TEAMS_LOADING = "ALL_TEAMS_LOADING";
export const ALL_TEAMS_FAILED = "ALL_TEAMS_FAILED";

export const ADD_USER_FAV = "ADD_USER_FAV";
export const USER_FAV_LOADING = "USER_FAV_LOADING";
export const USER_FAV_FAILED = "USER_FAV_FAILED";

export const ADD_USER_CHOICES_FAV = "ADD_USER_CHOICES_FAV";
export const USER_CHOICES_FAV_LOADING = "USER_CHOICES_FAV_LOADING";
export const USER_CHOICES_FAV_FAILED = "USER_CHOICES_FAV_FAILED";
