import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TeamsCarouselLoading from "./TeamsCarouselLoading";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import "./ClubsComponent.css";
import { getAllTeams } from "../../redux/AllTeams/AllTeams.Actions";
import { IDS, ImgURL } from "../../globalData";
import { getServiceName } from "../HeaderNav/serviceUtil";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import SampleArrowBtn from "../../components/SampleArrowBtn/SampleArrowBtn";
import ErrorMsg from "../../components/ErrorPage/ErrorMsg";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={className}
      style={{
        ...style,
        fontSize: "20px",
        display: "block",
        background: "var(--heavy-metal)",
        border: "unset",
        position: "absolute",
        height: "calc(100% + 10px)",
        left: "calc(100% - 22px)",
        zIndex: "15",
        width: " 22px",
        color: "var(--tertiary-color)",
      }}
    >
      <i className="swipe-animation fa fa-chevron-right" aria-hidden="true"></i>
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      onClick={onClick}
      className={className}
      style={{
        ...style,
        fontSize: "20px",
        display: "block",
        background: "var(--heavy-metal)",
        border: "unset",
        position: "absolute",
        height: "calc(100% + 10px)",
        left: 0,
        zIndex: "15",
        width: " 22px",
        color: "var(--tertiary-color)",
      }}
    >
      <i className="swipe-animation fa fa-chevron-left" aria-hidden="true"></i>
    </button>
  );
}

function TeamsCarousel({
  AllTeams,
  isLoadingAllTeam,
  activeTeamTab,
  getAllTeams,
}) {
  const { serviceId, sportId } = useServiceAndSport();
  const { pathname } = useLocation();
  const isWorldCupPage = pathname.split("/").includes("726");

  const worldCupUpdatesToParams = (params) => {
    if (isWorldCupPage) {
      return {
        ...params,
        team_type: 2,
        tournament_id: 726
      }
    } else {
      return params
    }
  }

  useEffect(() => {
    if (serviceId === null) return;
    const params = {
      team_type: 1,
      in_carousel: true,
      sport_id: sportId,
      service_id: serviceId,
    }
    getAllTeams(worldCupUpdatesToParams(params))
  }, [serviceId, sportId, isWorldCupPage]);

  const settings = {
    infinite: false,
    draggable: false,
    touchMove: false,
    speed: 900,
    slidesToShow: 24,
    slidesToScroll: 12,
    rtl: false,
    // accessibility: true,
    nextArrow: <SampleArrowBtn arrowDirection="left"
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        borderRadius: "0px",
        border: "unset",
        position: "absolute",
        top: "-5px",
        height: "115%",
        right: "0px",
        zIndex: "15",
        width: "20px",
        opacity: "1",
        color: "white"
      }}
    />,
    prevArrow: <SampleArrowBtn
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        border: "unset",
        borderRadius: "0px",
        position: "absolute",
        top: "-5px",
        height: "115%",
        left: "0px",
        zIndex: "15",
        width: "20px",
        opacity: "1",
        color: "white"
      }}
    />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 15,
          slidesToScroll: 12,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 4,
        },
      },
    ],
  };

  if (serviceId === 10 || pathname === '/fitness') {
    return <></>;
  } else {
    return (
      <Container fluid className="px-0">
        {isLoadingAllTeam ? (
          <TeamsCarouselLoading />
        ) : (
          <div className="slick-carousel start-carousel-at-right">
            <Slider {...settings} arrows={true}>
              {AllTeams[0] === ""
                ? <ErrorMsg msg={"لا يوجد فرق"} p={1} fs={'sm'} />
                : AllTeams.map((team) => (
                  <div key={team.id} data-testid="teams-carousel-team">
                    <Link
                      data-testid="teams-carousel-team-link"
                      to={{
                        pathname: `/${getServiceName(team.services?.[0])}/team/${team.id
                          }/${activeTeamTab}`,
                        state: { previousPathname: pathname },
                      }}
                    >
                      <div>
                        <img
                          className="mt-1"
                          src={ImgURL(team.logo)}
                          style={{ width: "50px" }}
                          alt={team.name}
                        />
                      </div>
                    </Link>
                  </div>
                ))}
            </Slider>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  AllTeams: state.AllTeams.AllTeams,
  isLoadingAllTeam: state.AllTeams.isFetchingAllTeams,
  activeTeamTab: state.teamDetails.activeTab,
});

const mapDispatchToProps = (dispatch) => ({
  getAllTeams: (params) => dispatch(getAllTeams(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsCarousel);
