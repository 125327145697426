import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import './PredictAndWin.css';
import { ImgURL } from '../../globalData';
import { useTranslation } from 'react-i18next';
import api from '../../apis';
import { getUserPhoneNumbers } from '../../globalFn';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';


function PredictAndWin(props) {

    const { t } = useTranslation();
    const { team1, team2 } = props.el;
    const [msisdn, setMsisdn] = useState("");
    const [match_id, setMatch_id] = useState("");
    const [isFetchingPredictAndWinStatus, setIsFetchingPredictAndWinStatus] = useState(false);
    const [predictAndWinErrors, setPredictAndWinErrors] = useState(null);
    const [predictAndWinData, setPredictAndWinData] = useState({});
    const [formValues, setFormValues] = useState({ team_one: "", team_two: "" });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        api.predictAndWin.sendUserPrediction({
            team_one: +formValues.team_one,
            team_two: +formValues.team_two,
            match_id,
            msisdn
        })
        props.onHide();
    }

    useEffect(() => {
        if (!props.show) return;
        setIsFetchingPredictAndWinStatus(true);
        const abortController = new AbortController();
        const msisdn = getUserPhoneNumbers()[0];
        const match_id = props.el.id
        api.predictAndWin.getUserPrediction({ msisdn, match_id }, abortController.signal).then(res => {
            if (res?.status === 200) {
                setPredictAndWinData(res.data);
                setMsisdn(msisdn);
                setMatch_id(match_id);
            } else {
                setPredictAndWinErrors(res.message);
            }
            setIsFetchingPredictAndWinStatus(false);
        })
        return () => {
            abortController.abort();
        }

    }, [props.el.id, props.show])


    return (
        <Modal {...props} size="xl" contentClassName={"modal__content"} dialogClassName="modal__dialog" data-testid="predict-and-win-modal"
        >
            <Modal.Header className='modal__header' closeButton />
            <Modal.Body className="show-grid">
                <Container>
                    <Row className='justify-content-center'>
                        <Col xs={12} className="modal__bodyTitle text-center text-white">
                        توقع النتيجة
                        </Col>
                        {isFetchingPredictAndWinStatus &&
                            <div className='my-5'>
                                <LoadingSpinnner p={3} fs={"md"} />
                            </div>}
                        {!isFetchingPredictAndWinStatus && predictAndWinData.predict &&
                            <Col xs={10} >
                                <form className="predict__resultForm" onSubmit={handleSubmit}>
                                    <div className='d-flex flex-column align-items-center'>
                                        <figure className='d-flex flex-column align-items-center mb-4'>
                                            <img src={ImgURL(team1.logo)} alt="" />
                                            <figcaption className='mt-3'>{team1.title}</figcaption>
                                        </figure>
                                        <div className='select__wrapper'>
                                            <select id="match-result" name="team_one" value={formValues.team_one} onChange={handleChange}>
                                                <option value="" >اختر هنا</option>
                                                {
                                                    [...Array(11).keys()].map(ele => (
                                                        <option key={ele} value={ele}>{ele}</option>
                                                    ))

                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center'>
                                        <figure className='d-flex flex-column align-items-center mb-4'>
                                            <img src={ImgURL(team2.logo)} alt="" />
                                            <figcaption className='mt-3'>{team2.title}</figcaption>
                                        </figure>
                                        <div className='select__wrapper'>
                                            <select id="match-result" name="team_two" value={formValues.team_two} onChange={handleChange}>
                                                <option value="" >اختر هنا</option>
                                                {
                                                    [...Array(11).keys()].map(ele => (
                                                        <option value={ele}>{ele}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='button__wrapper text-center'>
                                        <Button type="submit" className='text-capitalize'>
                                        إرسال
                                        </Button>
                                    </div>
                                </form>
                            </Col>
                        }
                        {!isFetchingPredictAndWinStatus && !predictAndWinData.predict && !predictAndWinErrors &&
                            <Col xs={10} className='text-center my-5 text-white'>
                                تم ارسال توقعك مسبقا
                            </Col>
                        }
                        {!isFetchingPredictAndWinStatus && predictAndWinErrors &&
                            <Col xs={10} className='text-center my-5 text-white'>
                                حدث خطا يرجي اعادة المحاولة
                            </Col>
                        }
                    </Row>
                </Container>
            </Modal.Body>
        </Modal >
    );
}

export default PredictAndWin