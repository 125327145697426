import React, { useEffect } from 'react'
import { setActiveSeason } from '../../redux/Seasons/Seasons.Actions'
import { connect } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import "./SeasonsDropdown.css"
import api from '../../apis'
import { useState } from 'react'

const SeasonsDropdown = ({
    tournament_id = 317,
    classes,
    activeSeason,
    setActiveSeason,
    onChange = () => {},
}) => {
    const [seasons, setSeasons] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const getSeasons = (params, signal) => {
        api.seasons.getSeasons(params, signal)
            .then(res => {
                if (res?.status === 200) {
                    setSeasons(res.data);
                    setLoading(false);
                }
            })
    }

    useEffect(() => {
        const abortController = new AbortController();
        
        getSeasons({ tournament_id }, abortController.signal);

        return () => {
            abortController.abort();
        }
    }, [tournament_id])

    return (
        loading
            ? <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" data-testid="seasons-dropdown-loading">
                    ...Loading
                </Dropdown.Toggle>
            </Dropdown>
            : <Dropdown className='season-dropdown m-1'>
                <Dropdown.Toggle
                    variant="secondary"
                    id={`dropdown-button-drop-down`} drop='down'
                    className={classes}
                    data-testid="seasons-dropdown-selected-item"
                >
                    {activeSeason.title ?? 'اختر موسم'}
                </Dropdown.Toggle>

                <Dropdown.Menu className='text-light'>
                    {
                        seasons.map((season) => <div key={season.id}>
                            <Dropdown.Item
                                data-testid="seasons-dropdown-item"
                                className='text-light'
                                onClick={() => {setActiveSeason(season);onChange(season)}}
                            >{season.title}</Dropdown.Item>
                        </div>)
                    }
                </Dropdown.Menu>
            </Dropdown>
    )
}
const mapDispatchToProps = dispatch => ({
    setActiveSeason: (info) => dispatch(setActiveSeason(info)),
})

const mapStateToProps = state => ({
    activeSeason: state.seasons.activeSeason,
})

export default connect(mapStateToProps, mapDispatchToProps)(SeasonsDropdown)