import { useEffect } from 'react';
import api from '../apis';
import Cookies from "universal-cookie";
import { getSubsCookiesInfo } from './helper';
import TwistSettings from '../TwistSettings.json'

export default function useSubscriptionCookieValidator() {
  const services = ['local', 'international', 'africa', 'othersports'];
  const cookies = new Cookies();

  useEffect(() => {
    if (TwistSettings['subscription']) {
      const {msisdns} = getSubsCookiesInfo();
      services.forEach((serviceName) => {
        msisdns.forEach(msisdn => {
          api.subscription[serviceName]?.checkSubsByMsisdn(msisdn)
            .then((res) => {
              if (res.status === 1) {
                cookies.set(`${serviceName}`, `wifi,${msisdn}`, {
                  path: "/",
                  maxAge: `${60 * 60 * 12}`,
                })
              } else {
                cookies.remove(serviceName, { path: '/', domain: window.location.hostname })
              }
            })
        })
      })
    }
  }, [])

  return null;
}