import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom";
import { GiTrophyCup, GiSoccerKick, GiMorphBall } from "react-icons/gi";
import { BsChevronDown } from "react-icons/bs";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import "./MobileSideMenuStyle.css";
import twistLogo from "../../assets/img/twist-new-logo.png"
import etisalteLogo from "../../assets/img/transparentLogo.png"
import FullSearch from "../../components/FullSearch/FullSearch";
import { ImHome } from "react-icons/im";
import { BsPlayBtn } from "react-icons/bs";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import { isActive } from "../helper";
import { MdSportsTennis, MdSportsHandball, MdOutlineSportsCricket, MdOutlineSports, MdSlowMotionVideo, MdListAlt, MdFitnessCenter, MdOutlineFitnessCenter } from "react-icons/md";
import { IoFootball, IoFitness } from "react-icons/io5";
import TwistSettings from '../../TwistSettings.json'
import { getUserPhoneNumbers } from "../../globalFn";

function MobileSideMenu(props) {

  const { pathname } = useLocation();
  const { serviceName, sportName } = useServiceAndSport()
  const ref = useRef();
  const [tournamentShow, setTournamentShow] = useState(false);
  const [opendNestedLink, setOpendNestedLink] = useState("");
  const isAlAhlyTeam = pathname.includes("team/6631");
  const isHandballWorldCup = pathname.includes("tournament/7940");

  const { t } = useTranslation()
  const handleClose = () => {
    props.setShowMobileSideMenu(false);
  };
  const handleShow = () => {
    props.setShowMobileSideMenu(true);
  };

  const handleClickedNestedLink = (nestedLinkName) => {
    if (opendNestedLink !== nestedLinkName) {
      setOpendNestedLink(nestedLinkName)
    } else {
      setOpendNestedLink("")
    }
  }

  const handleToggleNestedLink = (clickedNestedLink) => {
    return opendNestedLink === clickedNestedLink ? true : "";
  }


  const handleRenderSubNestedLinks = (nestedLink) => {
    return (
      <ul className="nested__subLinks--wrapper list-unstyled">
        <li>
          <Link to={{ pathname: nestedLink + "/news", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-3 ${isActive(pathname.endsWith(`${nestedLink}/news`))}`}><MdListAlt className="fs-lg" />&nbsp;الأخبار</Link>
        </li>
        {
          TwistSettings['videos'] && <li>
            <Link to={{ pathname: nestedLink + "/videos", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-3 ${isActive(pathname.endsWith(`${nestedLink}/videos`))}`}><MdSlowMotionVideo className="fs-lg" />&nbsp;الفيديوهات</Link>
          </li>
        }
        <li>
          <Link to={{ pathname: nestedLink + "/tournaments", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-3 ${isActive(pathname.endsWith(`${nestedLink}/tournaments`))}`}><GiTrophyCup className="fs-lg" />&nbsp;المسابقات</Link>
        </li>
        <li>
          <Link to={{ pathname: nestedLink + "/matches", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-3 ${isActive(pathname.endsWith(`${nestedLink}/matches`))}`}><GiSoccerKick className="fs-lg" />&nbsp;المباريات</Link>
        </li>
      </ul>
    )
  }

  const firstLoggedInMSISDN = getUserPhoneNumbers()?.[0]?.substring(1);

  return (
    <div ref={ref}>
      <div className="MobileSideMenu text-light d-lg-none " >
        <div className="d-flex justify-content-between bg-black-color p-2 p-md-3 ">
          <div onClick={handleShow} className="ms-0">
            <img
              src={TwistSettings['twist-ui'] ? "/img/twist_bars.png" : "/img/red_bars.png"}
              width="30px"
              className="menu_btn mt-2"
              alt="Close"
            />
          </div>
          <FullSearch />
          <div className="">
            <Link to="/">
              <img
                src={TwistSettings["twist-ui"] ? twistLogo : etisalteLogo}
                width="55px"
                className="ml-lg-5 my-1"
                alt="Etisalat"
              />
            </Link>
          </div>
        </div>
        <div>
          <div className={`collapse_show ${props.showMobileSideMenu ? "in" : "out"}`}>
            <div>
              <div onClick={handleClose} className="right_arrow">
                <img
                  src={TwistSettings['twist-ui'] ? "/img/twist_right_arrow.png" : "/img/red_right_arrow.png"}
                  width="30px"
                  className="mt-3 ms-0 mx-3"
                  alt="Close"
                />
              </div>
              <div className="text-center">
                <img
                  src="/img/DM-profile.png"
                  width="40%"
                  alt="User"
                  className="mt-3"
                />
                <p className="py-3 text-center">
                  <Link
                    to={{ pathname: "/local/subscribe", state: { previousPathname: pathname } }}
                    className="text-white"
                    data-testid="mobile-side-menu-profile-msisdn"
                  >
                    {firstLoggedInMSISDN ?? "تسجيل الدخول"}
                  </Link>
                </p>
              </div>
            </div>
            <ul className="main__links list-unstyled px-0 fs-sm">
              <li>
                <Link to={{ pathname: "/", state: { previousPathname: pathname } }} className={`mx-3 ${isActive(pathname === "/")}`} onClick={handleClose}>
                  <ImHome className="fs-lg" />
                  &nbsp;الرئيسية </Link>
                <BsChevronDown className={`fs-md ${handleToggleNestedLink("Home") && "nested__subLink--opened"}`} onClick={() => handleClickedNestedLink("Home")} />
                {!!handleToggleNestedLink("Home") && handleRenderSubNestedLinks("")}
              </li>
              <li>
                <Link
                  to={{ pathname: "/othersports/squash/tournament/11707", state: { previousPathname: pathname } }}
                  onClick={handleClose}
                  className={`mx-3 ${isActive(isHandballWorldCup)}`}
                  data-testid="mobile-side-menu-world-cub-nav-item"
                >
                  <MdOutlineSportsCricket className="fs-lg" />&nbsp;<span>بطولة بلاك بول المفتوحة 2023</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to={{ pathname: "/othersports/handball/tournament/7940", state: { previousPathname: pathname } }}
                  onClick={handleClose}
                  className={`mx-3 ${isActive(isHandballWorldCup)}`}
                  data-testid="mobile-side-menu-world-cub-nav-item"
                >
                  <MdSportsHandball className="fs-lg" />&nbsp;بطولة العالم لكرة اليد٢٠٢٣"
                </Link>
              </li> */}
              <li>
                <Link to={{ pathname: "/international", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-3 ${isActive(serviceName === "international")}`}><IoFootball className="fs-lg" />&nbsp;الكرة العالمية</Link>
                <BsChevronDown className={`fs-md ${handleToggleNestedLink("internationalFootball") && "nested__subLink--opened"}`} onClick={() => handleClickedNestedLink("internationalFootball")} />
                {!!handleToggleNestedLink("internationalFootball") && handleRenderSubNestedLinks("/international")}
              </li>
              <li>
                <Link to={{ pathname: "/local", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-3 ${isActive(serviceName === "local" && !isAlAhlyTeam)}`}><IoFootball className="fs-lg" />&nbsp;الكرة المحلية </Link>
                <BsChevronDown className={`fs-md ${handleToggleNestedLink("localFootball") && "nested__subLink--opened"}`} onClick={() => handleClickedNestedLink("localFootball")} />
                {!!handleToggleNestedLink("localFootball") && handleRenderSubNestedLinks("/local")}
              </li>
              <li>
                <Link to={{ pathname: "/africa", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-3 ${isActive(serviceName === "africa")}`}><IoFootball className="fs-lg" />&nbsp;الكرة الأفريقية</Link>
                <BsChevronDown className={`fs-md ${handleToggleNestedLink("africaFootball") && "nested__subLink--opened"}`} onClick={() => handleClickedNestedLink("africaFootball")} />
                {!!handleToggleNestedLink("africaFootball") && handleRenderSubNestedLinks("/africa")}
              </li>
              <li>
                <Link
                  to={{ pathname: "/local/team/6631/overview", state: { previousPathname: pathname } }}
                  onClick={handleClose}
                  className={`mx-3 ${isActive(isAlAhlyTeam)}`}
                >
                  <IoFootball className="fs-lg" />&nbsp;النادي الأهلي
                </Link>
              </li>
              {/* <li>
                <a
                  href={window.location.origin + "/meloukelkora"}
                  className="mx-3"
                >
                  <IoFootball className="fs-lg" />&nbsp;{"ملوك الكورة"}
                </a>
              </li> */}
              <li>
                <Link to={{ pathname: "/othersports", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-4 ${isActive(serviceName === "othersports" && !sportName)}`}><MdOutlineSports className="fs-lg" />&nbsp;الرياضات الأخرى</Link>
                <BsChevronDown className={`fs-md ${handleToggleNestedLink("OtherSports") && "nested__subLink--opened"}`} onClick={() => handleClickedNestedLink("OtherSports")} />
                {!!handleToggleNestedLink("OtherSports") && handleRenderSubNestedLinks("/othersports")}
              </li>
              <ul className="list-unstyled main__links other__sportsList fs-sm pr-3">
                <li className="">
                  <Link to={{ pathname: "/othersports/tennis", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-4 ${isActive(pathname === "/othersports/tennis")}`}>
                    <MdSportsTennis className="fs-lg" />
                    &nbsp;التنس
                  </Link>
                  <BsChevronDown className={`fs-md ${handleToggleNestedLink("OtherSportsTennis") && "nested__subLink--opened"}`} onClick={() => handleClickedNestedLink("OtherSportsTennis")} />
                  {!!handleToggleNestedLink("OtherSportsTennis") && handleRenderSubNestedLinks("/othersports/tennis")}
                </li>
                <li className="">
                  <Link to={{ pathname: "/othersports/squash", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-3 ${isActive(pathname === "/othersports/squash")}`}>
                    <MdOutlineSportsCricket className="fs-lg" />&nbsp;الاسكواش
                  </Link>
                  <BsChevronDown className={`fs-md ${handleToggleNestedLink("OtherSportsSquash") && "nested__subLink--opened"}`} onClick={() => handleClickedNestedLink("OtherSportsSquash")} />
                  {!!handleToggleNestedLink("OtherSportsSquash") && handleRenderSubNestedLinks("/othersports/squash")}
                </li>
                <li className="border-bottom-0">
                  <Link to={{ pathname: "/othersports/handball", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-3 ${isActive(pathname === "/othersports/handball")}`}>
                    <MdSportsHandball className="fs-lg" />&nbsp;كرة اليد
                  </Link>
                  <BsChevronDown className={`fs-md ${handleToggleNestedLink("OtherSportsHandball") && "nested__subLink--opened"}`} onClick={() => handleClickedNestedLink("OtherSportsHandball")} />
                  {!!handleToggleNestedLink("OtherSportsHandball") && handleRenderSubNestedLinks("/othersports/handball")}
                </li>
              </ul>
              {
                TwistSettings['media-sections'] && <>
                  <li>
                    <a href={window.location.origin + "/meloukelkora"} onClick={handleClose} className='mx-3'><GiMorphBall className="fs-lg" />&nbsp;ملوك الكورة</a>
                  </li>
                  <li>
                    <Link to={{ pathname: "/sections/fun2fit", state: { previousPathname: pathname } }} onClick={handleClose} className='mx-3'><IoFitness className="fs-lg" />&nbsp;fun 2 fit</Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/sections/fl3aglaelsalama", state: { previousPathname: pathname } }} onClick={handleClose} >
                      <BsPlayBtn className="fs-lg" />
                      &nbsp;في العجلة السلامة</Link>
                  </li>
                  <li>
                    <div onClick={() => setTournamentShow(!tournamentShow)} className="dropdown">
                      <GiTrophyCup className="fs-lg" />
                      &nbsp;الطريق الي البطولة
                      {tournamentShow ? (
                        <RiArrowUpSLine className="float-left" />
                      ) : (
                        <RiArrowDownSLine className="float-left" />
                      )}
                    </div>
                  </li>
                </>
              }
              {
                TwistSettings["twist-fitness"]
                  ? <li>
                    {/* <Link to={{ pathname: "/fitness", state: { previousPathname: pathname } }} onClick={handleClose} className={`mx-3 ${isActive(pathname === "/fitness")}`} ><MdFitnessCenter className="fs-lg" />&nbsp;تويست فيتنس</Link> */}
                    <a href={window.location.origin + "/fitness"} onClick={handleClose} className='mx-3'><MdOutlineFitnessCenter className="fs-lg" />&nbsp;اتصالات فيتنس</a>
                  </li>
                  : <li>
                    <a href="http://fitness.etisalatsports.com/" onClick={handleClose} className='mx-3'><MdOutlineFitnessCenter className="fs-lg" />&nbsp;اتصالات فيتنس</a>
                  </li>
              }
              {tournamentShow && (
                <div className="dropdown">
                  <li >
                    <Link to={{ pathname: "/sections/road-to-championship/1", state: { previousPathname: pathname } }} onClick={handleClose}>
                      &nbsp;علي فرج ونور الطيب
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/sections/road-to-championship/2", state: { previousPathname: pathname } }} onClick={handleClose}>
                      &nbsp;على خلف الله
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/sections/road-to-championship/3", state: { previousPathname: pathname } }} onClick={handleClose}>
                      &nbsp;إيهاب عبد الرحمن
                    </Link>
                  </li>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div >
    </div >
  );
}

export default MobileSideMenu
