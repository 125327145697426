import SeasonsTypes from "./Seasons.Types";
import axios from 'axios'
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_SEASONS } = process.env

const getSeasonsStart = () => ({
  type: SeasonsTypes.GET_SEASONS_START
})

const getSeasonsSuccess = (info) => ({
  type: SeasonsTypes.GET_SEASONS_SUCCESS,
  payload: info
})
const getAppSeasonsSuccess = (info) => ({
  type: SeasonsTypes.GET_APP_SEASONS_SUCCESS,
  payload: info
})

const getSeasonsFailed = (error) => ({
  type: SeasonsTypes.GET_SEASONS_FAILED,
  payload: error
})

const setActiveSeason = (info) => ({
  type: SeasonsTypes.SET_ACTIVE_SEASON,
  payload: info
})


const getSeasons = (params) => (
  dispatch => {
    dispatch(getSeasonsStart())
    axios({
      method: "get",
      url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_SEASONS}`,
      params,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `ar`
      },
    }).then(resp => {
      if (resp.data.status === 200) {
        params.parent ?
          dispatch(getAppSeasonsSuccess(resp.data)) :
          dispatch(getSeasonsSuccess(resp.data));
      }
      else {
        dispatch(getSeasonsFailed(resp.data.message))
      }
    }
    )
      .catch(error => dispatch(getSeasonsFailed(error)))

  }
)

export { getSeasons, setActiveSeason }