import TournamentDetailsTypes from "./TournamentDetails.Types";

const INITIAL_STATE = {
  TournamentDetails:[],
  isFetchingTournamentDetails:true,
  TournamentDetailsFailed:null,
  activeTab:'overview'
 };
 
 const TournamentDetails = (state = INITIAL_STATE, action) => {
   switch (action.type) {
    case TournamentDetailsTypes.SET_TOURNAMENT_ACTIVE_TAB:
      return {
        ...state,
        activeTab:action.payload
      } 
    case TournamentDetailsTypes.GET_TOURNAMENT_DETAILS_START:
      return {
        ...state,
        isFetchingTournamentDetails:true
      }
    case TournamentDetailsTypes.GET_TOURNAMENT_DETAILS_SUCCESS:
      return {
        ...state,
        TournamentDetails:action.payload,
        isFetchingTournamentDetails:false,
        TournamentDetailsFailed:null
      }
    case TournamentDetailsTypes.GET_TOURNAMENT_DETAILS_FAILED:
      return {
        ...state,
        TournamentDetailsFailed:action.payload,
        isFetchingTournamentDetails:false
      }
     default:
       return state;
   }
 };

 export default TournamentDetails