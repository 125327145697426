import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getStanding } from "../../redux/Standing/Standing.Actions";
import { addH2Hteam } from '../../redux/Standing/Standing.Actions'
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom";
import { Container } from 'react-bootstrap';
import './TournamentStatistics.css'
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';
import { getServiceName, getSportRoute } from '../../Layout/helper';

const TourStatsDetails = ({
    Standing,
    standingFailed,
    isFetchingStanding,
    tournamentDetails,
    currentLanguageCode,
    getStanding,
    sport_id,
    season_id,
}) => {
    const id = useParams().id;

    useEffect(() => {
        getStanding(sport_id, season_id, id, currentLanguageCode)
    }, [sport_id, season_id, id, currentLanguageCode])

    const { t } = useTranslation()
    const history = useHistory();
    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/team/${id}`, { previousPathname: history.location.pathname });
    }

    return <Container>
        <div className='bg-black-color white-color fs-xs mt-3 row'>
            <table className="responsive-table tournament-statistics table-borderless bg-black-color table-striped table-dark white-color col-12">
                <thead>
                    <tr style={{ padding: '10px' }} className="text-center">
                        <th>المركز</th>
                        <th scope="col" style={{minWidth: '130px'}} className={window.document.body.dir === 'rtl' ? 'text-right' : 'text-left'}>الفريق</th>
                        <th scope="col" className='text-nowrap'>اهداف له</th>
                        <th scope="col" className='text-nowrap'>شباك نظيفه</th>
                        <th scope="col" className='text-nowrap'>محاولات على المرمى</th>
                        <th scope="col" className='text-nowrap'>المراوغات</th>
                        <th scope="col" className='text-nowrap'>تمريرات ناجحه</th>
                        <th scope="col" className='text-nowrap'>استعاد الكرة</th>
                        <th scope="col" className='text-nowrap'>خسر الكرة</th>
                        <th scope="col" className='text-nowrap'>اخطاء له</th>
                        <th scope="col" className='text-nowrap'>اخطاء عليه</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        standingFailed !== null ?
                            <tr>
                                <td colSpan={15} className='p-0'>
                                    <ErrorMsg msg="لا يوجد احصائيات" p={5} fs={'lg'} />
                                </td>
                            </tr>
                            :
                            isFetchingStanding ?
                                <tr>
                                    <td colSpan={15} className='p-0'>
                                        <LoadingSpinnner p={4} fs={'md'} />
                                    </td>
                                </tr>
                                :
                                Standing.length === 0 ?
                                    <tr>
                                        <td colSpan={15} className='p-0'>
                                            <ErrorMsg msg="لا يوجد احصائيات" p={5} fs={'lg'} />
                                        </td>
                                    </tr>
                                    :
                                    Standing.flat().map(
                                        (team, i) =>
                                            <tr key={team.id} onClick={() => handleRowClick(tournamentDetails.services?.[0], team.sport_id, team.team_id)} className='table-clickable-row text-center'>
                                                <td style={{ width: '25px' }}>{i + 1}</td>
                                                <td className={window.document.body.dir === 'rtl' ? 'text-right team-name' : 'text-left team-name'}><img src={ImgURL(team.team_logo)} style={{ width: '20px' }} alt="team logo" /><span className="mx-2 fs-xxs ">{team.team_name}</span></td>
                                                <td>{team.goals_in}</td>
                                                <td>{team.clean_sheet}</td>
                                                <td>{team.attempts}</td>
                                                <td>{team.dribbles}</td>
                                                <td>{team.passes}</td>
                                                <td>{team.ball_win}</td>
                                                <td>{team.ball_lose}</td>
                                                <td>{team.fouls_out}</td>
                                                <td>{team.fouls_in}</td>
                                            </tr>

                                    )
                    }

                </tbody>
            </table>
        </div>
    </Container>
}


const mapDispatchToProps = (dispatch) => ({
    getStanding: (sport_id, season_id, tournament_id, lang) => dispatch(getStanding(sport_id, season_id, tournament_id, lang)),
    addH2Hteam: (info) => dispatch(addH2Hteam(info))
});

const mapStateToProps = (state) => ({
    isFetchingStanding: state.standing.isFetchingStanding,
    standingFailed: state.standing.StandingFailed,
    Standing: state.standing.Standing,
    currentLanguageCode: state.language.currentLanguageCode,
    tournamentDetails: state.standing.TournamentDetails,
    H2HTeams: state.standing.H2HTeams,
    showModal: state.standing.showModal,
    activeSeason: state.seasons.activeSeason,
});

export default connect(mapStateToProps, mapDispatchToProps)(TourStatsDetails)
