import TopScorersTypes from "./topScorers.Types";
import axios from 'axios'
import { globalBaseUrl } from "../../globalData";

const { REACT_APP_NEW_GET_TOP_SCORERS } = process.env

const getTopScorersStart = () => ({
  type: TopScorersTypes.GET_TOP_SCORERS_START
})

const getTopScorersSuccess = (info) => ({
  type: TopScorersTypes.GET_TOP_SCORERS_SUCCESS,
  payload: info
})

const getTopScorersFailed = (error) => ({
  type: TopScorersTypes.GET_TOP_SCORERS_FAILED,
  payload: error
})


const getTopScorers = (sport_id, season_id, tournament_id, team_id, lang) => (
  dispatch => {
    dispatch(getTopScorersStart())
    axios({
      method: "get",
      url: `${globalBaseUrl.current}${REACT_APP_NEW_GET_TOP_SCORERS}`,
      params: { sport_id, season_id, tournament_id, team_id },
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": `${lang}`
      },
    }).then(resp => {
      if (resp.data.status === 200) {
        dispatch(getTopScorersSuccess(resp.data.data))
      }
      else {
        dispatch(getTopScorersFailed(resp.data.message))
      }
    }
    )
      .catch(error => dispatch(getTopScorersFailed(error)))

  }
)

export { getTopScorers }