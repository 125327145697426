import SquashMatchCalenderTypes from "./SquashMatchCalender.Types";



const INITIAL_STATE = {
  matchCalender: [''],
  isFetchingMatchCalender: null,
  matchCalenderFailed: null
};

const SquashMatchCalender = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SquashMatchCalenderTypes.GET_SQUASH_MATCH_CALENDER_START:
      return {
        ...state,
        isFetchingMatchCalender: true
      }
    case SquashMatchCalenderTypes.GET_SQUASH_MATCH_CALENDER_SUCCESS:
      return {
        ...state,
        matchCalender: action.payload,
        isFetchingMatchCalender: false,
        matchCalenderFailed: null
      }
    case SquashMatchCalenderTypes.GET_SQUASH_MATCH_CALENDER_FAILED:
      return {
        ...state,
        matchCalenderFailed: action.payload,
        isFetchingMatchCalender: false
      }
    default:
      return state;
  }
};

export default SquashMatchCalender