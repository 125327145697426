import React, { useState, useEffect } from "react";
import "./newsSection.css";
import MediaList from "./MediaList";
import api from "../../apis";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import MediaCard from "./MediaCard";
import SampleArrowBtn from "../SampleArrowBtn/SampleArrowBtn";

function AllMedia({
  sport_id,
  service_id,
  season_id,
  items_count,
  type,
  tournament_id,
  media_id,
  section_id,
  team_id,
  player_id,
  top_stories,
  latest,
  popular,
  slider
}) {

  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [allMedia, setAllMedia] = useState([]);
  const [pages, setPages] = useState({ currentPage: 1, lastPage: null });

  const controller = new AbortController()
  const getByPage = (page) => {
    callMediaApi(page);
  };

  const callMediaApi = (page) => {
    if (!page) setIsFetchLoading(true);

    setIsPaginationLoading(true);
    api.mediaList
      .getMediaList({
        sport_id,
        service_id,
        season_id,
        items_count,
        type,
        tournament_id,
        media_id,
        section_id,
        team_id,
        player_id,
        top_stories,
        latest,
        popular,
        page,
      }, controller.signal)

      .then((res) => {
        if (res !== "canceled") {
          if (page) {
            setAllMedia((prev) => [...prev, ...res?.data?.data]);
          } else {
            setAllMedia(res?.data?.data);
          }
          setPages((prev) => ({ ...prev, lastPage: res?.data?.last_page }));
          setPages((prev) => ({ ...prev, currentPage: res?.data?.current_page }));
          setIsFetchLoading(false);
          setIsPaginationLoading(false);
        }
      });
  };

  useEffect(() => {

    if (service_id === null) return;
    callMediaApi();

    return () => {
      return controller.abort()
    }
  }, [tournament_id, media_id, team_id, player_id, sport_id, service_id]);

  const settings = {
    infinite: true,
    draggable: false,
    touchMove: false,
    speed: 900,
    slidesToShow: 3.8,
    slidesToScroll: 1,
    rtl: true,
    nextArrow: <SampleArrowBtn arrowDirection="left"
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        borderRadius: "6px",
        border: "unset",
        position: "absolute",
        top: "0px",
        height: "100%",
        right: "-12px",
        zIndex: "15",
        width: "30px",
        opacity: "1",
        color: "white"
      }}
    />,
    prevArrow: <SampleArrowBtn
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        borderRadius: "6px",
        border: "unset",
        position: "absolute",
        top: "0px",
        height: "100%",
        left: "-12px",
        zIndex: "15",
        width: "30px",
        opacity: "1",
        color: "white"
      }}
    />,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (slider) {
    return <Container className="news-section">
      <Slider {...settings}>
        {
          isFetchLoading ?
            <div>...loading</div>
            :
            allMedia?.length === 0 ?
              <div className='my-5 text-light'>لا يوجد اخبار</div>
              :
              allMedia?.map(news =>
                <div className="ml-auto mr-auto" key={news.id}>
                  <MediaCard news={news} type={1} />
                </div>)

        }
      </Slider>
    </Container>
  } else {
    return (
      <MediaList
        initialLoading={isFetchLoading}
        paginationLoading={isPaginationLoading}
        current_page={pages.currentPage}
        last_page={pages.lastPage}
        AllMedia={allMedia}
        getByPage={getByPage}
        media_type={type}
      />
    );
  }
}

export default AllMedia;