import CalendarTypes from "./Calendar.Types";



const INITIAL_STATE = {
    Calendar:[''],
    isFetchingCalendar:true,
    CalendarFailed:null
   };
   
   const Calendar = (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case CalendarTypes.GET_CALENDAR_START:
        return {
          ...state,
          isFetchingCalendar:true
        }
      case CalendarTypes.GET_CALENDAR_SUCCESS:
        return {
          ...state,
          Calendar:action.payload,
          isFetchingCalendar:false,
          CalendarFailed:null
        }
      case CalendarTypes.GET_CALENDAR_FAILED:
        return {
          ...state,
          CalendarFailed:action.payload,
          isFetchingCalendar:false
        }
       default:
         return state;
     }
   };
 
   export default Calendar