import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import './PlayerPosition.css'
import playerPosImg from '../../assets/playerPos.png';

const PositionField = ({ playerPosition, positionAcronym }) => {
    const positions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
    const { t } = useTranslation()

    const positionMap={
        // حارس مرمي
        GK:16,
        CB:14,
        // مدافع
        DR:14,
        DC:14,
        DM:14,
        DL:14,
        // BACK LEFT
        LB:13,
        // BACK RIGHT
        RB:15,
        // جناح يمين
        RW:6,
        // جناح يسار
        LW:4,
        // مهاجم
        ST:2,
        SS:2,
        CF:2,
        // خط الوسط
        LMF:10,
        RMF:12,
        DMF:11,
        CMF:8,
        MC:10,
        MR:10,
        ML:10,
        AM:10,
        OMF:10,
        AMF:5,
        
    }

    return (
        <div className='bg-black-color pb-4 text-light mt-3 mt-md-0'>
            <div className='bg-light-black-color white-color p-3'>المركز الاساسي</div>
            <Row>
                <Col xs={4} className='text-center d-flex flex-column align-items-center justify-content-center'>
                    <div className='fs-sm white-color'>المركز الاساسي:</div>
                    <div className='fs-md'>{playerPosition}</div>
                </Col>
                <Col xs={6}>
                    <div className='player-details-position-field'>
                        {
                            <Row className='d-flex justify-content-around mt-3' style={{ height: '90%' }}>
                                {
                                    positions.map((pos, index) => positionMap[positionAcronym] === pos
                                        ? <div className='col-4' style={{ height: '15%' }} key={index} >
                                            <img src={playerPosImg} alt="player-position" />
                                        </div>
                                        : <div className='col-4' style={{ height: '15%' }} key={index} />)
                                }
                            </Row>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default PositionField