import HttpHelper from "./helper";

export const fanClubEndpoints = {
    getFavTeams: (params, signal) =>
        HttpHelper.baseAxios
            .get(`/my_favourites`, { params, signal })
            .then((res) => res?.data)
            .catch((err) => err?.response?.data),

    postFavTeams: (inputData) =>
        HttpHelper.baseAxios
            .post(`/favourite`, inputData)
            .then((res) => res?.data)
            .catch((err) => err?.response?.data),
};