import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getTeamStatistics } from '../../redux/TeamStatistics/TeamStatistics.Actions';
import { Row, Col, Container } from 'reactstrap';
import { useTranslation } from "react-i18next"
import TeamStatChart from './TeamStatChart';
import TeamOverviewStats from './TeamOverviewStats';
import TopScorers from '../topScorers/topScorers'
import TopAssists from '../topAssists/topAssists'
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import Comparison from '../Comparison/Comparison'
import SeasonsDropdown from '../SeasonsDropdown/SeasonsDropdown';
import TwistSettings from '../../TwistSettings.json'

const TeamStatsDetail = ({
    TeamStatistics,
    TeamDetails,
    isFetchingTeamStatistics,
    isFetchingTeamDetails,
    TeamStatisticsFailed,
    TeamDetailsFailed,
    sport_id,
    tournament_id,
    team_id,
    currentLanguageCode,
    getTeamStatistics,
    toLocalTab,
    activeSeason,
}) => {
    const { t } = useTranslation()

    useEffect(() => {
        getTeamStatistics(sport_id, activeSeason?.id, tournament_id, team_id, currentLanguageCode)
    }, [sport_id, activeSeason?.id, tournament_id, team_id, currentLanguageCode])

    return (
        <Container>
            <SeasonsDropdown tournament_id={tournament_id} />
            <Row>
                <Col xs={12} md={4}>
                    {
                        TeamStatisticsFailed !== null ?
                            <ErrorMsg p={5} fs={'md'} msg={'Error.StatisticsError'} />
                            :
                            isFetchingTeamStatistics ?
                                <LoadingSpinnner p={5} fs={'md'} />
                                :
                                <div>
                                    <TeamStatChart TeamStatistics={TeamStatistics} />
                                    <div className='bg-black-color mt-3 fs-sm  p-4 d-flex justify-content-around text-light'>
                                        <div>
                                            <span className='mx-2'>{TeamStatistics.red_cards}</span>
                                            <span style={{ backgroundColor: 'red', padding: '7px', color: 'transparent' }}>1</span>
                                            <span className="fs-sm">كروت حمراء</span>
                                        </div>
                                        <div>
                                            <span className='mx-2'>{TeamStatistics.yellow_cards}</span>
                                            <span style={{ backgroundColor: 'yellow', padding: '7px', color: 'transparent' }}>1</span>
                                            <span className="fs-sm">كروت صفراء</span>
                                        </div>
                                    </div>
                                </div>
                    }
                    {TwistSettings["comparison"] &&
                        <div className="col-12 ml-auto mr-auto  mt-4 mx-md-0 text-center  fs-xs p-3" style={{ height: '300px' }}>
                            <Comparison />
                        </div>
                    }
                </Col>
                <Col xs={12} md={4}>
                    <TeamOverviewStats sport_id={1} season_id={activeSeason?.id} tournament_id={1} team_id={team_id} toLocalTab={toLocalTab} />
                    <div className='bg-black-color white-color mt-3 p-3'>
                        <div className='p-1'>دقائق التهديف</div>
                        {
                            TeamStatisticsFailed !== null ?
                                <ErrorMsg p={5} fs={'md'} msg={'Error.StatisticsError'} />
                                :
                                isFetchingTeamStatistics ?
                                    <LoadingSpinnner p={5} fs={'md'} />
                                    :
                                    <div className='row '>
                                        <div className='col-3'>
                                            {
                                                Object.keys(TeamStatistics.scoringIntervals).map((el, i) =>
                                                    <div key={el + i} className='mt-1 fs-sm p-1'>
                                                        {i * 15} - {el}'
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className='col-9'>
                                            {
                                                Object.values(TeamStatistics.scoringIntervals).map((el, i) =>
                                                    <div key={el + i} style={{ width: `${el}%`, backgroundColor: 'var(--red)' }} className='mt-2 p-1 fs-xs text-light'>
                                                        <strong>{Math.round((TeamStatistics.goals_in * el) / 100)}</strong>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                        }
                    </div>

                </Col>
                <Col xs={12} md={4}>
                    {
                        isFetchingTeamDetails || TeamDetailsFailed !== null ?
                            <div>
                                <div className="p-2 fs-md bg-black-color white-color ">هداف الفريق</div>
                                <LoadingSpinnner p={5} fs={'sm'} />
                            </div>
                            :
                            <TopScorers sport_id={1} season_id={activeSeason?.id} tournament_id={TeamDetails.main_tournament !== null ? TeamDetails.main_tournament.id : ''} team_id={team_id} />
                    }

                    {
                        isFetchingTeamDetails || TeamDetailsFailed !== null ?
                            <div className='mt-2'>
                                <div className='bg-black-color white-color p-2'>{"الترتيب"}</div>
                                <LoadingSpinnner p={5} fs={'sm'} />
                            </div>
                            :
                            <TopAssists sport_id={1} season_id={activeSeason?.id} tournament_id={TeamDetails.main_tournament !== null ? TeamDetails.main_tournament.id : ''} team_id={team_id} />
                    }
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = state => ({
    TeamDetailsFailed: state.teamDetails.TeamDetailsFailed,
    TeamDetails: state.teamDetails.TeamDetails,
    isFetchingTeamDetails: state.teamDetails.isFetchingTeamDetails,
    TeamStatistics: state.teamStatistics.TeamStatistics,
    isFetchingTeamStatistics: state.teamStatistics.isFetchingTeamStatistics,
    TeamStatisticsFailed: state.teamStatistics.TeamStatisticsFailed,
    currentLanguageCode: state.language.currentLanguageCode,
    activeSeason: state.seasons.activeSeason,
})

const mapDispatchToProps = dispatch => ({
    getTeamStatistics: (sport_id, season_id, tournament_id, team_id, lang) => dispatch(getTeamStatistics(sport_id, season_id, tournament_id, team_id, lang))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamStatsDetail)