import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import PlayerInfoBar from './PlayerInfoBar';
import { Container, Row, Col } from 'react-bootstrap';
import PlayerMatches from './PlayerMatches';
import PositionField from './PlayerPosition';
import TransferHistory from './TransferHistory';
import PlayerPrizes from '../PlayerPrizes/PlayerPrizes';
import PlayerOverviewLoader from './playerOverviewLoader';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import useServiceAndSport from '../../hooks/useServiceAndSport';


const PlayerOverview = ({ playerDetails, isFetchingPlayerDetails, PlayerDetailsFailed }) => {
  const { t } = useTranslation()
  const { sportName } = useServiceAndSport();


  return (
    PlayerDetailsFailed !== null ?
      <Container>
        <ErrorMsg p={5} fs={'lg'} msg="لايوجد معلومات" />
      </Container>
      :
      isFetchingPlayerDetails ?
        <Container>
          <PlayerOverviewLoader />
        </Container>
        :
        <Container>
          <PlayerInfoBar playerDetails={playerDetails} />
          {
            sportName !== "squash" &&
            <PlayerMatches sport_id={1} team_id={playerDetails.current_team?.id} />
          }
          {
            sportName !== "squash" &&
            <Row className='mt-3'>
              <Col xs={12} md={7}>
                <TransferHistory />
              </Col>
              <Col xs={12} md={5}>
                <PositionField playerPosition={playerDetails.position} positionAcronym={playerDetails.position_acronym} />
              </Col>
            </Row>
          }

        </Container>

  )
}

export default PlayerOverview

