import StandingTypes from "./Standing.Types";



const INITIAL_STATE = {
  Standing: [''],
  TournamentDetails: {},
  H2HTeams: [],
  isFetchingStanding: true,
  StandingFailed: null,
  showModal: false,
  competitionType: "",
  groups: []
};

const Standing = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StandingTypes.GET_STANDING_START:
      return {
        ...state,
        isFetchingStanding: true
      }
    case StandingTypes.GET_STANDING_SUCCESS:
      const competitionType = action.payload.data[0].competition_type.name;
      const isCup = competitionType === "cup";
      const Standing = isCup ?
        Object.values(action.payload.data.data["Group Stage"]) :
        action.payload.data[0].data.map(el => ({ ...el, checked: false }));
      return {
        ...state,
        Standing,
        TournamentDetails: action.payload.data.tournament,
        isFetchingStanding: false,
        StandingFailed: null,
        competitionType,
        groups: isCup ? Object.keys(action.payload.data.data["Group Stage"]) : [],
      }
    case StandingTypes.ADD_H2H_TEAM:
      const isChecked = !action.payload.checked;
      const teamIndex = [...state.H2HTeams].map(team => team.id).indexOf(action.payload.id);
      const H2HTeams = (isChecked && teamIndex === -1) ? [...state.H2HTeams, { ...action.payload, checked: isChecked }] : [...state.H2HTeams].filter((_, index) => index !== teamIndex);
      return {
        ...state,
        H2HTeams,
        Standing: state.Standing.map(el => el.id === action.payload.id ? { ...action.payload, checked: isChecked } : el),
        showModal: H2HTeams.length === 4 ? true : false,
      }
    case StandingTypes.CLOSE_H2H_MODAL:
      return {
        ...state,
        H2HTeams: [],
        Standing: state.Standing.map(el => ({ ...el, checked: false })),
        showModal: false
      }
    case StandingTypes.OPEN_H2H_MODAL:
      return {
        ...state,
        showModal: true
      }
    case StandingTypes.GET_STANDING_FAILED:
      return {
        ...state,
        StandingFailed: action.payload,
        isFetchingStanding: false
      }
    default:
      return state;
  }
};

export default Standing